import React from 'react';
import { reduxForm, getFormValues, formValueSelector } from 'redux-form';
import { Button, Header, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import axios from 'axios';
import { CheckoutModal, PRICE_TYPE } from './CheckoutContainer';


class PaymentInfo extends React.Component {

  state = {
    clientToken: null,
    showBuyButton: false,
    buyButtonColor: 'grey',
    planType: '',
    errors: '',
    plan: '',
    stripePublishableKey: null,
    // productList: [],
    showCheckoutModal: false
  }

  generateLocalStorageData = () => {

    const data = {      
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      email: this.props.email,
      password: this.props.password,
      password2: this.props.password2,    
      country: this.props.country,
      companyName: this.props.companyName,
      addressOne: this.props.addressOne,
      addressTwo: this.props.addressTwo,
      addressThree: this.props.addressThree,
      addressFour: this.props.addressFour,
      postZip: this.props.postZip,      
      planType: this.props.planType.key
    }
    // Storing data in localStorage
    localStorage.setItem("data", JSON.stringify(data) );
  }

  async componentDidMount() {
    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // let foo = params.get('type');

    // if (foo === 'plus') {
    //   this.setState({ plan: ' Plus' })
    // }

    console.log("this.props ", this.props)
    
    // Get settings
    let response = await axios.get(`/api/account/settings`)
    this.setState({stripePublishableKey: response.data.publishableKey})

    // // Get products
    // response = await axios.get('/api/users/v2/product/')
    // this.setState({ productList: response.data })

    // Store just in case
    this.generateLocalStorageData()
  }

  confirmFree = (e) => {
    e.preventDefault();
    this.props.handleSubmit()
  }

  onShowCheckoutModal = () => {
    this.setState({ showCheckoutModal: true})
  }

  render() {
    const errors = this.state.errors;
    const { previousPage, planType, country, promocode } = this.props;
    
    console.log("planType ", planType)
    console.log("promocode ", promocode)

    let priceAfterTaxes = null
    let price = null
    if( planType ){  
          
      priceAfterTaxes = country == 'GB'?planType.pricePlusVAT:planType.price;
      priceAfterTaxes = promocode?((100-promocode.percent_off)/100)*priceAfterTaxes:priceAfterTaxes
      price = promocode?((100-promocode.percent_off)/100)*planType.price:planType.price
    }
      
    if (this.props.planType === 'unlimited') {
      // return (
      //   <div>
      //     {/* <FetchValues /> */}
      //     <div style={{ marginBottom: "30px" }}>
      //       <Header>You are about to subscribe to MagStand {planType.name} with<br />
      //         {planType.name} Publication{planType !== 1 ? 's' : ''} @ £{planType.price}/month {country === "GB" ? "+ VAT" : ""}
      //       </Header>
      //     </div>

      //     <Button type="button" color="green" onClick={this.confirmFree}>Confirm</Button>
      //   </div>
      // )
    } else {
      return (
        <div>
          <div style={{ marginBottom: "30px" }}>
            <Header>You are about to subscribe to MagStandwith<br />
              {planType.name} @ £{price}/month {country === "GB" ? "+ VAT" : ""}
            </Header>
          </div>

          <CheckoutModal 
            open={this.state.showCheckoutModal} 
            onClose={() => this.setState({ showCheckoutModal: false}) }
            email={this.props.email}
            username={this.props.username}
            planType={this.props.planType}
            price={priceAfterTaxes} 
            price_type={this.props.country=="GB"?PRICE_TYPE.VAT:PRICE_TYPE.TAXFREE} 
            promocode={this.props.promocode} />

          {errors && <Message negative><Message.Header>{errors}</Message.Header></Message>}
          <div style={{ marginTop: "20px" }}>
            <Button type='button' color='blue' onClick={previousPage}>Previous</Button>
            <Button type='button' color='blue' onClick={() => this.onShowCheckoutModal()}>Pay</Button>
          </div>
        </div>
      )
    }
  }

}
//}

const validate = (formValues) => {
  const errors = {};

  // if (!formValues.email) {
  //   errors.email = "You must enter an email address";
  // }

  return errors;
}

const formWrapped = reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(PaymentInfo)

const selector = formValueSelector('wizard')
export default connect(state => {
  const values = getFormValues('wizard')(state);
  return { ...values }
  // const { firstName, lastName, email, 
  //     companyName, price, country, planType } = selector(state, 'planType', 'firstName', 'lastName', 'companyName', 'price', 'country', 'email')
  // return { planType, firstName, lastName, companyName, price, country, email }
})(formWrapped);
