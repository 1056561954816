import React, { Component } from 'react'
import axios from 'axios';

export default class SecureTrading extends Component {
  state = {
    message: ""
  }
  downloadAllData = () => {
    axios
      .post(`/api/order/testst`)
      .then(res => {
        console.log(res.data)
        // this.setState({ message: res.data })
      })
      .catch(err => console.log(err))
  }
  render() {
    return (
      <div>
        <button onClick={this.downloadAllData()}>Click</button>
        {this.state.message}
      </div>
    )
  }
}
