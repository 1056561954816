import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Grid, Segment, Button, Modal } from "semantic-ui-react";
import { getPublications } from "../../../actions/publicationActions";
import { getAllData } from "../../../actions/readerActions";
import DataLayout from "./DataLayout";
import axios from "axios";
import Spinner from "../../common/Spinner";
import { CSVLink } from "react-csv";

class ChoosePub extends Component {
  state = {
    pubId: null,
    publicationOptions: [],
    publications: [],
    allData: [],    
    showAllModal: false,
    showUploadedModal: false,
  };
  componentDidMount() {
    if (this.props.auth.user.userType === "pubadmin") {
      this.props.getPublications(this.props.auth.user.parentPublisher);
    } else {
      this.props.getPublications(this.props.auth.user.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.publication.publications) {
      if (nextProps.publication.publications.length > 0) {
        if (nextProps.publication.publications !== this.state.publications) {
          let publicationOptions = [];
          nextProps.publication.publications.map((publication) => {
            publicationOptions.push({
              key: publication._id,
              text: publication.publicationName,
              value: publication._id,
            });
          });
          this.setState({
            publicationOptions: publicationOptions,
            publications: nextProps.publication.publications,
            pubId: publicationOptions[0].value,
          });
        }
      }
    }
  }

  handleChange = (e, { value }) => {
    this.setState({ pubId: value });
  };

  onAllDataHandler = (e) => {
    e.preventDefault();
    this.props.getAllData(this.state.pubId);
  };

  downloadData = (id, options = { target: ''}) => {
    this.setState({ showUploadedModal: true });
    setTimeout(() => {
      axios
        .get(`/api/subscriber/all/${id}?target=${options.target}`)
        .then((res) => {
          if (res.data.length > 0) {
            console.log("res.data ", res.data, res.data.length)
            this.setState({ allData: res.data });
          }
        })
        .then((res) => {
          let btn = this.refs.all;
          btn.link.click();
          this.setState({ allData: [] });
        })
        .catch((err) => console.log(err));
    }, 100);
  }

  doneDownloadingAll = () => {
    this.setState({ showAllModal: false, showUploadedModal: false });
  };

  generateFilename = () => {
    if( this.state.pubId == null) return "unknown.csv"
    const today = new Date();
    const month = today.getMonth() + 1;
    const publication = this.state.publicationOptions.find( item => item.key == this.state.pubId)
    return `${publication.text}_${today.getDate()}${month}${today.getFullYear()}.csv`;
  }

  render() {
    const { publications } = this.props.publication;
    let publicationItems;
    

    let displayModal = null;
    if (this.state.showAllModal || this.state.showUploadedModal) {
      let header = ""
      if( this.state.showAllModal ) header = "Downloading Entire Database"
      if( this.state.showUploadedModal ) header = "Downloading Uploded User Database"
      displayModal = (
        <Modal
          dimmer={true}
          open={true}
          closeOnDimmerClick={false}
          onClose={this.close}
        >
          <Modal.Header>{header}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Your download will appear in your browser downloads
              <Spinner />
            </Modal.Description>
          </Modal.Content>
          <CSVLink
              ref="all"
              style={{ display: "none" }}
              filename={ this.generateFilename() }
              asyncOnClick={true}
              data={this.state.allData}
              onClick={(event, done) => {
                done(this.doneDownloadingAll());
              }}
            />
        </Modal>
      );
    }

    if (publications !== null) {
      if (publications.length === 0) {
        publicationItems = (
          <Segment>
            <h4>No Publications found for this User</h4>
            <p>
              To add one, please go to the Publications menu option and click
              the Blue circle to the right
            </p>
          </Segment>
        );
      } else {
        if (publications.length > 0) {
          if (this.state.publicationOptions.length > 0) {
            publicationItems = (
              <Segment>
                <Grid>
                  <Grid.Column mobile={16} tablet={8} computer={3}>
                    <Form.Select
                      options={this.state.publicationOptions}
                      onChange={this.handleChange}
                      placeholder="Publication"
                      fluid
                      size="large"
                      selection
                      name="pubId"
                      value={this.state.pubId}
                    />
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={13}>

                    <Grid>
                      <Grid.Column mobile={16} tablet={16} computer={6} textAlign="left">
                        <Button onClick={this.onAllDataHandler} fluid color="blue" 
                          style={{ height: '100%'}}>
                          View All Reader Data
                        </Button>
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={16} computer={5}>
                        <Link to={`/uploadreplacement/${this.state.pubId}?target=non-magstand`}>
                          <Button color="blue" fluid  style={{ height: '100%'}}>
                            Replace Imported Data
                          </Button>
                        </Link>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={16} computer={5} textAlign="right">
                          <Button fluid color="blue" style={{ height: '100%'}} 
                            onClick={() => this.downloadData(this.state.pubId, {target: 'non-magstand'})}>
                            Download Imported Data
                          </Button>
                      </Grid.Column>
                    </Grid>
                    <Grid>
                      <Grid.Column mobile={16} tablet={16} computer={6} textAlign="right">
                        <Link to={`/uploadaddandcombine/${this.state.pubId}`}>
                          <Button color="blue" fluid style={{ height: '100%'}}>
                            Add & Update Records
                          </Button>
                        </Link>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={16} computer={5} textAlign="left">
                        <Link to={`/uploadreplacement/${this.state.pubId}`}>
                          <Button color="blue" fluid  style={{ height: '100%'}}>
                            Replace ALL Data
                          </Button>
                        </Link>
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={16} computer={5}>
                        <Button color="blue" fluid  style={{ height: '100%'}}
                          onClick={() => this.downloadData(this.state.pubId)}>
                          Download ALL Data
                        </Button>
                      </Grid.Column>

                    </Grid>
                  </Grid.Column>
                </Grid>
              </Segment>
            );
          }
        }
      }
    }

    return (
      <React.Fragment>
        {publicationItems}
        {displayModal}
        <DataLayout pubid={this.state.pubId} />
      </React.Fragment>
    );
  }
}

ChoosePub.propTypes = {
  getPublications: PropTypes.func.isRequired,
  publication: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  publication: state.publication,
  auth: state.auth,
});

export default connect(mapStateToProps, { getPublications, getAllData })(
  ChoosePub
);
