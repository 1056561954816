// Renders a Normal Form Input Field with the label to the left and an asterisk to the right

import React from 'react';
import { Input, Label, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const RenderFieldLeftAndRequired = ({
  onChange,
  value,
  name,
  error,
  label,
  disabled,
  type }) => (
    <div>
      <div style={{ padding: "10px" }}>
        <Input
          fluid
          size='large'
          value={value}
          name={name}
          onChange={onChange}
          labelPosition='right corner'
          type={type}
          disabled={disabled}
          error={error ? true : false}>
          <Label color="blue" inverted="true">{label}</Label>
          <input />
          <Label color="blue" inverted="true" corner='right'><Icon name='asterisk' size='small' /></Label>
        </Input>
        {error && <div className="ui pointing red basic label">{error}</div>}
      </div>
    </div>
  )

RenderFieldLeftAndRequired.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  label: PropTypes.string,
};

RenderFieldLeftAndRequired.defaultProps = {
  type: 'text'
};

export default RenderFieldLeftAndRequired;