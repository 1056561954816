// Checks to see if the user is logged in on ComponentWillReceiveProps

import jwt_decode from 'jwt-decode';
import store from '../../store';

// Redux Actions
import { logoutUser } from '../../actions/authActions';

export const isUserLoggedIn = () => {
  const decoded = jwt_decode(localStorage.jwtToken);
  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = '/login';
  }
}
