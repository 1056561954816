// Renders a Normal Form Input Field with the Label Above

import React from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const RenderFieldLabelAbove = ({
  onChange,
  value,
  name,
  error,
  label,
  type,
  readOnly,
  disabled }) => (
    <div>
      <div style={{ padding: "10px" }}>
        <Form.Input
          fluid
          size='large'
          label={label}
          type={type}
          value={value}
          name={name}
          onChange={onChange}
          readOnly={readOnly ? true : false}
          disabled={disabled ? true : false}
          error={error ? true : false} />
        {error && <div className="ui pointing red basic label">{error}</div>}
      </div>
    </div>
  )

RenderFieldLabelAbove.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  label: PropTypes.string,
};

RenderFieldLabelAbove.defaultProps = {
  type: 'text'
};

export default RenderFieldLabelAbove;