import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import PublicationItem from './components/PublicationItem';
import { CardGroup } from 'semantic-ui-react';
import { getPublicationsReaders, getPublications } from '../../actions/publicationActions';
import { isUserLoggedIn } from '../common/isUserLoggedIn';

class Readers extends Component {
  componentDidMount() {
    if (this.props.auth.user.userType === "publisher") {
      this.props.getPublications(this.props.auth.user.id);
    } else {
      this.props.getPublications(this.props.auth.user.parentPublisher);
    }
  }

  componentWillReceiveProps() {
    isUserLoggedIn();
  }

  render() {
    const { publications, loading } = this.props.publication;
    let publicationItems;

    if (loading) {
      publicationItems = <Spinner />;
    } else {
      if (publications !== null) {
        if (publications.length === 0) {
          publicationItems = (
            <div style={{ margin: "10px" }}>
              <h4>No Publications found for this User</h4>
              <p>To add one, please go to the Publications menu option and click the Blue circle to the right</p>
            </div>
          )
        } else {
          if (publications.length > 0) {
            publicationItems = publications.map(publication => (
              <PublicationItem key={publication._id} publication={publication} userType={this.props.auth.user.userType} />
            ));
          }
        }
      }
    }
    return (
      <React.Fragment>
        <CardGroup>
          {publicationItems}
        </CardGroup>
      </React.Fragment>
    )
  }
}

Readers.propTypes = {
  getPublicationsReaders: PropTypes.func.isRequired,
  getPublications: PropTypes.func.isRequired,
  publication: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  publication: state.publication,
  auth: state.auth
});

export default connect(mapStateToProps, { getPublicationsReaders, getPublications })(Readers);