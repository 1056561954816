// Renders a normal form Input field with label to the right

import React from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const RenderFieldWithRightLabel = ({
  label,
  type,
  onChange,
  value,
  name,
  error }) => {
  return (
    <div style={{ padding: "10px" }}>
      <Input
        fluid
        size='large'
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        label={label}
        labelPosition='right'
        error={error ? true : false}
      />
      {error && <div className="ui pointing red basic label">{error}</div>}
    </div>
  )
}

RenderFieldWithRightLabel.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

RenderFieldWithRightLabel.defaultProps = {
  type: 'text'
};

export default RenderFieldWithRightLabel;