// Main Logged In Layout

import React, { Component } from 'react'
import SideMenu from "./components/SideMenu";
import TopMenu from "./components/TopMenu";
import './Layout.css';

class LoggedInLayout extends Component {
  state = {
    smallMenu: false
  };

  toggleSideMenu = () => {
    this.setState({ smallMenu: !this.state.smallMenu })
  }

  render() {
    return (
      <div className="grid1">
        <div className="menu1">
          <TopMenu toggleSideMenu={this.toggleSideMenu} />
        </div>
        <div className="main-content1" style={{ background: "white" }}>
          <SideMenu smallMenu={this.state.smallMenu}>
            {this.props.children}
          </SideMenu>
        </div>
      </div>
    )
  }
}

export default LoggedInLayout;