

import React, { Component, Fragment, useState, useEffect } from 'react';
import { Segment, Form, Button, Header, Grid, Label, Checkbox } from 'semantic-ui-react';


const PriceLabel = (props) => {
  return <Label
          color={props.color}
          ribbon
          style={{
            paddingLeft: '5px',
            paddingRight: '5px',
            marginRight: '-2em',
          }}
        >
          {props.children}
        </Label>
}


export const PriceSet = (props) => {
  const [priceOneYear, setPriceOneYear ] = useState(false);
  const [priceSixMonths, setPriceSixMonths ] = useState(false);
  const [priceThreeMonths, setPriceThreeMonths ] = useState(false);

  useEffect( () => {
    if( props.valueDefault == null ) return;
    setPriceOneYear(props.valueDefault.priceOneYear)
    setPriceSixMonths(props.valueDefault.priceSixMonths)
    setPriceThreeMonths(props.valueDefault.priceThreeMonths)
  }, [props.valueDefault])

  return <>    
          <Form.Group widths='equal'>
            <PriceLabel color={props.color}>Sub price -<br />one year</PriceLabel>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Checkbox toggle
                  onClick={() => {console.log("clicking"); setPriceOneYear(!priceOneYear);props.onClick('priceOneYear', !priceOneYear)}}
                  checked={priceOneYear}
                />
            </div>
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <PriceLabel color={props.color}>Sub price -<br />6 months</PriceLabel>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Checkbox toggle
                  onClick={() => {setPriceSixMonths(!priceSixMonths);props.onClick('priceSixMonths', !priceSixMonths)}}
                  checked={priceSixMonths}
                />
            </div>
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <PriceLabel color={props.color}>Sub price -<br />3 months</PriceLabel>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Checkbox toggle
                  onClick={() => {setPriceThreeMonths(!priceThreeMonths);props.onClick('priceThreeMonths', !priceThreeMonths)}}
                  checked={priceThreeMonths}
                />
            </div>
          </Form.Group>
          </>
}