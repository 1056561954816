import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import usersReducer from "./usersReducer";
import accountReducer from "./accountReducer";
import publicationReducer from "./publicationReducer";
import readerReducer from "./readerReducer";
import orderReducer from "./orderReducer";
import analyticReducer from "./analyticReducer";
import backIssueReducer from "./backIssueReducer";
import { reducer as toastrReducer } from "react-redux-toastr";

export default combineReducers({
  auth: authReducer,
  users: usersReducer,
  account: accountReducer,
  publication: publicationReducer,
  reader: readerReducer,
  order: orderReducer,
  errors: errorReducer,
  analytics: analyticReducer,
  backIssue: backIssueReducer,
  form: formReducer,
  toastr: toastrReducer,
});
