// Renders an inline Redux Form Input Field

import React from 'react';
import { Input } from 'semantic-ui-react';

const RenderInlineField = ({
  input,
  type,
  icon,
  label,
  meta: { touched, error }
}) => {
  return (
    <div className="field">
      <label>{label}</label>
      <Input
        fluid
        {...input}
        size='small'
        icon={icon}
        iconPosition='left'
        type={type}
        error={touched && error ? true : false} />
      {touched && error && <div className="ui pointing red basic label">{error}</div>}
    </div>
  )
}

RenderInlineField.defaultProps = {
  type: 'text'
};

export default RenderInlineField;