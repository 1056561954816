// Publication Information

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Segment, Button, Label, Input, Form } from "semantic-ui-react";
import NewForm from "./NewForm";
import { createBackIssue } from "../../../actions/backIssueActions";

class AddBackIssue extends Component {
  onSubmit = (formValues) => {
    console.log(formValues);
    // Save all the data in MongoDB
    return this.props.createBackIssue(formValues, this.props.history);
  };

  render() {
    return <NewForm onSubmit={this.onSubmit} />;
  }
}

const mapStateToProps = (state) => {};

export default connect(mapStateToProps, { createBackIssue })(
  withRouter(AddBackIssue)
);
