// Renders a Redux Form Textarea Field

import React from "react";
import PropTypes from "prop-types";
import { Form, Label, Icon } from "semantic-ui-react";

const RenderSelectRedux = ({
  input,
  label,
  placeholder,
  options,
  meta: { touched, error },
}) => {
  return (
    <div>
      <div style={{ padding: "10px" }}>
        <Form.Select
          {...input}
          size="small"
          label={label}
          placeholder={placeholder}
          options={options}
          error={touched && error ? true : false}
          onChange={(param, data) => input.onChange(data.value)}
        />
        {error && touched && (
          <div className="ui pointing red basic label">{error}</div>
        )}
      </div>
    </div>
  );
};

RenderSelectRedux.propTypes = {
  label: PropTypes.string,
};

export default RenderSelectRedux;
