import axios from 'axios';
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


const asyncValidate = (values/*, dispatch */) => {
  var error = {};
  return axios
    .get(`/api/users/email/${values.email}`)
    .then()
    .catch(err => {
      error.email = "That email is already taken";
      throw { email: 'That email is already taken' }
    })

  // return sleep(100) // simulate server latency
  //   .then(() => {
  //     if (error.email) {
  //       throw { email: 'That email is already taken' }
  //     }
  //   })
}

export default asyncValidate