// Description
// 
// Action Creators for User actions

import axios from 'axios';
import { toastr } from 'react-redux-toastr'

import {
  GET_USERS,
  ADD_USER,
  GET_ERRORS,
  UPDATE_USER,
  GET_USER,
  CLEAR_ERRORS,
  DELETE_USER,
  GET_ALL_USERS,
  DEACTIVATE_USER,
  GET_PUBLISHERS,
  ADD_PUBLISHER
} from './types';

// Get All Users for SuperUser
export const getAllUsers = () => dispatch => {
  axios
    .get(`/api/users/allusers/`)
    .then(res =>
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data
      })
    )
    .catch(err => console.log(err)
    )
}

// Get All Users by userKey
export const getUsers = (userKey) => dispatch => {
  console.log(userKey)
  axios
    .get(`/api/users/userKey/${userKey}`)
    .then(res =>
      dispatch({
        type: GET_USERS,
        payload: res.data
      })
    )
    .catch(err => console.log(err)
    )
}

// Get All Publishers by Resellers UserKey
export const getPublishers = (userKey) => dispatch => {
  axios
    .get(`/api/users/getpublishers/${userKey}`)
    .then(res =>
      dispatch({
        type: GET_PUBLISHERS,
        payload: res.data
      })
    )
    .catch(err => console.log(err)
    )
}

// Create user (logged in)
export const createUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/adduser", userData)
    .then(res => {
      dispatch({
        type: ADD_USER,
        payload: res.data
      });
      // Show toast notification
      toastr.success('New user added');
      history.push("/users")
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      toastr.error('User not added', 'Please fix the errors and retry');
    });
};

// Create Publisher/Reseller
export const createPublisher = (userData, history) => dispatch => {
  axios
    .post("/api/users/addpublisher", userData)
    .then(res => {
      dispatch({
        type: ADD_PUBLISHER,
        payload: res.data
      });
      // Show toast notification
      toastr.success('New user added');
      history.push("/publishers")
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      toastr.error('User not added', 'Please fix the errors and retry');
    });
};



// Get user by userID
export const getUserByUserID = id => dispatch => {
  dispatch(clearErrors());
  axios
    .get(`/api/users/${id}`)
    .then(res =>
      dispatch({
        type: GET_USER,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Update User
export const updateUser = (userData, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .post('/api/users/updateuser', userData)
    .then(res => {
      toastr.success('User successfully updated');
      dispatch({
        type: UPDATE_USER,
        payload: res.data
      });
      history.push('/users')
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Delete User
export const deleteUser = id => dispatch => {
  axios
    .delete(`/api/users/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_USER,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Deactivate User
export const deactivateUser = id => dispatch => {
  axios
    .post(`/api/users/deactivateuser/${id}`)
    .then(res =>
      dispatch({
        type: DEACTIVATE_USER,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      }))
}

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};