import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Segment, Form, Button, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { createUser } from '../../../actions/userActions';
import RenderFieldLeft from '../../common/NormalForm/RenderFieldLeft';
import RenderFieldLeftAndRequired from '../../common/NormalForm/RenderFieldLeftAndRequired';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';

class AddUser extends Component {

  state = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    password: '',
    password2: '',
    parentPublisher: this.props.auth.user.id,
    companyName: this.props.auth.user.companyName,
    userType: '',
    errors: {}
  }

  componentDidMount() {
    if (this.props.auth.user.userType !== "publisher" && this.props.auth.user.userType !== 'pubadmin' && this.props.auth.user.userType !== "superuser" && this.props.auth.user.userType !== "reseller") {
      window.location.href = "/readers"
    }

    // When current user is not publisher assign parent publisher to this user
    if(this.props.auth.user.userType != 'publisher')
      this.setState({parentPublisher: this.props.auth.user.parentPublisher})
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleChange = (e, { value }) => {
    console.log("this.state ", this.state)
    this.setState({ userType: value })
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    const newUser = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      companyName: this.state.companyName,
      password: this.state.password,
      password2: this.state.password2,
      parentPublisher: this.state.parentPublisher,
      userType: this.state.userType
    }

    // this.props.history needed withRouter so we can use it in the redux action
    this.props.createUser(newUser, this.props.history);
  }

  render() {
    const { errors } = this.state;
    const { value } = this.state.userType;
    // Select options for status
    const options = [
      {
        key: 'pubadmin',
        text: 'Publication Administrator (same privileges as Publisher)',
        value: 'pubadmin'
      },
      {
        key: 'admin',
        text: 'Reader Administrator',
        value: 'admin'
      },
      {
        key: 'mailer',
        text: 'Mailer',
        value: 'mailer'
      }
    ];

    return (
      <Form onSubmit={this.onSubmitHandler} role="presentation" autocomplete="nofill">
        <Segment>
          <Header as="h2" textalign='center'>Add User</Header>
          <RenderFieldLeftAndRequired
            name="firstName"
            value={this.state.firstName}
            label="First Name"
            onChange={this.onChangeHandler}
            placeholder="First Name"
            error={errors.firstName}
          />
          <RenderFieldLeftAndRequired
            name="lastName"
            value={this.state.lastName}
            onChange={this.onChangeHandler}
            placeholder="Last Name"
            error={errors.lastName}
            label="Last Name"
          />
          <RenderFieldLeftAndRequired
            name="email"
            value={this.state.email}
            onChange={this.onChangeHandler}
            placeholder="Email Address"
            error={errors.email}
            label="Email Address/Username"
            autocomplete="new-password"
          />
          <RenderFieldLeft
            name="companyName"
            value={this.state.companyName}
            onChange={this.onChangeHandler}
            placeholder="Company Name (optional)"
            error={errors.companyName}
            label="Company"
          />
          <RenderFieldLeftAndRequired
            type="password"
            value={this.state.password}
            onChange={this.onChangeHandler}
            placeholder="Password"
            name="password"
            error={errors.password}
            label="Password"
            autocomplete="new-password"
          />
          <RenderFieldLeftAndRequired
            type="password"
            value={this.state.password2}
            onChange={this.onChangeHandler}
            placeholder="Confirm Password"
            name="password2"
            error={errors.password2}
            label="Confirm Password"
          />
          <div style={{ padding: "10px" }}>
            <Form.Select
              options={options}
              onChange={this.handleChange}
              placeholder="Select User Type"
              fluid
              size="large"
              selection
              name="userType"
              value={value}
              error={errors.userType}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <Button color="blue" type="submit" fluid>Add User</Button>
          </div>
        </Segment>
      </Form>

    );
  }
}

AddUser.propTypes = {
  createUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { createUser })(withRouter(AddUser));
