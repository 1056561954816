// Publication Information

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  Segment,
  Button,
  Label,
  Input,
  Form,
  Select,
  Image,
  Grid,
} from 'semantic-ui-react';
import axios from 'axios';
import { getPublicationsWithBackIssue } from '../../../actions/publicationActions';

import RenderFieldLeftRedux from '../../common/ReduxForm/RenderFieldLeftRedux';
import RenderTextAreaRedux from '../../common/ReduxForm/RenderTextAreaRedux';
import RenderFieldLeftAndRequiredRedux from '../../common/ReduxForm/RenderFieldLeftAndRequiredRedux';
import RenderInlineFieldRedux from '../../common/ReduxForm/RenderInlineFieldRedux';
import RenderSelectRedux from '../../common/ReduxForm/RenderSelectRedux';

import {
  required,
  number1,
} from '../../common/ReduxFormValidation/validationRules';

class AddBackIssue extends Component {
  fileSelectedHandler = (e) => {
    const fd = new FormData();
    fd.append('image', e.target.files[0], e.target.files[0].name);
    axios
      .post(`/api/backIssue/uploadCover`, fd)
      .then((res) => {
        console.log('chchch');
        this.props.change('coverImg', res.data);
      })
      .catch((err) => console.log(err.response.data));
  };

  render() {
    const { handleSubmit, submitting, coverImg } = this.props;

    return (
      <Form onSubmit={handleSubmit} size='large'>
        <h1>Back Issue Details</h1>
        <Field
          name='publication'
          component={RenderSelectRedux}
          label='Publication *'
          options={this.props.publications}
          validate={[required]}
          placeholder='Please choose the publication you wish to add a single issue for sale. If none are listed, please select ‘sell back issues’ in the ‘edit publication'
        />
        <Field
          name='volume'
          component={RenderFieldLeftAndRequiredRedux}
          type='text'
          label='Publication Volume *'
          validate={[required]}
        />
        <Field
          name='description'
          component={RenderTextAreaRedux}
          label='Publication Description *'
          placeholder='The back issue description that will appear on your Storefront'
          maxLength={500}
        />
        <Field
          name='stock'
          component={RenderFieldLeftRedux}
          type='text'
          label='Quantity available'
          validate={[number1]}
          placeholder='Please enter the quantity of this publication you have in stock, leave blank if you have unlimited stock'
        />
        <Form.Group widths='equal' style={{ marginTop: '20px', padding: 10 }}>
          <Label
            color='violet'
            ribbon
            style={{
              paddingLeft: '5px',
              paddingRight: '5px',
              marginRight: 10,
              left: 10,
              fontSize: '1em',
            }}
          >
            Single Purchase Price *
          </Label>
          <Field
            name='price'
            component={RenderInlineFieldRedux}
            icon='pound sign'
            iconPosition='left'
          />
          <Field
            name='priceUS'
            component={RenderInlineFieldRedux}
            icon='dollar sign'
            iconPosition='left'
          />
          <Field
            name='priceEU'
            component={RenderInlineFieldRedux}
            icon='euro sign'
            iconPosition='left'
          />
        </Form.Group>
        <Form.Group widths='equal' style={{ marginTop: '20px', padding: 10 }}>
          <Label
            color='violet'
            ribbon
            style={{
              paddingLeft: '5px',
              paddingRight: '5px',
              marginRight: 10,
              left: 10,
              fontSize: '1em',
              minWidth: 182,
            }}
          >
            P&amp;P Price
          </Label>
          <Field
            name='ppPrice'
            component={RenderInlineFieldRedux}
            icon='pound sign'
            iconPosition='left'
          />
          <Field
            name='ppPriceUS'
            component={RenderInlineFieldRedux}
            icon='dollar sign'
            iconPosition='left'
          />
          <Field
            name='ppPriceEU'
            component={RenderInlineFieldRedux}
            icon='euro sign'
            iconPosition='left'
          />
        </Form.Group>
        <Grid>
          <Grid.Column
            style={{
              margin: '30px',
            }}
            width={7}
          >
            <p>Please upload the Magazine cover</p>
            <Input
              type='file'
              name='myfile'
              onChange={this.fileSelectedHandler}
              accept='image/*'
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Field
              name='coverImg'
              component={Image}
              src={coverImg}
              centered
              style={{ maxWidth: 300 }}
            />
          </Grid.Column>
        </Grid>

        <div style={{ margin: '10px', textAlign: 'center' }}>
          <Button color='blue' disabled={submitting}>
            Confirm and Submit
          </Button>
        </div>
      </Form>
    );
  }
}

const selector = formValueSelector('addBackIssue');

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    publications: state.publication.publications,
    coverImg: selector(state, 'coverImg'),
  };
};

export default connect(mapStateToProps, { getPublicationsWithBackIssue })(
  reduxForm({
    form: 'addBackIssue',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(AddBackIssue),
);
