import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Segment, Form, Button, Header, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import {
  getPublication,
  updateRenewalText,
} from "../../../actions/publicationActions";
import { getAccountTel } from "../../../actions/accountActions";

import { isUserLoggedIn } from "../../common/isUserLoggedIn";
import RenderTextArea from "../../common/NormalForm/RenderTextArea";

class RenewalReceipt extends Component {
  state = {
    receiptText: "",
    errors: {},
    publication: null,
    logo: "",
    logofull: "",
    publisherName: "",
    addressOne: "",
    addressTwo: "",
    addressThree: "",
    postZip: "",
    mainCurrency: "",
    mainCurrencySymbol: "",
    publicationName: "",
    pubid: "",
    tel: "",
    emailAddress: "",
  };

  componentDidMount() {
    this.props.getPublication(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.publication) {
      if (this.state.logo !== nextProps.publication.logo) {
        this.setState({
          receiptText: nextProps.publication.renewText,
          pubid: nextProps.publication._id,
          publication: nextProps.publication,
          publicationName: nextProps.publication.publicationName,
          logo: nextProps.publication.logo,
          emailAddress: nextProps.publication.emailAddress,
          logofull: nextProps.publication.logo,
        });
        this.props.getAccountTel(nextProps.publication.user);
      }
    }

    if (nextProps.account.account) {
      if (nextProps.account.account.accountDets) {
        if (
          this.state.companyName !==
          nextProps.account.account.accountDets.companyName
        ) {
          this.setState({
            tel: nextProps.account.account.accountDets.telephone,
            publisherName: nextProps.account.account.accountDets.companyName,
            addressOne: nextProps.account.account.accountDets.addressOne,
            addressTwo: nextProps.account.account.accountDets.addressTwo,
            addressThree: nextProps.account.account.accountDets.addressThree,
            postZip: nextProps.account.account.accountDets.postZip,
            mainCurrency: nextProps.account.account.accountDets.mainCurrency,
          });
          switch (nextProps.account.account.accountDets.mainCurrency) {
            case "GBP":
              this.setState({ mainCurrencySymbol: "£" });
              break;
            case "USD":
              this.setState({ mainCurrencySymbol: "$" });
              break;
            case "EUR":
              this.setState({ mainCurrencySymbol: "€" });
              break;
            default:
              this.setState({ mainCurrencySymbol: "£" });
              break;
          }
        }
      }
    }
  }

  handleChange = (e, { value }) => {
    this.setState({ title: value });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitHandler = (e) => {
    e.preventDefault();
    const receiptText = {
      _id: this.props.match.params.id,
      receiptText: this.state.receiptText,
    };

    // this.props.history needed withRouter so we can use it in the redux action
    this.props.updateRenewalText(receiptText, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <React.Fragment>
        <Form onSubmit={this.onSubmitHandler}>
          <Segment>
            <Header as="h2" textAlign="center">
              Renewal Email Template for {this.state.publicationName}
            </Header>
          </Segment>
          <Grid stackable columns={2}>
            <Grid.Column>
              <Segment>
                <RenderTextArea
                  name="receiptText"
                  label="Receipt Text"
                  value={this.state.receiptText}
                  onChange={this.onChangeHandler}
                  error={errors.message}
                />
              </Segment>
              <Segment>
                <Button color="blue" type="submit" fluid>
                  Update Renewal Template
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <div
                  style={{ height: "100px", borderBottom: "1px solid black" }}
                >
                  <div style={{ float: "left" }}>
                    <img src={this.state.logo} style={{ width: "200px" }} />
                  </div>
                  <div style={{ float: "right" }}>
                    <i>{this.state.publisherName}</i>
                  </div>
                </div>
                <div style={{ paddingTop: "2em" }}>
                  <strong>Dear FullName or First name</strong>
                  <br />
                  <br />
                  Your subscription to {this.state.publicationName} is due to
                  expire on [expiry date].
                  <br />
                  <br />
                  {this.state.receiptText}
                  <br />
                  <br />
                  To renew your subscription, please click here and log in with
                  your user credentials. If you purchased a renewable
                  subscription, please ignore this email.
                  <br />
                  <br />
                  If you have any queries, please don't hesitate to contact us
                  via {this.state.emailAddress}.<br />
                  <br />
                  Kind regards
                  <br />
                  <br />
                  {this.state.publicationName}
                  <br />
                </div>
              </Segment>
            </Grid.Column>
          </Grid>
        </Form>
      </React.Fragment>
    );
  }
}

RenewalReceipt.propTypes = {
  getPublication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors,
  publication: state.publication.publication,
  account: state.account,
});

export default connect(mapStateToProps, {
  getPublication,
  getAccountTel,
  updateRenewalText,
})(withRouter(RenewalReceipt));
