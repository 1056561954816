// Renders a Normal Form Date Picker Field

import React from 'react';
import PropTypes from 'prop-types';
import {
  DateInput
} from 'semantic-ui-calendar-react';

const RenderDatePickerTable = ({
  onChange,
  value,
  name,
  error,
  label,
  disabled
}) => (
    <div>
      <DateInput
        fluid
        disabled={disabled}
        dateFormat="DD-MM-YYYY"
        size='large'
        label={label}
        value={value}
        iconPosition='left'
        onChange={onChange}
        name={name}
        closable={true}
        error={error ? true : false}>
      </DateInput>
      {error && <div className="ui pointing red basic label">{error}</div>}
    </div>
  )

RenderDatePickerTable.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

RenderDatePickerTable.defaultProps = {
  type: 'text'
};

export default RenderDatePickerTable;