// Redux form validation rules

// Required
export const required = value => (value || typeof value === 'number' ? undefined : 'Required')

// Max length
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

// example of using maxLength
export const maxLength15 = maxLength(15)

// Min length
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined

// example of using minLength
export const minLength2 = minLength(2)

// Numeric
export const number1 = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined

// Minimum value
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined

// example of using minValue
export const minValue13 = minValue(13)

// Email
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

// Url
export const url = value =>
  value && !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i.test(value) ? 'Invalid Web address' : undefined

// Age check
export const tooYoung = value =>
  value && value < 13
    ? 'You do not meet the minimum age requirement!'
    : undefined

// Alphanumeric
export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined

// MagStand url key
export const urlkey = value =>
  value && /[^a-z0-9_-]/i.test(value)
    ? 'Only alphanumeric characters, underscores and hyphens allowed'
    : undefined

// Phone number
export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{10})$/i.test(value)
    ? 'Invalid phone number, must be 11 digits'
    : undefined