// Login Component

import React, { Component } from 'react';
import axios from 'axios';
import { Form, Grid, Header, Image, Segment, Button } from 'semantic-ui-react';
import RenderFieldWithRightLabel from '../common/NormalForm/RenderFieldWithRightLabel';

//import Logo from '../../assets/img/ms200blk.png';
import Logo from '../../assets/img/ms200blkNew.png';

class ForgottenPassword extends Component {
  state = {
    email: '',
    emailError: '',
    emailResetMessage: '',

  };

  // Submit function
  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email
    };

    axios
      .post('/api/users/forgot', userData)
      .then(res => {
        if (res.data === "No such user") {
          this.setState({ emailError: "We cannot find a user with that email address in the system. Please try again.", emailResetMessage: "" })
        } else {
          this.setState({ emailResetMessage: "Thank you. An email has been sent to your entered email address with further instructions", emailError: "" })
        }

      })
  }

  // Controlled Component Change event
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    console.log("props ", this.props)
    return (
      <div className='login-form'>
        <style>{`
          body > div,
          body > div > div,
          body > div > div > div.login-form {
            height: 100%;
          }
          body { background: #2185d0; }
        `}
        </style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Segment>
              <Header as='h2' color='black' textAlign='center'>
                <Image src={Logo} alt='Magstand Logo' style={{ width: "150px" }} />
              </Header>
              <Header as='h3' color='black' textAlign='center'>Forgotten Password</Header>
              <Form onSubmit={this.onSubmit}>
                <RenderFieldWithRightLabel
                  placeholder='Email'
                  name='email'
                  label='Email'
                  value={this.state.email}
                  onChange={this.onChange}
                  error={this.state.emailError}
                  type='email'
                />
                <div style={{ padding: "10px" }}>
                  <Button color="blue" fluid type="submit">Send Request</Button>
                </div>
                {this.state.emailResetMessage}
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>

    );
  }
}

export default (ForgottenPassword);
