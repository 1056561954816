import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Form, Button, Header, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { searchReaders } from '../../../actions/readerActions';
import RenderFieldLabelAbove from '../../common/NormalForm/RenderFieldLabelAbove';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';
import DataTable from '../../common/DataTable';

class SearchReaders extends Component {

  state = {
    lastName: '',
    companyName: '',
    email: '',
    fullName: '',
    uniqueReference: '',
    postZip: '',
    showReader: false,
    errors: {},
    data: null
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    };
    if (nextProps.reader.readers.length > 0) {
      this.setState({ showReader: true });
    }
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    if (this.state.lastName !== "" || this.state.email !== "" || this.state.companyName !== "" || this.state.fullName !== "" || this.state.uniqueReference !== "" || this.state.postZip !== "") {
      const searchReader = {
        lastName: this.state.lastName,
        email: this.state.email,
        companyName: this.state.companyName,
        fullName: this.state.fullName,
        uniqueReference: this.state.uniqueReference,
        postZip: this.state.postZip,
        publicationID: this.props.match.params.id,
      }
      // this.props.history needed withRouter so we can use it in the redux action
      this.props.searchReaders(searchReader);
    }
  }

  render() {
    const { errors } = this.state;
    const { readers } = this.props.reader

    const headers = {
      'fullName': {
        text: 'Full Name',
        invisible: false,
        sortable: true,
        filterable: true
      },
      'title': {
        text: 'Title',
        invisible: false,
        sortable: true,
        filterable: true
      },
      'firstName': {
        text: 'First Name',
        invisible: false,
        sortable: true,
        filterable: true
      },
      'lastName': {
        text: 'Last Name',
        invisible: false,
        sortable: true,
        filterable: true
      },
      'companyName': {
        text: 'Company',
        invisible: false,
        sortable: true,
        filterable: true
      },
      'email': {
        text: 'Email',
        invisible: false,
        sortable: true,
        filterable: true
      },
      'uniqueReference': {
        text: 'Unique Ref',
        invisible: false,
        sortable: true,
        filterable: true
      },
      'postCode': {
        text: 'Post Code',
        invisible: false,
        sortable: true,
        filterable: true
      },
      '_id': {
        text: 'Action',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Link to={`/editreader/${row._id}`}>
              <Button fluid color="green">View</Button>
            </Link>
          )
        }
      }
    }

    let readerTable = '';
    // if (loading) {
    //   readerTable = <Spinner />;
    // } else {
    if (readers.noreader !== undefined) {
      readerTable = (
        <Segment>
          <Header>No record matches your search</Header>
        </Segment>
      )
    }

    if (readers !== null && this.state.showReader) {
      if (readers.length > 0) {
        readerTable = (
          <Segment key={new Date().getTime()}>
            <DataTable data={readers} headers={headers} />

          </Segment>
        )
      } else {
        readerTable = (
          <Segment>
            <Header>There were no readers found for your search query</Header>
          </Segment>
        )
      }
    }
    //}
    return (
      <Form onSubmit={this.onSubmitHandler}>
        <Segment>
          <Header as="h2" textAlign='center'>Search Readers</Header>
        </Segment>
        <Segment>
          <Grid stackable columns={3}>
            <Grid.Column>
              <RenderFieldLabelAbove
                value={this.state.lastName}
                onChange={this.onChangeHandler}
                name="lastName"
                error={errors.lastName}
                label="Surname"
              />
              <RenderFieldLabelAbove
                value={this.state.fullName}
                onChange={this.onChangeHandler}
                name="fullName"
                error={errors.fullName}
                label="Full Name"
              />
            </Grid.Column>
            <Grid.Column>
              <RenderFieldLabelAbove
                name="companyName"
                value={this.state.companyName}
                onChange={this.onChangeHandler}
                error={errors.companyName}
                label="Company"
              />
              <RenderFieldLabelAbove
                name="uniqueReference"
                value={this.state.uniqueReference}
                onChange={this.onChangeHandler}
                error={errors.uniqueReference}
                label="Unique Reference"
              />
            </Grid.Column>
            <Grid.Column>
              <RenderFieldLabelAbove
                name="email"
                value={this.state.email}
                label="Email"
                onChange={this.onChangeHandler}
                error={errors.email}
              />
              <RenderFieldLabelAbove
                name="postZip"
                value={this.state.postZip}
                label="Post/Zip Code"
                onChange={this.onChangeHandler}
                error={errors.postZip}
              />
            </Grid.Column>
          </Grid>
          <div style={{ padding: "10px" }}>
            <Button color="blue" type="submit" fluid>Search Readers</Button>
          </div>
        </Segment>
        {readerTable}
      </Form>

    );
  }
}

SearchReaders.propTypes = {
  searchReaders: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors,
  reader: state.reader
});

export default connect(mapStateToProps, { searchReaders })(withRouter(SearchReaders));
