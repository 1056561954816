import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getPublicationByUrlKey } from "../../actions/publicationActions";
import { getAccountTel } from "../../actions/accountActions";
import hex2rgba from "hex2rgba";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Form,
} from "semantic-ui-react";

class OrderCancel extends Component {
  state = {
    logo: "FFFFFF-1.png",
    thumbImageName: "FFFFFF-1.png",
    publisherName: "",
    mainContactEmail: "",
    email: "",
    title: "",
    firstName: "",
    lastName: "",
    currencySymbol: "£",
    publication: {},
    bg75: "",
    bg50: "",
    bg25: "",
    bg15: "",
    bg05: "",
    purchasePrice: "",
    tel: "",
    errors: {},
  };

  componentDidMount() {
    this.props.getPublicationByUrlKey(this.props.order.order.urlKey);
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.publication.publication) {
      if (this.state.logo !== nextProps.publication.publication.logo) {
        document.title =
          "Magstand - " + nextProps.publication.publication.publicationName;

        this.setState({
          publication: nextProps.publication.publication,
          logo: nextProps.publication.publication.logo,
          thumbImageName: nextProps.publication.publication.thumbImageName,
        });
        this.setState({
          bg75: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.75
          ),
          bg50: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.5
          ),
          bg25: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.25
          ),
          bg15: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.15
          ),
          bg10: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.1
          ),
          bg05: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.05
          ),
        });
        this.props.getAccountTel(nextProps.publication.publication.user);
      }
    }
    if (nextProps.account.account) {
      if (this.state.tel !== nextProps.account.account.accountDets.telephone) {
        this.setState({
          tel: nextProps.account.account.accountDets.telephone,
          publisherName: nextProps.account.account.accountDets.companyName,
          mainContactEmail: nextProps.account.account.accountDets.emailAddress,
        });
      }
    }
  }

  render() {
    const publication = this.state.publication;
    const style = {
      background: {
        backgroundColor: this.state.bg75,
        // padding: '5em 0em'
      },
      magbackground: {
        backgroundColor: this.state.bg10,
        marginTop: "-15px",
        marginBottom: "-20px",
        paddingTop: "3em",
        paddingBottom: "3em",
      },
      menu: {
        backgroundColor: "#333",
      },
      container: {
        backgroundColor: publication.backgroundColour,
        color: "#fff",
        padding: "1em",
        fontSize: "20px",
        width: "100%",
      },
      logocontainer: {
        backgroundColor: "#fff",
        color: "#fff",
        padding: "2em",
        marginTop: "-30px",
        fontSize: "20px",
        width: "100%",
      },
      tableHeader: {
        backgroundColor: publication.backgroundColour,
        padding: "2em",
        fontSize: "17px",
        color: "#fff",
      },
      tableContent: {
        padding: "2em",
        fontSize: "17px",
      },
    };

    return (
      <Form>
        <div style={style.background}>
          <Grid
            container
            stackable
            verticalAlign="middle"
            style={{ backgroundColor: "#f3f3f3" }}
          >
            <Grid.Row>
              <div style={style.container}>
                <div style={{ float: "left" }}>
                  <p>
                    <span style={{ fontSize: "24px", marginLeft: "30px" }}>
                      Subscribe Today!
                    </span>
                  </p>
                </div>
                <div style={{ textAlign: "right", float: "right" }}>
                  <p>
                    <span
                      style={{
                        marginBottom: "-80px",
                        fontSize: "16px",
                        marginRight: "30px",
                      }}
                    >
                      {this.state.publisherName}
                    </span>
                  </p>
                </div>
              </div>
            </Grid.Row>
            <Grid.Row>
              <div style={style.logocontainer}>
                <Image
                  src={this.state.logo}
                  style={{ height: "80px" }}
                  centered
                />
              </div>
            </Grid.Row>
            <Grid.Row style={style.magbackground}>
              <Grid.Column width={2}>&nbsp;</Grid.Column>
              <Grid.Column width={5}>
                <Image
                  src={this.state.thumbImageName}
                  style={{
                    height: "387px",
                    boxShadow: "-1px 2px 12px 0px #000000",
                  }}
                  centered
                />
              </Grid.Column>
              <Grid.Column width={7}>
                <Header as="h1">
                  {this.state.publication.publicationName}
                </Header>
                <p style={{ fontSize: "17px", marginRight: "50px" }}>
                  {this.state.publication.publicationInfo}
                </p>
              </Grid.Column>
              <Grid.Column width={2}>&nbsp;</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <div style={{ padding: "25px" }}>
                <p>
                  You have successfully cancelled your order of{" "}
                  {this.props.order.order.product} for{" "}
                  {this.state.publication.publicationName}.
                </p>
                <br />
                <br />
                <p>
                  We have created an account for you so that should you wish to
                  purchase any future subscriptions, you can log in as a
                  returning customer with the credentials you provided.
                </p>
                <br />
                <br />
                <Link to={`/${this.props.order.order.urlKey}`}>
                  <Button color="black">Return to the Storefront</Button>
                </Link>
                <br />
              </div>
            </Grid.Row>
            <Grid.Row style={style.menu}>
              <Menu inverted={true} secondary={true} size="small" borderless>
                <Container>
                  {this.state.mainContactEmail !== undefined ? (
                    <Menu.Item
                      position="left"
                      name="tel"
                      href={"tel:" + this.state.tel}
                      style={{ marginLeft: "55px" }}
                    >
                      {this.state.tel}
                    </Menu.Item>
                  ) : null}
                  {this.state.mainContactEmail !== undefined ? (
                    <Menu.Item
                      position="right"
                      name="infoemail"
                      href={"mailto:" + this.state.mainContactEmail}
                      target="_blank"
                    >
                      {this.state.mainContactEmail}
                    </Menu.Item>
                  ) : null}
                  <Menu.Item
                    position="right"
                    name="privacy"
                    href="/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </Menu.Item>

                  {this.state.publication.facebook !== undefined ? (
                    <Menu.Item
                      position="right"
                      name="login"
                      href={
                        "https://facebook.com/" +
                        this.state.publication.facebook
                      }
                      target="_blank"
                    >
                      <Icon name="facebook" size="large" />
                    </Menu.Item>
                  ) : null}
                  {this.state.publication.twitter !== undefined ? (
                    <Menu.Item
                      name="login"
                      href="https://twitter.com"
                      target="_blank"
                    >
                      <Icon name="twitter" size="large" />
                    </Menu.Item>
                  ) : null}
                  {this.state.publication.instagram !== undefined ? (
                    <Menu.Item
                      name="login"
                      href="https://linkedin.com"
                      target="_blank"
                    >
                      <Icon name="linkedin" size="large" />
                    </Menu.Item>
                  ) : null}
                </Container>
              </Menu>
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  paddingBottom: "30px",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    color: "#fff",
                    marginBottom: "40px",
                  }}
                >
                  Powered by MagStand part of{" "}
                  <a href="https://detl.co.uk">
                    Digital Edition Technology Ltd.
                  </a>
                </span>
              </div>
            </Grid.Row>
          </Grid>
        </div>
      </Form>
    );
  }
}

OrderCancel.propTypes = {
  getAccountTel: PropTypes.func.isRequired,
  getPublicationByUrlKey: PropTypes.func.isRequired,
  publication: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  publication: state.publication,
  order: state.order,
  account: state.account,
});

export default connect(mapStateToProps, {
  getAccountTel,
  getPublicationByUrlKey,
})(OrderCancel);
