import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  Button,
  Header,
  Input,
  Form,
  Image,
  Grid,
  Segment,
} from "semantic-ui-react";
import axios from "axios";

class PublicationImage extends Component {
  fileSelectedHandler = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0], e.target.files[0].name);
    axios
      .post(`/api/publication/uploadimage`, fd)
      .then((res) => {
        this.props.change("thumbImageName", res.data);
      })
      .catch((err) => console.log(err.response.data));
  };

  logoFileSelectedHandler = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0], e.target.files[0].name);
    axios
      .post(`/api/publication/uploadlogo`, fd)
      .then((res) => {
        this.props.change("logo", res.data);
      })
      .catch((err) => console.log(err.response.data));
  };

  render() {
    const {
      handleSubmit,
      submitting,
      previousPage,
      thumbImageName,
      logo,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit} size="large">
        <Grid stackable columns={2}>
          <Grid.Column textAlign="center">
            <Header textAlign="center">Magazine Cover</Header>
            <p>Suggested cover height of 390px</p>
            <Field
              name="thumbImageName"
              component={Image}
              src={thumbImageName}
              centered
              size="medium"
            />
            <div
              style={{
                margin: "10px",
              }}
            >
              <Input
                type="file"
                name="myfile"
                onChange={this.fileSelectedHandler}
                accept="image/*"
              />
            </div>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header textAlign="center">Your Logo</Header>
            <p>
              Suggested logo height of 80px, with white or transparent
              background
            </p>
            <Field
              name="logo"
              component={Image}
              src={logo}
              centered
              size="medium"
            />
            <div
              style={{
                margin: "10px",
              }}
            >
              <Input
                type="file"
                name="logo"
                onChange={this.logoFileSelectedHandler}
                accept="image/*"
              />
            </div>
          </Grid.Column>
        </Grid>
        <Segment>
          <div style={{ margin: "10px", textAlign: "center" }}>
            <Button color="blue" onClick={previousPage}>
              Previous
            </Button>
            <Button color="blue" disabled={submitting}>
              Next
            </Button>
          </div>
        </Segment>
      </Form>
    );
  }
}

const selector = formValueSelector("wizard");

const mapStateToProps = (state) => {
  return {
    thumbImageName: selector(state, "thumbImageName"),
    logo: selector(state, "logo"),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(PublicationImage)
);
