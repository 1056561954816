// Choose the Plan Type and the Price for the Subscription

import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Header, Label, Input, Grid } from 'semantic-ui-react';
import { Slider } from 'react-semantic-ui-range';
import RenderField from '../../common/ReduxForm/RenderFieldRedux';
import axios from 'axios'

class PlanType extends React.Component {
  state = {
    value1: 1,
    value: 0,
    price: 65,
    freeSub: false,
    showNext: false,
    showSlider: true,
    freeCode: 'FREE20',
    ninetynine: 'SAVE99',
    promo: '',
    plan: '',
    productList: [],
    coupon: null
  }

  onPromoLookup = async (e) => {
    e.preventDefault();

    try{

      const body = {
        pattern: this.state.promo
      }
      const response = await axios.post('/api/users/v2/promocode/check/', body)
      this.setState({ coupon: response.data })

      // Setting field
      this.props.change('promocode', response.data);
    }catch(e){
      console.log("promocode NOT found")
      this.setState({ coupon: null })
    }

  }

  calculatePrice = (value) => {
    const product = this.state.productList.find( item => (item.order+1) == value)
    if( product == null ){
      this.setState({ showContactUs: true })
    }else{
      this.props.change('planType', product);
      this.setState({ showContactUs: false })
    }
  }

  async componentDidMount() {
    // Retrieve productList
    const response = await axios.get('/api/users/v2/product/')
    this.setState({ productList: response.data })

    const product = response.data.find(item => item.order == 0)
    this.props.change('planType', product);
  }

  // Generic Change Handler
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };


  render() {
    const { handleSubmit, previousPage, country } = this.props;
    const { coupon } = this.state

    // const { freeSub } = this.state;
    // let showPrice;
    let showSlider;
    let showPromoCode;

    // Compute labelMessage
    const product = this.state.productList.find( item => (item.order+1) == this.state.value1)
    let price = null, labelMessage = null
    if(product){
      price = coupon?((100-coupon.percent_off)/100)*product.price:product.price
      labelMessage = product != null?`${product.name} @ £${price}/month ${country==="GB"?"+ VAT":""}`:''
    }
    

    if (this.state.freeSub) {
      showSlider = <Header>Promotion Code Successfully Applied - Free Unlimited Use Plan</Header>
      showPromoCode = null;
    } else {
      showSlider = (
        <React.Fragment>
          <Header>
            Please select your Magstand{this.state.plan} plan type
        </Header>
          <div style={{ padding: "10px" }}>
            {this.state.showContactUs?
            <Label size='large'>For more than 10 Publications, please Contact Us at <a href="mailto:support@magstand.com" alt="support@magstand.com">support@magstand.com</a></Label>
            :
            <Label size='large'>{labelMessage}</Label>
            }
          </div>
          <div style={{ padding: "10px" }}>
            <Slider color="blue" inverted={false}
              settings={{
                start: this.state.value1,
                min: 1, max: 11, step: 1,
                onChange: (value) => {
                  this.calculatePrice(value);
                  this.setState({ value1: value })
                }
              }} />
          </div>
        </React.Fragment>
      );
      showPromoCode = (
        <React.Fragment>
          <div style={{ marginTop: "20px" }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>&nbsp;</Grid.Column>
                <Grid.Column width={8}>
                  <Input
                    label={{ basic: true, content: 'Promo Code' }}
                    action={{ color: 'blue', labelPosition: 'right', icon: 'search', content: 'Apply', 
                      onClick: this.onPromoLookup }}
                    name="promo"
                    value={this.state.promo}
                    onChange={this.onChange}
                    fluid
                  >
                  </Input>
                </Grid.Column>
              </Grid.Row>
              {coupon?
              <Grid.Row>
                <Grid.Column width={8}>&nbsp;</Grid.Column>
                <Grid.Column width={8} style={{ textAlign: 'end'}}>
                  Coupon <b>{coupon.name}</b> was applied offering <b>{coupon.percent_off}%</b> discount
                </Grid.Column>
              </Grid.Row>
              :null}
            </Grid>
          </div>
        </React.Fragment>
      )
    }
    return (
      <form onSubmit={handleSubmit} size='large'>
        {showSlider}
        {showPromoCode}
        <Field component={RenderField} name="promocode"
          value={this.state.coupon?.name} type="hidden" />
        <Field component={RenderField} name="planType"
          value={this.state.value1} type="hidden" />
        <Field component={RenderField} name="price" value={this.state.price}
          type="hidden" />
        <Button color='blue' type='button' onClick={previousPage} >
          Previous
        </Button>
        <Button type='submit' color='blue' disabled={this.state.showContactUs} >
          Next
        </Button>
      </form>
    )
  }
}

const validate = (formValues) => {
  const errors = {};
  return errors;
}

const formWrapped = reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(PlanType);

const selector = formValueSelector('wizard')
export default connect(state => {
  try{
    let { country } = selector(state, 'country')
    return { country }
  }catch( e ){
    return { country: 'GB' }
  }

})(formWrapped);

