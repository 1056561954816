import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { Button, Form } from 'semantic-ui-react';
import RenderFieldLabelAboveRedux from '../../../common/ReduxForm/RenderFieldLabelAboveRedux';

class SocialLinks extends Component {


  render() {
    const { handleSubmit, previousPage, submitting } = this.props;
    return (
      <Form onSubmit={handleSubmit} size='large'>
        <Field
          name="facebook"
          component={RenderFieldLabelAboveRedux}
          type="text"
          label="Facebook"
        />
        <Field
          name="twitter"
          component={RenderFieldLabelAboveRedux}
          type="text"
          label="Twitter"
        />
        <Field
          name="instagram"
          component={RenderFieldLabelAboveRedux}
          type="text"
          label="Instagram"
        />
        <Field
          name="youtube"
          component={RenderFieldLabelAboveRedux}
          type="text"
          label="Youtube"
        />
        <div style={{ margin: "10px", textAlign: "center" }}>
          <Button color='blue'
            onClick={previousPage}>
            Previous
            </Button>
          <Button color='blue'
            disabled={submitting}>
            Next
            </Button>
        </div>
      </Form>
    )
  }
}


export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(SocialLinks);