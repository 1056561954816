import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { Segment, Header } from 'semantic-ui-react';
import { getYearlyGraphData, getAllYearlyGraphData } from '../../../actions/analyticActions';

class LineGraph extends Component {
  state = {
    pubId: null,
    dataPrintSets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    dataBundleSets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    dataDigitalSets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    yearlyGraphData: [],
    stepSize: 1
  }

  componentDidMount() {
    this.forceUpdate()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pubid) {
      if (nextProps.pubid !== this.state.pubId) {
        if (nextProps.pubid === "all") {
          this.props.getAllYearlyGraphData(nextProps.pubid);
        } else {
          this.props.getYearlyGraphData(nextProps.pubid);
        }
        this.setState({ pubId: nextProps.pubid })
      }
    }

    if (nextProps.analytics) {
      console.log(nextProps.analytics)
      let dataDigitalSetTemp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let dataPrintSetTemp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let dataBundleSetTemp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (nextProps.analytics.yearlyGraphData !== this.state.yearlyGraphData) {
        this.setState({ yearlyGraphData: nextProps.analytics.yearlyGraphData });
        if (nextProps.analytics.yearlyGraphData.length > 0) {
          nextProps.analytics.yearlyGraphData.map(data => {
            switch (data._id.subType) {
              case "bundle":
                dataBundleSetTemp.splice(data._id.month - 1, 1, data.count);
                break;
              case "print":
                dataPrintSetTemp.splice(data._id.month - 1, 1, data.count);
                break;
              case "digital":
                dataDigitalSetTemp.splice(data._id.month - 1, 1, data.count);
                break;
            }
          })
          let total = [];
          total.push(Math.max.apply(Math, dataPrintSetTemp));
          total.push(Math.max.apply(Math, dataDigitalSetTemp));
          total.push(Math.max.apply(Math, dataBundleSetTemp));
          const totalValue = Math.max.apply(Math, total);
          if (totalValue < 200000) {
            this.setState({ stepSize: 10000 })
          }
          if (totalValue < 20000) {
            this.setState({ stepSize: 1000 })
          }
          if (totalValue < 1000) {
            this.setState({ stepSize: 100 })
          }
          if (totalValue < 100) {
            this.setState({ stepSize: 10 })
          }
        }
        this.setState({ dataBundleSets: dataBundleSetTemp, dataPrintSets: dataPrintSetTemp, dataDigitalSets: dataDigitalSetTemp })
      }
    }
  }

  render() {
    const data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: [
        {
          label: 'Print',
          fill: false,
          lineTension: 0,
          backgroundColor: '#DCDCDC',
          borderColor: '#DCDCDC',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#DCDCDC',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#DCDCDC',
          pointHoverBorderColor: '#DCDCDC',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.state.dataPrintSets
        }
        ,
        {
          label: 'Digital',
          fill: false,
          lineTension: 0,
          backgroundColor: '#9AB9CC',
          borderColor: '#9AB9CC',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#9AB9CC',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#9AB9CC',
          pointHoverBorderColor: '#9AB9CC',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.state.dataDigitalSets
        },
        {
          label: 'Bundle',
          fill: false,
          lineTension: 0,
          backgroundColor: '#C9E2DA',
          borderColor: '#C9E2DA',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#C9E2DA',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#C9E2DA',
          pointHoverBorderColor: '#C9E2DA',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.state.dataBundleSets
        }]
    };

    const options = {
      spanGaps: true,
      scales: {
        yAxes: [{
          ticks: {
            stepSize: this.state.stepSize,
            beginAtZero: true
          }
        }]
      }
    }
    return (
      <Segment>
        <Header textAlign="center">Subscribers</Header>
        <Line data={data} options={options} height={70} />
      </Segment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  analytics: state.analytics
});

export default connect(mapStateToProps, { getYearlyGraphData, getAllYearlyGraphData })(LineGraph);