// Main App.js

import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import setAuthToken from "../utils/setAuthToken";

// Component Imports
import Landing from "../components/Landing/Landing";
import Signup from "../components/Signup/Signup";
import Success from "../components/Signup/Success";
import Login from "../components/Login/Login";
import SuperLogin from "../components/Login/SuperLogin";
import ForgottenPassword from "../components/ForgottenPassword/ForgottenPassword";
import StoreForgottenPassword from "../components/ForgottenPassword/StoreForgottenPassword";
import ResetMagstand from "../components/ResetPassword/ResetMagstand";
import ResetBackend from "../components/ResetPassword/ResetBackend";
import LoggedInLayout from "../components/Layout/Layout";
import Dashboard from "../components/Dashboard/Dashboard";
import Publications from "../components/Publications/Publications";
import BackIssues from "./BackIssues";
import AddPublication from "../components/Publications/AddPublication/AddPublication";
import AddBackIssue from "../components/BackIssues/AddBackIssue";
import EditBackIssue from "../components/BackIssues/EditBackIssue";
import EditPublication from "../components/Publications/EditPublication/EditPublication";
import PromoCodes from "../components/Publications/PromoCodes";
import AddPromo from "../components/Publications/AddPromo/AddPromo";
import EditPromo from "../components/Publications/EditPromo/EditPromo";
import Users from "../components/Users/Users";
import Readers from "../components/Readers/Readers";
import AddReader from "../components/Readers/AddReader/AddReader";
import EditReader from "../components/Readers/EditReader/EditReader";
import SearchReaders from "../components/Readers/SearchReaders/SearchReaders";
import UploadAdditional from "../components/Readers/UploadAdditional/UploadAdditional";
import UploadReplacement from "../components/Readers/UploadReplace/UploadReplace";
import UploadAddAndCombine from "../components/Readers/UploadAddAndCombine/UploadAddAndCombine";
import Account from "../components/Account/Account";
import AddUser from "../components/Users/AddUser/AddUser";
import EditUser from "../components/Users/EditUser/EditUser";
import Publishers from "../components/SuperUser/Publishers";
import AddPublisher from "../components/SuperUser/AddUser/AddUser";
import Storefront from "../components/Storefront/Storefront";
import OrderSuccess from "../components/Storefront/OrderSuccess";
import OrderCancel from "../components/Storefront/OrderCancel";
import Payments from "../components/Account/Payments";
import ManageData from "../components/ManageData/ManageData";
import ContactMe from "../components/ContactMe/ContactMe";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import EmailTemplates from "../components/EmailTemplates/EmailTemplates";
import PurchaseReceipt from "../components/EmailTemplates/PurchaseReceipt/PurchaseReceipt";
import RenewalReceipt from "../components/EmailTemplates/RenewalReceipt/RenewalReceipt";
import ThirdParty from "../components/ThirdParty/ThirdParty";
import UploadPostcodes from "../components/Readers/UploadAdditional/UploadPostcodes";
import OrderDetails from "../components/Account/OrderDetails";

// Tests

import SecureTrading from "../components/Tests/SecureTrading";

// Policies
import PrivacyPolicy from "../components/Policies/PrivacyPolicy";

// Private and Public Routes
import PrivateRoute from "../components/common/PrivateRoute";
import PublicRoute from "../components/common/PublicRoute";

// Toastr CSS
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

// App CSS
import "../App.css";

// Redux store
import store from "../store";

// Redux Actions
import { setCurrentUser, logoutUser } from "../actions/authActions";

// Check for JWT token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  localStorage.setItem("avatar", decoded.avatar);
  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/login";
  }
}

class App extends React.Component {

  render() {
    // The below is used so that we can have multiple designs (i.e. logged out state and logged in)
    const OfflineLayout = (props) => <div>{props.children}</div>;

    const StoreLayout = (props) => <div>{props.children}</div>;

    const OnlineLayout = (props) => (
      <Fragment>
        <LoggedInLayout>
          {props.children}
          <ReduxToastr
            timeOut={15000}
            newestOnTop={true}
            preventDuplicates
            position="bottom-center"
            transitionIn="bounceIn"
            transitionOut="bounceOut"
            progressBar
            style={{ height: "70px" }}
            closeOnToastrClick
          />
        </LoggedInLayout>
      </Fragment>
    );
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <PublicRoute
              exact
              path="/success"
              layout={OfflineLayout}
              component={Success}
            />
            <PublicRoute
              exact
              path="/signup"
              layout={OfflineLayout}
              component={Signup}
            />
            <PublicRoute
              exact
              path="/login"
              layout={OfflineLayout}
              component={Login}
            />
            <PublicRoute
              exact
              path="/slogin"
              layout={OfflineLayout}
              component={SuperLogin}
            />
            <PublicRoute
              exact
              path="/forgot"
              layout={OfflineLayout}
              component={ForgottenPassword}
            />
            <PublicRoute
              exact
              path="/resetmagstand/:id"
              layout={OfflineLayout}
              component={ResetMagstand}
            />
            <PublicRoute
              exact
              path="/reset/:id"
              layout={OfflineLayout}
              component={ResetBackend}
            />
            <PublicRoute
              exact
              path="/privacy"
              layout={OfflineLayout}
              component={PrivacyPolicy}
            />
            <PrivateRoute
              exact
              path="/dashboard"
              layout={OnlineLayout}
              component={Dashboard}
            />
            <PrivateRoute
              exact
              path="/publications"
              layout={OnlineLayout}
              component={Publications}
            />
            <PrivateRoute
              exact
              path="/backIssues"
              layout={OnlineLayout}
              component={BackIssues}
            />
            <PrivateRoute
              exact
              path="/addpublication"
              layout={OnlineLayout}
              component={AddPublication}
            />
            <PrivateRoute
              exact
              path="/addbackIssue"
              layout={OnlineLayout}
              component={AddBackIssue}
            />
            <PrivateRoute
              exact
              path="/editBackIssue/:id"
              layout={OnlineLayout}
              component={EditBackIssue}
            />
            <PrivateRoute
              exact
              path="/editpublication/:id"
              layout={OnlineLayout}
              component={EditPublication}
            />
            <PrivateRoute
              exact
              path="/promocodes/:id"
              layout={OnlineLayout}
              component={PromoCodes}
            />
            <PrivateRoute
              exact
              path="/addpromo/:id"
              layout={OnlineLayout}
              component={AddPromo}
            />
            <PrivateRoute
              exact
              path="/editpromo/:id"
              layout={OnlineLayout}
              component={EditPromo}
            />
            <PrivateRoute
              exact
              path="/users"
              layout={OnlineLayout}
              component={Users}
            />
            <PrivateRoute
              exact
              path="/publishers"
              layout={OnlineLayout}
              component={Publishers}
            />
            <PrivateRoute
              exact
              path="/adduser"
              layout={OnlineLayout}
              component={AddUser}
            />
            <PrivateRoute
              exact
              path="/addpublisher"
              layout={OnlineLayout}
              component={AddPublisher}
            />
            <PrivateRoute
              exact
              path="/account"
              layout={OnlineLayout}
              component={Account}
            />
            <PrivateRoute
              exact
              path="/edituser/:id"
              layout={OnlineLayout}
              component={EditUser}
            />
            <PrivateRoute
              exact
              path="/readers"
              layout={OnlineLayout}
              component={Readers}
            />
            <PrivateRoute
              exact
              path="/addreader/:id"
              layout={OnlineLayout}
              component={AddReader}
            />
            <PrivateRoute
              exact
              path="/editreader/:id"
              layout={OnlineLayout}
              component={EditReader}
            />
            <PrivateRoute
              exact
              path="/searchreaders/:id"
              layout={OnlineLayout}
              component={SearchReaders}
            />
            <PrivateRoute
              exact
              path="/uploadadditional/:id"
              layout={OnlineLayout}
              component={UploadAdditional}
            />
            <PrivateRoute
              exact
              path="/uploadreplacement/:id"
              layout={OnlineLayout}
              component={UploadReplacement}
            />
            <PrivateRoute
              exact
              path="/uploadaddandcombine/:id"
              layout={OnlineLayout}
              component={UploadAddAndCombine}
            />
            <PrivateRoute
              exact
              path="/payments/"
              layout={OnlineLayout}
              component={Payments}
            />
            <PrivateRoute
              exact
              path="/managedata/"
              layout={OnlineLayout}
              component={ManageData}
            />
            <PrivateRoute
              exact
              path="/emailtemplates/"
              layout={OnlineLayout}
              component={EmailTemplates}
            />
            <PrivateRoute
              exact
              path="/purchasereceipt/:id"
              layout={OnlineLayout}
              component={PurchaseReceipt}
            />
            <PrivateRoute
              exact
              path="/renewalreceipt/:id"
              layout={OnlineLayout}
              component={RenewalReceipt}
            />
            <PrivateRoute
              exact
              path="/thirdparty/"
              layout={OnlineLayout}
              component={ThirdParty}
            />
            <PrivateRoute
              exact
              path="/uploadpostcodes/"
              layout={OnlineLayout}
              component={UploadPostcodes}
            />
            <PrivateRoute
              exact
              path="/testst/"
              layout={OnlineLayout}
              component={SecureTrading}
            />
            <PrivateRoute
              exact
              path="/orderDetails/:id"
              layout={OnlineLayout}
              component={OrderDetails}
            />

            <PublicRoute
              exact
              path="/ordersuccess"
              layout={StoreLayout}
              component={OrderSuccess}
            />
            <PublicRoute
              exact
              path="/ordercancel"
              layout={StoreLayout}
              component={OrderCancel}
            />
            <Route exact path="/contactme" component={ContactMe} />
            <Route exact path="/howto" component={HowItWorks} />

            <PublicRoute
              exact
              path="/:id"
              layout={StoreLayout}
              component={Storefront}
            />

            <PublicRoute
              exact
              path="/:id/forgot"
              layout={StoreLayout}
              component={StoreForgottenPassword}
              storeForgot
            />

            <Route exact path="/" component={Landing} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}
export default App;
