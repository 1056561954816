import React from 'react'
import { Button, Grid, Header, Image, Segment } from 'semantic-ui-react';
import axios from 'axios';

//import Logo from '../../assets/img/ms200blk.png';
import Logo from '../../assets/img/ms200blkNew.png';

class Success extends React.Component {
  handleClick = (e) => {
    window.location.href = "/login";
  }

  getStripeRedirect = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const payment_intent = urlParams.get('payment_intent')
    const redirect_status = urlParams.get('redirect_status')

    return { payment_intent, redirect_status }
  }

  async componentDidMount() {

    // Checking whether we are coming back from redirect
    const { payment_intent, redirect_status } = this.getStripeRedirect()
    if( payment_intent && redirect_status === 'succeeded'){
      // Get product name
      const data = JSON.parse( localStorage.getItem("data") )
      data.paymentIntentId = payment_intent
      
      // Register user
      await axios.post(`/api/users/v2/register/`, { ...data })

      // // Remove sensible data
      // localStorage.removeItem("data")

    }

  }
  render() {
    return (

      <div className='login-form'>
        <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
      body { background: #2185d0; }
    `}
        </style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>

            <Segment stacked>
              <Header as='h2' color='black' textAlign='center'>
                <Image src={Logo} alt='Magstand Logo' style={{ width: "150px" }} />
              </Header>
              <p textAlign='center'>Thank you for choosing MagStand, an email confirmation and instructions have been emailed to you.</p>
              <Button color='blue' fluid size='large' style={{ marginTop: "20px" }} onClick={this.handleClick}>
                Login
            </Button>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default Success;