import React from 'react';
import { useState, useEffect } from "react";

// See: https://github.com/stripe/stripe-js
//import { loadStripe } from "@stripe/stripe-js";
import { loadStripe } from '@stripe/stripe-js/pure';

import { Elements } from "@stripe/react-stripe-js";
import axios from 'axios';
import { Button, Modal } from 'semantic-ui-react';



import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
//import "./CheckoutContainer.css"


export const PRICE_TYPE = {
  VAT: 'VAT', TAXFREE: 'TAXFREE'
}

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// const stripePromise = loadStripe("pk_test_17bINGsvNYKKQnQtkakWwx4N00bKyMmkE5");

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  useEffect( () => {
    if( props.triggerSubmit == null ) return
    // Triggering handle
    handleSubmit()
  }, [props.triggerSubmit])
  


  const handleSubmit = async () => {

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.origin + '/success',
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const handleSubmitEvent = (e) => {
    e.preventDefault()
    handleSubmit()
  }


  return (
    <>
    <form id="payment-form" onSubmit={handleSubmitEvent}>
      <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
      
      {/* <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button> */}
      {message && <div id="payment-message">{message}</div>}
    </form>
    </>
  );
}





export const CheckoutModal = (props) => {
  const [clientSecret, setClientSecret] = useState("");
  const [ triggerSubmit, setTriggerSubmit ] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);


  useEffect(() => {
    ( async () => {

      const body = {         
        plan_type: props.planType.key,
        app_option: props.appOption, 
        email: props.email || Math.floor( Math.random()*100 ) + "email@admin.com",
        username: props.username || Math.floor( Math.random()*100 ) + "email@admin.com",
        price_type: props.price_type,
        promocode: props.promocode?.id
      }


      console.log("-------------------")
      console.log("-------------------", body)
      console.log("-------------------", props)
      console.log("-------------------")

      const response = await axios.post(`/api/users/v2/subscribe/`, body)
      //console.log("response.data ", response.data)
      setClientSecret(response.data.client_secret)
    })();

  }, [props.promoCode]);

  useEffect( () => {
    ( async () => {
      let response = await axios.get(`/api/account/settings/`)
      const stripePromise = await loadStripe(response.data.publishableKey);
      // NOTE: This is required to make the stripePromise to be reloaded
      setStripePromise(stripePromise);
    })();
  }, [])

  const options = {
    clientSecret,
    appearance: { theme: 'flat' },
  };

  const price = props.price?props.price:null
  return  <Modal onClose={() => props.onClose()}
            open={props.open} size="tiny" >
            <Modal.Header>Flickread Checkout</Modal.Header>
            <Modal.Content>
              <div className="Label" style={{ padding: "1em 0", fontSize: "1.1em", textAlign: 'center'}}>
                You will be charged £{price?.toFixed(2)} every month (inc. VAT)
              </div>
              <div className="App">
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm triggerSubmit={triggerSubmit} />
                  </Elements>
                )}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' 
                onClick={() => setTriggerSubmit(triggerSubmit?triggerSubmit+1:1)}>
                Pay
              </Button>
            </Modal.Actions>
          </Modal>
}