// Main Layout Side Menu

import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { logoutUser } from "../../../actions/authActions";
import PropTypes from "prop-types";
import TextIcon from "../../../extension/TextIcon";

class SideMenu extends Component {
  state = {
    activeItem: "dashboard",
    currencyIcon: "pound",
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  changeSize = () => this.setState({ smallSidebar: !this.props.smallMenu });

  // Logout the user
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  getMenu() {
    const { activeItem } = this.state;
    let currencyIcon;
    switch (this.props.user.mainCurrency) {
      case "GBP":
        currencyIcon = "pound";
        break;
      case "USD":
        currencyIcon = "dollar";
        break;
      case "EUR":
        currencyIcon = "euro";
        break;
      default:
        currencyIcon = "pound";
        break;
    }

    if (
      this.props.user.userType === "superuser" ||
      this.props.user.userType === "reseller"
    ) {
      return (
        <Menu
          fixed="left"
          borderless
          className={(this.props.smallMenu ? "small-side1" : "") + " side1"}
          vertical
        >
          <Menu.Item
            as={Link}
            to={"/publishers"}
            name="publishers"
            active={activeItem === "publishers"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="users">
              Publishers
            </TextIcon>
          </Menu.Item>
          <Menu.Item
            name="logout"
            active={activeItem === "logout"}
            onClick={this.onLogoutClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="log out">
              Logout
            </TextIcon>
          </Menu.Item>
        </Menu>
      );
    }

    // Publisher Side Menu Links
    if (
      this.props.user.userType === "publisher" ||
      this.props.user.userType === "pubadmin"
    ) {
      return (
        <Menu
          fixed="left"
          borderless
          className={(this.props.smallMenu ? "small-side1" : "") + " side1"}
          vertical
        >
          <Menu.Item
            as={Link}
            to={"/dashboard"}
            name="dashboard"
            active={activeItem === "dashboard"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="home">
              Analytics
            </TextIcon>
          </Menu.Item>

          <Menu.Item
            as={Link}
            to={"/publications"}
            name="publications"
            active={activeItem === "publications"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="book">
              Publications
            </TextIcon>
          </Menu.Item>

          <Menu.Item
            as={Link}
            to={"/backIssues"}
            name="backIssues"
            active={activeItem === "backIssues"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="book">
              Single &amp; Back Issues
            </TextIcon>
          </Menu.Item>

          <Menu.Item
            as={Link}
            to={"/users"}
            name="users"
            active={activeItem === "users"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="users">
              Users
            </TextIcon>
          </Menu.Item>

          <Menu.Item
            as={Link}
            to={"/readers"}
            name="readers"
            active={activeItem === "readers"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="address card">
              Readers
            </TextIcon>
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={"/managedata"}
            name="managedata"
            active={activeItem === "managedata"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="wrench">
              Manage Data
            </TextIcon>
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={"/payments"}
            name="payments"
            active={activeItem === "payments"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name={currencyIcon}>
              Sales Ledger
            </TextIcon>
          </Menu.Item>
          <Menu.Item>
            <a
              href="/account"
              name="account"
              style={{ color: "black" }}
              active={activeItem === "account"}
              onClick={this.handleItemClick}
            >
              <TextIcon hideText={this.props.smallMenu} name="user">
                My Account
              </TextIcon>
            </a>
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={"/emailtemplates"}
            name="email"
            active={activeItem === "email"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="mail">
              Email Templates
            </TextIcon>
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={"/thirdparty"}
            name="third"
            active={activeItem === "third"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="code">
              Third Party API
            </TextIcon>
          </Menu.Item>
          <Menu.Item
            name="logout"
            active={activeItem === "logout"}
            onClick={this.onLogoutClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="log out">
              Logout
            </TextIcon>
          </Menu.Item>
        </Menu>
      );
    } else {
      // Non-Publisher (Reader Admin/Mailer) Links
      return (
        <Menu
          fixed="left"
          borderless
          className={(this.props.smallMenu ? "small-side1" : "") + " side1"}
          vertical
        >
          <Menu.Item
            as={Link}
            to={"/readers"}
            name="readers"
            active={activeItem === "readers"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="address card">
              Readers
            </TextIcon>
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={"/account"}
            name="account"
            active={activeItem === "account"}
            onClick={this.handleItemClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="user">
              My Account
            </TextIcon>
          </Menu.Item>
          <Menu.Item
            name="logout"
            active={activeItem === "logout"}
            onClick={this.onLogoutClick}
          >
            <TextIcon hideText={this.props.smallMenu} name="log out">
              Logout
            </TextIcon>
          </Menu.Item>
        </Menu>
      );
    }
  }
  render() {
    return (
      <div className="parent1">
        <div className={(this.props.smallMenu ? "small-side1 " : "") + "side1"}>
          {this.getMenu()}
        </div>
        <div
          className={
            (this.props.smallMenu ? "small-content1 " : "") + "content1"
          }
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

SideMenu.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { logoutUser })(SideMenu);
