import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { Segment, Header, Button } from 'semantic-ui-react';
import { getPublication } from '../../../actions/publicationActions';

class StepOne extends Component {
  state = {
    pubId: null,
    publicationOptions: [],
    userkey: ''
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pubid) {
      console.log(nextProps.pubid)
      if (nextProps.pubid !== this.state.pubId) {

        this.setState({ pubId: nextProps.pubid })
        this.props.getPublication(nextProps.pubid);
      }
    }

    if (nextProps.publication) {
      console.log(nextProps.publication)
      if (nextProps.publication.userkey !== undefined && nextProps.publication.userkey !== "" && nextProps.publication.userkey !== null) {
        this.setState({ userkey: nextProps.publication.userkey })
      } else {
        this.setState({ userkey: '' })

      }
      this.setState({ pubName: nextProps.publication.publicationName })
    }
  }

  generateKey = (id) => {
    axios.get(`/api/publication/generateuserkey/${this.state.pubId}`)
      .then((response) => {
        this.setState({
          userkey: response.data.userkey
        })
      })
      .catch((error) => {
        console.log(error);
      });

  }

  render() {



    return (
      <React.Fragment>
        <Segment>
          <Header>Step One - API Key</Header>
          {this.state.userkey !== '' ? <p>Your API key for this publication is {this.state.userkey}</p> : <React.Fragment><Button onClick={() => this.generateKey(this.state.pubId)} type="button" color="blue">Generate new API Key</Button></React.Fragment>}
        </Segment>
        <Segment>
          <Header>Step Two - API Integration</Header>
          <Header as="h3">API CALL</Header>
          <p>Url: https://store.magstand.com/api/external/checksubscriber</p>
          <p>HTTP Method: POST</p>
          <p>The following is an example request:<br />
            oauth: "{this.state.userkey}"<br />
            email: "John.Doe@email.com"<br />
            password: "Doe123"</p>
          <p>The following is an example response:<br />
            success: "true"<br />
            single: "false"<br />
            fname: "John"<br />
            sname: "Doe<br />
            subStartDate: 1570638334<br />
            subEndDate: 1602260734</p>
          <p>The following is an example JSON response:<br />
            &#123;<br />
            "success": "true",<br />
            "single": "false",<br />
            "fname": "John",<br />
            "sname": "Doe",<br />
            "subStartDate": 1570638334,<br />
            "subEndDate": 1602260734<br />
            &#125;</p>


        </Segment>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  publication: state.publication.publication
});

export default connect(mapStateToProps, { getPublication })(StepOne);