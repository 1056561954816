import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Button, Header } from 'semantic-ui-react';
import RenderFieldRedux from '../../common/ReduxForm/RenderFieldRedux';
import axios from 'axios';
import asyncValidate from './functions/asyncValidate';

class CompanyInfo extends React.Component {
  checkEmail = (values) => {
    axios
      .get(`/api/users/email/${values.email}`)
      .then(res => {
        if (res.data !== "success") {
          throw new SubmissionError("Email already exists");
        } else {
          this.props.handleSubmit();
        }
      })
  }

  onPaste = (e) => {
    e.preventDefault();
  }
  
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit} size='large'>
        <Header>
          Please fill in all the fields below and click Next to continue
        </Header>
        <Field
          name="firstName"
          component={RenderFieldRedux}
          type="text"
          label="First Name"
        />
        <Field
          name="lastName"
          component={RenderFieldRedux}
          type="text"
          label="Last Name"
        />
        <Field
          name="email"
          component={RenderFieldRedux}
          type="text"
          label="Email Address"
          autocomplete="new-email"
        />
        <Field
          name="confirmemail"
          component={RenderFieldRedux}
          type="text"
          label="Confirm Email Address"
          autocomplete="new-email"
          onpaste={this.onPaste}
        />
        <Field
          name="password"
          component={RenderFieldRedux}
          type="password"
          label="Password"
          autocomplete="new-password"
        />
        <Field
          name="password2"
          component={RenderFieldRedux}
          type="password"
          label="Confirm Password"
          onpaste={this.onPaste}
        />
        <Button color='blue'
          disabled>
          Previous
            </Button>
        <Button color='blue'>
          Next
            </Button>
      </form>
    )
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.firstName) {
    errors.firstName = 'Required'
  }

  if (!formValues.lastName) {
    errors.lastName = 'Required'
  }

  if (!formValues.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
    errors.email = 'Invalid email address'
  }

  if (!formValues.confirmemail) {
    errors.confirmemail = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.confirmemail)) {
    errors.confirmemail = 'Invalid email address'
  } else if (formValues.email !== formValues.confirmemail) {
    errors.confirmemail = 'Emails must match. Please re-enter'
  }

  if (!formValues.password) {
    errors.password = 'Required'
  } else if (formValues.password.length < 6) {
    errors.password = 'Must be greater than 6 characters'
  }

  if (!formValues.password2) {
    errors.password2 = 'Required'
  } else if (formValues.password2 !== formValues.password) {
    errors.password2 = 'Passwords must match'
  }

  return errors;
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  asyncValidate,
  asyncBlurFields: ['email']
})(CompanyInfo);
