import axios from "axios";
import {
  CREATE_ORDER,
  GET_ERRORS,
  GET_TOTAL_ORDER_AMOUNT,
  GET_ORDERS,
  LOADING,
  CANCEL_ORDER,
  CREATE_ST_ORDER,
  GET_ORDER,
  CREATE_BACKISSUE_ORDER,
} from "./types";

// Create Storefront Order
export const createOrder = (paymentDetails, history) => (dispatch) => {
  axios
    .post("/api/order/createOrder", paymentDetails)
    .then((res) => {
      dispatch({
        type: CREATE_ORDER,
        payload: res.data,
      });
      dispatch(getTotalOrderAmount("3"));
      history.push("/ordersuccess");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      history.push("/ordersuccess");
    });
};

// Create Storefront Order
export const createOrderv2 = (paymentDetails) => {
  
  return axios.post("/api/order/createOrder", paymentDetails)
};
export const createOrderResult = (isSuccess, res) => (dispatch) => {
  if( isSuccess ){
    dispatch({ type: CREATE_ORDER, payload: res.data, });
    dispatch(getTotalOrderAmount("3"));
  }else{
    dispatch({ type: GET_ERRORS, payload: res.data, });
  }
}

// Create Storefront ST Order
export const createSTOrder = (paymentDetails, history) => (dispatch) => {
  axios
    .post("/api/order/createSTOrder", paymentDetails)
    .then((res) => {
      dispatch({
        type: CREATE_ST_ORDER,
        payload: res.data,
      });
      dispatch(getTotalOrderAmount("3"));
      history.push("/ordersuccess");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      history.push("/ordersuccess");
    });
};

// Create Storefront Order
export const cancelOrder = (paymentDetails, history) => (dispatch) => {
  axios
    .post("/api/order/cancelOrder", paymentDetails)
    .then((res) => {
      dispatch({
        type: CANCEL_ORDER,
        payload: res.data,
      });
      history.push("/ordercancel");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      history.push("/ordercancel");
    });
};

// Get total order amount for the publisher
export const getTotalOrderAmount = (id) => (dispatch) => {
  axios
    .get(`/api/order/totalorders/${id}`)
    .then((res) => {
      dispatch({
        type: GET_TOTAL_ORDER_AMOUNT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total orders for the publisher
export const getTotalOrders = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/order/allorders/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ORDERS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

// Get order details by id
export const getOrder = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/order/order/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ORDER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

// Create Storefront back issue Order
export const createBackIssueOrder = (paymentDetails, history) => (dispatch) => {
  axios
    .post("/api/order/createBackIssueOrder", paymentDetails)
    .then((res) => {
      dispatch({
        type: CREATE_BACKISSUE_ORDER,
        payload: res.data,
      });
      history.push("/ordersuccess");
    })
    .catch((err) => {
      dispatch({
        type: CREATE_BACKISSUE_ORDER,
        payload: err.response.data,
      });
      history.push("/ordersuccess");
    });
};

// Loading
export const loading = () => {
  return {
    type: LOADING,
  };
};
