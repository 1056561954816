import { GET_USERS, ADD_USER, UPDATE_USER, GET_USER, DELETE_USER, GET_ALL_USERS, DEACTIVATE_USER, ADD_PUBLISHER, GET_PUBLISHERS } from '../actions/types';
import { updateObject } from '../utils/utility';

// Create an initial state for the model
const initialState = {
  users: []
}

// Action Creator to get the users under the logged in account parentPublisher
const getUsers = (state, action) => {
  return updateObject(state, {
    users: action.payload
  });
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
    case GET_ALL_USERS:
    case GET_PUBLISHERS:
      return getUsers(state, action)
    case ADD_USER: return state;
    case ADD_PUBLISHER: return state;
    case GET_USER: return {
      ...state,
      user: action.payload,
      loading: false
    }
    case UPDATE_USER: return state;
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter(user => user._id !== action.payload)
      };
    case DEACTIVATE_USER:
      return {
        ...state,
        users: state.users.filter(user => user._id !== action.payload)
      };

    default: return state;
  }
}