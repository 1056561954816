import React, { Component } from "react";
import { Card, Image, Button, Reveal } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { deactivatePublication } from "../../../actions/publicationActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";

class PublicationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publication: this.props.publication,
    };
  }

  componentDidMount() {
    this.setState({ publication: this.props.publication });
  }

  // Pass the image file to the server in a FormData (multi/enc) object
  fileSelectedHandler = (e) => {
    this.setState({
      selectedFile: e.target.files[0].name,
    });
    const fd = new FormData();
    fd.append("image", e.target.files[0], e.target.files[0].name);
    axios
      .post(`/api/publication/image/${this.state.publication._id}`, fd)
      .then((res) => {
        toastr.success("Publication Image updated");
        this.setState({ publication: res.data });
      })
      .catch((err) => console.log(err.response.data));
  };

  // Pass the logo image file to the server in a FormData (multi/enc) object
  logoSelectedHandler = (e) => {
    this.setState({
      selectedLogo: e.target.files[0].name,
    });
    const fd = new FormData();
    console.log(this.state.publication._id);
    fd.append("image", e.target.files[0], e.target.files[0].name);
    axios
      .post(`/api/publication/logo/${this.state.publication._id}`, fd)
      .then((res) => {
        toastr.success("Publication Logo updated");
        this.setState({ publication: res.data });
      })
      .catch((err) => console.log(err.response.data));
  };

  upload() {
    document.getElementById("myfile").click();
  }

  uploadLogo() {
    document.getElementById("mylogo").click();
  }

  onDeleteClick = (id) => {
    this.props.deactivatePublication(id);
  };

  render() {
    return (
      <Card>
        <Reveal
          style={{ margin: "10px", padding: "0px" }}
          animated="move"
          key={this.state.publication.publicationName}
        >
          <Reveal.Content visible>
            <div style={{ height: "360px", backgroundColor: "#fff", display:'flex', alignItems:'center' }}>
              <Image
                src={this.state.publication.thumbImageName}
                style={{ margin: "0px" }}
                size="massive"
              />
            </div>
          </Reveal.Content>
          <Reveal.Content hidden>
            <Card.Content>
              <Card.Description>
                <div
                  className="upload-btn-wrapper"
                  style={{ marginBottom: "10px" }}
                >
                  <Button
                    fluid
                    style={{ marginBottom: "10px" }}
                    onClick={() => {
                      document
                        .getElementById(this.state.publication._id)
                        .click();
                    }}
                  >
                    Change Cover Image
                  </Button>
                  <input
                    type="file"
                    hidden
                    name="myfile"
                    id={this.state.publication._id}
                    onChange={this.fileSelectedHandler}
                  />
                </div>
                <Link to={`/editpublication/${this.state.publication._id}`}>
                  <Button fluid style={{ marginBottom: "10px" }}>
                    Edit Details
                  </Button>
                </Link>

                <Link to={`/promocodes/${this.state.publication._id}`}>
                  <Button fluid style={{ marginBottom: "10px" }}>
                    Promo Codes
                  </Button>
                </Link>
                <Link to={`/${this.state.publication.urlKey}`} target="_blank">
                  <Button fluid style={{ marginBottom: "10px" }}>
                    View Storefront
                  </Button>
                </Link>
                <Button
                  type="button"
                  onClick={(e) =>
                    window.confirm(
                      "Are you sure you want to remove this Publication?"
                    ) && this.onDeleteClick(this.state.publication._id)
                  }
                  fluid
                  style={{ marginBottom: "10px" }}
                >
                  Remove Publication
                </Button>
                <div className="upload-btn-wrapper">
                  <Button
                    fluid
                    style={{ marginBottom: "10px" }}
                    onClick={() => {
                      document
                        .getElementById("logo" + this.state.publication._id)
                        .click();
                    }}
                  >
                    Change Logo
                  </Button>
                  <input
                    type="file"
                    hidden
                    name="mylogo"
                    id={"logo" + this.state.publication._id}
                    onChange={this.logoSelectedHandler}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "70px",
                      display: "block",
                      margin: "0 auto",
                    }}
                    src={this.state.publication.logo}
                    alt={this.state.publication.publicationName}
                  />
                </div>
              </Card.Description>
            </Card.Content>
          </Reveal.Content>
        </Reveal>
      </Card>
    );
  }
}

PublicationItem.propTypes = {
  deactivatePublication: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deactivatePublication })(
  PublicationItem
);
