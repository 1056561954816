import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, Segment, Header, Form, Image } from 'semantic-ui-react';
import RenderFieldLeftRedux from '../../../common/ReduxForm/RenderFieldLeftRedux';
import Flickread from '../../../../assets/img/frurlkey.png';

class UserKeyDetails extends Component {
  render() {
    const { handleSubmit, submitting, previousPage } = this.props;
    return (
      <Form onSubmit={handleSubmit} size='large'>
        <Grid stackable columns={2}>
          <Grid.Column>
            <Header textAlign='center'>Link Apazine/Flickread User key</Header>
            <Field
              name="userkey"
              component={RenderFieldLeftRedux}
              type="text"
              label="User Key"
            />
            <div style={{ padding: "10px" }}>
              <Header>Apazine</Header>
              <p>Login and go to Manage App and at the top of the screen you can access your User Key</p>
              <Header >Flickread</Header>
              <p>Login and go to your Account -> Manage Settings and at the bottom of the screen you can access (or generate one if you don't have one) your User Key</p>
              <p>Please note: The key will be the same if using both platforms</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <Image size='big' src={Flickread} centered />

          </Grid.Column>
        </Grid>
        <Segment>
          <div style={{ margin: "10px", textAlign: "center" }}>
            <Button color='blue' style={{ width: "100px" }}
              onClick={previousPage}>
              Previous
            </Button>
            <Button color='green'
              disabled={submitting}>
              Confirm and Submit
            </Button>
          </div>

        </Segment>
      </Form>
    )
  }
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(UserKeyDetails);