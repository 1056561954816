// Login Component

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginSuperUser } from '../../actions/authActions';
import { Form, Grid, Header, Image, Segment, Button } from 'semantic-ui-react';
import RenderFieldWithRightLabel from '../common/NormalForm/RenderFieldWithRightLabel';

//import Logo from '../../assets/img/ms200blk.png';
import Logo from '../../assets/img/ms200blkNew.png';

class SuperLogin extends Component {
  state = {
    email: '',
    password: '',
    errors: {}
  };

  // redirect the user back to the landing page if they're already logged in
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      switch (this.props.auth.user.userType) {
        case 'publisher':
        case 'pubadmin':
          return this.props.history.push('/dashboard');
        case 'reseller':
        case 'superuser':
          return this.props.history.push('/publishers');

        default:
          return this.props.history.push('/readers');
      }
    }
  }

  // redirect the user back to the landing page if they're already logged in
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      switch (nextProps.user.userType) {
        case 'publisher':
        case 'pubadmin':
          return this.props.history.push('/dashboard');
        case 'reseller':
        case 'superuser':
          return this.props.history.push('/publishers');
        default:
          return this.props.history.push('/readers');
      }
    }


    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  // Submit function
  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    // Call the loginUser Action Creator
    this.props.loginSuperUser(userData);
  }

  // Controlled Component Change event
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;

    return (
      <div className='login-form'>
        <style>{`
          body > div,
          body > div > div,
          body > div > div > div.login-form {
            height: 100%;
          }
          body { background: #2185d0; }
        `}
        </style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Segment>
              <Header as='h2' color='black' textAlign='center'>
                <Image src={Logo} alt='Magstand Logo' style={{ width: "150px" }} />
              </Header>
              <Header as='h3' color='black' textAlign='center'>Login (Super)</Header>
              <Form onSubmit={this.onSubmit}>
                <RenderFieldWithRightLabel
                  placeholder='Email'
                  name='email'
                  label='Email/Username'
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />
                <RenderFieldWithRightLabel
                  name="password"
                  type="password"
                  label="Password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                />
                <div style={{ padding: "10px" }}>
                  <Button color="blue" fluid type="submit">Log In</Button>
                </div>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>

    );
  }
}

SuperLogin.propTypes = {
  loginSuperUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  user: state.auth.user
});

export default connect(mapStateToProps, { loginSuperUser })(SuperLogin);
