// Renders a Redux Form Textarea Field

import React from "react";
import { Form } from "semantic-ui-react";
import PropTypes from "prop-types";

const RenderTextAreaRedux = ({
  input,
  label,
  placeholder,
  maxLength,
  meta: { touched, error },
}) => {
  return (
    <div>
      <div style={{ padding: "10px" }}>
        <Form.TextArea
          {...input}
          size="small"
          label={label}
          maxLength={maxLength}
          placeholder={placeholder}
          error={touched && error ? true : false}
        />
        {error && touched && (
          <div className="ui pointing red basic label">{error}</div>
        )}
      </div>
    </div>
  );
};

RenderTextAreaRedux.propTypes = {
  label: PropTypes.string,
};

export default RenderTextAreaRedux;
