// Description
//
// Action Creators for Publication related actions

import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset } from 'redux-form';

import {
  GET_ERRORS,
  CHECK_NUM_PUBS,
  LOADING,
  ADD_PUBLICATION,
  GET_PUBLICATIONS,
  GET_PUBLICATIONS_WITH_BACKISSUE,
  GET_PUBLICATION,
  CLEAR_ERRORS,
  GET_PROMOS,
  DELETE_PROMO,
  ADD_PROMO,
  GET_PROMO,
  GET_PUBLICATION_BY_URLKEY,
  GET_PAYPAL_DETAILS,
  UPDATE_PUBLICATION,
  UPDATE_PROMO,
  UPDATE_PAGEVIEWS,
  UPDATE_RECEIPT_TEXT,
  CLEAR_PUB_DATA,
  UPDATE_RENEWAL_TEXT,
  DEACTIVATE_PUBLICATION,
} from './types';

// Get Publications for the logged in Publisher
export const getPublications = (id) => async (dispatch) => {
  console.log('id' + id);
  dispatch(loading());
  await axios
    .get(`/api/publication/${id}`)
    .then((res) =>
      dispatch({
        type: GET_PUBLICATIONS,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_PUBLICATIONS,
        payload: null,
      }),
    );
};

// Get Publications for the logged in Publisher
export const getPublicationsWithBackIssue = (userId) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(`/api/publication/backIssue/${userId}`)
    .then((res) =>
      dispatch({
        type: GET_PUBLICATIONS_WITH_BACKISSUE,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_PUBLICATIONS_WITH_BACKISSUE,
        payload: null,
      }),
    );
};

// Get Publications for the logged in Publisher
export const getPublicationsReaders = (id) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(`/api/publication/${id}`)
    .then((res) =>
      dispatch({
        type: GET_PUBLICATIONS,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_PUBLICATIONS,
        payload: null,
      }),
    );
};

// Get Publication by ID
export const getPublication = (id) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .get(`/api/publication/getpublication/${id}`)
    .then((res) =>
      dispatch({
        type: GET_PUBLICATION,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      }),
    );
};

// Get Publication by urlKey
export const getPublicationByUrlKey = (id) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .get(`/api/publication/getpublicationbyurl/${id}`)
    .then((res) =>
      dispatch({
        type: GET_PUBLICATION_BY_URLKEY,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      }),
    );
};

// Get the publisher's paypal details
export const getPaypalDetails = (id) => (dispatch) => {
  axios
    .get(`api/publication/paypaldetails//${id}`)
    .then((res) =>
      dispatch({
        type: GET_PAYPAL_DETAILS,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      }),
    );
};

// Check Current Number of Publications the Publisher has added
export const checkNumPubs = (id) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(`/api/publication/getnumberpublication/${id.id}`)
    .then((res) =>
      dispatch({
        type: CHECK_NUM_PUBS,
        payload: res.data,
      }),
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Check Current Number of Publications the Publisher has added
export const getNumPubs = (id) => async (dispatch) => {
  await axios
    .get(`/api/publication/totalpublications/${id.id}`)
    .then((res) =>
      dispatch({
        type: CHECK_NUM_PUBS,
        payload: res.data,
      }),
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Add Publication
export const addPublication = (publicationData, history) => (dispatch) => {
  dispatch(loading());
  axios
    .post('/api/publication', publicationData)
    .then((res) => {
      dispatch({
        type: ADD_PUBLICATION,
        payload: res.data,
      });
      dispatch(reset('addwizard'));
      dispatch(getNumPubs('3'));
      toastr.success('New Publication added');
      history.push('/publications');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      toastr.error('Publication not added', 'Please fix the errors and retry');
    });
};

// Update Publication
export const updatePublication = (publicationData, history) => (dispatch) => {
  dispatch(loading());
  axios
    .post('/api/publication/updatepublication', publicationData)
    .then((res) => {
      dispatch({
        type: UPDATE_PUBLICATION,
        payload: res.data,
      });
      dispatch({
        type: GET_PUBLICATIONS,
        payload: res.data,
      });
      toastr.success('Publication updated successfully');
      history.push('/publications');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      toastr.error(
        'Publication not updated',
        'Please fix the errors and retry',
      );
    });
};

// Update Receipt Text
export const updateReceiptText = (receiptData, history) => (dispatch) => {
  dispatch(clearPubData());
  dispatch(loading());
  axios
    .post('/api/publication/updatereceipttext', receiptData)
    .then((res) => {
      dispatch({
        type: UPDATE_RECEIPT_TEXT,
        payload: res.data,
      });
      toastr.success('Purchase Receipt text updated successfully');
      history.push('/emailtemplates');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      toastr.error(
        'Purchase Receipt text not updated',
        'Please fix the errors and retry',
      );
    });
};

// Update Receipt Text
export const updateRenewalText = (receiptData, history) => (dispatch) => {
  dispatch(clearPubData());
  dispatch(loading());
  axios
    .post('/api/publication/updaterenewaltext', receiptData)
    .then((res) => {
      dispatch({
        type: UPDATE_RENEWAL_TEXT,
        payload: res.data,
      });
      toastr.success('Renewal Email text updated successfully');
      history.push('/emailtemplates');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      toastr.error(
        'Renewal Email text not updated',
        'Please fix the errors and retry',
      );
    });
};

// Deactivate Publication
export const deactivatePublication = (id) => (dispatch) => {
  axios
    .post(`/api/publication/deactivatepublication/${id}`)
    .then((res) =>
      dispatch({
        type: DEACTIVATE_PUBLICATION,
        payload: id,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    );
};

// Get Promo Codes for a Publication ID
export const getPromos = (id) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(`/api/publication/promo/${id}`)
    .then((res) => {
      dispatch({
        type: GET_PROMOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PROMOS,
        payload: null,
      });
    });
};

// Get Promo for backend
export const getPromoDets = (id) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .get(`/api/publication/promo/getpromodets/${id}`)
    .then((res) =>
      dispatch({
        type: GET_PROMO,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      }),
    );
};

// Get Promo for storefront
export const getPromo = (id) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .get(`/api/publication/promo/getpromo/${id}`)
    .then((res) =>
      dispatch({
        type: GET_PROMO,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      }),
    );
};

// Delete Promo Code
export const deletePromoCode = (id) => (dispatch) => {
  axios
    .delete(`/api/publication/promo/${id}`)
    .then((res) =>
      dispatch({
        type: DELETE_PROMO,
        payload: id,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      }),
    );
};

// Add Promo Code
export const addPromoCode = (promoData, history) => (dispatch) => {
  dispatch(loading());
  axios
    .post('/api/publication/promo', promoData)
    .then((res) => {
      dispatch({
        type: ADD_PROMO,
        payload: res.data,
      });
      toastr.success('New Promo Code added');
      history.push(`/promocodes/${promoData.pubid}`);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      toastr.error('Promo Code not added', 'Please fix the errors and retry');
    });
};

// Add Promo Code
export const updatePromoCode = (promoData, history) => (dispatch) => {
  dispatch(loading());
  axios
    .post('/api/publication/updatepromo', promoData)
    .then((res) => {
      dispatch({
        type: UPDATE_PROMO,
        payload: res.data,
      });
      toastr.success('Promo Code updated');
      history.push(`/promocodes/${promoData.pubid}`);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      toastr.error('Promo Code not updated', 'Please fix the errors and retry');
    });
};

// Get Promo for storefront
export const updatePageViews = (id) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .get(`/api/publication/updatepageviews/${id}`)
    .then((res) =>
      dispatch({
        type: UPDATE_PAGEVIEWS,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      }),
    );
};

// Loading
export const loading = () => {
  return {
    type: LOADING,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

// Clear data
export const clearPubData = () => {
  return {
    type: CLEAR_PUB_DATA,
  };
};
