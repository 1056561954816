// Description
// 
// Action Creators for Reader actions

import axios from 'axios';
import { toastr } from 'react-redux-toastr'

import {
  ADD_READER,
  SEARCH_READERS,
  UPLOAD_ADDITIONAL,
  LOADING,
  GET_ERRORS,
  GET_READER,
  RESET_STATE,
  CLEAR_ERRORS,
  UPDATE_READER,
  REMOVE_READER,
  REACTIVATE_READER,
  GET_TOTAL_READERS,
  MAILER_DATA,
  FINISHED_LOADING,
  SHOW_MODAL,
  UPLOAD_REPLACEMENT,
  UPLOAD_ADD_AND_COMBINE,
  GET_NOFULLNAMECOUNT,
  GET_NOFULLNAME,
  GET_NOPOSTCODECOUNT,
  GET_EXPIRED,
  GET_EXPIRED_NEXT_THIRTY,
  GET_EXPIRED_NEXT_THIRTY_COUNT,
  GET_EXPIRED_COUNT,
  GET_NOPOSTCODE,
  AUTO_FIX_FULLNAME,
  GET_ALL_DATA,
  GET_DUPLICATE_FULLNAME_COUNT,
  GET_DUPLICATE_FULLNAME,
  GET_DUPLICATE_LASTNAME_COUNT,
  GET_DUPLICATE_LASTNAME,
  GET_DUPLICATE_POSTCODE_COUNT,
  GET_DUPLICATE_POSTCODE,
  GET_DUPLICATE_ADDRESS_COUNT,
  GET_DUPLICATE_ADDRESS,
  DELETE_READER_FULLNAME,
  DELETE_READER_LASTNAME,
  DELETE_READER_POSTCODE,
  DELETE_READER_ADDRESS,
  DELETE_READER,
  CLEAR_DATA,
  UPLOAD_POSTCODES,
  BAD_POSTCODE,
  BAD_POSTCODE_COUNT,
  GET_DUPLICATE_EMAIL_COUNT,
  GET_DUPLICATE_EMAILS,
  DELETE_READER_EMAIL,
  AUTO_FIX_FIRSTNAME,
  GET_NOFIRSTNAME_COUNT,
  GET_NOFIRSTNAME,
  GET_BAD_ADDRESS_DATA_COUNT,
  GET_BAD_ADDRESS_DATA,
  AUTO_FIX_ADDRESS,
  GET_EXPIRED_LAST_THIRTY_COUNT

} from './types';

// Create Reader
export const createReader = (readerData, history) => dispatch => {
  axios
    .post("/api/subscriber", readerData)
    .then(res => {
      dispatch({
        type: ADD_READER,
        payload: res.data
      });
      // Show toast notification
      toastr.success('New Reader added');
      dispatch(getTotalReaders("3"));
      history.push("/readers")
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      toastr.error('Reader not added', 'Please fix the errors and retry');
    });
};

// Create Reader
export const updateReader = (readerData, history) => dispatch => {
  axios
    .post("/api/subscriber/update", readerData)
    .then(res => {
      dispatch({
        type: UPDATE_READER,
        payload: res.data
      });
      // Show toast notification
      toastr.success('Reader updated successfully');
      history.push("/readers")
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      toastr.error('Reader not updated', 'Please fix the errors and retry');
    });
};

// Deactivate Reader
export const deactivateReader = (id, history) => dispatch => {
  axios
    .post(`/api/subscriber/remove/${id}`)
    .then(res => {
      dispatch({
        type: REMOVE_READER,
        payload: res.data
      });
      // Show toast notification
      toastr.success('Reader deactivated successfully');
      dispatch(getTotalReaders("3"));
      history.push("/readers")
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      toastr.error('Reader not removed', 'Please fix the errors and retry');
    });
};

// Delete Reader 
export const deleteReader = (id, history) => dispatch => {
  axios
    .delete(`/api/subscriber/${id}`)
    .then(res => {
      dispatch({
        type: DELETE_READER,
        payload: id
      })
      // Show toast notification
      toastr.success('Reader removed successfully');
      dispatch(getTotalReaders("3"));
      history.push("/readers")
    })
    // .then(res => {
    //   dispatch(getDuplicateLastNameCount(pid));
    // })
    // .then(res => {
    //   dispatch(getDuplicatePostCodeCount(pid));
    // })
    // .then(res => {
    //   dispatch(getExpiredThirtyCount(pid));
    // })
    // .then(res => {
    //   dispatch(getExpiredCount(pid));
    // })
    // .then(res => {
    //   dispatch(getDuplicateAddressCount(pid));
    // })
    // .then(res => {
    //   dispatch(getNoFullNameCount(pid));
    // })
    // .then(res => {
    //   dispatch(getNoFirstNameCount(pid));
    // })
    // .then(res => {
    //   dispatch(getNoPostcodeCount(pid));
    // })
    // .then(res => {
    //   dispatch(getDuplicateEmailCount(pid));
    // })
    // .then(res => {
    //   dispatch(getBadPostcodeCount(pid));
    // })
    .catch(err => {
      console.log(err)
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    }
    );
};

// Delete Reader FullName
export const deleteReaderFullName = (id, pid) => dispatch => {
  axios
    .delete(`/api/subscriber/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_READER_FULLNAME,
        payload: id
      })
    )
    .then(res => {
      dispatch(getDuplicateFullNameCount(pid));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Delete Reader FullName
export const deleteReaderEmail = (id, pid) => dispatch => {
  axios
    .delete(`/api/subscriber/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_READER_EMAIL,
        payload: id
      })
    )
    .then(res => {
      dispatch(getDuplicateEmailCount(pid));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Delete Reader LastName
export const deleteReaderLastName = (id, pid) => dispatch => {
  axios
    .delete(`/api/subscriber/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_READER_LASTNAME,
        payload: id
      })
    )
    .then(res => {
      dispatch(getDuplicateLastNameCount(pid));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Delete Reader PostCode
export const deleteReaderPostCode = (id, pid) => dispatch => {
  axios
    .delete(`/api/subscriber/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_READER_POSTCODE,
        payload: id
      })
    )
    .then(res => {
      dispatch(getDuplicatePostCodeCount(pid));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Delete Reader Address
export const deleteReaderAddress = (id, pid) => dispatch => {
  axios
    .delete(`/api/subscriber/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_READER_ADDRESS,
        payload: id
      })
    )
    .then(res => {
      dispatch(getDuplicateAddressCount(pid));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Remove Reader
export const reactivateReader = (id, history) => dispatch => {
  axios
    .post(`/api/subscriber/reactivate/${id}`)
    .then(res => {
      dispatch({
        type: REACTIVATE_READER,
        payload: res.data
      });
      // Show toast notification
      toastr.success('Reader reactivated successfully');
      dispatch(getTotalReaders("3"));
      history.push("/readers")
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      toastr.error('Reader not reactivated', 'Please fix the errors and retry');
    });
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

// Clear data
export const clearData = () => {
  return {
    type: CLEAR_DATA
  };
};


// Get reader by ID
export const getReader = (id) => dispatch => {
  dispatch(clearErrors());
  dispatch({
    type: RESET_STATE
  });
  dispatch(loading());
  axios
    .get(`/api/subscriber/sub/${id}`)
    .then(res =>
      dispatch({
        type: GET_READER,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};



// Upload Additional Reader Data
export const uploadAdditional = (readerData, history) => dispatch => {
  let totaladded = 0;
  let totalfailed = 0;
  axios
    .post("/api/subscriber/uploadadditional", readerData)
    .then(res => {
      dispatch({
        type: UPLOAD_ADDITIONAL,
        payload: res.data
      });
      totaladded = res.data.added;
      totalfailed = res.data.failed;

    }).then(() => {
      return setTimeout(() => {
        dispatch(getTotalReaders("3"));
        toastr.success('Additional data file successfully uploaded. Processing data. This may take a while');
        history.push("/managedata")
      }, 1000)
    })
    .catch(err => {
      return setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        dispatch(getTotalReaders("3"));
        toastr.error('Additional Reader data failed due to data errors', 'This is usually caused by incorrect headers in the CSV file. Please fix the errors and retry');
        history.push("/managedata")
      }, 1000)
    });
};

// Upload Additional Reader Data
export const uploadPostcodes = (readerData, history) => dispatch => {
  let totaladded = 0;
  axios
    .post("/api/subscriber/uploadpostcodes", readerData)
    .then(res => {
      dispatch({
        type: UPLOAD_ADDITIONAL,
        payload: res.data
      });
      totaladded = res.data.added;
    }).then(() => {
      return setTimeout(() => {
        dispatch(getTotalReaders("3"));
        toastr.success('Postcode data file successfully uploaded. Processing data. This may take a while');
        history.push("/managedata")
      }, 1000)
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      toastr.error('Reader data failed due to data errors', 'Please fix the errors and retry');
    });
};

// Upload Replacement Reader Data
export const uploadReplacement = (readerData, history, options={ target: '', mode: '' }) => dispatch => {
  let stats = {};
  axios
    .post(`/api/subscriber/uploadreplace?target=${options.target}`, readerData)
    .then(res => {
      dispatch({
        type: UPLOAD_REPLACEMENT,
        payload: res.data
      });

      stats = {...res.data}
      // Show toast notification
    })
    .then((res) => {
      return setTimeout(() => {
        dispatch(getTotalReaders("3"));
        const message = `Processing ${stats.processed} records, added ${stats.added}, updated ${stats.updated} ` +
          `removed ${stats.removed} and failed ${stats.failed} records.`
        toastr.success('Replacement data file successfully uploaded. ' + message);
        history.push("/managedata")
      }, 1000)
    })
    .catch(err => {
      return setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        dispatch(getTotalReaders("3"));
        toastr.error('Replacement data failed due to data errors', 'This is usually caused by incorrect headers in the CSV file. Please fix the errors and retry');
        history.push("/managedata")
      }, 1000)
    });
};


// // Upload Replacement Reader Data
// export const uploadAddAndCombine = (readerData, history) => dispatch => {
//   let stats = {};
//   axios
//     .post("/api/subscriber/uploadaddandcombine", readerData)
//     .then(res => {
//       dispatch({
//         type: UPLOAD_ADD_AND_COMBINE,
//         payload: res.data
//       });
//       stats = {...res.data}
//     })
//     .then((res) => {
//       return setTimeout(() => {
//         dispatch(getTotalReaders("3"));
//         const message = `Processing ${stats.processed} records, added ${stats.added}, updated ${stats.updated}` +
//           `removed ${stats.removed} and failed ${stats.failed} records.`
//         toastr.success(message);
//         history.push("/managedata")
//       }, 1000)
//     })
//     .catch(err => {
//       return setTimeout(() => {
//         dispatch({
//           type: GET_ERRORS,
//           payload: err.response.data
//         });
//         dispatch(getTotalReaders("3"));
//         toastr.error('Replacement data failed due to data errors', 'This is usually caused by incorrect headers in the CSV file. Please fix the errors and retry');
//         history.push("/managedata")
//       }, 1000)
//     });
// };


// Get all reader data for publication
export const getAllData = id => dispatch => {
  axios
    .get(`/api/subscriber/alldata/${id}`)
    .then(res => {
      dispatch({
        type: GET_ALL_DATA,
        payload: res.data
      });
    }).catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
}

// Get Mailer Data for Publication
export const getMailerData = id => dispatch => {
  dispatch(showModal());
  axios
    .get(`/api/subscriber/mailer/${id}`)
    .then(res => {
      dispatch({
        type: MAILER_DATA,
        payload: res.data
      });
    }).catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
}



// Search Readers
export const searchReaders = (searchReaders) => dispatch => {

  axios
    .post("/api/subscriber/search", searchReaders)
    .then(res => {
      dispatch({
        type: SEARCH_READERS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

// Get reader's print subs by ID
export const getTotalReaders = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/totalreaders/${id}`)
    .then(res => {
      dispatch({
        type: GET_TOTAL_READERS,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get count of no full names
export const getNoFullNameCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/nofullnamecount/${id}`)
    .then(res => {
      dispatch({
        type: GET_NOFULLNAMECOUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get count of bad address data
export const getBadAddressCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/badaddressdatacount/${id}`)
    .then(res => {
      dispatch({
        type: GET_BAD_ADDRESS_DATA_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get bad address data
export const getBadAddress = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/badaddressdata/${id}`)
    .then(res => {
      console.log(res.data)
      dispatch({
        type: GET_BAD_ADDRESS_DATA,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get bad address data
export const autoFixAddress = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/fixbadaddressdata/${id}`)
    .then(res => {

      dispatch({
        type: AUTO_FIX_ADDRESS,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get count of no full names
export const getNoFirstNameCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/nofirstnamecount/${id}`)
    .then(res => {
      dispatch({
        type: GET_NOFIRSTNAME_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get count of no postcodes
export const getNoPostcodeCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/nopostcodecount/${id}`)
    .then(res => {
      dispatch({
        type: GET_NOPOSTCODECOUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get count of expired
export const getExpiredCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/expiredsubscribercount/${id}`)
    .then(res => {
      dispatch({
        type: GET_EXPIRED_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get expired
export const getExpired = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/expiredsubscriber/${id}`)
    .then(res => {
      dispatch({
        type: GET_EXPIRED,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get expired next 30 count
export const getExpiredThirtyCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/expirednextthirtycount/${id}`)
    .then(res => {
      dispatch({
        type: GET_EXPIRED_NEXT_THIRTY_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get expired last 30 count
export const getExpiredLastThirtyCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/expiredlastthirtycount/${id}`)
    .then(res => {
      dispatch({
        type: GET_EXPIRED_LAST_THIRTY_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Get expired last 30
export const getExpiredThirty = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/expirednextthirty/${id}`)
    .then(res => {
      dispatch({
        type: GET_EXPIRED_NEXT_THIRTY,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get bad postcode count
export const getBadPostcodeCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/badpostcodescount/${id}`)
    .then(res => {
      dispatch({
        type: BAD_POSTCODE_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err => {
      if (err.response) {
        console.log(err.response.data)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      }
    }
    );
};

// Get bad postcodes
export const getBadPostcode = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/badpostcodes/${id}`)
    .then(res => {
      dispatch({
        type: BAD_POSTCODE,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get no full names
export const getNoFullName = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/nofullname/${id}`)
    .then(res => {
      dispatch({
        type: GET_NOFULLNAME,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get no full names
export const getNoFirstName = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/nofirstname/${id}`)
    .then(res => {
      dispatch({
        type: GET_NOFIRSTNAME,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Fix no full names
export const autoFixNoFullName = (id) => async dispatch => {
  dispatch(loading());
  await axios
    .get(`/api/subscriber/autofixnofullname/${id}`)
    .then(res => {
      dispatch({
        type: AUTO_FIX_FULLNAME,
        payload: res.data
      })
    }
    ).then(() => {
      return setTimeout(() => {
        dispatch(getNoFullNameCount(id));
      }, 1000)
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Fix no full names
export const autoFixNoFirstName = (id) => async dispatch => {
  dispatch(loading());
  await axios
    .get(`/api/subscriber/autofixnofirstname/${id}`)
    .then(res => {
      dispatch({
        type: AUTO_FIX_FIRSTNAME,
        payload: res.data
      })
    }
    ).then(() => {
      return setTimeout(() => {
        dispatch(getNoFirstNameCount(id));
      }, 1000)
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get count of duplicate full names and postcodes
export const getDuplicateFullNameCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/duplicatefullnamecount/${id}`)
    .then(res => {
      dispatch({
        type: GET_DUPLICATE_FULLNAME_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get duplicate full names and postcodes
export const getDuplicateFullName = (id) => dispatch => {
  dispatch(clearData());
  dispatch(loading());
  axios
    .get(`/api/subscriber/duplicatefullname/${id}`)
    .then(res => {
      dispatch({
        type: GET_DUPLICATE_FULLNAME,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get count of duplicate last names and postcodes count
export const getDuplicateLastNameCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/duplicatelastnamecount/${id}`)
    .then(res => {
      dispatch({
        type: GET_DUPLICATE_LASTNAME_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get count of duplicate emails count
export const getDuplicateEmailCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/duplicateemailcount/${id}`)
    .then(res => {
      dispatch({
        type: GET_DUPLICATE_EMAIL_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get count of duplicate emails count
export const getDuplicateEmails = (id) => dispatch => {
  dispatch(clearData());

  dispatch(loading());
  axios
    .get(`/api/subscriber/duplicateemails/${id}`)
    .then(res => {
      dispatch({
        type: GET_DUPLICATE_EMAILS,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get duplicate last names and postcodes
export const getDuplicateLastName = (id) => dispatch => {
  dispatch(clearData());

  dispatch(loading());
  axios
    .get(`/api/subscriber/duplicatelastname/${id}`)
    .then(res => {
      dispatch({
        type: GET_DUPLICATE_LASTNAME,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get count of duplicate postcodes count
export const getDuplicatePostCodeCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/duplicatepostcodecount/${id}`)
    .then(res => {
      dispatch({
        type: GET_DUPLICATE_POSTCODE_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get duplicate postcodes
export const getDuplicatePostCode = (id) => dispatch => {
  dispatch(clearData());
  dispatch(loading());
  axios
    .get(`/api/subscriber/duplicatepostcode/${id}`)
    .then(res => {
      dispatch({
        type: GET_DUPLICATE_POSTCODE,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get count of duplicate postcodes count
export const getDuplicateAddressCount = (id) => dispatch => {
  dispatch(loading());
  axios
    .get(`/api/subscriber/duplicateaddresscount/${id}`)
    .then(res => {
      dispatch({
        type: GET_DUPLICATE_ADDRESS_COUNT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get duplicate postcodes
export const getDuplicateAddress = (id) => dispatch => {
  dispatch(clearData());
  dispatch(loading());
  axios
    .get(`/api/subscriber/duplicateaddress/${id}`)
    .then(res => {
      dispatch({
        type: GET_DUPLICATE_ADDRESS,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get no postcodes
export const getNoPostcode = (id) => dispatch => {
  dispatch(clearData());
  dispatch(loading());
  axios
    .get(`/api/subscriber/nopostcode/${id}`)
    .then(res => {
      dispatch({
        type: GET_NOPOSTCODE,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Loading
export const loading = () => {
  return {
    type: LOADING
  };
};

// Show Modal
export const showModal = () => {
  return {
    type: SHOW_MODAL
  }
}

// Finished Loading
export const finishedLoading = () => {
  return {
    type: FINISHED_LOADING
  }
}


