import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getUsers, getAllUsers } from '../../actions/userActions';
import UserItem from './components/UserItem';
import { CardGroup, Button } from 'semantic-ui-react';
import Spinner from '../common/Spinner';
import { isUserLoggedIn } from '../common/isUserLoggedIn';

class Users extends Component {
  componentDidMount() {
    isUserLoggedIn();
    switch (this.props.auth.user.userType) {
      case "superuser":
        return this.props.getAllUsers();
      case "publisher":
      case "reseller":
        return this.props.getUsers(this.props.auth.user.id);
      case "pubadmin":
        return this.props.getUsers(this.props.auth.user.parentPublisher);
      default:
        return window.location.href = "/readers"
    }

  }


  render() {
    // TODO: Change this to props and a let with a spinner
    let userItems;
    const { users } = this.props.users;

    if (users === null) {
      userItems = <Spinner />;
    } else {
      if (users.length > 0) {
        userItems = users.map(user => (
          <UserItem key={user._id} user={user} />
        ))
      }
    };

    return (
      <React.Fragment>
        <Link to="/adduser">
          <Button circular icon="plus circle" size="massive" color="blue" floated="right" />
        </Link>
        <CardGroup itemsPerRow={8}>
          {userItems}
        </CardGroup>
      </React.Fragment>
    )
  }
}

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  users: state.users,
  auth: state.auth
})

export default connect(mapStateToProps, { getUsers, getAllUsers })(Users);
