import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Form, Button, Header } from 'semantic-ui-react';
import Spinner from '../../common/Spinner';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import { uploadPostcodes } from '../../../actions/readerActions';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';

class UploadPostcodes extends Component {
  state = {
    csvfile: undefined,
    error: undefined,
    publicationID: this.props.match.params.id,
    uploading: false
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleChange = event => {
    var a = event.target.files[0].name.split(".");
    if (a.length === 1 || (a[0] === "" && a.length === 2)) {
      this.setState({ error: "Invalid file type" });
    } else {
      if (a[1] !== "csv" && a[1] !== "CSV") {
        this.setState({ error: "Invalid file type" });
      } else {
        this.setState({
          csvfile: event.target.files[0],
          error: undefined
        })
      }
    };
  }

  importCSV = () => {
    const { csvfile } = this.state;
    this.setState({ uploading: true })
    if (this.state.error === undefined) {
      Papa.parse(csvfile, {
        complete: this.updateData,
        header: true
      });
    };
  }

  updateData = (result) => {
    var data = result.data;
    const uploaddata = {
      csvdata: data,
      publicationID: this.state.publicationID,
    }
    this.props.uploadPostcodes(uploaddata, this.props.history)
  }

  handleError = err => {
    console.log("Error" + err);
  }

  render() {
    let displayFields = '';
    const error = this.state.error

    this.state.uploading ? displayFields = (
      <Segment>
        <Spinner />
      </Segment>
    ) : displayFields = (
      <Segment>
        <Header as="h2" textAlign='center'>Upload Postcodes Data</Header>
        <p>Please download the CSV template <Link to="/assets/templates/magstanduploadtemplate.csv" target="_blank">by clicking here and saving</Link></p>
        <p>Upload your CSV data</p>
        <Form.Input type="file" name="file" onChange={this.handleChange} />
        {error && <div className="ui pointing red basic label">{error}</div>}
        <br />
        <br />
        <Button onClick={this.importCSV}>Upload</Button>
      </Segment>
    )
    return (
      <React.Fragment>
        {displayFields}
      </React.Fragment>
    );
  }
}

UploadPostcodes.propTypes = {
  uploadPostcodes: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors,
  reader: state.reader
});

export default connect(mapStateToProps, { uploadPostcodes })(withRouter(UploadPostcodes));
