// Analytics Dashboard

import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment, Header } from 'semantic-ui-react';
import { isUserLoggedIn } from '../common/isUserLoggedIn';
import ChoosePub from './components/ChoosePub.js';

class ThirdParty extends Component {

  componentDidMount() {
    if (this.props.auth.user.userType !== "publisher" && this.props.auth.user.userType !== 'pubadmin') {
      if (this.props.auth.user.userType === "reseller" || this.props.auth.user.userType === "superuser") {
        window.location.href = "/publishers"
      } else {
        window.location.href = "/readers"
      }
    }
  }

  componentWillReceiveProps() {
    isUserLoggedIn();
  }
  render() {
    return (
      <Segment>
        <Header as="h2" textAlign="center">Third Party API</Header>
        <Header as="h3">Your subscriber data can be used to grant web access to your web paywalls and other third party applications.<br /><br />Provide the following information to your web developers, or app engineers to synchronise your live data to grant access.<br /><br />Please choose your Publication to continue</Header>
        <ChoosePub />
      </Segment>
    )
  }
}

ThirdParty.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,

});

export default connect(mapStateToProps)(ThirdParty);
