// Publication Information

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Label, Input, Form } from "semantic-ui-react";
import RenderFieldLeftRedux from "../../../common/ReduxForm/RenderFieldLeftRedux";
import RenderTextAreaRedux from "../../../common/ReduxForm/RenderTextAreaRedux";
import ColorPicker from "../../../common/ColorPicker";
import RenderFieldLeftAndRequiredRedux from "../../../common/ReduxForm/RenderFieldLeftAndRequiredRedux";
import {
  required,
  email,
  url,
  urlkey,
  number1,
} from "../../../common/ReduxFormValidation/validationRules";
import asyncValidate from "./functions/asyncValidate";
import hex2rgba from "hex2rgba";

class PublicationInfo extends Component {
  state = {
    color: "#ffffff",
    bg15: "ffffff",
    bg75: "ffffff",
  };

  handleColorChange = (color) => {
    this.props.change("backgroundColor", color);
    this.props.change("color", color);
    this.setState({
      color: color,
      bg15: hex2rgba(color, 0.15),
      bg75: hex2rgba(color, 0.75),
    });
  };
  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <Form onSubmit={handleSubmit} size="large">
        <Field
          name="publicationName"
          component={RenderFieldLeftAndRequiredRedux}
          type="text"
          label="Publication Name"
          validate={[required]}
        />
        <Field
          name="publicationPerYear"
          component={RenderFieldLeftAndRequiredRedux}
          type="number"
          label="Publications Per Year"
          validate={[required, number1]}
        />
        <Field
          name="urlKey"
          component={RenderFieldLeftAndRequiredRedux}
          type="text"
          label="https://store.magstand.com/"
          placeholder="Your unique Magstand Storefront URL"
          validate={[required, urlkey]}
        />
        <div className="form__field" style={{ margin: "10px" }}>
          <Label size="large" color="blue" style={{ padding: "13px" }}>
            Subscription Page Colour
          </Label>
          <Input
            as={ColorPicker}
            name="color"
            size="large"
            onColorChange={this.handleColorChange}
          />
          <label style={{ marginLeft: "20px", marginRight: "20px" }}>
            Headers
          </label>
          <Label
            style={{
              backgroundColor: this.state.color,
              lineHeight: "2.2",
              color: this.state.color,
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Label>
          <label style={{ marginLeft: "20px", marginRight: "20px" }}>
            Storefront Background
          </label>
          <Label
            style={{
              backgroundColor: this.state.bg75,
              lineHeight: "2.2",
              color: this.state.bg75,
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Label>
          {/* <label style={{ marginLeft: "20px", marginRight: "20px" }}>
            Magazine Cover Background
          </label>
          <Label
            style={{
              backgroundColor: this.state.bg15,
              lineHeight: "2.2",
              color: this.state.bg15,
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Label> */}
          <Field
            component={Input}
            name="backgroundColor"
            disabled={true}
            visible={false}
            fluid
          />
        </div>
        <Field
          name="publicationInfo"
          component={RenderTextAreaRedux}
          label="Magstand Store Information"
          placeholder="The publication description that will appear on your Storefront"
        />
        <Field
          name="webAddress"
          component={RenderFieldLeftRedux}
          type="text"
          label="Magazine Web Address"
          validate={[url]}
        />
        <Field
          name="emailAddress"
          component={RenderFieldLeftAndRequiredRedux}
          type="text"
          label="Enquiries Email Address"
          validate={[required, email]}
        />
        <Field
          name="orderEmailAddress"
          component={RenderFieldLeftAndRequiredRedux}
          type="text"
          label="Magstand Orders Email Address"
          validate={[required, email]}
        />
        <Field 
          name="sampleEdition"
          component={RenderFieldLeftRedux}
          type="text"
          label="Sample Edition"
          validate={[url]}
        />
        <div style={{ margin: "10px", textAlign: "center" }}>
          <Button color="blue" disabled>
            Previous
          </Button>
          <Button color="blue" disabled={submitting}>
            Next
          </Button>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      color: "#324323",
      // TODO: Remove these as they are for testing purposes
      thumbImageName:
        "https://magstand1.s3.eu-west-2.amazonaws.com/publications/magazine-xxl.png",
      logo:
        "https://magstand1.s3.eu-west-2.amazonaws.com/publogos/yourlogohere.jpg",
      subFree: false,
      printSubFree: false,
      printDigSubFree: false,
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "addwizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncValidate,
    asyncBlurFields: ["urlKey"],
    enableReinitialize: true,
  })(PublicationInfo)
);
