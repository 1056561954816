// Redux Store Setup

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

// Empty initial state
const initialState = {};

let composeEnhancers = compose;

if (process.env.NODE_ENV !== 'production') {
  if (
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
}

const enhancer = composeEnhancers(applyMiddleware(thunk));

// Redux store
const store = createStore(rootReducer, initialState, enhancer);

export default store;
