import PropTypes from 'prop-types';
import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { getPublicationByUrlKey, getPaypalDetails, updatePageViews, } from '../../actions/publicationActions';
import isEmpty from '../../extension/is-empty';
import { createOrder,
  createOrderResult,
  cancelOrder,
  createSTOrder,
  createBackIssueOrder,
} from '../../actions/orderActions';
import { getAccountTel } from '../../actions/accountActions';
import PaypalButton from '../common/PaypalButton/PaypalButton';
import RenderFieldLeftBasic from '../common/NormalForm/renderFieldLeftBasic';
import getTitles from '../common/Titles';
import hex2rgba from 'hex2rgba';
import axios from 'axios';
//import StripeCheckout from 'react-stripe-checkout';

import { Button, Table, Checkbox, 
  Grid, Header, Image, Menu, Input, Form, } from 'semantic-ui-react';
import getCountries from '../common/Countries';
import ReactGA from 'react-ga';
import LoadingOverlay from 'react-loading-overlay';


import DDImage from '../../assets/img/DDMethod.png';
import CCImage from '../../assets/img/card_icons.png';
import PPImage from '../../assets/img/pp-logo-100px.png';
import DigitalImage from '../../assets/img/digital.png';
import MagImage from '../../assets/img/magazine.png';
import BundleImage from '../../assets/img/bundle.png';
import moment from 'moment';
import './Storefront.css';

import BacsModal  from './BacsModal';
import CardModal  from './CardModal';
import PaymentRequestButton from './PaymentRequestButton';
import { StoreFrontFooter, StoreFrontHeader, ModalProcessingFailed, 
  StoreFrontOptIn, CustomQuestionContainer, PostalCodeLookup, PromoCode } from './StoreFrontBasics';


// See: https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined

class Storefront extends Component {
  state = {
    isLoading: false,
    modalProcessingFailed: false,
    modalOpen: false,
    modalDirectDebit: false,
    modalCard: false,
    // stModelOpen: false,
    showNewCustomer: true,
    showReturnCustomerDetails: false,
    loggedInCustomerID: null,
    logo: 'FFFFFF-1.png',
    thumbImageName: 'FFFFFF-1.png',

    publisherName: '',
    mainContactEmail: '',
    country: 'GB',
    countryText: '',
    checkboxSelection: '',
    backIssueSelection: [],
    backIssuePurchasePrice: 0,
    email: '',
    email2: '',
    buyer_jwt: null,
    title: '',
    firstName: '',
    forgottenEmail: '',
    emailExisting: '',
    passwordExisting: '',
    mobile: '',
    lastName: '',
    password: '',
    password2: '',
    emailError: '',
    passwordError: '',
    showForgotten: false,
    subFree: '',
    printSubFree: '',
    printDigSubFree: '',
    discountpercent: '',
    orderMonth: '',
    orderYear: '',
    showPromo: true,
    customQuestionAans: '',
    customQuestionBans: '',
    customQuestionCans: '',
    customQuestionDans: '',
    customQuestionEans: '',
    term: '',
    price: '',
    showUKPrices: false,
    showUSPrices: false,
    showEUPrices: false,
    showBillingPCLookup: false,
    showDeliveryAddress: true,
    showChangeAddressSuccess:false,
    displayDeliveryPostcodes: false,
    displayBillingPostcodes: false,
    showGiftNames: false,
    deliveryCheckbox: false,
    currencySymbol: '£',
    forgottenEmailError: '',
    emailResetMessage: '',
    deliveryCompanyName: '',
    deliveryPCLookup: '',
    deliveryPostcode: '',
    deliveryTitle: '',
    deliveryFirstName: '',
    deliveryLastName: '',
    billingCompanyName: '',
    billingPostcode: '',
    billingPCLookup: '',
    billingAddress1: '',
    billingAddress2: '',
    billingAddress3: '',
    billingTown: '',
    billingState: '',
    deliveryAddress1: '',
    deliveryAddress2: '',
    deliveryAddress3: '',
    deliveryTown: '',
    publication: {},
    bg75: '',
    bg50: '',
    bg25: '',
    bg15: '',
    bg05: '',
    gift: false,
    paypal: '',
    chargeableName: '',
    publishableKey: '',
    secretKey: '',
    user: '',
    pass: '',
    ref: '',
    monthOption: '',
    yearOption: '',
    ccnum: '',
    cvc: '',
    stError: '',
    recurring: false,
    showPaymentButtons: false,
    showSignUpForFreeButton: false,
    showPaymentRequestButton: false,
    showStripe: false,
    showDD: false,
    showpaypal: false,
    currency: '',
    pubOffers: false,
    pubOffersPost: false,
    partnerOffers: false,
    partnerOffersPost: false,
    pubOffersTel: false,
    logofull: false,
    partnerOffersTel: false,
    pubOffersSMS: false,
    partnerOffersSMS: false,
    purchasePrice: '',
    tel: '',
    urlKey: '',
    receiptText: '',
    errors: {},
    sellOverseas: true,
    publisherCountry: '',
    purchaseSubscription: true,
    showBackIssuePaymentButtons: false,
    sampleEdition: '',
    firstSubscritionStartDate: null,
    lastSubscritionEndDate: null,
    accountDets: null
  };

  async componentDidMount() {
    var path = this.props.location.pathname;
    path = path.substring(1);
    this.setState({ urlKey: path });
    this.props.getPublicationByUrlKey(path);
    ReactGA.initialize('UA-133495576-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Requesting current country
    const response = await axios.get('/api/external/country')
    const { code, country } = response.data
    this.setState({ country: code, countryText: country });
    this.followCountryChange(code, country);
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.publication.publication) {
      if (this.state.logo !== nextProps.publication.publication.logo) {
        document.title =
          'Magstand - ' + nextProps.publication.publication.publicationName;

        this.setState({
          publication: nextProps.publication.publication,
          logo: nextProps.publication.publication.logo,
          logofull: nextProps.publication.publication.logo,
          thumbImageName: nextProps.publication.publication.thumbImageName,
          iosAppLink: nextProps.publication.publication.iosAppLink,
          androidAppLink: nextProps.publication.publication.androidAppLink,
          kindleAppLink: nextProps.publication.publication.kindleAppLink,
          digitalEditionLink:
            nextProps.publication.publication.digitalEditionLink,
          receiptText: nextProps.publication.publication.receiptText,
          mainContactEmail: nextProps.publication.publication.emailAddress,
          ordersEmail: nextProps.publication.publication.orderEmailAddress,
          sampleEdition: nextProps.publication.publication.sampleEdition,
        });
        this.setState({
          bg75: 'rgba(222,224,225, 0.56)',
          bg50: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.5,
          ),
          bg25: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.25,
          ),
          bg15: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.15,
          ),
          bg10: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.1,
          ),
          bg05: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.05,
          ),
        });
        this.props.updatePageViews(nextProps.publication.publication._id);
        this.props.getAccountTel(nextProps.publication.publication.user);
      }
    }

    if (nextProps.account.account) {
      if (
        this.state.companyName !==
        nextProps.account.account.accountDets.companyName
      ) {
        const account = nextProps.account.account;
        // account.serviceUserNumber = !isEmpty(
        //   account.accountDets.directDebitDets,
        // )
        //   ? account.accountDets.directDebitDets.serviceUserNumber
        //   : '';
        account.chargeableName = !isEmpty(account.accountDets.directDebitDets)
          ? account.accountDets.directDebitDets.chargeableName
          : '';
        account.publishableKey = !isEmpty(account.accountDets.stripeDets)
          ? account.accountDets.stripeDets.publishableKey
          : '';
        account.secretKey = !isEmpty(account.accountDets.stripeDets)
          ? account.accountDets.stripeDets.secretKey
          : '';
        account.paypal = !isEmpty(account.accountDets.payPalDets)
          ? account.accountDets.payPalDets.payPalString
          : '';
        account.user = !isEmpty(account.accountDets.secureTradingDets)
          ? account.accountDets.secureTradingDets.user
          : '';
        account.pass = !isEmpty(account.accountDets.secureTradingDets)
          ? account.accountDets.secureTradingDets.pass
          : '';
        account.ref = !isEmpty(account.accountDets.secureTradingDets)
          ? account.accountDets.secureTradingDets.ref
          : '';

        this.setState({
          tel: nextProps.account.account.accountDets.telephone,
          publisherName: nextProps.account.account.accountDets.companyName,

          paypal: account.paypal,
          addressOne: nextProps.account.account.accountDets.addressOne,
          addressTwo: nextProps.account.account.accountDets.addressTwo,
          addressThree: nextProps.account.account.accountDets.addressThree,
          postZip: nextProps.account.account.accountDets.postZip,
          mainCurrency: nextProps.account.account.accountDets.mainCurrency,
          // serviceUserNumber: account.serviceUserNumber,
          chargeableName: account.chargeableName,
          publishableKey: account.publishableKey,
          secretKey: account.secretKey,
          user: account.user,
          pass: account.pass,
          ref: account.ref,
          sellOverseas: nextProps.account.account.accountDets.sellOverseas,
          publisherCountry: nextProps.account.account.accountDets.country,
          accountDets: account.accountDets
        });
        switch (nextProps.account.account.accountDets.mainCurrency) {
          case 'GBP':
            this.setState({ mainCurrencySymbol: '£' });
            break;
          case 'USD':
            this.setState({ mainCurrencySymbol: '$' });
            break;
          case 'EUR':
            this.setState({ mainCurrencySymbol: '€' });
            break;
          default:
            this.setState({ mainCurrencySymbol: '£' });
            break;
        }
      }
    }
  }

  autoSelectSubscription = () => {
    let checkboxState = '';
    let term = '';
    if (this.state.publication.printSubFree) {
      checkboxState = 'FPN';
      term = 'Free Print Subscription';
    } else if (this.state.publication.printPriceOneYear) {
      checkboxState = '1PN' + this.state.publication.printPriceOneYear;
      term = '1 Year Print Subscription';
    } else if (this.state.publication.printPriceOneYearRecurring) {
      checkboxState = '1PR' + this.state.publication.printPriceOneYearRecurring;
      term = '1 Year Recurring Print Subscription';
    } else if (this.state.publication.printPriceSixMonth) {
      checkboxState = '6PN' + this.state.publication.printPriceSixMonth;
      term = '6 Months Print Subscription';
    } else if (this.state.publication.printPriceSixMonthRecurring) {
      checkboxState =
        '6PR' + this.state.publication.printPriceSixMonthRecurring;
      term = '6 Months Recurring Print Subscription';
    } else if (this.state.publication.printPriceThreeMonth) {
      checkboxState = '3PN' + this.state.publication.printPriceThreeMonth;
      term = '3 Months Print Subscription';
    } else if (this.state.publication.printPriceThreeMonthRecurring) {
      checkboxState =
        '3PR' + this.state.publication.printPriceThreeMonthRecurring;
      term = '3 Months Recurring Print Subscription';
    } else if (this.state.publication.printPrice) {
      checkboxState = '2PN' + this.state.publication.printPrice;
      term = '1 Month Print Subscription';
    } else if (this.state.publication.subFree) {
      checkboxState = 'FDN';
      term = 'Free Digital Subscription';
    } else if (this.state.publication.priceOneYear) {
      checkboxState = '1DN' + this.state.publication.priceOneYear;
      term = '1 Year Digital Subscription';
    } else if (this.state.publication.priceOneYearRecurring) {
      checkboxState = '1DR' + this.state.publication.priceOneYearRecurring;
      term = '1 Year Recurring Digital Subscription';
    } else if (this.state.publication.priceSixMonth) {
      checkboxState = '6DN' + this.state.publication.priceSixMonth;
      term = '6 Months Digital Subscription';
    } else if (this.state.publication.priceSixMonthRecurring) {
      checkboxState = '6DR' + this.state.publication.priceSixMonthRecurring;
      term = '6 Months Recurring Digital Subscription';
    } else if (this.state.publication.priceThreeMonth) {
      checkboxState = '3DN' + this.state.publication.priceThreeMonth;
      term = '3 Months Digital Subscription';
    } else if (this.state.publication.priceThreeMonthRecurring) {
      checkboxState = '3DR' + this.state.publication.priceThreeMonthRecurring;
      term = '3 Months Recurring Digital Subscription';
    } else if (this.state.publication.price) {
      checkboxState = '2DN' + this.state.publication.price;
      term = '1 Month Digital Subscription';
    } else if (this.state.publication.printDigSubFree) {
      checkboxState = 'FBN';
      term = 'Free Bundle Subscription';
    } else if (this.state.publication.printDigPriceOneYear) {
      checkboxState = '1BN' + this.state.publication.printDigPriceOneYear;
      term = '1 Year Bundle Subscription';
    } else if (this.state.publication.printDigPriceOneYearRecurring) {
      checkboxState =
        '1BR' + this.state.publication.printDigPriceOneYearRecurring;
      term = '1 Year Recurring Bundle Subscription';
    } else if (this.state.publication.printDigPriceSixMonth) {
      checkboxState = '6BN' + this.state.publication.printDigPriceSixMonth;
      term = '6 Months Bundle Subscription';
    } else if (this.state.publication.printDigPriceSixMonthRecurring) {
      checkboxState =
        '6BR' + this.state.publication.printDigPriceSixMonthRecurring;
      term = '6 Months Recurring Bundle Subscription';
    } else if (this.state.publication.printDigPriceThreeMonth) {
      checkboxState = '3BN' + this.state.publication.printDigPriceThreeMonth;
      term = '3 Months Bundle Subscription';
    } else if (this.state.publication.printDigPriceThreeMonthRecurring) {
      checkboxState =
        '3BR' + this.state.publication.printDigPriceThreeMonthRecurring;
      term = '3 Months Recurring Bundle Subscription';
    } else if (this.state.publication.printDigPrice) {
      checkboxState = '2BN' + this.state.publication.printDigPrice;
      term = '1 Month Bundle Subscription';
    }
    let price = checkboxState.substring(3);

    if (price === 'N' || price === '0') {
      this.setState({ showDD: false });
      price = '0';
    }
    
    let showDeliveryAddress = false;
    let type = checkboxState.substring(1, 2);
    // if (type === 'B' || type === 'D') {
    if (type === 'P') {      
      showDeliveryAddress = true;
    }

    let recurring = false;
    if (checkboxState.substring(2, 3) === 'R') {
      recurring = true;
    }

    this.setState({
      purchasePrice: price,
      showDeliveryAddress,
      checkboxSelection: checkboxState,
      term,
      recurring,
    });
  };

  autoSelectSubscriptionEU = () => {
    let checkboxState = '';
    let term = '';
    if (this.state.publication.printSubFree) {
      checkboxState = 'FPN';
      term = 'Free Print Subscription';
    } else if (this.state.publication.printPriceOneYearEU) {
      checkboxState = '1PN' + this.state.publication.printPriceOneYearEU;
      term = '1 Year Print Subscription';
    } else if (this.state.publication.printPriceOneYearRecurringEU) {
      checkboxState =
        '1PR' + this.state.publication.printPriceOneYearRecurringEU;
      term = '1 Year Recurring Print Subscription';
    } else if (this.state.publication.printPriceSixMonthEU) {
      checkboxState = '6PN' + this.state.publication.printPriceSixMonthEU;
      term = '6 Months Print Subscription';
    } else if (this.state.publication.printPriceSixMonthRecurringEU) {
      checkboxState =
        '6PR' + this.state.publication.printPriceSixMonthRecurringEU;
      term = '6 Months Recurring Print Subscription';
    } else if (this.state.publication.printPriceThreeMonthEU) {
      checkboxState = '3PN' + this.state.publication.printPriceThreeMonthEU;
      term = '3 Months Print Subscription';
    } else if (this.state.publication.printPriceThreeMonthRecurringEU) {
      checkboxState =
        '3PR' + this.state.publication.printPriceThreeMonthRecurringEU;
      term = '3 Months Recurring Print Subscription';
    } else if (this.state.publication.printPriceEU) {
      checkboxState = '2PN' + this.state.publication.printPriceEU;
      term = '1 Month Print Subscription';
    } else if (this.state.publication.subFree) {
      checkboxState = 'FDN';
      term = 'Free Digital Subscription';
    } else if (this.state.publication.priceOneYearEU) {
      checkboxState = '1DN' + this.state.publication.priceOneYearEU;
      term = '1 Year Digital Subscription';
    } else if (this.state.publication.priceOneYearRecurringEU) {
      checkboxState = '1DR' + this.state.publication.priceOneYearRecurringEU;
      term = '1 Year Recurring Digital Subscription';
    } else if (this.state.publication.priceSixMonthEU) {
      checkboxState = '6DN' + this.state.publication.priceSixMonthEU;
      term = '6 Months Digital Subscription';
    } else if (this.state.publication.priceSixMonthRecurringEU) {
      checkboxState = '6DR' + this.state.publication.priceSixMonthRecurringEU;
      term = '6 Months Recurring Digital Subscription';
    } else if (this.state.publication.priceThreeMonthEU) {
      checkboxState = '3DN' + this.state.publication.priceThreeMonthEU;
      term = '3 Months Digital Subscription';
    } else if (this.state.publication.priceThreeMonthRecurringEU) {
      checkboxState = '3DR' + this.state.publication.priceThreeMonthRecurringEU;
      term = '3 Months Recurring Digital Subscription';
    } else if (this.state.publication.priceEU) {
      checkboxState = '2DN' + this.state.publication.priceEU;
      term = '1 Month Digital Subscription';
    } else if (this.state.publication.printDigSubFree) {
      checkboxState = 'FBN';
      term = 'Free Bundle Subscription';
    } else if (this.state.publication.printDigPriceOneYearEU) {
      checkboxState = '1BN' + this.state.publication.printDigPriceOneYearEU;
      term = '1 Year Bundle Subscription';
    } else if (this.state.publication.printDigPriceOneYearRecurringEU) {
      checkboxState =
        '1BR' + this.state.publication.printDigPriceOneYearRecurringEU;
      term = '1 Year Recurring Bundle Subscription';
    } else if (this.state.publication.printDigPriceSixMonthEU) {
      checkboxState = '6BN' + this.state.publication.printDigPriceSixMonthEU;
      term = '6 Months Bundle Subscription';
    } else if (this.state.publication.printDigPriceSixMonthRecurringEU) {
      checkboxState =
        '6BR' + this.state.publication.printDigPriceSixMonthRecurringEU;
      term = '6 Months Recurring Bundle Subscription';
    } else if (this.state.publication.printDigPriceThreeMonthEU) {
      checkboxState = '3BN' + this.state.publication.printDigPriceThreeMonthEU;
      term = '3 Months Bundle Subscription';
    } else if (this.state.publication.printDigPriceThreeMonthRecurringEU) {
      checkboxState =
        '3BR' + this.state.publication.printDigPriceThreeMonthRecurringEU;
      term = '3 Months Recurring Bundle Subscription';
    } else if (this.state.publication.printDigPriceEU) {
      checkboxState = '2BN' + this.state.publication.printDigPriceEU;
      term = '1 Month Bundle Subscription';
    }

    let price = checkboxState.substring(3);
    if (price === 'N' || price === '0') {
      this.setState({ showDD: false });
      price = '0';
    }

    let showDeliveryAddress = false;
    let type = checkboxState.substring(1, 2);
    // if (type === 'B' || type === 'D') {
    if (type === 'P') {      
      showDeliveryAddress = true;
    }
    let recurring = false;
    if (checkboxState.substring(2, 3) === 'R') {
      recurring = true;
    }
    this.setState({
      purchasePrice: price,
      showDeliveryAddress,
      checkboxSelection: checkboxState,
      term,
      recurring,
    });
  };

  autoSelectSubscriptionUS = () => {
    let checkboxState = '';
    let term = '';
    if (this.state.publication.printSubFree) {
      checkboxState = 'FPN';
      term = 'Free Print Subscription';
    } else if (this.state.publication.printPriceOneYearUS) {
      checkboxState = '1PN' + this.state.publication.printPriceOneYearUS;
      term = '1 Year Print Subscription';
    } else if (this.state.publication.printPriceOneYearRecurringUS) {
      checkboxState =
        '1PR' + this.state.publication.printPriceOneYearRecurringUS;
      term = '1 Year Recurring Print Subscription';
    } else if (this.state.publication.printPriceSixMonthUS) {
      checkboxState = '6PN' + this.state.publication.printPriceSixMonthUS;
      term = '6 Months Print Subscription';
    } else if (this.state.publication.printPriceSixMonthRecurringUS) {
      checkboxState =
        '6PR' + this.state.publication.printPriceSixMonthRecurringUS;
      term = '6 Months Recurring Print Subscription';
    } else if (this.state.publication.printPriceThreeMonthUS) {
      checkboxState = '3PN' + this.state.publication.printPriceThreeMonthUS;
      term = '3 Months Print Subscription';
    } else if (this.state.publication.printPriceThreeMonthRecurringUS) {
      checkboxState =
        '3PR' + this.state.publication.printPriceThreeMonthRecurringUS;
      term = '3 Months Recurring Print Subscription';
    } else if (this.state.publication.printPriceUS) {
      checkboxState = '2PN' + this.state.publication.printPriceUS;
      term = '1 Month Print Subscription';
    } else if (this.state.publication.subFree) {
      checkboxState = 'FDN';
      term = 'Free Digital Subscription';
    } else if (this.state.publication.priceOneYearUS) {
      checkboxState = '1DN' + this.state.publication.priceOneYearUS;
      term = '1 Year Digital Subscription';
    } else if (this.state.publication.priceOneYearRecurringUS) {
      checkboxState = '1DR' + this.state.publication.priceOneYearRecurringUS;
      term = '1 Year Recurring Digital Subscription';
    } else if (this.state.publication.priceSixMonthUS) {
      checkboxState = '6DN' + this.state.publication.priceSixMonthUS;
      term = '6 Months Digital Subscription';
    } else if (this.state.publication.priceSixMonthRecurringUS) {
      checkboxState = '6DR' + this.state.publication.priceSixMonthRecurringUS;
      term = '6 Months Recurring Digital Subscription';
    } else if (this.state.publication.priceThreeMonthUS) {
      checkboxState = '3DN' + this.state.publication.priceThreeMonthUS;
      term = '3 Months Digital Subscription';
    } else if (this.state.publication.priceThreeMonthRecurringUS) {
      checkboxState = '3DR' + this.state.publication.priceThreeMonthRecurringUS;
      term = '3 Months Recurring Digital Subscription';
    } else if (this.state.publication.priceUS) {
      checkboxState = '2DN' + this.state.publication.priceUS;
      term = '1 Month Digital Subscription';
    } else if (this.state.publication.printDigSubFree) {
      checkboxState = 'FBN';
      term = 'Free Bundle Subscription';
    } else if (this.state.publication.printDigPriceOneYearUS) {
      checkboxState = '1BN' + this.state.publication.printDigPriceOneYearUS;
      term = '1 Year Bundle Subscription';
    } else if (this.state.publication.printDigPriceOneYearRecurringUS) {
      checkboxState =
        '1BR' + this.state.publication.printDigPriceOneYearRecurringUS;
      term = '1 Year Recurring Bundle Subscription';
    } else if (this.state.publication.printDigPriceSixMonthUS) {
      checkboxState = '6BN' + this.state.publication.printDigPriceSixMonthUS;
      term = '6 Months Bundle Subscription';
    } else if (this.state.publication.printDigPriceSixMonthRecurringUS) {
      checkboxState =
        '6BR' + this.state.publication.printDigPriceSixMonthRecurringUS;
      term = '6 Months Recurring Bundle Subscription';
    } else if (this.state.publication.printDigPriceThreeMonthUS) {
      checkboxState = '3BN' + this.state.publication.printDigPriceThreeMonthUS;
      term = '3 Months Bundle Subscription';
    } else if (this.state.publication.printDigPriceThreeMonthRecurringUS) {
      checkboxState =
        '3BR' + this.state.publication.printDigPriceThreeMonthRecurringUS;
      term = '3 Months Recurring Bundle Subscription';
    } else if (this.state.publication.printDigPriceUS) {
      checkboxState = '2BN' + this.state.publication.printDigPriceUS;
      term = '1 Month Bundle Subscription';
    }

    let price = checkboxState.substring(3);
    if (price === 'N' || price === '0') {
      this.setState({ showDD: false });
      price = '0';
    }

    let showDeliveryAddress = false;
    let type = checkboxState.substring(1, 2);
    // if (type === 'B' || type === 'D') {
    if (type === 'P') {
      showDeliveryAddress = true;
    }


    let recurring = false;
    if (checkboxState.substring(2, 3) === 'R') {
      recurring = true;
    }

    this.setState({
      purchasePrice: price,
      showDeliveryAddress,
      checkboxSelection: checkboxState,
      term,
      recurring,
    });
  };

  handleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  // onSTPayNow = () => this.setState({ stModelOpen: !this.state.stModelOpen });

  validateForm = () => {
    const productAndCredentials = this.state.currency !== '' && 
                  this.state.checkboxSelection &&
                  this.state.email !== '' &&
                  this.state.email2 !== '' && 
                  this.state.firstName !== '' &&
                  this.state.lastName !== '' &&
                  this.state.password !== '' && 
                  this.state.password2 !== ''

    const { customQuestionA, customQuestionB, 
      customQuestionC, customQuestionD, customQuestionE} = this.state.publication
    let customQuestionAnswered = true;
    if(customQuestionA && this.state.customQuestionAans !== 'Yes') customQuestionAnswered = false;
    if(customQuestionB && this.state.customQuestionBans !== 'Yes') customQuestionAnswered = false;
    if(customQuestionC && this.state.customQuestionCans !== 'Yes') customQuestionAnswered = false;
    if(customQuestionD && this.state.customQuestionDans !== 'Yes') customQuestionAnswered = false;
    if(customQuestionE && this.state.customQuestionEans !== 'Yes') customQuestionAnswered = false;

    this.setState({ showPaymentRequestButton: productAndCredentials })                  

    // Some dirty code here! Need a better way to handle this but it'll do for now
    if (productAndCredentials && customQuestionAnswered) {
      if (
        this.state.billingAddress1 !== '' &&
        this.state.billingPostcode !== ''
      ) {

        if (this.state.showDeliveryAddress) {
          if (
            (this.state.showDeliveryAddress &&
              this.state.deliveryCheckbox) ||
            (this.state.deliveryPostcode !== '' &&
              this.state.deliveryAddress1 !== '')
          ) {
            console.log('PP:' + this.state.purchasePrice + " " + this.state.checkboxSelection);

            if (
              // Choose free product
              ( ['FDN', 'FPN', 'FBN'].includes(this.state.checkboxSelection) )
              // Product with discount 100%
              || (this.state.purchasePrice === '0' || this.state.purchasePrice === '0.00' ||
              this.state.purchasePrice.length == 0)
            ) {
              this.setState({ showPaymentButtons: false, showSignUpForFreeButton: true });
            }else if(this.state.checkboxSelection.length > 0){
              this.setState({ showPaymentButtons: true, showSignUpForFreeButton: false });
            }
          } else {
            this.setState({
              //validated: false,
              showPaymentButtons: false,
            });
          }
        } else {
          //this.setState({ validated: true });
          console.log('PP:' + this.state.purchasePrice + " " + this.state.checkboxSelection);
          console.log("through here4", this.state.checkboxSelection)

          if (
            // Choose free product
            ( ['FDN', 'FPN', 'FBN'].includes(this.state.checkboxSelection) )
            // Product with discount 100%
            || (this.state.purchasePrice === '0' || this.state.purchasePrice === '0.00' ||
            this.state.purchasePrice.length == 0)
          ) {
            this.setState({ showPaymentButtons: false, showSignUpForFreeButton: true });                          
          }else if(this.state.checkboxSelection.length > 0){
            this.setState({ showPaymentButtons: true, showSignUpForFreeButton: false });
          }
        }
      } else {
        this.setState({
          // validated: false,
          showPaymentButtons: false,
        });
      }
    } else {
      this.setState({
        // validated: false,
        showPaymentButtons: false, showSignUpForFreeButton: false
      });
    } 


    if (
      productAndCredentials &&
      this.state.billingAddress1 !== '' &&
      this.state.billingPostcode !== '' &&
      this.state.backIssueSelection.length > 0
    ) {
      this.setState({
        // validated: true,
        showPaymentButtons: true,
      });
    }
  };

  handleTitleChange = (e, { value }) => {
    this.setState({ title: value });
  };

  handleGiftTitleChange = (e, { value }) => {
    this.setState({ deliveryTitle: value });
  };

  handleQuestionAChange = (e, { value }) => {
    this.setState({ customQuestionAans: value });
  };

  handleQuestionBChange = (e, { value }) => {
    this.setState({ customQuestionBans: value });
  };

  handleMonthChange = (e, { value }) => {
    this.setState({ monthOption: value });
  };

  handleYearChange = (e, { value }) => {
    this.setState({ yearOption: value });
  };

  // Country Dropdown Change Handler
  handleCountryChange = (e, { value, text }) => {
    e.persist();
    this.setState({ country: value, countryText: e.target.textContent });
    const { i18n } = this.props;
    const countryOptions = getCountries();
    const selectedCountry = countryOptions.find((c) => c.value === value);
    i18n.changeLanguage(selectedCountry.lang);
    this.followCountryChange(value, text);
  };

  followCountryChange = (value, text) => {
    // const { t, i18n } = this.props;
    // i18n.changeLanguage(value);
    switch (value) {
      case 'GB':
        // this.autoSelectSubscription();
        return this.setState(
          {
            showBillingPCLookup: true,
            showUKPrices: true,
            showEUPrices: false,
            showUSPrices: false,
            currency: 'GBP',
            currencySymbol: '£',
          },
          () => {
            this.validateForm();
          },
        );
      case 'US':
      case 'PR':
      case 'EC':
      case 'SV':
      case 'ZW':
      case 'GU':
      case 'VI':
      case 'TL':
      case 'WS':
      case 'MP':
      case 'FM':
      case 'PW':
      case 'MH':
        // this.autoSelectSubscriptionUS();
        return this.setState(
          {
            showBillingPCLookup: false,
            showUKPrices: false,
            showEUPrices: false,
            showUSPrices: true,
            currency: 'USD',
            currencySymbol: '$',
          },
          () => {
            this.validateForm();
          },
        );
      case 'AD':
      case 'AT':
      case 'BE':
      case 'CY':
      case 'EE':
      case 'FR':
      case 'FI':
      case 'DE':
      case 'GR':
      case 'IE':
      case 'IT':
      case 'LV':
      case 'LT':
      case 'LU':
      case 'MT':
      case 'MC':
      case 'NL':
      case 'PT':
      case 'SM':
      case 'SK':
      case 'SI':
      case 'ES':
        // this.autoSelectSubscriptionEU();
        return this.setState(
          {
            showBillingPCLookup: false,
            showUKPrices: false,
            showEUPrices: true,
            showUSPrices: false,
            currency: 'EUR',
            currencySymbol: '€',
          },
          () => {
            this.validateForm();
          },
        );
      default:
        // this.autoSelectSubscriptionUS();
        // TODO: Change this so it defaults to the publishers currency in My Account
        return this.setState(
          {
            showUKPrices: false,
            showEUPrices: false,
            showUSPrices: true,
            // currency: this.state.mainCurrency,
            // currencySymbol: this.state.mainCurrencySymbol,
            currency: 'USD',
            currencySymbol: '$',
          },
          () => {
            this.validateForm();
          },
        );
    }
  };

  // Billing Postcode Dropdown Change Handler
  handleBillingChange = (e, { value, text }) => {
    e.persist();
    const address = value.split(',');
    if (
      address[0].includes('Limited') ||
      address[0].includes('Ltd') ||
      address[0].includes('Plc') ||
      address[0].includes('LLP')
    ) {
      this.setState(
        {
          billingCompanyName: address[0],
          billingAddress1: address[1],
          billingAddress2: address[2],
          billingAddress3: address[3],
          billingTown: address[5],
          billingPostcode: this.state.billingPCLookup,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState(
        {
          billingAddress1: address[0],
          billingAddress2: address[1],
          billingAddress3: address[2],
          billingTown: address[5],
          billingPostcode: this.state.billingPCLookup,
        },
        () => {
          this.validateForm();
        },
      );
    }
  };

  // Delivery Postcode Dropdown Change Handler
  handleDeliveryChange = (e, { value, text }) => {
    e.persist();
    const address = value.split(',');
    if (
      address[0].includes('Limited') ||
      address[0].includes('Ltd') ||
      address[0].includes('Plc') ||
      address[0].includes('LLP')
    ) {
      this.setState(
        {
          deliveryCompanyName: address[0],
          deliveryAddress1: address[1],
          deliveryAddress2: address[2],
          deliveryAddress3: address[3],
          deliveryTown: address[5],
          deliveryPostcode: this.state.deliveryPCLookup,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState(
        {
          deliveryAddress1: address[0],
          deliveryAddress2: address[1],
          deliveryAddress3: address[2],
          deliveryTown: address[5],
          deliveryPostcode: this.state.deliveryPCLookup,
        },
        () => {
          this.validateForm();
        },
      );
    }
  };

  // Sub selection
  onChangeHandler = (e, { value }) => {
    if (
      value !== 'FDN' &&
      value !== 'FPN' &&
      value !== 'FBN' &&
      value.substring(3) !== '0'
    ) {
      if (
        this.state.paypal === '' &&
        this.state.secretKey === '' &&
        // (this.state.serviceUserNumber === '' ||
        //   this.state.serviceUserNumber === undefined) &&
        this.state.ref === ''
      ) {
        var strMessage =
          'Unfortunately payments cannot currently be taken. Please contact ' +
          this.state.publisherName +
          ' ';
        if (this.state.tel !== '' && this.state.tel !== undefined) {
          strMessage += 'on ' + this.state.tel + ' or ';
        }
        strMessage +=
          'by emailing ' +
          this.state.mainContactEmail +
          ' quoting error code: AC74';
        alert(strMessage);
      } else {
        this.setState({
          checkboxSelection: value,
          term: e.target.textContent,
        });
      }
      this.setState({ purchasePrice: value.substring(3) });
    } else {
      this.setState({
        checkboxSelection: value,
        term: e.target.textContent,
      });
      this.setState({ purchasePrice: '0', showDD: false });
    }
    if (value.substring(2, 3) === 'R') {
      this.setState({ recurring: true });
    } else {
      this.setState({ recurring: false });
    }
    if (
      e.target.textContent.includes('Print') ||
      e.target.textContent.includes('Bundle')
    ) {
      this.setState({
          showDeliveryAddress: true,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState(
        {
          showDeliveryAddress: false,
        },
        () => {
          this.validateForm();
        },
      );
    }
  };

  onBackIssueHandler = (e, data) => {
    let selected = [...this.state.backIssueSelection];

    if (data.checked) {
      selected.push(data.value);
      this.setState((prevState) => ({
        backIssuePurchasePrice: prevState.backIssuePurchasePrice + data.price,
      }));
    } else {
      var index = selected.indexOf(data.value);
      this.setState((prevState) => ({
        backIssuePurchasePrice: prevState.backIssuePurchasePrice - data.price,
      }));
      selected.splice(index, 1);
    }
    this.setState({ backIssueSelection: selected }, () => {
      this.validateForm();
    });
  };



  handlePaymentChange = (e, { value }) => {
    const PAYMENT_TYPE = { PAYPAL: '1', CREDIT_CARD: '2', DIRECT_DEBIT: '3'};
    this.setState({ paymentType: value });
    switch (value) {
      case PAYMENT_TYPE.PAYPAL:
        this.setState({
          showpaypal: true,
          showDD: false,
          showStripe: false,
        });
        break;
      case PAYMENT_TYPE.CREDIT_CARD:
        this.setState({
          showpaypal: false,
          showDD: false,
          showStripe: true,
        });
        break;
      case PAYMENT_TYPE.DIRECT_DEBIT:
        this.setState({
          showpaypal: false,
          showDD: true,
          showStripe: false,
        });
        break;
      default:
        break;
    }
  };

  // Delivery Checkbox Handler
  handleDeliveryCheck = (e, { value }) => {
    this.setState(
      (prevState) => ({ deliveryCheckbox: !prevState.deliveryCheckbox }),
      () => {
        this.validateForm();
      },
    );
  };

  handleGiftCheck = (e, { value }) => {
    this.setState(
      (prevState) => ({
        gift: !prevState.gift,
        showGiftNames: !prevState.showGiftNames,
      }),
      () => {
        this.validateForm();
      },
    );
  };

  // Generic Change Handler
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.validateForm();
    });
  };

  // Confirm Password Validation
  onConfirmPasswordBlur = (e) => {
    const passwordError = this.state.password === e.target.value?'':'Passwords must match';
    this.setState({ passwordError });
  };

  // Confirm Email Validation
  onEmailBlur = (e) => {
    if( this.state.emailFirstError.length > 0 ) 
      return
    // Proceed
    const emailError = this.state.email === e.target.value?'':'Emails must match';
    this.setState({ emailFirstError: '', emailError });
  };

  // Email Validation
  onEmailFirstBlur = async (e) => {
    // 1. Check email first error required
    if (e.target.value === '') {
      this.setState({ emailFirstError: 'Required', emailError: '' });
      return
    }

    // 2. Check uniquess    
    try{
      await axios(`/api/order/email/${e.target.value}/${this.state.publication._id}`)
    }catch(e){
      this.setState({emailFirstError:"Log In under 'Returning Customers'", emailError: ''});
      return
    }


    // 3. Check both emails match
    const email1 = e.target?e.target.value:this.state.email1
    const emailError = this.state.email2 === email1?'':'Emails must match'
    if( emailError ){
      this.setState({ emailError, emailFirstError: '' });              
      return
    }

    // No error found
    this.setState({ emailFirstError: '', emailError: '' });
  };


  onApplyPromoToPrice = (promocodeObj) => {
    const {_id, discount} = promocodeObj
    const discountedValue =
      (this.state.purchasePrice * discount) / 100;
    let newValue = this.state.purchasePrice - discountedValue;
    newValue = newValue.toFixed(2);
    this.setState(
      {
        purchasePrice: newValue,
        discountpercent: discount,
        promoid: _id,
        showPromo: false,
      },
      () => {
        this.validateForm();
      },
    );

  };

  showForgotten = () => {
    this.setState({ showForgotten: true });
  };

  forgottenClick = (e) => {
    e.preventDefault();
    const buyerDets = {
      email: this.state.forgottenEmail,
      publicationID: this.state.publication._id,
    };
    axios.post('/api/order/forgot', buyerDets).then((res) => {
      if (res.data === 'No such user') {
        this.setState({
          forgottenEmailError:
            'We cannot find a user with that email address in the system. Please try again or sign up to the right.',
          emailResetMessage: '',
        });
      } else {
        this.setState({
          emailResetMessage:
            'Thank you. An email has been sent to your entered email address with further instructions',
          forgottenEmailError: '',
        });
      }
    });
  };

  performLoginCredentials = async (e, emailExisting, passwordExisting ) => {
    e.preventDefault();
    try{
      this.setState({ emailExisting, passwordExisting })
      const body = {
        email: emailExisting,
        password: passwordExisting,
        publicationID: this.state.publication._id,
      };
      const response = await axios.post('/api/order/buyerlogin', body)
      if( !response ) return   

      const { lastOrder } = response.data

      // Cover free case
      let defaultCheckboxSelection = '1PN' + this.state.publication.printPriceOneYear;
      let defaultPurchasePrice = this.state.publication.printPriceOneYear;
      if(this.state.publication.printPriceOneYear == '0' || this.state.publication.printPriceOneYear == '0.00' ||
      this.state.publication.printPriceOneYear.length == 0){
        defaultCheckboxSelection = 'FPN'
        defaultPurchasePrice = '0'
      }
        


      this.setState(
        {
          showNewCustomer: false,
          showReturnCustomerDetails: true,
          loggedInCustomerID: response.data.id,
          title: response.data.title,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          password: response.data.password,
          password2: response.data.password,
          email: response.data.email,
          email2: response.data.email,
          buyer_jwt: response.data.jwt,

          billingCompanyName: lastOrder.billingCompanyName?lastOrder.billingCompanyName:'',
          billingPostcode: lastOrder.billingPostcode?lastOrder.billingPostcode:'',
          billingAddress1: lastOrder.billingAddressOne?lastOrder.billingAddressOne:'',
          billingAddress2: lastOrder.billingAddressTwo?lastOrder.billingAddressTwo:'',
          billingAddress3: lastOrder.billingAddressThree?lastOrder.billingAddressThree:'',
          billingTown: lastOrder.billingCity?lastOrder.billingCity:'',
          billingState: lastOrder.billingState?lastOrder.billingState:'',

          showDeliveryAddress: false,
          //checkboxSelection: '',
          // showDeliveryAddress: lastOrder.shippingAddressOne!=null,
          checkboxSelection: lastOrder.shippingAddressOne!=null?defaultCheckboxSelection:this.state.checkboxSelection,
          purchasePrice: lastOrder.shippingAddressOne!=null?defaultPurchasePrice:this.state.purchasePrice,
          deliveryCompanyName: lastOrder.shippingCompanyName?lastOrder.shippingCompanyName:'',
          deliveryPostcode: lastOrder.shippingPostcode?lastOrder.shippingPostcode:'',
          deliveryTitle: lastOrder.shippingTitle?lastOrder.shippingTitle:'',
          deliveryFirstName: lastOrder.shippingFirstName?lastOrder.shippingFirstName:'',
          deliveryLastName: lastOrder.shippingLastName?lastOrder.shippingLastName:'',
          deliveryAddress1: lastOrder.shippingAddressOne?lastOrder.shippingAddressOne:'',
          deliveryAddress2: lastOrder.shippingAddressTwo?lastOrder.shippingAddressTwo:'',
          deliveryAddress3: lastOrder.shippingAddressThree?lastOrder.shippingAddressThree:'',
          deliveryTown: lastOrder.shippingCity?lastOrder.shippingCity:'',

          firstSubscritionStartDate: response.data.firstSubStartDate?moment(new Date(response.data.firstSubStartDate)).format('DD/MM/YYYY'):'-',
          lastSubscritionEndDate: moment(new Date(lastOrder.subEndDate)).format('DD/MM/YYYY'),

          modalLogin: false
        },
        () => {
          this.validateForm();
        });
        return true
    }catch(err){
      this.setState({ errors: err.response.data })
      return false
    }


  }


  onLogout = () => {
    let defaultCheckboxSelection = '1PN' + this.state.publication.printPriceOneYear;
    if(this.state.publication.printPriceOneYear = '0' || this.state.publication.printPriceOneYear == '0.00' ||
    this.state.publication.printPriceOneYear.length == 0)
      defaultCheckboxSelection = 'FPN'
    this.setState(
      {
        showNewCustomer: true,
        showReturnCustomerDetails: false,
        loggedInCustomerID: null,
        title: '',
        firstName: '',
        lastName: '',
        password: '',
        password2: '',
        email: '',
        email2: '',

        billingCompanyName: '',
        billingPostcode: '',
        billingAddress1: '',
        billingAddress2: '',
        billingAddress3: '',
        billingTown: '',
        billingState: '',

        showDeliveryAddress: true,
        checkboxSelection: defaultCheckboxSelection,
        deliveryCompanyName: '',
        deliveryPostcode: '',
        deliveryTitle: '',
        deliveryFirstName: '',
        deliveryLastName: '',
        deliveryAddress1: '',
        deliveryAddress2: '',
        deliveryAddress3: '',
        deliveryTown: '',

        firstSubscritionStartDate: '',
        lastSubscritionEndDate: '',
        showPaymentRequestButton: false
      }
    );

  }


  onSaveAddress = async () => {
    try{
      this.setState({showChangeAddressSuccess: false})
      const headers = { 'Authorization': `Bearer ${this.state.buyer_jwt}`}
      const body = { 
        shippingCompanyName: this.state.deliveryCompanyName,
        shippingPostcode: this.state.deliveryPostcode,
        shippingTitle: this.state.deliveryTitle,
        shippingFirstName: this.state.deliveryFirstName,
        shippingLastName: this.state.deliveryLastName,
        shippingAddressOne: this.state.deliveryAddress1,
        shippingAddressTwo: this.state.deliveryAddress2,
        shippingAddressThree: this.state.deliveryAddress3,
        shippingCity: this.state.deliveryTown,        
      }
      await axios.post('/api/order/buyer/address', body, { headers })
      this.setState({showChangeAddressSuccess: true})
    }catch(err){
      this.setState({ errors: err.response.data })
    }
  }

  setupFieldsToSend = (payment) => {
    const paymentData = {
      receiptText: this.state.receiptText,
      logo: this.state.logo,
      iosAppLink: this.state.iosAppLink,
      androidAppLink: this.state.androidAppLink,
      kindleAppLink: this.state.kindleAppLink,
      digitalEditionLink: this.state.digitalEditionLink,
      tel: this.state.tel,
      publisherName: this.state.publisherName,
      mainContactEmail: this.state.mainContactEmail,
      orderEmailAddress: this.state.publication.orderEmailAddress,
      addressOne: this.state.addressOne,
      addressTwo: this.state.addressTwo,
      addressThree: this.state.addressThree,
      postZip: this.state.postZip,
      recurring: this.state.recurring,
      country: this.state.country,
      checkboxSelection: this.state.checkboxSelection,
      maincurrency: this.state.currency,
      currency: this.state.currencySymbol,
      purchasePrice: this.state.purchaseSubscription
        ? this.state.purchasePrice
        : this.state.backIssuePurchasePrice,
      title: this.state.title,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      mobile: this.state.mobile,
      deliveryCompanyName: this.state.deliveryCompanyName,
      deliveryAddress1: this.state.deliveryAddress1,
      deliveryAddress2: this.state.deliveryAddress2,
      deliveryAddress3: this.state.deliveryAddress3,
      deliveryTown: this.state.deliveryTown,
      deliveryPostcode: this.state.deliveryPostcode,
      deliveryCheckbox: this.state.deliveryCheckbox,
      billingCompanyName: this.state.billingCompanyName,
      billingAddress1: this.state.billingAddress1,
      billingAddress2: this.state.billingAddress2,
      billingAddress3: this.state.billingAddress3,
      billingTown: this.state.billingTown,
      billingState: this.state.billingState,
      billingPostcode: this.state.billingPostcode,
      promoCode: this.state.promoCode,
      promoid: this.state.promoid,
      customQuestionAans: this.state.customQuestionAans,
      customQuestionBans: this.state.customQuestionBans,
      customQuestionCans: this.state.customQuestionCans,
      customQuestionDans: this.state.customQuestionDans,
      customQuestionEans: this.state.customQuestionEans,
      pubOffers: this.state.pubOffers,
      pubOffersPost: this.state.pubOffersPost,
      partnerOffers: this.state.partnerOffers,
      partnerOffersPost: this.state.partnerOffersPost,
      pubOffersTel: this.state.pubOffersTel,
      partnerOffersTel: this.state.partnerOffersTel,
      pubOffersSMS: this.state.pubOffersSMS,
      partnerOffersSMS: this.state.partnerOffersSMS,
      publicationID: this.state.publication._id,
      discountPercent: this.state.discountpercent,
      ccemail: this.state.publication.ccemail,
      emailText: this.state.publication.emailText,
      emailEnd: this.state.publication.emailEnd,
      payment: payment,
      urlKey: this.state.urlKey,
      loggedInCustomerID: this.state.loggedInCustomerID,
      secretKey: this.state.secretKey,
      ddaccountholder: this.state.ddaccountholder,
      ddsortcode: this.state.ddsortcode,
      ddaccountnumber: this.state.ddaccountnumber,
      onum: this.state.onum,
      gift: this.state.gift,
      deliveryTitle: this.state.deliveryTitle,
      deliveryFirstName: this.state.deliveryFirstName,
      deliveryLastName: this.state.deliveryLastName,
      publicationName: this.state.publication.publicationName,
      backIssues: this.state.backIssueSelection,
    };

    return paymentData;
  };

  payPalSubmit = (payment) => {
    const paymentData = this.setupFieldsToSend(payment);
    this.props.createOrder(paymentData, this.props.history);
  };

  payPayCancel = (payment) => {
    const paymentData = this.setupFieldsToSend(payment);
    this.props.cancelOrder(paymentData, this.props.history);
  };


  onPaymentRequestButtonSubmit = (paymentIntent) => {
    const paymentData = this.setupFieldsToSend()
    paymentData.isPaymentRequest = true
    localStorage.setItem('paymentData', JSON.stringify(paymentData))
    window.location = `/ordersuccess?url=interior-designer&payment_intent=${paymentIntent.id}`    
  }

  onSignUpForFreeButtonSubmit = () => {
    const paymentData = this.setupFieldsToSend()
    localStorage.setItem('paymentData', JSON.stringify(paymentData))
    window.location = `/ordersuccess?url=interior-designer&payment_intent=free`    
  }


  payPalBackIssueSubmit = (payment) => {
    const paymentData = this.setupFieldsToSend(payment);
    this.props.createBackIssueOrder(paymentData, this.props.history);
  };

  stripeBackIssueSubmit = (token) => {
    const paymentData = this.setupFieldsToSend();
    paymentData.stripeToken = token;
    this.props.createBackIssueOrder(paymentData, this.props.history);
  };


  render() {
    // const deliveryOptions = this.state.deliveryPostcodes;
    // const billingOptions = this.state.billingPostcodes;
    const showBillingPCLookup = this.state.showBillingPCLookup;
    const { showUKPrices, showUSPrices, showEUPrices } = this.state;
    const { t } = this.props;

    let countryOptions = getCountries();
    if (!this.state.sellOverseas)
      countryOptions = countryOptions.filter(
        (v) => v.key === this.state.publisherCountry,
      );

    const { errors } = this.state;
    const publication = this.state.publication;
    const titleOptions = getTitles();
    const yesNooptions = [
      { key: 'yes', text: 'Yes', value: 'Yes', },
      { key: 'no', text: 'No', value: 'No', },
    ];

    const onSuccess = (payment) => {
      this.payPalSubmit(payment);
    };

    const onError = (error) =>
      console.log('Erroneous payment OR failed to load script!', error);

    const onCancel = (data) => {
      this.payPayCancel(data);
    };

    const onBackIssueSuccess = (payment) => {
      this.payPalBackIssueSubmit(payment);
    };

    const onModalCard = () => {
      const paymentData = this.setupFieldsToSend()
      localStorage.setItem('paymentData', JSON.stringify(paymentData))
      this.setState({ modalCard: true })
    }

    const onModalDirectDebit = () =>  {
      const paymentData = this.setupFieldsToSend()
      localStorage.setItem('paymentData', JSON.stringify(paymentData))
      this.setState({modalDirectDebit:true})
    }

    const style = {
      background: {
        backgroundColor: this.state.bg75,
        // padding: '5em 0em'
      },
      magbackground: {
        backgroundColor: '#f3f3f3',
        //backgroundColor: this.state.bg10,
        marginTop: '-15px',
        marginBottom: '-20px',
        paddingTop: '3em',
        paddingBottom: '3em',
      },
      changecolor: {
        color: '#828282',
        fontSize: '18px',
      },
      digitalbackground: {
        backgroundColor: this.state.bg5,
      },
      printbackground: {
        backgroundColor: this.state.bg10,
      },
      bundlebackground: {
        backgroundColor: this.state.bg15,
      },
      menu: {
        backgroundColor: '#333',
      },
      container: {
        backgroundColor: publication.backgroundColour,
        color: '#fff',
        padding: '1em',
        fontSize: '20px',
        width: '100%',
      },
      logocontainer: {
        backgroundColor: '#fff',
        color: '#fff',
        padding: '2em',
        // marginTop: "-30px",
        fontSize: '20px',
        width: '100%',
      },
      tableHeader: {
        backgroundColor: publication.backgroundColour,
        padding: '2em',
        fontSize: '17px',
        color: '#fff',
      },
      tableContentdigital: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: this.state.bg05,
      },
      tableContentprint: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: '#f3f3f3',
      },
      tableContentbundle: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: this.state.bg15,
      },
    };
    let deliveryAddress;
    let purchaseHeader;
    let purchaseOptions;
    let paymentDetails;
    let showDiscount;
    let paymentButton;
    let forgottenContent;
    let newCustomerContent;
    let returnCustomerContent;
    let giftNameContent;

    if (this.state.showForgotten) {
      forgottenContent = (
        <React.Fragment>
          <Input
            name='forgottenEmail'
            type='email'
            label={{ basic: true, content: 'Email Address *' }}
            value={this.state.forgottenEmail}
            onChange={this.onChange}
            placeholder='Email'
            fluid
            error={this.state.forgottenEmailError}
          />
          {this.state.forgottenEmailError && (
            <div className='ui pointing red basic label'>
              {this.state.forgottenEmailError}
            </div>
          )}
          <br />
          <Button
            color='black'
            type='button'
            onClick={this.forgottenClick}
            fluid
          >
            Reset Password
          </Button>
          <p>{this.state.emailResetMessage}</p>
        </React.Fragment>
      );
    } else {
      forgottenContent = null;
    }

    // -------------------------
    // INIT: Payment Button
    // -------------------------
    console.log("CheckboxSelection ", this.state.checkboxSelection)
    if (this.state.showpaypal) {
      if (this.state.purchaseSubscription) {
        paymentButton = (
          <div style={{ marginLeft: 'auto', marginRight: 'auto', paddingBottom: '20px', }} >
            <PaypalButton
              client={{ production: this.state.paypal, }}
              env={'production'}
              commit={true}
              currency={this.state.currency}
              total={this.state.purchasePrice}
              onSuccess={onSuccess}
              onError={onError}
              onCancel={onCancel}
            />
          </div>
        );
      } else {
        paymentButton = (
          <div style={{ marginLeft: 'auto', marginRight: 'auto', paddingBottom: '20px', }} >
            <PaypalButton
              client={{ production: this.state.paypal, }}
              env={'production'}
              commit={true}
              currency={this.state.currency}
              total={this.state.backIssuePurchasePrice}
              onSuccess={onBackIssueSuccess}
              onError={onError}
              onCancel={onCancel}
            />
          </div>
        );
      }
    } else if(this.state.showStripe && this.state.secretKey && this.state.purchasePrice>0 ) {
        paymentButton = (
          <Button
            type='button'
            onClick={() => onModalCard()}
            size='massive'
            color='black'
            style={{ background: '#5cb123'}}
            className="dd-button">
            Pay now
          </Button>
        );      
    }else if (this.state.showDD && this.state.purchasePrice>0) {
      paymentButton = (
        <Button
          type='button'
          onClick={() => onModalDirectDebit()}
          size='massive'
          color='black'
          style={{ background: '#5cb123'}}
          className="dd-button">
          Pay now
        </Button>
      );
    }else if( this.state.showSignUpForFreeButton ){
      paymentButton = (
        <Button
          type='button'
          onClick={() => this.onSignUpForFreeButtonSubmit()}
          size='massive'
          color='black'
          style={{ background: '#5cb123'}}
          className="dd-button">
          Sign Up
        </Button>
      );    
    }else{
      paymentButton = (
        <div style={{ marginLeft: 'auto', marginRight: 'auto', paddingBottom: '20px', }} >
          <Button disabled size='massive' color='black'>
            {t('completeForm')}
          </Button>
        </div>
      );
    }




    // -------------------------
    // END: Payment Button
    // -------------------------


    if (this.state.discountpercent !== '') {
      showDiscount = (
        <Table.Row>
          <Table.Cell>Discount</Table.Cell>
          <Table.Cell>{this.state.discountpercent}%</Table.Cell>
        </Table.Row>
      );
    } else {
      showDiscount = null;
    }

    if (this.props.publication.publication?.backIssues.length > 0) {
      purchaseHeader = (
        <Grid.Row style={style.container}>
          <Grid.Column width={8}>{t('chooseOffer')}</Grid.Column>
          <Grid.Column width={4}>
            <div
              className={
                this.state.purchaseSubscription
                  ? 'purchase--options purchase--options__active'
                  : 'purchase--options'
              }
              onClick={() => this.setState({ purchaseSubscription: true })}
            >
              {t('subscriptionOptions')}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div
              className={
                !this.state.purchaseSubscription
                  ? 'purchase--options purchase--options__active'
                  : 'purchase--options'
              }
              onClick={() =>
                this.setState({
                  purchaseSubscription: false,
                  checkboxSelection: '',
                })
              }
            >
              BUY INDIVIDUAL ISSUES
            </div>
          </Grid.Column>
        </Grid.Row>
      );
    } else {
      purchaseHeader = (
        <Grid.Row style={style.container}>
          <Grid.Column>{t('chooseOffer')}</Grid.Column>
        </Grid.Row>
      );
    }

    if (this.state.purchaseSubscription) {
      if (showUKPrices) {
        if (
          !this.state.publication.subFree &&
          this.state.publication.price === undefined &&
          this.state.publication.priceOneYear === undefined &&
          this.state.publication.priceSixMonth === undefined &&
          this.state.publication.priceThreeMonth === undefined &&
          this.state.publication.printPrice === undefined &&
          this.state.publication.printPriceThreeMonth === undefined &&
          this.state.publication.printPriceOneYear === undefined &&
          this.state.publication.printPriceSixMonth === undefined &&
          this.state.publication.printDigPrice === undefined &&
          this.state.publication.printDigPriceOneYear === undefined &&
          this.state.publication.printDigPriceSixMonth === undefined &&
          this.state.publication.printDigPriceThreeMonth === undefined &&
          this.state.publication.priceOneYearRecurring === undefined &&
          this.state.publication.priceSixMonthRecurring === undefined &&
          this.state.publication.priceThreeMonthRecurring === undefined &&
          this.state.publication.printPriceThreeMonthRecurring === undefined &&
          this.state.publication.printPriceOneYearRecurring === undefined &&
          this.state.publication.printPriceSixMonthRecurring === undefined &&
          this.state.publication.printDigPriceOneYearRecurring === undefined &&
          this.state.publication.printDigPriceSixMonthRecurring === undefined &&
          this.state.publication.printDigPriceThreeMonthRecurring ===
            undefined &&
          !this.state.publication.printSubFree &&
          !this.state.publication.printDigSubFree
        ) {
          purchaseOptions = (
            <div style={{ padding: '25px' }}>
              <h3>{t('unavailableSubscriptionInCountry')}</h3>
            </div>
          );
        } else {
          purchaseOptions = (
            <div style={{ padding: '25px' }}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      textAlign='center'
                      style={style.tableHeader}
                    ></Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign='center'
                      style={style.tableHeader}
                    >
                      {t('subscription')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign='center'
                      style={style.tableHeader}
                    >
                      {t('price')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.publication.printSubFree ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} alt='' />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('fps')}
                            name='checkboxSelection'
                            value='FPN'
                            checked={this.state.checkboxSelection === 'FPN'}
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>Free</span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceOneYear ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} alt='' />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yps')}
                            name='checkboxSelection'
                            value={
                              '1PN' + this.state.publication.printPriceOneYear
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1PN' + this.state.publication.printPriceOneYear
                            }
                            onChange={this.onChangeHandler}
                          />                          
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.printPriceOneYear}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceOneYearRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} alt='' />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yrps')}
                            name='checkboxSelection'
                            value={
                              '1PR' +
                              this.state.publication.printPriceOneYearRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1PR' +
                                this.state.publication
                                  .printPriceOneYearRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;
                          {this.state.publication.printPriceOneYearRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceSixMonth ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} alt='' />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mps')}
                            name='checkboxSelection'
                            value={
                              '6PN' + this.state.publication.printPriceSixMonth
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6PN' + this.state.publication.printPriceSixMonth
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.printPriceSixMonth}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceSixMonthRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} alt='' />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mrps')}
                            name='checkboxSelection'
                            value={
                              '6PR' +
                              this.state.publication.printPriceSixMonthRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6PR' +
                                this.state.publication
                                  .printPriceSixMonthRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;
                          {this.state.publication.printPriceSixMonthRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceThreeMonth ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} alt='' />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mps')}
                            name='checkboxSelection'
                            value={
                              '3PN' +
                              this.state.publication.printPriceThreeMonth
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3PN' +
                                this.state.publication.printPriceThreeMonth
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.printPriceThreeMonth}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPrice ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} alt='' />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1mps')}
                            name='checkboxSelection'
                            value={'2PN' + this.state.publication.printPrice}
                            checked={
                              this.state.checkboxSelection ===
                              '2PN' + this.state.publication.printPrice
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.printPrice}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceThreeMonthRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} alt='' />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mrps')}
                            name='checkboxSelection'
                            value={
                              '3PR' +
                              this.state.publication
                                .printPriceThreeMonthRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3PR' +
                                this.state.publication
                                  .printPriceThreeMonthRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;
                          {this.state.publication.printPriceThreeMonthRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.subFree ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img
                          src={DigitalImage}
                          style={{ width: '50px' }}
                          alt=''
                        />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            name='checkboxSelection'
                            label={t('fds')}
                            value='FDN'
                            checked={this.state.checkboxSelection === 'FDN'}
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>Free</span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceOneYear ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img
                          src={DigitalImage}
                          style={{ width: '50px' }}
                          alt=''
                        />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yds')}
                            name='checkboxSelection'
                            value={'1DN' + this.state.publication.priceOneYear}
                            checked={
                              this.state.checkboxSelection ===
                              '1DN' + this.state.publication.priceOneYear
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.priceOneYear}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceOneYearRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img
                          src={DigitalImage}
                          style={{ width: '50px' }}
                          alt=''
                        />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yrds')}
                            name='checkboxSelection'
                            value={
                              '1DR' +
                              this.state.publication.priceOneYearRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1DR' +
                                this.state.publication.priceOneYearRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.priceOneYearRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceSixMonth ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img
                          src={DigitalImage}
                          style={{ width: '50px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mds')}
                            name='checkboxSelection'
                            value={'6DN' + this.state.publication.priceSixMonth}
                            checked={
                              this.state.checkboxSelection ===
                              '6DN' + this.state.publication.priceSixMonth
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.priceSixMonth}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceSixMonthRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img
                          src={DigitalImage}
                          style={{ width: '50px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mrds')}
                            name='checkboxSelection'
                            value={
                              '6DR' +
                              this.state.publication.priceSixMonthRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6DR' +
                                this.state.publication.priceSixMonthRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.priceSixMonthRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceThreeMonth ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img
                          src={DigitalImage}
                          style={{ width: '50px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mds')}
                            name='checkboxSelection'
                            value={
                              '3DN' + this.state.publication.priceThreeMonth
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3DN' + this.state.publication.priceThreeMonth
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.priceThreeMonth}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.price ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img
                          src={DigitalImage}
                          style={{ width: '50px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1mds')}
                            name='checkboxSelection'
                            value={'2DN' + this.state.publication.price}
                            checked={
                              this.state.checkboxSelection ===
                              '2DN' + this.state.publication.price
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.price}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceThreeMonthRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img
                          src={DigitalImage}
                          style={{ width: '50px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mrds')}
                            name='checkboxSelection'
                            value={
                              '3DR' +
                              this.state.publication.priceThreeMonthRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3DR' +
                                this.state.publication.priceThreeMonthRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;
                          {this.state.publication.priceThreeMonthRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigSubFree ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img
                          src={BundleImage}
                          style={{ width: '100px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('fbs')}
                            name='checkboxSelection'
                            value='FBN'
                            checked={this.state.checkboxSelection === 'FBN'}
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>Free</span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceOneYear ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img
                          src={BundleImage}
                          style={{ width: '100px' }}
                          alt=''
                        />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1ybs')}
                            name='checkboxSelection'
                            value={
                              '1BN' +
                              this.state.publication.printDigPriceOneYear
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1BN' +
                                this.state.publication.printDigPriceOneYear
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.printDigPriceOneYear}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceOneYearRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img
                          src={BundleImage}
                          style={{ width: '100px' }}
                          alt=''
                        />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yrbs')}
                            name='checkboxSelection'
                            value={
                              '1BR' +
                              this.state.publication
                                .printDigPriceOneYearRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1BR' +
                                this.state.publication
                                  .printDigPriceOneYearRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;
                          {this.state.publication.printDigPriceOneYearRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceSixMonth ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img
                          src={BundleImage}
                          style={{ width: '100px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mbs')}
                            name='checkboxSelection'
                            value={
                              '6BN' +
                              this.state.publication.printDigPriceSixMonth
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6BN' +
                                this.state.publication.printDigPriceSixMonth
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.printDigPriceSixMonth}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceSixMonthRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img
                          src={BundleImage}
                          style={{ width: '100px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mrbs')}
                            name='checkboxSelection'
                            value={
                              '6BR' +
                              this.state.publication
                                .printDigPriceSixMonthRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6BR' +
                                this.state.publication
                                  .printDigPriceSixMonthRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;
                          {
                            this.state.publication
                              .printDigPriceSixMonthRecurring
                          }
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceThreeMonth ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img
                          src={BundleImage}
                          style={{ width: '100px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mbs')}
                            name='checkboxSelection'
                            value={
                              '3BN' +
                              this.state.publication.printDigPriceThreeMonth
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3BN' +
                                this.state.publication.printDigPriceThreeMonth
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;
                          {this.state.publication.printDigPriceThreeMonth}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}

                  {this.state.publication.printDigPriceThreeMonthRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img
                          src={BundleImage}
                          style={{ width: '100px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mrbs')}
                            name='checkboxSelection'
                            value={
                              '3BR' +
                              this.state.publication
                                .printDigPriceThreeMonthRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3BR' +
                                this.state.publication
                                  .printDigPriceThreeMonthRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;
                          {
                            this.state.publication
                              .printDigPriceThreeMonthRecurring
                          }
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPrice ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img
                          src={BundleImage}
                          style={{ width: '100px' }}
                          alt=''
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1mbs')}
                            name='checkboxSelection'
                            value={'2BN' + this.state.publication.printDigPrice}
                            checked={
                              this.state.checkboxSelection ===
                              '2BN' + this.state.publication.printDigPrice
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          &pound;{this.state.publication.printDigPrice}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </Table.Body>
                <Table.Footer></Table.Footer>
              </Table>
            </div>
          );
        }
      }

      if (showUSPrices) {
        if (
          !this.state.publication.subFree &&
          this.state.publication.priceUS === undefined &&
          this.state.publication.priceOneYearUS === undefined &&
          this.state.publication.priceSixMonthUS === undefined &&
          this.state.publication.priceThreeMonthUS === undefined &&
          this.state.publication.printPriceUS === undefined &&
          this.state.publication.printPriceThreeMonthUS === undefined &&
          this.state.publication.printPriceOneYearUS === undefined &&
          this.state.publication.printPriceSixMonthUS === undefined &&
          this.state.publication.printDigPriceUS === undefined &&
          this.state.publication.printDigPriceOneYearUS === undefined &&
          this.state.publication.printDigPriceSixMonthUS === undefined &&
          this.state.publication.printDigPriceThreeMonthUS === undefined &&
          this.state.publication.priceOneYearUSRecurring === undefined &&
          this.state.publication.priceSixMonthUSRecurring === undefined &&
          this.state.publication.priceThreeMonthUSRecurring === undefined &&
          this.state.publication.printPriceThreeMonthUSRecurring ===
            undefined &&
          this.state.publication.printPriceOneYearUSRecurring === undefined &&
          this.state.publication.printPriceSixMonthUSRecurring === undefined &&
          this.state.publication.printDigPriceOneYearUSRecurring ===
            undefined &&
          this.state.publication.printDigPriceSixMonthUSRecurring ===
            undefined &&
          this.state.publication.printDigPriceThreeMonthUSRecurring ===
            undefined &&
          !this.state.publication.printSubFree &&
          !this.state.publication.printDigSubFree
        ) {
          purchaseOptions = (
            <div style={{ padding: '25px' }}>
              <h3>{t('unavailableSubscriptionInCountry')}</h3>
            </div>
          );
        } else {
          purchaseOptions = (
            <div style={{ padding: '25px' }}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      textAlign='center'
                      style={style.tableHeader}
                    ></Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign='center'
                      style={style.tableHeader}
                    >
                      {t('subscription')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign='center'
                      style={style.tableHeader}
                    >
                      {t('price')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.publication.printSubFree ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('fps')}
                            name='checkboxSelection'
                            value='FPN'
                            checked={this.state.checkboxSelection === 'FPN'}
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>Free</span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceOneYearUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yps')}
                            name='checkboxSelection'
                            value={
                              '1PN' + this.state.publication.printPriceOneYearUS
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1PN' + this.state.publication.printPriceOneYearUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.printPriceOneYearUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceOneYearUSRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yrps')}
                            name='checkboxSelection'
                            value={
                              '1PR' +
                              this.state.publication
                                .printPriceOneYearUSRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1PR' +
                                this.state.publication
                                  .printPriceOneYearUSRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.printPriceOneYearUSRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceSixMonthUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mps')}
                            name='checkboxSelection'
                            value={
                              '6PN' +
                              this.state.publication.printPriceSixMonthUS
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6PN' +
                                this.state.publication.printPriceSixMonthUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.printPriceSixMonthUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceSixMonthUSRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mrps')}
                            name='checkboxSelection'
                            value={
                              '6PR' +
                              this.state.publication
                                .printPriceSixMonthUSRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6PR' +
                                this.state.publication
                                  .printPriceSixMonthUSRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          $
                          {this.state.publication.printPriceSixMonthUSRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceThreeMonthUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mps')}
                            name='checkboxSelection'
                            value={
                              '3PN' +
                              this.state.publication.printPriceThreeMonthUS
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3PN' +
                                this.state.publication.printPriceThreeMonthUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.printPriceThreeMonthUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1mps')}
                            name='checkboxSelection'
                            value={'2PN' + this.state.publication.printPriceUS}
                            checked={
                              this.state.checkboxSelection ===
                              '2PN' + this.state.publication.printPriceUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.printPriceUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceThreeMonthUSRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mrps')}
                            name='checkboxSelection'
                            value={
                              '3PR' +
                              this.state.publication
                                .printPriceThreeMonthUSRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3PR' +
                                this.state.publication
                                  .printPriceThreeMonthUSRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          $
                          {
                            this.state.publication
                              .printPriceThreeMonthUSRecurring
                          }
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.subFree ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            name='checkboxSelection'
                            label={t('fds')}
                            value='FDN'
                            checked={this.state.checkboxSelection === 'FDN'}
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>Free</span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceOneYearUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yds')}
                            name='checkboxSelection'
                            value={
                              '1DN' + this.state.publication.priceOneYearUS
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1DN' + this.state.publication.priceOneYearUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.priceOneYearUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceOneYearUSRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yrds')}
                            name='checkboxSelection'
                            value={
                              '1DR' +
                              this.state.publication.priceOneYearUSRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1DR' +
                                this.state.publication.priceOneYearUSRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.priceOneYearUSRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceSixMonthUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mds')}
                            name='checkboxSelection'
                            value={
                              '6DN' + this.state.publication.priceSixMonthUS
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6DN' + this.state.publication.priceSixMonthUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.priceSixMonthUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceSixMonthUSRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mrds')}
                            name='checkboxSelection'
                            value={
                              '6DR' +
                              this.state.publication.priceSixMonthUSRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6DR' +
                                this.state.publication.priceSixMonthUSRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.priceSixMonthUSRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceThreeMonthUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mds')}
                            name='checkboxSelection'
                            value={
                              '3DN' + this.state.publication.priceThreeMonthUS
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3DN' + this.state.publication.priceThreeMonthUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.priceThreeMonthUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1mds')}
                            name='checkboxSelection'
                            value={'2DN' + this.state.publication.priceUS}
                            checked={
                              this.state.checkboxSelection ===
                              '2DN' + this.state.publication.priceUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.priceUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceThreeMonthUSRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mrds')}
                            name='checkboxSelection'
                            value={
                              '3DR' +
                              this.state.publication.priceThreeMonthUSRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3DR' +
                                this.state.publication
                                  .priceThreeMonthUSRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.priceThreeMonthUSRecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigSubFree ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('fbs')}
                            name='checkboxSelection'
                            value='FBN'
                            checked={this.state.checkboxSelection === 'FBN'}
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>Free</span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceOneYearUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1ybs')}
                            name='checkboxSelection'
                            value={
                              '1BN' +
                              this.state.publication.printDigPriceOneYearUS
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1BN' +
                                this.state.publication.printDigPriceOneYearUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.printDigPriceOneYearUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceOneYearUSRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yrbs')}
                            name='checkboxSelection'
                            value={
                              '1BR' +
                              this.state.publication
                                .printDigPriceOneYearUSRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1BR' +
                                this.state.publication
                                  .printDigPriceOneYearUSRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          $
                          {
                            this.state.publication
                              .printDigPriceOneYearUSRecurring
                          }
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceSixMonthUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mbs')}
                            name='checkboxSelection'
                            value={
                              '6BN' +
                              this.state.publication.printDigPriceSixMonthUS
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6BN' +
                                this.state.publication.printDigPriceSixMonthUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.printDigPriceSixMonthUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceSixMonthUSRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mrbs')}
                            name='checkboxSelection'
                            value={
                              '6BR' +
                              this.state.publication
                                .printDigPriceSixMonthUSRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6BR' +
                                this.state.publication
                                  .printDigPriceSixMonthUSRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          $
                          {
                            this.state.publication
                              .printDigPriceSixMonthUSRecurring
                          }
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceThreeMonthUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mbs')}
                            name='checkboxSelection'
                            value={
                              '3BN' +
                              this.state.publication.printDigPriceThreeMonthUS
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3BN' +
                                this.state.publication.printDigPriceThreeMonthUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.printDigPriceThreeMonthUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceUS ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1mbs')}
                            name='checkboxSelection'
                            value={
                              '2BN' + this.state.publication.printDigPriceUS
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '2BN' + this.state.publication.printDigPriceUS
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          ${this.state.publication.printDigPriceUS}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceThreeMonthUSRecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mrbs')}
                            name='checkboxSelection'
                            value={
                              '3BR' +
                              this.state.publication
                                .printDigPriceThreeMonthUSRecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3BR' +
                                this.state.publication
                                  .printDigPriceThreeMonthUSRecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          $
                          {
                            this.state.publication
                              .printDigPriceThreeMonthUSRecurring
                          }
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </Table.Body>
                <Table.Footer></Table.Footer>
              </Table>
            </div>
          );
        }
      }

      if (showEUPrices) {
        if (
          !this.state.publication.subFree &&
          this.state.publication.priceEU === undefined &&
          this.state.publication.priceOneYearEU === undefined &&
          this.state.publication.priceSixMonthEU === undefined &&
          this.state.publication.priceThreeMonthEU === undefined &&
          this.state.publication.printPriceEU === undefined &&
          this.state.publication.printPriceThreeMonthEU === undefined &&
          this.state.publication.printPriceOneYearEU === undefined &&
          this.state.publication.printPriceSixMonthEU === undefined &&
          this.state.publication.printDigPriceEU === undefined &&
          this.state.publication.printDigPriceOneYearEU === undefined &&
          this.state.publication.printDigPriceSixMonthEU === undefined &&
          this.state.publication.printDigPriceThreeMonthEU === undefined &&
          this.state.publication.priceOneYearEURecurring === undefined &&
          this.state.publication.priceSixMonthEURecurring === undefined &&
          this.state.publication.priceThreeMonthEURecurring === undefined &&
          this.state.publication.printPriceThreeMonthEURecurring ===
            undefined &&
          this.state.publication.printPriceOneYearEURecurring === undefined &&
          this.state.publication.printPriceSixMonthEURecurring === undefined &&
          this.state.publication.printDigPriceOneYearEURecurring ===
            undefined &&
          this.state.publication.printDigPriceSixMonthEURecurring ===
            undefined &&
          this.state.publication.printDigPriceThreeMonthEURecurring ===
            undefined &&
          !this.state.publication.printSubFree &&
          !this.state.publication.printDigSubFree
        ) {
          purchaseOptions = (
            <div style={{ padding: '25px' }}>
              <h3>
                Sorry, but this subscription is not available for your chosen
                Country
              </h3>
            </div>
          );
        } else {
          purchaseOptions = (
            <div style={{ padding: '25px' }}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      textAlign='center'
                      style={style.tableHeader}
                    ></Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign='center'
                      style={style.tableHeader}
                    >
                      {t('subscription')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign='center'
                      style={style.tableHeader}
                    >
                      {t('price')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.publication.printSubFree ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('fps')}
                            name='checkboxSelection'
                            value='FPN'
                            checked={this.state.checkboxSelection === 'FPN'}
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>Free</span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceOneYearEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yps')}
                            name='checkboxSelection'
                            value={
                              '1PN' + this.state.publication.printPriceOneYearEU
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1PN' + this.state.publication.printPriceOneYearEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.printPriceOneYearEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceOneYearEURecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yrps')}
                            name='checkboxSelection'
                            value={
                              '1PR' +
                              this.state.publication
                                .printPriceOneYearEURecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1PR' +
                                this.state.publication
                                  .printPriceOneYearEURecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.printPriceOneYearEURecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceSixMonthEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mps')}
                            name='checkboxSelection'
                            value={
                              '6PN' +
                              this.state.publication.printPriceSixMonthEU
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6PN' +
                                this.state.publication.printPriceSixMonthEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.printPriceSixMonthEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceSixMonthEURecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mrps')}
                            name='checkboxSelection'
                            value={
                              '6PR' +
                              this.state.publication
                                .printPriceSixMonthEURecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6PR' +
                                this.state.publication
                                  .printPriceSixMonthEURecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €
                          {this.state.publication.printPriceSixMonthEURecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceThreeMonthEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mps')}
                            name='checkboxSelection'
                            value={
                              '3PN' +
                              this.state.publication.printPriceThreeMonthEU
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3PN' +
                                this.state.publication.printPriceThreeMonthEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.printPriceThreeMonthEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1mps')}
                            name='checkboxSelection'
                            value={'2PN' + this.state.publication.printPriceEU}
                            checked={
                              this.state.checkboxSelection ===
                              '2PN' + this.state.publication.printPriceEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.printPriceEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printPriceThreeMonthEURecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <img src={MagImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mrps')}
                            name='checkboxSelection'
                            value={
                              '3PR' +
                              this.state.publication
                                .printPriceThreeMonthEURecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3PR' +
                                this.state.publication
                                  .printPriceThreeMonthEURecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentprint}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €
                          {
                            this.state.publication
                              .printPriceThreeMonthEURecurring
                          }
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.subFree ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            name='checkboxSelection'
                            label={t('fds')}
                            value='FDN'
                            checked={this.state.checkboxSelection === 'FDN'}
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>Free</span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceOneYearEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yds')}
                            name='checkboxSelection'
                            value={
                              '1DN' + this.state.publication.priceOneYearEU
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1DN' + this.state.publication.priceOneYearEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.priceOneYearEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceOneYearEURecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yrds')}
                            name='checkboxSelection'
                            value={
                              '1DR' +
                              this.state.publication.priceOneYearEURecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1DR' +
                                this.state.publication.priceOneYearEURecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.priceOneYearEURecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceSixMonthEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mds')}
                            name='checkboxSelection'
                            value={
                              '6DN' + this.state.publication.priceSixMonthEU
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6DN' + this.state.publication.priceSixMonthEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.priceSixMonthEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceSixMonthEURecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mrds')}
                            name='checkboxSelection'
                            value={
                              '6DR' +
                              this.state.publication.priceSixMonthEURecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6DR' +
                                this.state.publication.priceSixMonthEURecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.priceSixMonthEURecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceThreeMonthEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mds')}
                            name='checkboxSelection'
                            value={
                              '3DN' + this.state.publication.priceThreeMonthEU
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3DN' + this.state.publication.priceThreeMonthEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.priceThreeMonthEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1mds')}
                            name='checkboxSelection'
                            value={'2DN' + this.state.publication.priceEU}
                            checked={
                              this.state.checkboxSelection ===
                              '2DN' + this.state.publication.priceEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.priceEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.priceThreeMonthEURecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <img src={DigitalImage} style={{ width: '50px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mrds')}
                            name='checkboxSelection'
                            value={
                              '3DR' +
                              this.state.publication.priceThreeMonthEURecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3DR' +
                                this.state.publication
                                  .priceThreeMonthEURecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentdigital}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.priceThreeMonthEURecurring}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigSubFree ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('fbs')}
                            name='checkboxSelection'
                            value='FBN'
                            checked={this.state.checkboxSelection === 'FBN'}
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>Free</span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceOneYearEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1ybs')}
                            name='checkboxSelection'
                            value={
                              '1BN' +
                              this.state.publication.printDigPriceOneYearEU
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1BN' +
                                this.state.publication.printDigPriceOneYearEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.printDigPriceOneYearEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceOneYearEURecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>

                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1yrbs')}
                            name='checkboxSelection'
                            value={
                              '1BR' +
                              this.state.publication
                                .printDigPriceOneYearEURecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '1BR' +
                                this.state.publication
                                  .printDigPriceOneYearEURecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €
                          {
                            this.state.publication
                              .printDigPriceOneYearEURecurring
                          }
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceSixMonthEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mbs')}
                            name='checkboxSelection'
                            value={
                              '6BN' +
                              this.state.publication.printDigPriceSixMonthEU
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6BN' +
                                this.state.publication.printDigPriceSixMonthEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.printDigPriceSixMonthEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceSixMonthEURecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('6mrbs')}
                            name='checkboxSelection'
                            value={
                              '6BR' +
                              this.state.publication
                                .printDigPriceSixMonthEURecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '6BR' +
                                this.state.publication
                                  .printDigPriceSixMonthEURecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €
                          {
                            this.state.publication
                              .printDigPriceSixMonthEURecurring
                          }
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceThreeMonthEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mbs')}
                            name='checkboxSelection'
                            value={
                              '3BN' +
                              this.state.publication.printDigPriceThreeMonthEU
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3BN' +
                                this.state.publication.printDigPriceThreeMonthEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.printDigPriceThreeMonthEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceEU ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('1mbs')}
                            name='checkboxSelection'
                            value={
                              '2BN' + this.state.publication.printDigPriceEU
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '2BN' + this.state.publication.printDigPriceEU
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €{this.state.publication.printDigPriceEU}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {this.state.publication.printDigPriceThreeMonthEURecurring ? (
                    <Table.Row>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <img src={BundleImage} style={{ width: '150px' }} />
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <Form.Field>
                          <Checkbox
                            style={style.changecolor}
                            radio
                            label={t('3mrbs')}
                            name='checkboxSelection'
                            value={
                              '3BR' +
                              this.state.publication
                                .printDigPriceThreeMonthEURecurring
                            }
                            checked={
                              this.state.checkboxSelection ===
                              '3BR' +
                                this.state.publication
                                  .printDigPriceThreeMonthEURecurring
                            }
                            onChange={this.onChangeHandler}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell
                        textAlign='center'
                        style={style.tableContentbundle}
                      >
                        <span style={{ fontSize: '18px' }}>
                          €
                          {
                            this.state.publication
                              .printDigPriceThreeMonthEURecurring
                          }
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </Table.Body>
                <Table.Footer></Table.Footer>
              </Table>
            </div>
          );
        }
      }
    } else {
      if (showEUPrices || showUKPrices || showUSPrices) {
        purchaseOptions = (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  textAlign='center'
                  style={style.tableHeader}
                  width={2}
                ></Table.HeaderCell>
                <Table.HeaderCell
                  textAlign='center'
                  style={style.tableHeader}
                  width={7}
                >
                  {t('backIssue')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign='center'
                  style={style.tableHeader}
                  width={2}
                >
                  {t('price')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign='center'
                  style={style.tableHeader}
                  width={3}
                >
                  {t('ppPrice')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign='center'
                  style={style.tableHeader}
                  width={2}
                >
                  {t('buy')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.publication.publication.backIssues.map(
                (backIssue) => (
                  <Table.Row key={backIssue._id}>
                    <Table.Cell
                      textAlign='center'
                      style={style.tableContentprint}
                    >
                      <img
                        src={backIssue.coverImg}
                        style={{ width: '100%' }}
                        alt=''
                      />
                    </Table.Cell>
                    <Table.Cell
                      textAlign='center'
                      style={style.tableContentprint}
                    >
                      <h3 style={{ fontSize: '18px' }}>{backIssue.volume}</h3>
                      <p style={{ fontSize: '13px' }}>
                        {backIssue.description}
                      </p>
                    </Table.Cell>
                    <Table.Cell
                      textAlign='center'
                      style={style.tableContentprint}
                    >
                      <span style={{ fontSize: '18px' }}>
                        {showUKPrices
                          ? '£' + backIssue.price
                          : showEUPrices
                          ? '€' + backIssue.priceEU
                          : '$' + backIssue.priceUS}
                      </span>
                    </Table.Cell>
                    <Table.Cell
                      textAlign='center'
                      style={style.tableContentprint}
                    >
                      <span style={{ fontSize: '18px' }}>
                        {showUKPrices
                          ? '£' + backIssue.ppPrice
                          : showEUPrices
                          ? '€' + backIssue.ppPriceEU
                          : '$' + backIssue.ppPriceUS}
                      </span>
                    </Table.Cell>
                    <Table.Cell
                      textAlign='center'
                      style={style.tableContentprint}
                    >
                      <Form.Field>
                        <Checkbox
                          style={style.changecolor}
                          name='backIssueSelection'
                          value={backIssue._id}
                          onChange={this.onBackIssueHandler}
                          price={
                            showUKPrices
                              ? backIssue.price + backIssue.ppPrice
                              : showEUPrices
                              ? backIssue.priceEU + backIssue.ppPriceEU
                              : backIssue.priceUS + backIssue.ppPriceUS
                          }
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        );
      }
    }





    if (this.state.showGiftNames) {
      giftNameContent = (
        <React.Fragment>
          <Form.Select
            options={titleOptions}
            onChange={this.handleGiftTitleChange}
            placeholder={t('itle')}
            selection
            style={{ width: '10px!important' }}
            name='deliveryTitle'
            value={this.state.deliveryTitle}
            error={errors.deliveryTitle}
          />
          <div>
            <Input
              name='deliveryFirstName'
              label={{ basic: true, content: t('firstName') + ' *' }}
              width={4}
              value={this.state.deliveryFirstName}
              onChange={this.onChange}
              placeholder={t('firstName')}
              error={errors.deliveryFirstName}
            />
            {errors.deliveryFirstName && (
              <div className='ui pointing red basic label'>
                {errors.deliveryFirstName}
              </div>
            )}
          </div>
          <br />
          <div>
            <Input
              name='deliveryLastName'
              label={{ basic: true, content: t('lastName') + ' *' }}
              value={this.state.deliveryLastName}
              onChange={this.onChange}
              placeholder={t('lastName')}
              fluid
              error={errors.deliveryLastName}
            />
            {errors.deliveryLastName && (
              <div className='ui pointing red basic label'>
                {errors.deliveryLastName}
              </div>
            )}
          </div>
          <br />
          <div></div>
        </React.Fragment>
      );
    } else {
      giftNameContent = null;
    }

    const deliveryAddressForm = (
        <div>
          <Input
              label={{ basic: true, content: t('companyName') }} name='deliveryCompanyName'
              value={this.state.deliveryCompanyName} onChange={this.onChange} fluid
            />
            <br />
            <Input
              label={{ basic: true, content: t('addr1') + ' *' }} name='deliveryAddress1'
              value={this.state.deliveryAddress1} onChange={this.onChange} fluid
            />
            <br />
            <Input
              label={{ basic: true, content: t('addr2') }} name='deliveryAddress2'
              value={this.state.deliveryAddress2} onChange={this.onChange} fluid
            />
            <br />
            <Input
              label={{ basic: true, content: t('addr3') }} name='deliveryAddress3'
              value={this.state.deliveryAddress3} onChange={this.onChange} fluid
            />
            <br />
            <Input
              label={{ basic: true, content: t('townCity') }} name='deliveryTown'
              value={this.state.deliveryTown} onChange={this.onChange} fluid
            />
            <br />
            <Input
              label={{ basic: true, content: t('postcode') + ' *' }} name='deliveryPostcode'
              value={this.state.deliveryPostcode} onChange={this.onChange} fluid
            />
            <br />
            <Input
              label={{ basic: true, content: t('country') }} name='deliveryCountry'
              fluid value={this.state.countryText} disabled={true}
            />
        </div>)

    if (this.state.showDeliveryAddress) {
      if (this.state.deliveryCheckbox) {
        deliveryAddress = (
          <React.Fragment>
            <Grid.Row>
              <div style={style.container}>{t('deliveryAddress')}</div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div style={{ padding: '25px' }}>
                  <Checkbox
                    name='deliveryCheckbox'
                    label={t('sameBillingAddr')}
                    checked={this.state.deliveryCheckbox}
                    onChange={this.handleDeliveryCheck}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        );
      } else if (!this.state.deliveryCheckbox) {
        deliveryAddress = (
          <React.Fragment>
            <Grid.Row>
              <div style={style.container}>{t('deliveryAddress')}</div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div style={{ padding: '25px' }}>
                  <Checkbox
                    name='gift'
                    label={t('boughtAsGift')}
                    checked={this.state.gift}
                    onChange={this.handleGiftCheck}
                  />
                  <br />
                  {giftNameContent}
                  <br />
                  <Checkbox
                    name='deliveryCheckbox'
                    label={t('sameBillingAddr')}
                    checked={this.state.deliveryCheckbox}
                    onChange={this.handleDeliveryCheck}
                  />
                  <br />
                  <br />
                  {showBillingPCLookup &&
                    <PostalCodeLookup 
                      onSelected={(e,{value,text}) => this.handleDeliveryChange(e,{value,text})}/>
                  }
                  {deliveryAddressForm}
                </div>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        );
      }
    } else {
      deliveryAddress = null;
    }


    let payPalRadio;
    let dDRadio;
    let ccradio;

    this.state.paypal && this.state.checkboxSelection.substring(2, 3) !== 'R'
      ? (payPalRadio = (
          <React.Fragment>
            <Checkbox radio name='paymentType' value='1'
              checked={this.state.paymentType === '1'} onChange={this.handlePaymentChange}/>
              {' '}
              <img src={PPImage} alt=''
                style={{ marginLeft: '10px', verticalAlign: 'middle', width: '100px', }} />
            <br />
            <br />
          </React.Fragment>
        ))
      : (payPalRadio = null);


    this.state.publishableKey || this.state.user
      ? (ccradio = (
          <React.Fragment>
            <Checkbox radio name='paymentType' value='2'
              checked={this.state.paymentType === '2'}
              onChange={this.handlePaymentChange}/>{' '}
            <img src={CCImage} alt=''
              style={{ marginLeft: '10px', verticalAlign: 'middle', width: '100px', }}/>
            <br />
            <br />
          </React.Fragment>
        ))
      : (ccradio = null);

    const directDebitDets = this.state.accountDets?this.state.accountDets.directDebitDets:null
    directDebitDets && directDebitDets.selectedDD && this.state.currency === 'GBP'
      ? (dDRadio = (
          <React.Fragment>
            <Checkbox radio name='paymentType' value='3'
              checked={this.state.paymentType === '3'}
              onChange={this.handlePaymentChange}/>{' '}
            <img src={DDImage} alt=''
              style={{ marginLeft: '10px', verticalAlign: 'middle', width: '100px',}} />
            <br />
            <br />
          </React.Fragment>
        ))
      : (dDRadio = null);

    this.state.showNewCustomer
      ? (newCustomerContent = (
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <div style={{ padding: '25px' }}>
              <Header as='h3'>{t('newCustomer')}</Header>
              <div>
                <Input
                  name='email'
                  type='email'
                  label={{ basic: true, content: t('emailAddr') + ' *' }}
                  value={this.state.email}
                  onChange={this.onChange}
                  onBlur={this.onEmailFirstBlur}
                  placeholder='Email'
                  autoComplete='new-email'
                  fluid
                  error={errors.email}
                />
                {this.state.emailFirstError && (
                  <div className='ui pointing red basic label'>
                    {this.state.emailFirstError}
                  </div>
                )}
              </div>
              <br />
              <RenderFieldLeftBasic
                name='email2'
                type='email'
                label={t('confirmEmail') + ' *'}
                value={this.state.email2}
                onChange={this.onChange}
                onBlur={this.onEmailBlur}
                autoComplete='new-email'
                placeholder={t('confirmEmail')}
                error={this.state.emailError}
              />
              <br />
              <Form.Group>
                <Form.Select
                  options={titleOptions}
                  onChange={this.handleTitleChange}
                  placeholder={t('title')}
                  selection
                  name='title'
                  value={this.state.title}
                  error={errors.title}
                  width={4}
                  style={{ minWidth: 0 }}
                  className='customer--title'
                />
                <Form.Field width={12}>
                  <Input
                    name='firstName'
                    label={{ basic: true, content: t('firstName') + ' *' }}
                    value={this.state.firstName}
                    onChange={this.onChange}
                    placeholder={t('firstName')}
                    error={errors.firstName}
                    className='customer--fname'
                  />
                  {errors.firstName && (
                    <div className='ui pointing red basic label'>
                      {errors.firstName}
                    </div>
                  )}
                </Form.Field>
                <br />
              </Form.Group>
              <div>
                <Input
                  name='lastName'
                  label={{ basic: true, content: t('lastName') + ' *' }}
                  value={this.state.lastName}
                  onChange={this.onChange}
                  placeholder={t('lastName')}
                  fluid
                  error={errors.lastName}
                />
                {errors.lastName && (
                  <div className='ui pointing red basic label'>
                    {errors.lastName}
                  </div>
                )}
              </div>
              <br />
              <div>
                <Input
                  name='mobile'
                  label={{ basic: true, content: t('mobile') }}
                  value={this.state.mobile}
                  onChange={this.onChange}
                  placeholder={t('mobile')}
                  fluid
                  error={errors.mobile}
                />
                {errors.mobile && (
                  <div className='ui pointing red basic label'>
                    {errors.mobile}
                  </div>
                )}
              </div>
              <br />
              <div>
                <Input
                  name='password'
                  type='password'
                  label={{ basic: true, content: t('choosePwd') + ' *' }}
                  value={this.state.password}
                  onChange={this.onChange}
                  placeholder={t('password')}
                  autoComplete='new-password'
                  fluid
                  error={errors.password}
                />
                {errors.password && (
                  <div className='ui pointing red basic label'>
                    {errors.password}
                  </div>
                )}
              </div>
              <br />
              <div>
                <Input
                  name='password2'
                  type='password'
                  label={{ basic: true, content: t('confirmPwd') + ' *' }}
                  value={this.state.password2}
                  onChange={this.onChange}
                  placeholder={t('confirmPwd')}
                  fluid
                  onBlur={this.onConfirmPasswordBlur}
                  error={this.state.passwordError.length > 0}
                />
                {this.state.passwordError && (
                  <div className='ui pointing red basic label'>
                    {this.state.passwordError}
                  </div>
                )}
              </div>
              <br />
            </div>
          </Grid.Column>
        ))
      : (newCustomerContent = null);
    this.state.showReturnCustomerDetails
      ? (returnCustomerContent = (
          <Grid.Column>
            <Grid>
              <Grid.Row style={{ padding: ' 25px'}}>
                <Grid.Column mobile={8} tablet={8} computer={8} style={{ marginTop: '10px'}}>
                  <div>
                    <Header as='h3'>{t('successLoggedIn')}</Header>
                    <p>
                      {t('welcomeBack')}, {this.state.title} {this.state.firstName}{' '}
                      {this.state.lastName}
                    </p> 
                  </div>             
                </Grid.Column>

                <Grid.Column mobile={8} tablet={8} computer={8} style={{ marginTop: '10px'}}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                    <div>Subscriber since: <b>{this.state.firstSubscritionStartDate}</b></div>
                    <div style={{ marginTop: '10px'}}>Current subscription expiry date: <b>{this.state.lastSubscritionEndDate}</b></div>
                  </div>
                </Grid.Column>

              </Grid.Row>
            </Grid>
            <Grid.Row>
              <Grid.Column>
                <div style={{ padding: '25px' }}>
                  <Header as='h3'>Delivery Address</Header>
                  {showBillingPCLookup &&
                    <PostalCodeLookup 
                      onSelected={(e, {value,text}) => this.handleDeliveryChange(e,{text,value})}/>
                  }
                  {deliveryAddressForm}
                  <br />
                  <br />
                  <div>
                    {this.state.showChangeAddressSuccess?
                    <div className="storefront-alert">                            
                      Address Changed Successfully
                    </div>                  
                    :null}
                  </div>
                  <br />
                  <div>
                    <Button type='button' fluid color='black' 
                      onClick={this.onSaveAddress} style={{ width: '50%', margin: '0 auto'}}>
                      Change Address
                    </Button>
                  </div>

                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        ))
      : (returnCustomerContent = (
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={8}
            style={{ height: '100%' }}
          >
            <div style={{ padding: '25px' }}>
              <Header as='h3'>{t('returningCustomers')}</Header>
              <Input
                name='emailExisting'
                type='email'
                label={{ basic: true, content: 'Email Address *' }}
                value={this.state.emailExisting}
                onChange={this.onChange}
                placeholder='Email'
                fluid
                error={errors.emailExisting != null}
              />
              {errors.emailExisting && (
                <div
                  className='ui pointing red basic label'
                  style={{ marginBottom: '10px' }}
                >
                  {errors.emailExisting}
                </div>
              )}
              <br />
              <Input
                name='passwordExisting'
                type='password'
                label={{ basic: true, content: 'Password *' }}
                value={this.state.passwordExisting}
                onChange={this.onChange}
                fluid
                placeholder='Password'
                error={errors.passwordExisting}
              />
              {errors.passwordExisting && (
                <div
                  className='ui pointing red basic label'
                  style={{ marginBottom: '10px' }}
                >
                  {errors.passwordExisting}
                </div>
              )}
              <br />
              <Button type='button' fluid
                color='black' onClick={(e) => 
                  this.performLoginCredentials(e, this.state.emailExisting, this.state.passwordExisting)}>
                {t('login')}
              </Button>
              <p
                style={{
                  marginTop: '5px',
                  textAlign: 'right',
                  cursor: 'pointer',
                }}
              >
                <Menu.Item onClick={() => this.showForgotten()}>
                  {t('forgotPwd')}
                </Menu.Item>
              </p>
              {forgottenContent}
            </div>
          </Grid.Column>
        ));

    if (this.state.purchaseSubscription) {
      paymentDetails = (
        <Table basic='very'>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {t('subscription')}:
              </Table.Cell>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {this.state.publication.publicationName}
              </Table.Cell>
            </Table.Row>
            <Table.Row style={{ borderTop: 'none!important' }}>
              <Table.Cell style={{ border: 'none!important' }}>
                {t('subscriptionTerm')}:
              </Table.Cell>
              <Table.Cell style={{ border: 'none!important' }}>
                {this.state.term}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {t('price')}:
              </Table.Cell>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {this.state.purchasePrice !== 'FREE'
                  ? this.state.currencySymbol
                  : null}
                {this.state.purchasePrice}
              </Table.Cell>
            </Table.Row>
            {showDiscount}
            <Table.Row>
              <Table.Cell>{t('deliveryCountry')}:</Table.Cell>
              <Table.Cell>{this.state.countryText}</Table.Cell>
            </Table.Row>
            {this.state.showPromo &&
              <PromoCode 
                onApplyPromo={(promocodeObj) => this.onApplyPromoToPrice(promocodeObj)} 
                publication={this.state.publication}
                lengthType={this.state.checkboxSelection[0]}
                productType={this.state.checkboxSelection[1]} />
            }
          </Table.Body>
        </Table>
      );
    } else {
      paymentDetails = (
        <Table basic='very'>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {t('backIssues')}:
              </Table.Cell>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {this.state.backIssueSelection.length} back issue(s) selected.
              </Table.Cell>
            </Table.Row>
            <Table.Row style={{ borderTop: 'none!important' }}>
              <Table.Cell style={{ border: 'none!important' }}>
                {t('purchaseTerm')}:
              </Table.Cell>
              <Table.Cell style={{ border: 'none!important' }}>
                {t('oneTimePurchase')}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {t('totalPrice')}:
              </Table.Cell>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {this.state.currencySymbol}{' '}
                {this.state.backIssuePurchasePrice.toFixed(2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t('deliveryCountry')}:</Table.Cell>
              <Table.Cell>{this.state.countryText}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      );
    }
    
    return (
      <LoadingOverlay
      active={this.state.isLoading}
      spinner
      text='Processing your request...'
      >

      <div style={{ height: '100vh', overflowX: 'hidden', overflowY: 'auto' }}>


      <Form>
        <div style={style.background}>
          <Grid
            container
            stackable
            verticalAlign='middle'
            style={{ backgroundColor: '#f3f3f3', margin: 0 }}
          >
            
            <StoreFrontHeader 
              publisherName={this.state.publisherName}
              logo={this.state.logo}
              sampleEdition={this.state.sampleEdition}
              thumbImageName={this.state.thumbImageName}
              publication={this.state.publication}
              showNewCustomer={this.state.showNewCustomer}
              style={style}

              onLoginClick={(e, email, password) => this.performLoginCredentials(e, email, password)}
              emailExisting={this.state.emailExisting}
              errors={this.state.errors}
            />

            {purchaseHeader}
            <Grid.Row>
              <Grid.Column>
                <div style={{ padding: '25px' }}>
                  <Form.Select
                    options={countryOptions}
                    label={t('selectCountry')}
                    onChange={this.handleCountryChange}
                    placeholder={t('selectCountry')}
                    fluid
                    size='large'
                    selection
                    name='country'
                    value={this.state.country}
                    error={errors.country}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{purchaseOptions}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              
                <div style={style.container}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>{t('yourDetails')}</div>
                  <div className={this.state.showNewCustomer?`d-none`:''}>
                    <Button color='black' type='button' onClick={this.onLogout}>
                      Log out
                    </Button>
                  </div>
                  </div>
                </div>
                
              
            </Grid.Row>
            <Grid.Row verticalAlign='top'>
              {newCustomerContent}
              {returnCustomerContent}
            </Grid.Row>
            <Grid.Row>
              <div style={style.container}>{t('billingAddr')}</div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div style={{ padding: '25px' }}>
                  {showBillingPCLookup && 
                    <PostalCodeLookup 
                      onSelected={(e,{value,text}) => this.handleBillingChange(e,{value,text})} />                      
                  }
                  <Input
                    label={{ basic: true, content: t('companyName') }}
                    name='billingCompanyName'
                    onChange={this.onChange}
                    value={this.state.billingCompanyName}
                    fluid
                  />
                  <br />
                  <Input
                    label={{ basic: true, content: t('addr1') + ' *' }}
                    name='billingAddress1'
                    value={this.state.billingAddress1}
                    onChange={this.onChange}
                    fluid
                  />
                  <br />
                  <Input
                    label={{ basic: true, content: t('addr2') }}
                    name='billingAddress2'
                    value={this.state.billingAddress2}
                    onChange={this.onChange}
                    fluid
                  />
                  <br />
                  <Input
                    label={{ basic: true, content: t('addr3') }}
                    name='billingAddress3'
                    value={this.state.billingAddress3}
                    onChange={this.onChange}
                    fluid
                  />
                  <br />
                  <Input
                    label={{ basic: true, content: t('townCity') }}
                    name='billingTown'
                    value={this.state.billingTown}
                    onChange={this.onChange}
                    fluid
                  />
                  <br />
                  {this.state.country === 'US' && (
                    <>
                      <Input
                        label={{ basic: true, content: 'State' }}
                        name='billingState'
                        value={this.state.billingState}
                        onChange={this.onChange}
                        fluid
                      />
                      <br />
                    </>
                  )}
                  {this.state.country === 'US' && (
                    <>
                      <Input
                        label={{ basic: true, content: 'Zip Code *' }}
                        name='billingPostcode'
                        value={this.state.billingPostcode}
                        onChange={this.onChange}
                        fluid
                      />
                      <br />
                    </>
                  )}
                  {this.state.country !== 'US' && (
                    <>
                      <Input
                        label={{ basic: true, content: t('postcode') + ' *' }}
                        name='billingPostcode'
                        value={this.state.billingPostcode}
                        onChange={this.onChange}
                        fluid
                      />
                      <br />
                    </>
                  )}
                  <Input
                    label={{ basic: true, content: t('country') }}
                    name='deliveryCountry'
                    fluid
                    value={this.state.countryText}
                    disabled={true}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            {deliveryAddress}
            <Grid.Row>
              <div style={style.container}>{t('paymentDetails')}</div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <Image
                  src={this.state.thumbImageName}
                  style={{ width: '150px' }}
                  centered
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Grid.Row>
                  {paymentDetails}
                  {this.state.showPaymentButtons && this.state.purchasePrice>0 ? (
                    <React.Fragment>
                      <p>{t('choosePayment')}</p>
                      {payPalRadio}
                      {ccradio}
                      {dDRadio}
                    </React.Fragment>
                  ) : null}
                  <div className={this.state.showPaymentRequestButton>0?'':'d-none'}
                    style={{ marginRight: '25px', marginTop: '10px', maxWidth: '200px' }}>
                    
                    {this.state.showPaymentRequestButton && this.state.purchasePrice>0?
                    <PaymentRequestButton 
                      //stripeSubmit={(paymentIntent) => this.stripeSubmit(paymentIntent, true)}
                      stripeSubmit={(paymentIntent) => this.onPaymentRequestButtonSubmit(paymentIntent)}
                      secretKey={this.state.secretKey}
                      publishableKey={this.state.publishableKey} 
                      accountDets={this.state.accountDets} 
                      amount={this.state.purchasePrice * 100}/>                
                    :null}
                    
                  </div>
                  {this.state.showPaymentRequestButton?'':''}
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width={3}>&nbsp;</Grid.Column>
            </Grid.Row>
            <CustomQuestionContainer 
              publication={this.state.publication} 
              errors={this.state.errors} 
              onChange={(customQuestionAnswer) => {
                console.log("customQuestion ", customQuestionAnswer)
                this.setState({ ...customQuestionAnswer })
                // Give a little bit of time to update the state
                setTimeout( () => this.validateForm(), 300)
                
              }}
              />
            <Grid.Row>
              <Grid.Column width={8}>
                <StoreFrontOptIn publication={this.state.publication} 
                  onChange={(optIn) => this.setState({...optIn })} />
              </Grid.Column>
              <Grid.Column width={8} textAlign='right' verticalAlign='bottom'>
                <div>
                  <p className="terms-desktop">
                    <Trans i18nKey='acceptPrivacyPolicy'>
                      By clicking the button you
                      <br />
                      accept the <a href='/privacy'>Privacy Policy</a>
                    </Trans>
                  </p>
                  <p className="terms-mobile">
                    <Trans i18nKey='acceptPrivacyPolicy'>
                      By clicking the button you                      
                      accept the <a href='/privacy'>Privacy Policy</a>
                    </Trans>
                  </p>
                  {paymentButton}

                </div>

              </Grid.Column>
            </Grid.Row>

            <StoreFrontFooter
              tel={this.state.tel}
              mainContactEmail={this.state.mainContactEmail}
              publication={this.state.publication}
              publisherName={this.state.publisherName}
              addressOne={this.state.addressOne}
              addressTwo={this.state.addressTwo}
              addressThree={this.state.addressThree}
              postZip={this.state.postZip}
              style={style}
              />

          </Grid>
        </div>
      </Form>
      
      </div>



      <ModalProcessingFailed 
        open={this.state.modalProcessingFailed} 
        onClose={() => this.setState({ modalProcessingFailed: false})} />
      
      <BacsModal open={this.state.modalDirectDebit} 
        onClose={() => this.setState({modalDirectDebit:false})} 
        onStripeError={() => this.setState({ modalProcessingFailed: true })}
        // stripeSubmit={(paymentIntent) => this.stripeSubmit(paymentIntent)}
        secretKey={this.state.secretKey}
        publishableKey={this.state.publishableKey} 
        accountDets={this.state.accountDets} 
        amount={this.state.purchasePrice * 100}/>

      <CardModal open={this.state.modalCard} 
        onClose={() => this.setState({modalCard:false})} 
        onStripeError={() => this.setState({ modalProcessingFailed: true })}
        // stripeSubmit={(paymentIntent) => this.stripeSubmit(paymentIntent)}
        secretKey={this.state.secretKey}
        publishableKey={this.state.publishableKey} 
        accountDets={this.state.accountDets} 
        amount={this.state.purchasePrice * 100} />

      </LoadingOverlay>
    );
  }
}

Storefront.propTypes = {
  getAccountTel: PropTypes.func.isRequired,
  getPaypalDetails: PropTypes.func.isRequired,
  getPublicationByUrlKey: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  createOrderResult: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  updatePageViews: PropTypes.func.isRequired,
  publication: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  publication: state.publication,
  auth: state.auth,
  errors: state.errors,
  account: state.account,
});

export default connect(mapStateToProps, {
  getPublicationByUrlKey,
  getPaypalDetails,
  getAccountTel,
  createOrder,
  createOrderResult,
  createSTOrder,
  cancelOrder,
  updatePageViews,
  createBackIssueOrder,
})(withTranslation()(withRouter(Storefront)));
