import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  Button,
  Label,
  Input,
  Form,
  Segment,
  Checkbox,
} from "semantic-ui-react";
import RenderFieldLeftRedux from "../../../common/ReduxForm/RenderFieldLeftRedux";
import RenderTextAreaRedux from "../../../common/ReduxForm/RenderTextAreaRedux";
import ColorPicker from "../../../common/ColorPicker";
import RenderFieldLeftAndRequiredRedux from "../../../common/ReduxForm/RenderFieldLeftAndRequiredRedux";
import {
  required,
  email,
  url,
  number1,
} from "../../../common/ReduxFormValidation/validationRules";
import { getPublication } from "../../../../actions/publicationActions";
import hex2rgba from "hex2rgba";

class PublicationInfo extends Component {
  state = {
    color: "#ffffff",
    bg75: "",
    bg15: "",
    sellBackIssue: false,
  };

  handleColorChange = (color) => {
    this.props.change("backgroundColour", color);
    this.setState({
      color: color,
      bg15: hex2rgba(color, 0.15),
      bg75: hex2rgba(color, 0.75),
    });
  };
  componentDidMount() {
    this.props.getPublication(this.props.pubid);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues) {
      if (nextProps.initialValues.backgroundColour !== this.state.color) {
        this.setState({
          color: nextProps.initialValues.backgroundColour,
          bg15: hex2rgba(nextProps.initialValues.backgroundColour, 0.15),
          bg75: hex2rgba(nextProps.initialValues.backgroundColour, 0.75),
        });
      }
    }
  }

  onSellBackIssueClick = (e) => {
    this.setState({ sellBackIssue: !this.state.sellBackIssue });
  };

  renderChkBox = ({ input, checked }) => {
    return <input {...input} type="text" hidden value={checked} />;
  };

  render() {
    const { handleSubmit, submitting, sellBackIssues } = this.props;
    console.log(sellBackIssues);
    return (
      <Form onSubmit={handleSubmit} size="large">
        <Field
          component={Checkbox}
          toggle
          name="sellBackIssues"
          label="Do you sell back issues?"
          checked={sellBackIssues}
          onClick={() => {
            this.props.change("sellBackIssues", !sellBackIssues);
          }}
        />

        <Field
          name="publicationName"
          component={RenderFieldLeftAndRequiredRedux}
          type="text"
          label="Publication Name"
          validate={[required]}
        />
        <Field
          name="publicationPerYear"
          component={RenderFieldLeftAndRequiredRedux}
          type="number"
          label="Publications Per Year"
          validate={[required, number1]}
        />
        <Field
          name="urlKey"
          component={RenderFieldLeftAndRequiredRedux}
          type="text"
          label="https://store.magstand.com/"
          placeholder="Your unique Magstand Storefront URL"
          disabled={true}
        />
        <div className="form__field" style={{ margin: "10px" }}>
          <Label size="large" color="blue" style={{ padding: "13px" }}>
            Subscription Page Colour
          </Label>
          <Input
            as={ColorPicker}
            name="color"
            size="large"
            onColorChange={this.handleColorChange}
          />
          <label style={{ marginLeft: "20px", marginRight: "20px" }}>
            Headers
          </label>
          <Label
            style={{
              backgroundColor: this.state.color,
              lineHeight: "2.2",
              color: this.state.color,
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Label>
          <label style={{ marginLeft: "20px", marginRight: "20px" }}>
            Storefront Background
          </label>
          <Label
            style={{
              backgroundColor: this.state.bg75,
              lineHeight: "2.2",
              color: this.state.bg75,
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Label>
          {/* <label style={{ marginLeft: "20px", marginRight: "20px" }}>Magazine Cover Background</label>
          <Label style={{ backgroundColor: this.state.bg15, lineHeight: "2.2", color: this.state.bg15 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Label> */}
          <Field
            component={Input}
            name="backgroundColour"
            disabled={true}
            fluid
          />
        </div>
        <Field
          name="publicationInfo"
          component={RenderTextAreaRedux}
          label="Magstand Store Information"
          placeholder="The publication description that will appear on your Storefront"
        />
        <Field
          name="webAddress"
          component={RenderFieldLeftRedux}
          type="text"
          label="Magazine Web Address"
          validate={[url]}
        />
        <Field
          name="emailAddress"
          component={RenderFieldLeftAndRequiredRedux}
          type="text"
          label="Enquiries Email Address"
          validate={[required, email]}
        />
        <Field
          name="orderEmailAddress"
          component={RenderFieldLeftAndRequiredRedux}
          type="text"
          label="Magstand Orders Email Address"
          validate={[required, email]}
        />
        <Field 
          name="sampleEdition"
          component={RenderFieldLeftRedux}
          type="text"
          label="Sample Edition"
          validate={[url]}
        />
        <div style={{ margin: "10px", textAlign: "center" }}>
          <Button color="blue" disabled style={{ minWidth: "100px" }}>
            Previous
          </Button>
          <Button
            color="blue"
            style={{ minWidth: "100px" }}
            disabled={submitting}
          >
            Next
          </Button>
        </div>
      </Form>
    );
  }
}
const selector = formValueSelector("wizard");

const mapStateToProps = (state) => {
  console.log(state.publication.publication);
  return {
    initialValues: state.publication.publication,
    publication: state.publication,
    sellBackIssues: selector(state, "sellBackIssues"),
  };
};

export default connect(mapStateToProps, { getPublication })(
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true,
  })(PublicationInfo)
);
