import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Segment, Form, Button, Header, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { updateUser, getUserByUserID } from '../../../actions/userActions';
import RenderFieldLeft from '../../common/NormalForm/RenderFieldLeft';
import RenderFieldLeftAndRequired from '../../common/NormalForm/RenderFieldLeftAndRequired';
import Moment from 'react-moment';
import isEmpty from '../../../extension/is-empty';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';

class EditUser extends Component {
  state = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    userType: '',
    id: '',
    password: '',
    password2: '',
    numberOfPublications: '',
    lastLogin: '',
    errors: {},
  };

  componentDidMount() {
    if (
      this.props.auth.user.userType !== 'publisher' &&
      this.props.auth.user.userType !== 'pubadmin' &&
      this.props.auth.user.userType !== 'superuser' &&
      this.props.auth.user.userType !== 'reseller'
    ) {
      window.location.href = '/readers';
    }
    this.props.getUserByUserID(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.users.user) {
      const user = nextProps.users.user;

      // If field doesnt exist, make empty string
      user.firstName = !isEmpty(user.firstName) ? user.firstName : '';
      user.lastName = !isEmpty(user.lastName) ? user.lastName : {};
      user.userName = !isEmpty(user.userName) ? user.userName : '';
      user.email = !isEmpty(user.email) ? user.email : '';
      user.companyName = !isEmpty(user.accountDets.companyName)
        ? user.accountDets.companyName
        : '';
      user.numberOfPublications = !isEmpty(
        user.accountDets.numberOfPublications,
      )
        ? user.accountDets.numberOfPublications
        : '';
      user.lastLogin = !isEmpty(user.lastLogin) ? user.lastLogin : '';

      // Set component fields state
      this.setState({
        firstName: user.firstName,
        lastName: user.lastName,
        userName: user.userName,
        email: user.email,
        userType: user.userType,
        companyName: user.companyName,
        id: user._id,
        lastLogin: user.lastLogin,
        numberOfPublications: user.numberOfPublications,
      });
    }
  }

  handleChange = (e, { value }) => this.setState({ userType: value });
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitHandler = (e) => {
    e.preventDefault();

    const updateUser = {
      id: this.state.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      companyName: this.state.companyName,
      userType: this.state.userType,
      numberOfPublications: this.state.numberOfPublications,
    };

    if (!!this.state.password) updateUser.password = this.state.password;
    if (!!this.state.password2) updateUser.password2 = this.state.password2;

    // this.props.history needed withRouter so we can use it in the redux action
    this.props.updateUser(updateUser, this.props.history);
  };

  render() {
    const { errors } = this.state;

    // Select options for status
    const options = [
      {
        key: 'pubadmin',
        text: 'Publication Administrator (same privileges as Publisher)',
        value: 'pubadmin',
      },
      {
        key: 'admin',
        text: 'Reader Administrator',
        value: 'admin',
      },
      {
        key: 'mailer',
        text: 'Mailer',
        value: 'mailer',
      },
    ];

    let showFields;

    if (this.state.userType !== 'publisher') {
      showFields = (
        <React.Fragment>
          <RenderFieldLeft
            name='companyName'
            value={this.state.companyName}
            onChange={this.onChangeHandler}
            placeholder='Company Name (optional)'
            error={errors.companyName}
            label='Company'
          />
          <div style={{ padding: '10px' }}>
            <Dropdown
              placeholder='Select User Type'
              label='User Type'
              fluid
              size='large'
              selection
              options={options}
              name='userType'
              onChange={this.handleChange}
              value={this.state.userType}
            />
          </div>
        </React.Fragment>
      );
    } else {
      showFields = null;
    }

    return (
      <Form onSubmit={this.onSubmitHandler}>
        <Segment>
          <Header as='h2' textalign='center'>
            Edit User
          </Header>
          <RenderFieldLeftAndRequired
            name='firstName'
            value={this.state.firstName}
            label='First Name'
            onChange={this.onChangeHandler}
            placeholder='First Name'
            error={errors.firstName}
          />
          <RenderFieldLeftAndRequired
            name='lastName'
            value={this.state.lastName}
            onChange={this.onChangeHandler}
            placeholder='Last Name'
            error={errors.lastName}
            label='Last Name'
          />
          <RenderFieldLeftAndRequired
            name='email'
            value={this.state.email}
            onChange={this.onChangeHandler}
            placeholder='Email Address'
            error={errors.email}
            label='Email Address'
          />
          <RenderFieldLeftAndRequired
            name='numberOfPublications'
            type='number'
            value={this.state.numberOfPublications}
            onChange={this.onChangeHandler}
            placeholder='Main Contact Mobile'
            label='Number of Publications'
            error={errors.numberOfPublications}
          />
          {showFields}
          <RenderFieldLeft
            type='password'
            value={this.state.password}
            onChange={this.onChangeHandler}
            placeholder='Password'
            name='password'
            error={errors.password}
            label='Password'
          />
          <RenderFieldLeft
            type='password'
            value={this.state.password2}
            onChange={this.onChangeHandler}
            placeholder='Confirm Password'
            name='password2'
            error={errors.password2}
            label='Confirm Password'
          />
          <div style={{ padding: '10px' }}>
            Last Login:{' '}
            {this.state.lastLogin !== '' ? (
              <Moment format='DD/MM/YYYY HH:MM:SS'>
                {this.state.lastLogin}
              </Moment>
            ) : (
              ''
            )}
          </div>
          <div style={{ padding: '10px' }}>
            <Button color='blue' fluid>
              Update User
            </Button>
          </div>
        </Segment>
      </Form>
    );
  }
}

EditUser.propTypes = {
  getUserByUserID: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // auth comes from the rootReducer
  users: state.users,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateUser, getUserByUserID })(
  withRouter(EditUser),
);
