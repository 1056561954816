// Renders a Normal Form Input Field with the label to the left

import React from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const RenderFieldLeftBasic = ({
  onChange,
  onBlur,
  value,
  name,
  error,
  label,
  type,
  placeholder,
}) => (
  <div>
    <Input
      fluid
      labelPosition='left'
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      placeholder={placeholder}
      error={error ? true : false}
      label={{ basic: true, content: label }}
    />
    {error && <div className='ui pointing red basic label'>{error}</div>}
  </div>
);

RenderFieldLeftBasic.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  label: PropTypes.string,
};

RenderFieldLeftBasic.defaultProps = {
  type: 'text',
};

export default RenderFieldLeftBasic;
