// Add Promo Code

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Segment, Form, Button, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import RenderFieldLeftAndRequired from '../../common/NormalForm/RenderFieldLeftAndRequired';
import RenderDatePickerLeft from '../../common/NormalForm/RenderDatePickerLeft';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';
import { addPromoCode } from '../../../actions/publicationActions';
import { PriceSet } from '../components/PriceSet';

class AddPromo extends Component {

  state = {
    code: '',
    name: '',
    discount: '',
    added: '',
    endDate: '',
    uses: '',
    errors: {}
  }
  
  applicableProducts = {}
  constructor() {
    super()
    this.applicableProducts = {
      digital: {
        priceOneYear: true, priceSixMonths: true, priceThreeMonths: true,
      },
      print: {
        priceOneYear: true, priceSixMonths: true, priceThreeMonths: true,
      },
      bundle: {
        priceOneYear: true, priceSixMonths: true, priceThreeMonths: true,
      }
    }   
  }

  componentDidMount() {
    if (this.props.auth.user.userType !== "publisher" && this.props.auth.user.userType !== 'pubadmin') {
      window.location.href = "/readers"
    }
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  // Change Event Handler for Controlled DatePicker Component
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  // Submit Promo Details
  onSubmitHandler = (e) => {
    e.preventDefault();
    const newPromo = {
      pubid: this.props.match.params.id,
      code: this.state.code,
      name: this.state.name,
      discount: this.state.discount,
      added: this.state.added,
      uses: this.state.uses,
      endDate: this.state.endDate,
      applicableProducts: this.applicableProducts
    }
    // Call the addPromoCode Action Creator
    this.props.addPromoCode(newPromo, this.props.history);
  }

  render() {
    const { errors } = this.state;
    return (
      <Form onSubmit={this.onSubmitHandler}>
        <Segment>
          <Header as="h2" textalign='center'>Add Promo Code</Header>
          <RenderFieldLeftAndRequired
            name="name"
            value={this.state.name}
            label="Promo Code Name"
            onChange={this.onChangeHandler}
            placeholder="Promo Code Identifier"
            error={errors.name}
          />
          <RenderFieldLeftAndRequired
            name="code"
            value={this.state.code}
            onChange={this.onChangeHandler}
            placeholder="Promo Code"
            error={errors.code}
            label="Promo Code"
          />
          <RenderFieldLeftAndRequired
            name="discount"
            type="number"
            value={this.state.discount}
            onChange={this.onChangeHandler}
            placeholder="Discount (%)"
            error={errors.discount}
            label="Discount (%)"
          />
          <RenderDatePickerLeft
            name="added"
            value={this.state.added}
            onChange={this.handleChange}
            placeholder="Start Date (leave blank if starting immediately)"
            error={errors.added}
            label="Start Date (leave blank if starting immediately)"
          />
          <RenderDatePickerLeft
            name="endDate"

            value={this.state.endDate}
            onChange={this.handleChange}
            placeholder="End Date"
            error={errors.endDate}
            label="End Date (Optional)"
          />
          <RenderFieldLeftAndRequired
            name="uses"
            type="number"
            value={this.state.uses}
            onChange={this.onChangeHandler}
            placeholder="Uses"
            error={errors.uses}
            label="Limit Uses (0 for unlimited)"
          />

          <Grid stackable centered style={{ marginTop: '1em'}} columns='equal'>
            <Grid.Column width={10}>
              <Header as="h3">Apply Promo Code to</Header>
            </Grid.Column>
            <Grid.Column>
            </Grid.Column>            
            <Grid.Column>
            </Grid.Column>
          </Grid>


          <Grid stackable columns={4} centered style={{ marginBottom: '1em', marginLeft: '1em'}}>
            <Grid.Column textAlign='center'>
              <Segment>
                <Header>Digital</Header>
                <PriceSet color='violet' 
                  valueDefault={this.applicableProducts.digital}
                  onClick={(key, value) => { console.log("key", key); this.applicableProducts.digital[key] = value}} />
              </Segment>
            </Grid.Column>
            <Grid.Column textAlign='center'>
              <Segment>
                <Header>Print</Header>
                <PriceSet color='teal' 
                  valueDefault={this.applicableProducts.print}
                  onClick={(key, value) => this.applicableProducts.print[key] = value} />
              </Segment>
            </Grid.Column>
            <Grid.Column textAlign='center'>
              <Segment>
                <Header>Bundle Print &amp; Digital</Header>
                <PriceSet color='green' 
                  valueDefault={this.applicableProducts.bundle}
                  onClick={(key, value) => this.applicableProducts.bundle[key] = value} />
              </Segment>
            </Grid.Column>
            <Grid.Column>
            </Grid.Column>
          </Grid>

          <div style={{ padding: "10px" }}>
            <Button color="blue" type="submit" fluid>Add Promo Code</Button>
          </div>
        </Segment>
      </Form>

    );
  }
}

AddPromo.propTypes = {
  addPromoCode: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { addPromoCode })(withRouter(AddPromo));
