import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Button, Header, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { getOrder } from '../../actions/orderActions';
import { getPublication } from '../../actions/publicationActions';
import { isUserLoggedIn } from '../common/isUserLoggedIn';
import Spinner from '../common/Spinner';
import moment from 'moment';

class OrderDetails extends Component {

  state = {
    gift: null,
    publication: null,
    orderNumber: null,
    orderDate: null,
    amount: null,
    currency: null,
    subType: null,
    subStartDate: null,
    subEndDate: null,
    billingTitle: null,
    billingFirstName: null,
    billingLastName: null,
    billingCompanyName: null,
    billingAddressOne: null,
    billingAddressTwo: null,
    billingAddressThree: null,
    billingCity: null,
    shippingTitle: null,
    shippingFirstName: null,
    shippingLastName: null,
    shippingAddressOne: null,
    shippingAddressTwo: null,
    shippingAddressThree: null,
    shippingCity: null,
    shippingCountry: null,
    paymentType: null,
    productName: null,
    productPrice: null,
    discount: null,
    publicationName: null,
    fulfilled: null,
    liability: null,
    billingState: null,
    billingCountry: null,
    billingPostCode: null,
    shippingState: null,
    shippingPostCode: null,
    promoCode: null
  }

  componentDidMount() {
    this.props.getOrder(this.props.match.params.id);
  }


  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();

    if (nextProps.order.orderDetail) {
      if (this.state.orderNumber !== nextProps.order.orderDetail.orderNumber) {
        var dets = nextProps.order.orderDetail;
        var lengthInMonths = "-"
        var fulfilled = "-";
        var liability = "-";
        var monthsLeft;
        if (dets.paymentType !== "Free") {
          lengthInMonths = new Date(dets.subStartDate).getMonth() - new Date(dets.subEndDate).getMonth() +
            (12 * (new Date(dets.subStartDate).getFullYear() - new Date(dets.subEndDate).getFullYear()))
          if (new Date().toISOString() < new Date(dets.subEndDate).toISOString()) {
            monthsLeft = new Date().getMonth() - new Date(dets.subEndDate).getMonth() +
              (12 * (new Date().getFullYear() - new Date(dets.subEndDate).getFullYear()))
            var oneMonth = dets.productPrice / lengthInMonths;

            liability = (monthsLeft * oneMonth).toFixed(2);
            fulfilled = ((lengthInMonths - monthsLeft) * oneMonth).toFixed(2);
          } else {
            fulfilled = dets.productPrice
            liability = "0"
          }
        }
        this.setState({
          fulfilled: fulfilled,
          liability: liability,
          gift: dets.gift,
          publication: dets.publication,
          orderNumber: dets.orderNumber,
          orderDate: dets.orderDate,
          amount: dets.amount,
          currency: dets.currency,
          subType: dets.subType,
          subStartDate: dets.subStartDate,
          subEndDate: dets.subEndDate,
          billingTitle: dets.billingTitle,
          billingFirstName: dets.billingFirstName,
          billingLastName: dets.billingLastName,
          billingCompanyName: dets.billingCompanyName,
          billingAddressOne: dets.billingAddressOne,
          billingAddressTwo: dets.billingAddressTwo,
          billingAddressThree: dets.billingAddressThree,
          billingCity: dets.billingCity,
          billingState: dets.billingState !== undefined ? dets.billingState : "",
          billingCountry: dets.billingCountry !== undefined ? dets.billingCountry : "",
          billingPostcode: dets.billingPostcode !== undefined ? dets.billingPostcode : "",
          shippingTitle: dets.shippingTitle,
          shippingFirstName: dets.shippingFirstName,
          shippingLastName: dets.shippingLastName,
          shippingAddressOne: dets.shippingAddressOne,
          shippingAddressTwo: dets.shippingAddressTwo,
          shippingAddressThree: dets.shippingAddressThree,
          shippingCity: dets.shippingCity,
          shippingState: dets.shippingState !== undefined ? dets.shippingState : "",
          shippingCountry: dets.shippingCountry !== undefined ? dets.shippingCountry : "",
          shippingPostcode: dets.shippingPostcode !== undefined ? dets.shippingPostcode : "",
          shippingCountry: dets.shippingCountry,
          paymentType: dets.paymentType,
          productName: dets.productName,
          productPrice: dets.productPrice,
          discount: dets.discount,
          promoCode: dets.promoCode ? dets.promoCode : ""
        })
        this.props.getPublication(dets.publication);
      }
    }

    if (nextProps.publication.publication) {
      if (this.state.publicationName !== nextProps.publication.publication.publicationName) {

        this.setState({
          publicationName: nextProps.publication.publication.publicationName
        });
      }
    }

  }


  render() {


    const { loading } = this.props.order;

    let orderContent;



    if (loading) {
      orderContent = <Spinner />;
    } else {
      orderContent = (

        <Segment>
          <Header as="h2" textalign='center'>Order Details - {this.state.orderNumber}</Header>
          <Grid columns='2'>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Header as="h3" textalign='center'>Order Details</Header>
                  <Grid columns='2'>
                    <Grid.Row>
                      <Grid.Column>
                        Publication Name:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.publicationName}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Order Date:
                      </Grid.Column>
                      <Grid.Column>
                        {moment(new Date(this.state.orderDate)).format('DD/MM/YYYY')}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Currency:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.currency}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Amount:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.productPrice}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Discount:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.discount}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Promo Code:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.promoCode}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Paid Using:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.paymentType}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Product:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.productName}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Start Date:
                      </Grid.Column>
                      <Grid.Column>
                        {moment(new Date(this.state.subStartDate)).format('DD/MM/YYYY')}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        End Date:
                      </Grid.Column>
                      <Grid.Column>
                        {moment(new Date(this.state.subEndDate)).format('DD/MM/YYYY')}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Fulfilled:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.fulfilled}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Liability:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.liability}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Purchased as Gift?
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.gift ? "Yes" : "No"}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <Header as="h3" textalign='center'>Billing Details</Header>
                  <Grid columns='2'>
                    <Grid.Row>
                      <Grid.Column>
                        Billing Name:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.billingTitle + " " + this.state.billingFirstName + " " + this.state.billingLastName}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Billing Address 1:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.billingAddressOne}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Billing Address 2:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.billingAddressTwo}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Billing Address 3:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.billingAddressThree}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Billing City:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.billingCity}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Billing State:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.billingState}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Billing Postcode:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.billingPostcode}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Billing Country:
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.billingCountry}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
                {this.state.shippingPostcode !== "" ? (
                  <Segment>
                    <Header as="h3" textalign='center'>Shipping Details</Header>
                    <Grid columns='2'>
                      <Grid.Row>
                        <Grid.Column>
                          Shipping Name:
                      </Grid.Column>
                        <Grid.Column>
                          {this.state.shippingTitle + " " + this.state.shippingFirstName + " " + this.state.shippingLastName}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          Shipping Address 1:
                      </Grid.Column>
                        <Grid.Column>
                          {this.state.shippingAddressOne}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          Shipping Address 2:
                      </Grid.Column>
                        <Grid.Column>
                          {this.state.shippingAddressTwo}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          Shipping Address 3:
                      </Grid.Column>
                        <Grid.Column>
                          {this.state.shippingAddressThree}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          Shipping City:
                      </Grid.Column>
                        <Grid.Column>
                          {this.state.shippingCity}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          Shipping State:
                      </Grid.Column>
                        <Grid.Column>
                          {this.state.shippingState}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          Shipping Postcode:
                      </Grid.Column>
                        <Grid.Column>
                          {this.state.shippingPostcode}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          Shipping Country:
                      </Grid.Column>
                        <Grid.Column>
                          {this.state.shippingCountry}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                ) : (
                    <Segment>Shipping Address same as Billing Address</Segment>
                  )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Link to="/payments">
            <Button style={{ marginTop: "10px" }} fluid color="blue">Back to Sales Ledger</Button>
          </Link>
        </Segment>
      )
    }
    return (
      <React.Fragment>
        {orderContent}
      </React.Fragment>
    );
  }
}

OrderDetails.propTypes = {
  getOrder: PropTypes.func.isRequired,
  getPublication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors,
  order: state.order,
  publication: state.publication
});

export default connect(mapStateToProps, { getOrder, getPublication })(withRouter(OrderDetails));
