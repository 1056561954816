import React, { Component, useState, useEffect } from 'react';
import {
  Elements,
  useStripe,
  useElements,
  PaymentRequestButtonElement, 
} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js/pure";
import axios from 'axios';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }


    const pr = stripe.paymentRequest({
      country: 'GB',
      currency: 'gbp',
      total: {
        label: 'Demo total',
        amount: props.amount,
        //amount: 10
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestShipping: true,
      // `shippingOptions` is optional at this point:
      shippingOptions: [
        // // The first shipping option in this list appears as the default
        // // option in the browser payment interface.
        // {
        //   id: 'free-shipping',
        //   label: 'Free shipping',
        //   detail: 'Arrives in 5 to 7 days',
        //   amount: 0,
        // },
      ],
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      if (result) {
        setPaymentRequest(pr);
      }
    });
    
  }, [stripe]);

  useEffect( () => {

    if( paymentRequest == null ) return
    
    paymentRequest.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
        props.clientSecret,
        {payment_method: ev.paymentMethod.id},
        {handleActions: false}
      );
      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const {error} = await stripe.confirmCardPayment(props.clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            console.log("payment has failed")
          } else {
            // The payment has succeeded.
            props.handleSubmit()
          }
        } else {
          // The payment has succeeded.
          props.handleSubmit()
        }
      }
    });

    paymentRequest.on('shippingaddresschange', async (ev) => {
      console.log("ev ", ev)
      ev.updateWith({
        status: 'success',
        // option in the browser payment interface.
        shippingOptions: [{
          id: 'free-shipping',
          label: 'Free shipping',
          detail: 'Arrives in 5 to 7 days',
          amount: 0,
        }],
      });

      // if (ev.shippingAddress.country !== 'US') {
      //   ev.updateWith({status: 'invalid_shipping_address'});
      // } else {
      //   // // Perform server-side request to fetch shipping options
      //   // const response = await fetch('/calculateShipping', {
      //   //   data: JSON.stringify({
      //   //     shippingAddress: ev.shippingAddress
      //   //   })
      //   // });
      //   // const result = await response.json();
    
      //   ev.updateWith({
      //     status: 'success',
      //     //shippingOptions: result.supportedShippingOptions,
      //     // The first shipping option in this list appears as the default
      //     // option in the browser payment interface.
      //     shippingOptions: {
      //       id: 'free-shipping',
      //       label: 'Free shipping',
      //       detail: 'Arrives in 5 to 7 days',
      //       amount: 0,
      //     },
      //   });
      // }
    });

  }, [paymentRequest])

  if (paymentRequest) {
    return <PaymentRequestButtonElement options={{paymentRequest}} />
  }
  return <div></div>
};
const PaymentRequestButton = (props) => {
  const [stripePromise, setStripePromise] = React.useState(null)
  const [paymentIntent, setPaymentIntent] = React.useState(null)
  const createPaymentIntent = async () =>{
    console.log("---- PaymentRequestButton")
    if(props.secretKey){
      // Create payment intent
      const body = { "secret_key": props.secretKey, amount: props.amount }
      const response = await axios.post(`/api/order/paymentintent/card/`, body)
      setPaymentIntent( response.data )
    }
  }

  const loadStripeLaunch = async () => {
    if( props.publishableKey ){      
      const stripePromise = await loadStripe(props.publishableKey);
      // NOTE: This is required to make the stripePromise to be reloaded
      setStripePromise(stripePromise)
    }else{
      setStripePromise(null)
    }
    await createPaymentIntent()
  }
  React.useEffect(() => {
    loadStripeLaunch()
  }, [props.publishableKey])   // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () =>   
    props.stripeSubmit(paymentIntent)

  const clientSecret = paymentIntent?paymentIntent.client_secret:null
  return <div>
    {clientSecret && (
      <Elements options={{ locale: 'en', clientSecret: clientSecret, theme: 'stripe' }}
          stripe={stripePromise}>
        <CheckoutForm onClose={() => props.onClose()} 
          handleSubmit={handleSubmit} amount={props.amount} 
          clientSecret={clientSecret} />
      </Elements>)}
    </div>

}

export default PaymentRequestButton