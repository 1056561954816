// Main Landing Homepage

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  // Card,
  Menu,
  Table,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Dropdown,
  Divider
} from 'semantic-ui-react'
import ReactGA from 'react-ga';
import { Link, animateScroll } from 'react-scroll';
import { Link as Link2 } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import './Landing.css';


import LogoWhite from '../../assets/img/ms200wht.png';
//import LogoBlack from '../../assets/img/Magstand-Logo800.png';
import LogoBlack from '../../assets/img/ms200blkNew.png';
import Subscribe from '../../assets/img/magsatndsubscribe.png';
import RHS from '../../assets/img/img1.png';
import Plus from '../../assets/img/Magstand-Plus-Logo.png';
import Eamonn from '../../assets/img/eamonn.png';
import Donna from '../../assets/img/donna.png';
import Jade from '../../assets/img/jade.png';
import Rebekah from '../../assets/img/rebekah.JPG';

// Gets the width of the viewport for responsiveness
const getWidth = () => {
  const isSSR = typeof window === 'undefined'
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

/* eslint-disable react/no-multi-comp */
const HomepageHeading = ({ mobile }) => (
  <React.Fragment>
    <Segment style={{ padding: '12em 0em', backgroundColor: '#fff', maxWidth: "1140px", marginLeft: "auto", marginRight: "auto" }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8} style={{ marginTop: "-200px" }}>
            <Header
              as='h1'

              style={{
                fontSize: mobile ? '2em' : '3em',
                fontWeight: 'bold',
                marginTop: mobile ? '-5em' : '3em',
                textAlign: 'left',
                fontFamily: 'Libre Franklin'
              }}
            >Welcome to Magstand,<br /><span style={{ fontWeight: "normal" }}>the subscription management platform<br />for magazine publishers</span></Header>

            <Header
              as='h2'
              style={{
                fontSize: mobile ? '1.5em' : '1.7em',
                fontWeight: 'bold',
                marginTop: mobile ? '0.5em' : '1.5em',
                textAlign: 'left',
                fontFamily: 'Libre Franklin'

              }}
            >Increase and manage your print and digital magazine audiences with ease, and have total peace of mind your valuable data is secure</Header>
            <Header
              as='h3'
              style={{
                fontSize: mobile ? '1em' : '1.2em',
                fontWeight: 'normal',
                marginTop: mobile ? '0.5em' : '1.5em',
                textAlign: 'left',
                fontFamily: 'Libre Franklin'

              }}
            >Manage new subscribers, renewals, mailing data, digital subscriber access, payments, receipts, data fix ups, secure user access, data backups and a whole lot more with the Magstand solution for magazine publishers</Header>
            <Button style={{ float: "left", marginTop: "1em" }}
              color='green'
              onClick={() => { window.location.href = '/signup' }}>
              SIGN UP
        </Button>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image style={{ marginLeft: "auto", marginRight: "auto" }} src={RHS} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </React.Fragment>
)
HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth} style={{ backgroundUrl: "url(../../assets/img/magsatndsubscribe.png)" }}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            textAlign='center'
            style={{ padding: '1em 0em' }}
            vertical
          >
            <Menu
              fixed={!fixed}
              secondary={!fixed}
              size='small'
              borderless
            >
              <Container>
                <Menu.Item>
                  <a href="/"><Image size='small' src={!fixed ? LogoBlack : LogoBlack} /></a>
                </Menu.Item>

                <Link
                  className="item"
                  style={{ color: "#000" }}
                  activeClass="item"
                  to="pricing"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                >Pricing</Link>
                <Link
                  className="item"
                  style={{ color: "#000" }}
                  activeClass="item"
                  to="features"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                >Features</Link>
                <Dropdown text='Resources' pointing className='link item'>
                  <Dropdown.Menu>
                    <Dropdown.Header>Magstand Resources</Dropdown.Header>
                    <Dropdown.Item as='a' href="/assets/templates/magstanduploadtemplate.csv" target="_blank">Data Upload Template</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Header>Payment Resources</Dropdown.Header>
                    <Dropdown.Item as='a' href='https://developer.paypal.com/developer/applications/' target="_new">Paypal</Dropdown.Item>
                    <Dropdown.Item as='a' href='https://stripe.com' target="_new">Stripe</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Menu.Item as='a' href="/contactme">
                  Contact Us
                  </Menu.Item>
                {/* <Menu.Item as='a' href="/howto">
                How It Works
                  </Menu.Item> */}
                <Menu.Item position='right'>
                  <Button as='a' href='/login' color='blue'>
                    Publisher Login
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
        inverted
      >
        <Sidebar
          as={Menu}
          borderless
          animation='push'
          onHide={this.handleSidebarHide}
          inverted
          color='white'
          vertical
          visible={sidebarOpened}
        >

          <Menu.Item as='a' href='/login'>Publisher Login</Menu.Item>
          <Menu.Item as='a' href="/contactme">
            Contact Us
                  </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign='center'
            style={{ minHeight: 50, padding: '1em 0em' }}
            vertical
          >
            <Container style={{ marginTop: "-35px" }}>
              <Menu pointing secondary borderless size='large' style={{ border: "none" }}>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' color='black' style={{ marginTop: "-35px" }} />
                </Menu.Item>
                <Menu.Item as='a'>
                  <Image size='small' src={LogoBlack} />
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

class HomepageLayout extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-133495576-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleClick = () => window.location.href = '/signup'
  handleClick2 = () => window.location.href = '/signup?type=plus'
  scrollToTop = () => {
    animateScroll.scrollToTop();
  };
  render() {
    return (
      <ResponsiveContainer >
        <CookieConsent
          acceptOnScroll={true}
          acceptOnScrollPercentage={50}>This website uses cookies to enhance the user experience</CookieConsent>
        <Segment vertical style={{ paddingTop: "5em", paddingBottom: "6em", backgroundColor: "#eceeef" }}>
          <p style={{ maxWidth: "1140px", marginLeft: "auto", marginRight: "auto" }}>
            <Header as='h2' style={{ marginLeft: "auto", paddingBottom: "1em", marginRight: "auto", width: "100%", fontSize: "2.5em", fontFamily: "Libre Franklin", textAlign: "center" }}>Check out our two minute elevator pitch</Header>
            <div class="embed-container">
              <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>

          </p>

        </Segment>
        <Segment style={{ padding: '8em 0em' }} vertical>
          <a id='features' alt="features" />
          <p style={{ maxWidth: "1140px", marginLeft: "auto", marginRight: "auto" }}>
            <Divider
              as='h2'
              horizontal
              style={{ margin: '0.2em 0em', textTransform: 'uppercase', fontSize: "3em", color: '#000' }}
            >
              <p style={{ color: '#000' }}>Features</p>
            </Divider>

            <Grid columns='equal' stackable >
              <Grid.Row>
                <Grid.Column>
                  <List>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          secure management and safe cloud storage of subscriber data
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          process Paypal, credit card, debit card &amp; direct debits
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          automated renewal email generation
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          intelligent database fix-up tools
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          instantly integrates with white label mobile app &amp; online editions
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          gift purchase support
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          PCI DSS payment gateway compliant
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          no set up fees
              </List.Header>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <List>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          GDPR compliant data handling
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          quick and easy promo code set up
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          subscription store web page analytics
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          secure single record access for publisher data admins
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          supports reader survey questions
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          securely backed up data
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          includes online PDF to HTML5 digital editions
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          no minimum contract length
              </List.Header>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <List>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          bespoke online store page
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          supports controlled free circulation sign up questions
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          auto renewable subscription support
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          secure real time mailing list access for mailing houses
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          sales and audience analytics
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          ABC audit compliant
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          includes white label iOS and Android app (Magstand Plus)
              </List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon name='check' color='green' />
                      <List.Content>
                        <List.Header>
                          easy sign up and set up, less than 24 hours
              </List.Header>
                      </List.Content>
                    </List.Item>
                  </List>

                </Grid.Column>
              </Grid.Row>

            </Grid>
          </p>
        </Segment>



        <Segment style={{ padding: '8em 0em', maxWidth: "1140px", marginLeft: "auto", marginRight: "auto", borderBottom: "none" }} vertical>
          <Header as='h3' textAlign='center' style={{ fontSize: '2em', paddingBottom: '1em' }}>
            <a id='pricing' alt="pricing" />Partner with MagStand today and enjoy the most<br />comprehensive solution with simple workflows and low costs
      </Header>


          <Grid columns='equal' stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Table columns={2} key="teal" style={{ maxWidth: "1140px", marginLeft: "auto", marginRight: "auto" }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colspan="2" style={{ textAlign: "center" }}><img src={LogoBlack} style={{ height: "48px" }} /><br />Magstand + online page turner</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colspan="2" style={{ textAlign: "center", fontWeight: "normal", paddingLeft: "60px", paddingRight: "60px" }}>Unlimited features for Magstand subscription cloud platform including online storefront, unlimited transactions &amp; subscribers. Includes free web digital editions account for online publishing.</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{ textAlign: "center", fontSize: "1.5em" }}>Titles</Table.HeaderCell>
                      <Table.HeaderCell style={{ textAlign: "center", fontSize: "1.5em" }}>Price per month</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{ textAlign: "center" }}>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>1</Table.Cell>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>&pound;65 per title</Table.Cell>
                    </Table.Row>
                    <Table.Row style={{ textAlign: "center" }}>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>2-5</Table.Cell>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>&pound;45 per title</Table.Cell>
                    </Table.Row>
                    <Table.Row style={{ textAlign: "center" }}>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>6-10</Table.Cell>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>&pound;35 per title</Table.Cell>
                    </Table.Row>
                    <Table.Row style={{ textAlign: "center" }}>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>10+</Table.Cell>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}><a href="/contactme">Enquire</a></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table><p>
                  <Button style={{ width: "45%", float: "left" }}
                    color='blue'
                    onClick={this.handleClick}>
                    SIGN ME UP
        </Button>

                  <Link2 to='/contactme'>
                    <Button
                      inverted style={{ width: "45%", float: "right" }}
                      color='blue'>
                      GET IN TOUCH
        </Button>
                  </Link2>

                </p>
              </Grid.Column>
              <Grid.Column>
                <Table columns={2} key="teal" style={{ maxWidth: "1140px", marginLeft: "auto", marginRight: "auto" }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colspan="2" style={{ textAlign: "center" }}><img src={Plus} style={{ height: "48px" }} /><br />Magstand + online page turner + mobile app (Android &amp; iOS)</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colspan="2" style={{ textAlign: "center", fontWeight: "normal", paddingLeft: "60px", paddingRight: "60px" }}>All the standard Magstand features, plus white label native iOS &amp; Android apps deployed &amp; maintained on Google Play &amp; Apple App Store. Unlimited publications, support &amp; push notifications.</Table.HeaderCell>
                    </Table.Row>

                  </Table.Header>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{ textAlign: "center", fontSize: "1.5em" }}>Titles</Table.HeaderCell>
                      <Table.HeaderCell style={{ textAlign: "center", fontSize: "1.5em" }}>Price per month</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{ textAlign: "center" }}>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>1</Table.Cell>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>&pound;195 per title</Table.Cell>
                    </Table.Row>
                    <Table.Row style={{ textAlign: "center" }}>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>2-5</Table.Cell>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>&pound;140 per title</Table.Cell>
                    </Table.Row>
                    <Table.Row style={{ textAlign: "center" }}>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>6-10</Table.Cell>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>&pound;115 per title</Table.Cell>
                    </Table.Row>
                    <Table.Row style={{ textAlign: "center" }}>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}>10+</Table.Cell>
                      <Table.Cell style={{ fontSize: "1.5em", backgroundColor: "#ebf4ff" }}><a href="/contactme">Enquire</a></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <p>
                  <Button style={{ width: "45%", float: "left" }}
                    color='blue'
                    onClick={this.handleClick2}>
                    SIGN ME UP
        </Button>

                  <Link2 to='/contactme'>
                    <Button
                      inverted style={{ width: "45%", float: "right" }}
                      color='blue'>
                      GET IN TOUCH
        </Button>
                  </Link2>

                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment style={{ backgroundColor: "#eceeef" }}>
          <p style={{ maxWidth: "1140px", marginLeft: "auto", marginRight: "auto" }}>
            <Grid columns='equal' stackable>
              <Grid.Row>
                <Grid.Column>
                  <div className="quote">
                    <div className="quote-content">
                      <p>“Since migrating to Magstand, I can’t believe we used to use Excel spreadsheets to manage data”</p>
                    </div>
                    <img className="quote-avatar" src={Rebekah} alt="Rebekah Killigrew" />
                    <div className="quote-triangle"></div>
                    <div className="quote-person text-muted">
                      Rebekah Killigrew<br />
                      Your Build Magazine
						</div>

                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="quote">
                    <div className="quote-content">
                      <p>“We saved so much time, since using Magstand, and get more new sign ups than ever”</p>
                    </div>
                    <img className="quote-avatar" src={Donna} alt="Donna Jenkins" />
                    <div className="quote-triangle"></div>
                    <div className="quote-person text-muted">
                      Donna Jenkins<br />
                      Architecture Magazine
						</div>


                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="quote">
                    <div className="quote-content">
                      <p>“Enabling real time access for digital audiences has given us a huge boost in business”<br /></p>
                    </div>
                    <img className="quote-avatar" src={Eamonn} alt="Eamonn Duff" />
                    <div className="quote-triangle"></div>
                    <div className="quote-person text-muted">
                      Eamonn Duff<br />
                      FMCG CEO Magazine
						</div>


                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="quote">
                    <div className="quote-content">
                      <p>“The Promo Code feature has revolutionised the way we drive new subscriptions”</p>
                    </div>
                    <img className="quote-avatar" src={Jade} alt="Jade Tilley" />
                    <div className="quote-triangle"></div>
                    <div className="quote-person text-muted">
                      Jade Tilley<br />
                      Interior Designer Magazine
						</div>


                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </p>

        </Segment>


        <Segment vertical style={{ padding: '5em 0em' }}>
          <Container>
            <Grid divided inverted stackable>
              <Grid.Row>
                <Grid.Column width={15}>
                  <Header inverted as='h4' content='About' />
                  <List link inverted>
                    <List.Item as='a' href='/contactme' style={{ color: '#000' }}>Contact Us</List.Item>
                    <List.Item as='a' href='/privacy' target="_blank" style={{ color: '#000' }}>Privacy Policy</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={1}>

                  <Icon circular size='big' inverted color='blue' name="arrow alternate circle up outline" link onClick={this.scrollToTop} />


                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </ResponsiveContainer>
    )
  }
}

export default HomepageLayout
