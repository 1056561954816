import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Header, Grid } from "semantic-ui-react";
import {
  getNewSubs,
  getNewSubsBundle,
  getNewSubsDigital,
  getNewSubsPrint,
  getNewOrders,
  getAllNewOrders,
  getAllNewSubs,
  getAllNewSubsPrint,
  getAllNewSubsBundle,
  getAllNewSubsDigital,
} from "../../../actions/analyticActions";
import { getExpiredLastThirtyCount } from "../../../actions/readerActions";

class MonthlyStats extends Component {
  state = {
    pubId: null,
    publicationOptions: [],
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.pubid) {
      if (nextProps.pubid !== this.state.pubId) {
        if (nextProps.pubid === "all") {
          this.props.getAllNewSubs(nextProps.pubid);
          this.props.getAllNewSubsPrint(nextProps.pubid);
          this.props.getAllNewSubsDigital(nextProps.pubid);
          this.props.getAllNewSubsBundle(nextProps.pubid);
          this.props.getAllNewOrders(nextProps.pubid);
          //this.props.getExpiredLastThirtyCount(nextProps.pubid);
        } else {
          this.props.getNewSubs(nextProps.pubid);
          this.props.getNewSubsPrint(nextProps.pubid);
          this.props.getNewSubsDigital(nextProps.pubid);
          this.props.getNewSubsBundle(nextProps.pubid);
          this.props.getNewOrders(nextProps.pubid);
          this.props.getExpiredLastThirtyCount(nextProps.pubid);
        }
        this.setState({ pubId: nextProps.pubid });
      }
    }
  }

  render() {
    let currencyIcon;
    switch (this.props.auth.user.mainCurrency) {
      case "GBP":
        currencyIcon = "£";
        break;
      case "USD":
        currencyIcon = "$";
        break;
      case "EUR":
        currencyIcon = "€";
        break;
      default:
        currencyIcon = "£";
        break;
    }

    let monthlyStatsContent;
    if (this.props.pubid === null) {
      monthlyStatsContent = null;
    } else {
      monthlyStatsContent = (
        <Grid.Row>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {this.props.analytics.newSubs}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {this.props.reader.totalExpiredLastThirtyCount}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {this.props.analytics.newSubsPrint}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {this.props.analytics.newSubsDigital}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {this.props.analytics.newSubsBundle}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {currencyIcon}
              {Number.isInteger(this.props.analytics.newOrders)
                ? this.props.analytics.newOrders.toFixed(2)
                : ""}
            </p>
          </Grid.Column>
        </Grid.Row>
      );
    }

    return (
      <Segment>
        <Header textAlign="center">Last 30 days</Header>
        <Grid columns={6}>
          <Grid.Row>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>Subscribers</p>
            </Grid.Column>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>
                Expirations
                <br />
              </p>
            </Grid.Column>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>
                Print Subs
                <br />
              </p>
            </Grid.Column>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>
                Digital Subs
                <br />
              </p>
            </Grid.Column>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>
                Bundle Subs
                <br />
              </p>
            </Grid.Column>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>
                New Revenue
                <br />
              </p>
            </Grid.Column>
          </Grid.Row>
          {monthlyStatsContent}
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  analytics: state.analytics,
  reader: state.reader,
});

export default connect(mapStateToProps, {
  getNewSubs,
  getNewSubsBundle,
  getNewSubsDigital,
  getNewSubsPrint,
  getNewOrders,
  getAllNewOrders,
  getAllNewSubs,
  getAllNewSubsBundle,
  getAllNewSubsDigital,
  getAllNewSubsPrint,
  getExpiredLastThirtyCount,
})(MonthlyStats);
