import React, { Component } from "react";
import { Card, Image, Button, Reveal } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import axios from "axios";

class PublicationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publication: this.props.publication,
    };
  }

  componentDidMount() {
    this.setState({ publication: this.props.publication });
  }

  // Pass the image file to the server in a FormData (multi/enc) object
  fileSelectedHandler = (e) => {
    this.setState({
      selectedFile: e.target.files[0].name,
    });
    const fd = new FormData();
    fd.append("image", e.target.files[0], e.target.files[0].name);
    axios
      .post(`/api/publication/image/${this.state.publication._id}`, fd)
      .then((res) => {
        toastr.success("Publication Image updated");
        this.setState({ publication: res.data });
      })
      .catch((err) => console.log(err.response.data));
  };

  // Pass the logo image file to the server in a FormData (multi/enc) object
  logoSelectedHandler = (e) => {
    this.setState({
      selectedLogo: e.target.files[0].name,
    });
    const fd = new FormData();
    fd.append("image", e.target.files[0], e.target.files[0].name);
    axios
      .post(`/api/publication/logo/${this.state.publication._id}`, fd)
      .then((res) => {
        toastr.success("Publication Logo updated");
        this.setState({ publication: res.data });
      })
      .catch((err) => console.log(err.response.data));
  };

  upload() {
    document.getElementById("myfile").click();
  }

  uploadLogo() {
    document.getElementById("mylogo").click();
  }

  render() {
    const { publication } = this.props;
    return (
      <Card>
        <Reveal
          style={{ margin: "10px", padding: "0px" }}
          animated="move"
          key={this.state.publication.publicationName}
        >
          <Reveal.Content visible>
            <div style={{ height: "360px", backgroundColor: "#fff" }}>
              <Image
                src={publication.thumbImageName}
                style={{ margin: "0px" }}
                size="massive"
              />
            </div>
          </Reveal.Content>
          <Reveal.Content hidden>
            <Card.Content>
              <Card.Description>
                <Link to={`/purchasereceipt/${this.state.publication._id}`}>
                  <Button fluid>Purchase Receipt</Button>
                </Link>
                <br />

                <Link to={`/renewalreceipt/${this.state.publication._id}`}>
                  <Button fluid>Renewal Template</Button>
                  <br />
                </Link>
              </Card.Description>
            </Card.Content>
          </Reveal.Content>
        </Reveal>
      </Card>
    );
  }
}

export default PublicationItem;
