// Renders a Redux Form Input Field with label above

import React from 'react';
import { Form } from 'semantic-ui-react';

const RenderFieldLabelAboveRedux = ({
  input,
  meta: { touched, error },
  label,
  type }) => (
    <div>
      <div style={{ padding: "10px" }}>
        <Form.Input
          {...input}
          fluid
          size='small'
          label={label}
          type={type}
          error={touched && error ? true : false} />
        {touched && error && <div className="ui pointing red basic label">{error}</div>}
      </div>
    </div>
  )

RenderFieldLabelAboveRedux.defaultProps = {
  type: 'text'
};

export default RenderFieldLabelAboveRedux;