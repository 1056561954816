import {
  ADD_READER,
  SEARCH_READERS,
  LOADING,
  FINISHED_LOADING,
  GET_READER,
  GET_READER_DIG_SUBS,
  GET_READER_PRINT_SUBS,
  RESET_STATE,
  UPDATE_READER,
  REMOVE_READER,
  REACTIVATE_READER,
  GET_TOTAL_READERS,
  MAILER_DATA,
  SHOW_MODAL,
  GET_NOFULLNAME,
  GET_NOFULLNAMECOUNT,
  GET_NOPOSTCODECOUNT,
  GET_EXPIRED,
  GET_EXPIRED_COUNT,
  GET_EXPIRED_NEXT_THIRTY,
  GET_EXPIRED_NEXT_THIRTY_COUNT,
  GET_NOPOSTCODE,
  AUTO_FIX_FULLNAME,
  GET_ALL_DATA,
  GET_DUPLICATE_FULLNAME_COUNT,
  GET_DUPLICATE_FULLNAME,
  GET_DUPLICATE_LASTNAME_COUNT,
  GET_DUPLICATE_LASTNAME,
  GET_DUPLICATE_POSTCODE_COUNT,
  GET_DUPLICATE_POSTCODE,
  GET_DUPLICATE_ADDRESS_COUNT,
  GET_DUPLICATE_ADDRESS,
  GET_DUPLICATE_EMAILS,
  GET_DUPLICATE_EMAIL_COUNT,
  DELETE_READER_FULLNAME,
  DELETE_READER_POSTCODE,
  DELETE_READER_LASTNAME,
  DELETE_READER_ADDRESS,
  DELETE_READER,
  CLEAR_DATA,
  BAD_POSTCODE,
  BAD_POSTCODE_COUNT,
  DELETE_READER_EMAIL,
  GET_NOFIRSTNAME_COUNT,
  AUTO_FIX_FIRSTNAME,
  GET_NOFIRSTNAME,
  GET_BAD_ADDRESS_DATA_COUNT,
  GET_BAD_ADDRESS_DATA,
  AUTO_FIX_ADDRESS,
  GET_EXPIRED_LAST_THIRTY_COUNT
} from '../actions/types';

// Create an initial state for the model
const initialState = {
  readers: {},
  reader: null,
  digSubs: [],
  printSubs: [],
  loading: false,
  mailerData: [],
  totalReaders: 0,
  showModal: false,
  noFullNameCount: 0,
  noFirstNameCount: 0,
  noPostcodeCount: 0,
  badPostcodeCount: 0,
  badPostcodes: {},
  badAddressCount: 0,
  badAddress: {},
  totalExpiredCount: 0,
  totalExpiredThirtyCount: 0,
  totalExpiredLastThirtyCount: 0,
  duplicateFullNameCount: 0,
  duplicateFullNames: {},
  duplicateLastNameCount: 0,
  duplicateLastNames: {},
  duplicatePostCodeCount: 0,
  duplicatePostCodes: {},
  duplicateAddressCount: 0,
  duplicateAddresses: {},
  duplicateEmailCount: 0,
  duplicateEmails: {},
}
export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_STATE:
      return initialState;
    case LOADING:
      return {
        ...state,
        loading: true
      };
    case SHOW_MODAL:
      return {
        ...state,
        showModal: true
      }
    case FINISHED_LOADING:
      return {
        ...state,
        loading: false
      }
    case GET_READER: return {
      ...state,
      reader: action.payload,
      loading: false
    }
    case GET_READER_DIG_SUBS: return {
      ...state,
      digSubs: action.payload
    }
    case GET_READER_PRINT_SUBS: return {
      ...state,
      printSubs: action.payload,
      loading: false
    }
    case MAILER_DATA: return {
      ...state,
      mailerData: action.payload,
      showModal: false
    }
    case ADD_READER: return state;
    case UPDATE_READER: return state;
    case REMOVE_READER: return state;
    case REACTIVATE_READER: return state;
    case AUTO_FIX_FULLNAME: return state;
    case AUTO_FIX_FIRSTNAME: return state;
    case SEARCH_READERS: return {
      ...state,
      readers: action.payload,
      loading: false
    };
    case GET_TOTAL_READERS: return {
      ...state,
      totalReaders: action.payload,
      loading: false
    };
    case GET_NOFULLNAME: return {
      ...state,
      readers: action.payload,
      loading: false
    };
    case GET_NOFIRSTNAME: return {
      ...state,
      readers: action.payload,
      loading: false
    };
    case GET_ALL_DATA: return {
      ...state,
      readers: action.payload,
      loading: false
    }
    case GET_NOFULLNAMECOUNT: return {
      ...state,
      noFullNameCount: action.payload,
      loading: false
    };
    case GET_NOFIRSTNAME_COUNT: return {
      ...state,
      noFirstNameCount: action.payload,
      loading: false
    };
    case BAD_POSTCODE_COUNT: return {
      ...state,
      badPostcodeCount: action.payload,
      loading: false
    };
    case BAD_POSTCODE: return {
      ...state,
      badPostcodes: action.payload,
      loading: false
    };
    case GET_BAD_ADDRESS_DATA_COUNT: return {
      ...state,
      badAddressCount: action.payload,
      loading: false
    };
    case GET_BAD_ADDRESS_DATA: return {
      ...state,
      badAddress: action.payload,
      loading: false
    }
    case GET_DUPLICATE_FULLNAME_COUNT: return {
      ...state,
      duplicateFullNameCount: action.payload,
      loading: false
    };
    case GET_DUPLICATE_FULLNAME: return {
      ...state,
      duplicateFullNames: action.payload,
      loading: false
    };
    case GET_DUPLICATE_LASTNAME_COUNT: return {
      ...state,
      duplicateLastNameCount: action.payload,
      loading: false
    };
    case GET_DUPLICATE_LASTNAME: return {
      ...state,
      duplicateLastNames: action.payload,
      loading: false
    };
    case GET_DUPLICATE_POSTCODE_COUNT: return {
      ...state,
      duplicatePostCodeCount: action.payload,
      loading: false
    };
    case GET_DUPLICATE_POSTCODE: return {
      ...state,
      duplicatePostCodes: action.payload,
      loading: false
    };
    case GET_DUPLICATE_ADDRESS_COUNT: return {
      ...state,
      duplicateAddressCount: action.payload,
      loading: false
    };
    case GET_DUPLICATE_ADDRESS: return {
      ...state,
      duplicateAddresses: action.payload,
      loading: false
    };
    case GET_DUPLICATE_EMAIL_COUNT: return {
      ...state,
      duplicateEmailCount: action.payload,
      loading: false
    };
    case GET_DUPLICATE_EMAILS: return {
      ...state,
      duplicateEmails: action.payload,
      loading: false
    };
    case GET_NOPOSTCODECOUNT: return {
      ...state,
      noPostcodeCount: action.payload,
      loading: false
    };
    case GET_NOPOSTCODE: return {
      ...state,
      readers: action.payload,
      loading: false
    }
    case GET_EXPIRED_COUNT: return {
      ...state,
      totalExpiredCount: action.payload,
      loading: false
    };
    case GET_EXPIRED: return {
      ...state,
      readers: action.payload,
      loading: false
    };
    case GET_EXPIRED_NEXT_THIRTY: return {
      ...state,
      readers: action.payload,
      loading: false
    };
    case GET_EXPIRED_NEXT_THIRTY_COUNT: return {
      ...state,
      totalExpiredThirtyCount: action.payload,
      loading: false
    };
    case GET_EXPIRED_LAST_THIRTY_COUNT: return {
      ...state,
      totalExpiredLastThirtyCount: action.payload,
      loading: false
    }
    case AUTO_FIX_ADDRESS:
      return {
        ...state,
        badAddress: state.badAddress.filter(badAddress => badAddress.data.id !== action.payload)
      };
    case DELETE_READER_FULLNAME:
      return {
        ...state,
        duplicateFullNames: state.duplicateFullNames.filter(reader => reader.data.id !== action.payload)
      };
    case DELETE_READER_EMAIL:
      return {
        ...state,
        duplicateEmails: state.duplicateEmails.filter(reader => reader.data.id !== action.payload)
      };
    case DELETE_READER_LASTNAME:
      return {
        ...state,
        duplicateLastNames: state.duplicateLastNames.filter(reader => reader.data.id !== action.payload)
      };
    case DELETE_READER_POSTCODE:
      return {
        ...state,
        duplicatePostCodes: state.duplicatePostCodes.filter(reader => reader.data.id !== action.payload)
      };
    case DELETE_READER_ADDRESS:
      return {
        ...state,
        duplicateAddresses: state.duplicateAddresses.filter(reader => reader.data.id !== action.payload)
      };
    case DELETE_READER:
      return state;
    case CLEAR_DATA:
      return {
        ...state,
        duplicateAddresses: {},
        duplicateFullNames: {},
        duplicateLastNames: {},
        duplicatePostCodes: {},
        readers: {}
      }
    default: return state;
  }
}