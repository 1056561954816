// Analytics Dashboard

import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment, Header } from 'semantic-ui-react';
import { isUserLoggedIn } from '../common/isUserLoggedIn';
import ChoosePub from './components/ChoosePub';

class ManageData extends Component {

  componentDidMount() {
    if (this.props.auth.user.userType !== "publisher" && this.props.auth.user.userType !== 'pubadmin') {
      if (this.props.auth.user.userType === "reseller" || this.props.auth.user.userType === "superuser") {
        window.location.href = "/publishers"
      } else {
        window.location.href = "/readers"
      }
    }
  }

  componentWillReceiveProps() {
    isUserLoggedIn();
  }
  render() {
    return (
      <Segment>
        <Header as="h2" textAlign="center">Manage Data</Header>
        <ChoosePub />
      </Segment>
    )
  }
}

ManageData.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,

});

export default connect(mapStateToProps)(ManageData);
