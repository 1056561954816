function getTitles() {
  return (
    [
      { key: '1', text: "Mr.", value: "Mr." },
      { key: '2', text: "Mrs.", value: "Mrs." },
      { key: '3', text: "Miss", value: "Miss" },
      { key: '4', text: "Dr.", value: "Dr." },
      { key: '5', text: "Ms.", value: "Ms." },
      { key: '6', text: "Prof", value: "Prof" },
      { key: '7', text: "Rev.", value: "Rev." },
      { key: '8', text: "Lady", value: "Lady" },
      { key: '9', text: "Sir", value: "Sir" },
      { key: '10', text: "Capt.", value: "Capt." },
      { key: '11', text: "Major", value: "Major" },
      { key: '12', text: "Lt.-Col.", value: "Lt.-Col." },
      { key: '13', text: "Col.", value: "Col." },
      { key: '14', text: "Lt.-Cmdr", value: "Lt.-Cmdr" },
      { key: '15', text: "The Hon.", value: "The Hon." },
      { key: '16', text: "Cmdr.", value: "Cmdr." },
      { key: '17', text: "Flt. Lt.", value: "Flt. Lt." },
      { key: '18', text: "Brgdr.", value: "Brgdr." },
      { key: '19', text: "Judge", value: "Judge" },
      { key: '20', text: "Lord", value: "Lord" },
      { key: '21', text: "Wng. Cmdr.", value: "Wng. Cmdr." },
      { key: '22', text: "Group Capt.", value: "Group Capt." },
      { key: '23', text: "Rt. Hon. Lord", value: "Rt. Hon. Lord" },
      { key: '24', text: "Revd. Father", value: "Revd. Father" },
      { key: '25', text: "Revd Canon", value: "Revd Canon" },
      { key: '26', text: "Maj.-Gen.", value: "Maj.-Gen." },
      { key: '27', text: "Air Cdre.", value: "Air Cdre." },
      { key: '28', text: "Viscount", value: "Viscount" },
      { key: '29', text: "Dame", value: "Dame" },
      { key: '30', text: "Rear Admrl.", value: "Real Admrl." }
    ])
};

export default getTitles;
