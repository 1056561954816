import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CardGroup, Button } from 'semantic-ui-react';

import Spinner from '../common/Spinner';
import BackIssueItem from './components/BackIssueItem';
import { getBackIssues } from '../../actions/backIssueActions';
import { getPublicationsWithBackIssue } from '../../actions/publicationActions';
import { isUserLoggedIn } from '../common/isUserLoggedIn';

class BackIssues extends Component {
  componentDidMount() {
    if (this.props.auth.user.userType === 'publisher') {
      this.props.getPublicationsWithBackIssue(this.props.auth.user.id);

      this.props.getBackIssues(this.props.auth.user.id);
    } else {
      if (this.props.auth.user.userType === 'pubadmin') {
        this.props.getBackIssues(this.props.auth.user.parentPublisher);
      } else {
        window.location.href = '/publications';
      }
    }
  }

  componentWillReceiveProps() {
    isUserLoggedIn();
  }

  render() {
    const { backIssue, publication } = this.props;
    let backIssueItems;

    if (backIssue.loading || publication.loading) {
      backIssueItems = <Spinner />;
    } else {
      if (!publication.publications || !publication.publications.length) {
        backIssueItems = (
          <div style={{ margin: '10px' }}>
            <h4>You don't have any publications which sells back issues</h4>
            <p>To add one, please check 'Sell Back Issue' on publications</p>
          </div>
        );
      } else if (!backIssue.backIssues || !backIssue.backIssues.length) {
        backIssueItems = (
          <div style={{ margin: '10px' }}>
            <h4>No Back issue found for this User</h4>
            <p>To add one, please click the Blue circle to the right</p>
          </div>
        );
      } else {
        if (backIssue.backIssues.length > 0) {
          backIssueItems = backIssue.backIssues.map((backIssue) => (
            <BackIssueItem key={backIssue._id} backIssue={backIssue} />
          ));
        }
      }
    }
    return (
      <React.Fragment>
        <Link to='/addbackIssue'>
          <Button
            circular
            icon='plus circle'
            size='massive'
            color='blue'
            floated='right'
          />
        </Link>
        <CardGroup>{backIssueItems}</CardGroup>
      </React.Fragment>
    );
  }
}

BackIssues.propTypes = {
  getBackIssues: PropTypes.func.isRequired,
  backIssue: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    backIssue: state.backIssue,
    auth: state.auth,
    publication: state.publication,
  };
};

export default connect(mapStateToProps, {
  getBackIssues,
  getPublicationsWithBackIssue,
})(BackIssues);
