import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Form,
  Button,
  Header,
  Grid,
  Image,
  Table,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import {
  getPublication,
  updateReceiptText,
} from "../../../actions/publicationActions";
import { getAccountTel } from "../../../actions/accountActions";

import { isUserLoggedIn } from "../../common/isUserLoggedIn";
import RenderTextArea from "../../common/NormalForm/RenderTextArea";

import Tick from "../../../assets/img/ecommerce-template_order-confirmed-icon.jpg";

class PurchaseReceipt extends Component {
  state = {
    receiptText: "",
    errors: {},
    publication: null,
    logo: "",
    logofull: "",
    publisherName: "",
    addressOne: "",
    addressTwo: "",
    addressThree: "",
    postZip: "",
    mainCurrency: "",
    mainCurrencySymbol: "",
    publicationName: "",
    pubid: "",
    iosAppLink: "",
    androidAppLink: "",
    digitalEditionLink: "",
  };

  componentDidMount() {
    this.props.getPublication(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.publication) {
      if (this.state.logo !== nextProps.publication.logo) {
        this.setState({
          receiptText: nextProps.publication.receiptText,
          pubid: nextProps.publication._id,
          publication: nextProps.publication,
          publicationName: nextProps.publication.publicationName,
          logo: nextProps.publication.logo,
          iosAppLink: nextProps.publication.iosAppLink,
          androidAppLink: nextProps.publication.androidAppLink,
          digitalEditionLink: nextProps.publication.digitalEditionLink,
          logofull: nextProps.publication.logo,
        });
        this.props.getAccountTel(nextProps.publication.user);
      }
    }

    if (nextProps.account.account) {
      if (nextProps.account.account.accountDets) {
        if (
          this.state.companyName !==
          nextProps.account.account.accountDets.companyName
        ) {
          this.setState({
            publisherName: nextProps.account.account.accountDets.companyName,
            addressOne: nextProps.account.account.accountDets.addressOne,
            addressTwo: nextProps.account.account.accountDets.addressTwo,
            addressThree: nextProps.account.account.accountDets.addressThree,
            postZip: nextProps.account.account.accountDets.postZip,
            mainCurrency: nextProps.account.account.accountDets.mainCurrency,
          });
          switch (nextProps.account.account.accountDets.mainCurrency) {
            case "GBP":
              this.setState({ mainCurrencySymbol: "£" });
              break;
            case "USD":
              this.setState({ mainCurrencySymbol: "$" });
              break;
            case "EUR":
              this.setState({ mainCurrencySymbol: "€" });
              break;
            default:
              this.setState({ mainCurrencySymbol: "£" });
              break;
          }
        }
      }
    }
  }

  handleChange = (e, { value }) => {
    this.setState({ title: value });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitHandler = (e) => {
    e.preventDefault();
    const receiptText = {
      _id: this.props.match.params.id,
      receiptText: this.state.receiptText,
    };

    // this.props.history needed withRouter so we can use it in the redux action
    this.props.updateReceiptText(receiptText, this.props.history);
  };

  render() {
    const { errors } = this.state;
    let iosLink = "";
    let androidLink = "";
    let digitalLink = "";

    this.state.iosAppLink
      ? (iosLink = (
          <p>
            To access the iOS version of the mobile app, please click this{" "}
            <a
              href={this.state.iosAppLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
            </a>
            <br />
          </p>
        ))
      : (iosLink = null);
    this.state.androidAppLink
      ? (androidLink = (
          <p>
            To access the Android version of the mobile app, please click this{" "}
            <a
              href={this.state.androidAppLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
            </a>
            <br />
          </p>
        ))
      : (androidLink = null);
    this.state.digitalEditionLink
      ? (digitalLink = (
          <p>
            For the online digital edition access please click{" "}
            <a
              href={this.state.digitalEditionLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            <br />
          </p>
        ))
      : (digitalLink = null);

    return (
      <React.Fragment>
        <Form onSubmit={this.onSubmitHandler}>
          <Segment>
            <Header as="h2" textAlign="center">
              Purchase Receipt Template for{" "}
            </Header>
          </Segment>
          <Grid stackable columns={2}>
            <Grid.Column>
              <Segment>
                <RenderTextArea
                  name="receiptText"
                  label="Receipt Text"
                  value={this.state.receiptText}
                  onChange={this.onChangeHandler}
                  error={errors.message}
                />
              </Segment>
              <Segment>
                <Button color="blue" type="submit" fluid>
                  Update Receipt Text
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <div
                  style={{ height: "100px", borderBottom: "1px solid black" }}
                >
                  <div style={{ float: "left" }}>
                    <img
                      src={this.state.logo}
                      style={{ width: "200px" }}
                      alt="logo"
                    />
                  </div>
                  <div style={{ float: "right" }}>
                    <i>{this.state.publisherName}</i>
                  </div>
                </div>
                <div style={{ paddingTop: "2em" }}>
                  <strong>Title First name Last name</strong>
                  <br />
                  Address Line 1<br />
                  Address Line 2<br />
                  Address Line 3<br />
                  PostCode
                </div>
                <Image centered src={Tick} />
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <h3>
                    Thank you for placing your online
                    <br />
                    order for a subscription!
                  </h3>
                  <br />
                  This email is to confirm your recent order.
                </div>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        style={{ backgroundColor: "#000", color: "#fff" }}
                      >
                        ORDER OD-XXXXXXX
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ backgroundColor: "#000", color: "#fff" }}
                      >
                        PRICE
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        1 Year Digital Subscription to{" "}
                        {this.state.publicationName}
                      </Table.Cell>
                      <Table.Cell>
                        {this.state.mainCurrencySymbol}29.99
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ fontWeight: "bold" }}>
                        TOTAL
                      </Table.Cell>
                      <Table.Cell style={{ fontWeight: "bold" }}>
                        {this.state.mainCurrencySymbol}29.99
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <div
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    textAlign: "center",
                    borderBottom: "1px solid black",
                  }}
                >
                  {iosLink}
                  {androidLink}
                  {digitalLink}
                  <br />
                  {this.state.receiptText}
                </div>
                <div
                  style={{
                    paddingTop: "20px",
                    textAlign: "center",
                    paddingBottom: "20px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <strong>{this.state.publisherName}</strong>,{" "}
                  {this.state.addressOne}, {this.state.addressTwo},{" "}
                  {this.state.addressThree}, {this.state.postZip}
                </div>
              </Segment>
            </Grid.Column>
          </Grid>
        </Form>
      </React.Fragment>
    );
  }
}

PurchaseReceipt.propTypes = {
  getPublication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors,
  publication: state.publication.publication,
  account: state.account,
});

export default connect(mapStateToProps, {
  getPublication,
  getAccountTel,
  updateReceiptText,
})(withRouter(PurchaseReceipt));
