// Main Landing Homepage

import PropTypes from 'prop-types'
import axios from 'axios';
import React, { Component } from 'react'
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Card,
  Menu,
  Form,
  Table,
  Responsive,
  Segment,
  Dropdown,
  Sidebar,
  Visibility,
  Divider
} from 'semantic-ui-react'
import { Link, animateScroll } from 'react-scroll';
import { Link as Link2 } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import RenderFieldLabelAbove from '../common/NormalForm/RenderFieldLabelAbove';
import RenderTextArea from '../common/NormalForm/RenderTextArea';

import './HowItWorks.css';


import LogoWhite from '../../assets/img/ms200wht.png';
//import LogoBlack from '../../assets/img/ms200blk.png';
import LogoBlack from '../../assets/img/ms200blkNew.png';
import Subscribe from '../../assets/img/magsatndsubscribe.png';
import RHS from '../../assets/img/img1.png';
import Plus from '../../assets/img/Magstand-Plus-Logo.png';
import DETL from '../../assets/img/delogo60.png';
import ReCAPTCHA from "react-google-recaptcha";

// Gets the width of the viewport for responsiveness
const getWidth = () => {
  const isSSR = typeof window === 'undefined'
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

/* eslint-disable react/no-multi-comp */
const HomepageHeading = ({ mobile }) => (
  <React.Fragment>

  </React.Fragment>
)
HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth} style={{ backgroundUrl: "url(../../assets/img/magsatndsubscribe.png)" }}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            textAlign='center'
            style={{ padding: '1em 0em' }}
            vertical
          >
            <Menu
              fixed={!fixed}
              secondary={!fixed}
              size='small'
              borderless
            >
              <Container>
                <Menu.Item>
                  <a href="/"><Image size='small' src={!fixed ? LogoBlack : LogoBlack} /></a>
                </Menu.Item>

                <Menu.Item>
                  <a href="/#pricing" style={{ color: "#000" }}>Pricing</a>
                </Menu.Item>
                <Menu.Item>
                  <a href="/#features" style={{ color: "#000" }}>Features</a>
                </Menu.Item>
                <Dropdown text='Resources' pointing className='link item'>
                  <Dropdown.Menu>
                    <Dropdown.Header>Magstand Resources</Dropdown.Header>
                    <Dropdown.Item as='a' href="/assets/templates/magstanduploadtemplate.csv" target="_blank">Data Upload Template</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Header>Payment Resources</Dropdown.Header>
                    <Dropdown.Item as='a' href='https://developer.paypal.com/developer/applications/' target="_new">Paypal</Dropdown.Item>
                    <Dropdown.Item as='a' href='https://stripe.com' target="_new">Stripe</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Menu.Item as='a' href="/contactme">
                  Contact Us
                  </Menu.Item>
                {/* <Menu.Item as='a' href="/howto">
                How It Works
                  </Menu.Item> */}


                <Menu.Item position='right'>
                  <Button as='a' href='/login' color='blue'>
                    Publisher Login
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as='a' active>
            <Image size='small' src={LogoBlack} />
          </Menu.Item>
          <Menu.Item as='a' href='/login'>Publisher Login</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign='center'
            style={{ minHeight: 350, padding: '1em 0em' }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button as='a' href='/login'>
                    Publisher Login
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

class HowItWorks extends Component {
  state = {
    errors: {},
    name: "",
    phone: "",
    email: "",
    numPubs: "",
    message: "",
    captcha: "",
    showButton: false
  }
  handleChange = (e, { value }) => {
    this.setState({ numPubs: value })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCapture = (e) => {
    this.setState({ captcha: e, showButton: true })
  }

  handleClick = () => window.location.href = '/signup'

  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      name: this.state.name,
      phone: this.state.phone,
      message: this.state.message,
      captcha: this.state.captcha,
      emailResetMessage: ""
    };

    axios
      .post('/api/order/contactme', userData)
      .then(res => {
        this.setState({ emailResetMessage: "Thank you for your Contact Request. A member of the Magstand team will be in touch.", emailError: "" })

      })
  }

  scrollToTop = () => {
    animateScroll.scrollToTop();
  };
  render() {
    let showButton;

    this.state.showButton ? showButton = (
      <div style={{ padding: "10px" }}>
        <Button color="blue" fluid type="submit">Submit Request</Button>
      </div>
    ) : showButton = null

    const { errors } = this.state
    return (
      <ResponsiveContainer >
        <Segment style={{ padding: '2em 0em', backgroundColor: '#fff', maxWidth: "1140px", marginLeft: "auto", marginRight: "auto" }} vertical>
          <div style={{ marginLeft: "12px" }}>
            <Header as='h1'>

              How it works
</Header>
            <p style={{ paddingBottom: "2em" }}>
              13 simple steps to setup your Magstand Dashboard and Storefront
            </p>
          </div>
          <Card.Group itemsPerRow={2} stackable>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>1. Set up and manage your Magstand Account</Card.Header>
                <Card.Description>Sign up and sign in<br />
                  <ul><li>Sign up is easy, just have your credit or debit card ready</li>
                    <li>There are no contractual minim periods and you can cancel any time</li>
                    <li>We debit your card monthly and send automatic VAT invoices by email</li>
                    <li>Once signed up, click on ‘My Account’ and enter your company details</li>
                    <li>If you are taking payments you will need to enter your credit card provider and PayPal API Keys. Click on Resources above for more information</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>2. Add Flickread or Apazine User keys</Card.Header>
                <Card.Description>Synchronise your Magstand account<br />
                  <ul>
                    <li>Magstand is already connected to Apazine mobile apps, and Flickread online digital editons. Synchronising your Magstand subscribers is easy</li>
                    <li>Log into your Apazine account and click  on ‘Manage App’</li>
                    <li>Then copy the ‘User Key’ by clicking  the copy button</li>
                    <li>In the publication set up, you will be asked for your user key, paste this user key in the field and your digital subscribers will be able to log into your app, and non free digital editions.</li>
                    <li>For non Apazine or Flickread connections, see the ‘Third Party API’ instructions below</li>
                  </ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>3. Add your first publication</Card.Header>
                <Card.Description>Adding your publication automatically creates your store page<br />
                  <ul><li>Before staring, you need to have a few things ready; a magazine logo, ideally white background and 500pixels wide. A sample front cover jpg, 500px tall. 70-100 words about your publication, and your subscription pricing</li>
                    <li>Click on ‘Publications’ and then click the plus button to add a publication.</li>
                    <li>Complete the forms, and upload your graphics</li>
                    <li>Choose custom colours for your store page</li>
                    <li>You can choose your unique store page URL</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>4. Import Reader Data</Card.Header>
                <Card.Description>Adding all your data is easy<br />
                  <ul><li>Download our CSV data upload templates from ‘Resources’ above</li>
                    <li>Create a CSV of your data file, and ensure you use the same headers as our data template. You don’t need to include blank rows if you don’t use all the headers</li>
                    <li>Upload your CSV file, and click ‘Manage Data’ to preview what’s in the Magstand System.</li>
                    <li>You will already see Magstand suggesting data fix ups</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>5. Create User Accounts</Card.Header>
                <Card.Description>Be in control of your data assets<br />
                  <ul><li>As the main account holder you have access to everything</li>
                    <li>We know you won’t or can’t always want to give access to your full database and financial information to your admin staff, so you can create them their own log in</li>
                    <li>Admins can only view or add one record at a time, and see no analytics or reports</li>
                    <li>Create a ‘Mailer’ account for your mailng house, and when they log in, they see no reporting, have no access to your full database and can only click to download the latest real-time mailing list</li>
                    <li>Secure, GDPR compliant, and working smarter than ever.
</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>6. Manage Data</Card.Header>
                <Card.Description>Saving you postage by fixing your data<br />
                  <ul><li>View and manage your data in one easy user interface.</li>
                    <li>Magstand automatically highlights potential duplicates, missing postcodes and other common data problems, which can save postage on every mailing.</li>
                    <li>Easily view, edit or remove readers</li>
                    <li>Upload additional data, or a complete data replacement set</li>
                    <li>Easily export expiring and expired subscriptions to chase up</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>7. Setup Promo Code</Card.Header>
                <Card.Description>Boost subscription sales<br />
                  <ul><li>Promo codes and voucher codes are great way to attract new subscribers</li>
                    <li>Setting up a promo code in Magstand takes just seconds</li>
                    <li>Enter a code, the percentage discount, end date and nuber of uses unless unlimited and it will instanlt update your store page.</li>
                    <li>Share your promo codes I your marketing and social media to boost sales</li>
                    <li>Cancel the code whenever you’re ready</li>
                    <li>Set up as many promo codes as you like, for each of your titles</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>8. Setup Email Templates</Card.Header>
                <Card.Description>Customise your receipts and renewal emails<br />
                  <ul><li>Magstand takes care of sending your subscribers customised receipts by email. Featuring your magazine branding, you can also customise the email footer text.</li>
                    <li>Before your subscribers expire, Magstand will send an automated email to them asking them to click the link and resubscribe. It will remind them their user name and part of their password, to ensure maximum renewals. The email will be branded with your magazine logo and you can customise the email text easily in your account.</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>9. Storefront Purchases</Card.Header>
                <Card.Description>Boosting sales and signups<br />
                  <ul><li>When you add your publication to Magstand, a custom branded store page is automatically created which will handle all new subscription sign ups, payments and renewals.</li>
                    <li>Share this link, and link to it fro your web site to handle all your future transactions.</li>
                    <li>You can customise the store page for paid for, or controlled free circulations with the system capturing your controlled circulation questions and answers.</li>
                    <li>You can configure the store page to capture other reader survey questions, as well as ABC audit verification questions.</li>
                    <li>Fully GDPR and PCI compliant, your store page will harvest your subscribers marketing preferences
</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>10. View Sales Ledger</Card.Header>
                <Card.Description>Sales reporting to measure success<br />
                  <ul><li>Every sale is recorded in your Sales Ledger screen in Magstand, which can be exported as a spreadsheet for your accounts. </li>
                    <li>Measure sales growth, trends and keep track of all your titles performance
</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>11. Analytics</Card.Header>
                <Card.Description>All the information you need at your fingertips<br />
                  <ul><li>View a snapshot of all your readers, broken down into print, digital, expired, renewed.</li>
                    <li>See the performance of your Magstand Store Page</li>
                    <li>Display recent activity and see graphs of your revenue and sign ups </li>
                    <li>Track expiring subscriptions
</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>12. Maintain Reader data</Card.Header>
                <Card.Description>Your day-to-day list maintenance <br />
                  <ul><li>Create an ‘admin’ user account to only grant single record database maintenance</li>
                    <li>List administrators can search the database to identify individual records when handling address changes, gone away’s and removals.</li>
                    <li>List admins can add or delete individual readers </li>
                    <li>Financial, analytics or quantity data is never available to list administrators who cannot export data or see multiple records.</li>
                    <li>Keeping your data safe and secure, as well as GDPR compliant
</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
            <Card raised>
              <Card.Content style={{ padding: "2.1em" }}>
                <Card.Header>13. Download mailing file</Card.Header>
                <Card.Description>Set your mailer up with an account<br />
                  <ul><li>Add a new ‘Mailer’ user account and enable limited third party access to your Magstand account</li>
                    <li>Mailer account holders are unable to export data, view financial information or analytics. They can only export the current real-time live mailing list file.</li></ul>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card raised>
              <Card.Content>
                <div class="embed-container">
                  <iframe src="https://player.vimeo.com/video/364761081" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>          </Card.Content>
            </Card>
          </Card.Group>
        </Segment>
        <Segment vertical style={{ padding: '5em 0em' }}>
          <Container>
            <Grid divided inverted stackable>
              <Grid.Row>
                <Grid.Column width={15}>
                  <Header inverted as='h4' content='About' />
                  <List link inverted>
                    <List.Item as='a' href='/contactme' style={{ color: '#000' }}>Contact Us</List.Item>
                    <List.Item as='a' href='/privacy' target="_blank" style={{ color: '#000' }}>Privacy Policy</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={1}>

                  <Icon circular size='big' inverted color='blue' name="arrow alternate circle up outline" link onClick={this.scrollToTop} />


                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </ResponsiveContainer>
    )
  }
}

export default HowItWorks
