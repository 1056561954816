import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { Button, Form } from 'semantic-ui-react';
import RenderFieldLabelAboveRedux from '../../../common/ReduxForm/RenderFieldLabelAboveRedux';

class AppLinks extends Component {


  render() {
    const { handleSubmit, previousPage, submitting } = this.props;
    return (
      <Form onSubmit={handleSubmit} size='large'>
        <Field
          name="iosAppLink"
          component={RenderFieldLabelAboveRedux}
          type="text"
          label="iOS App Link"
        />
        <Field
          name="androidAppLink"
          component={RenderFieldLabelAboveRedux}
          type="text"
          label="Android App Link"
        />
        <Field
          name="kindleAppLink"
          component={RenderFieldLabelAboveRedux}
          type="text"
          label="Kindle App Link"
        />
        <Field
          name="digitalEditionLink"
          component={RenderFieldLabelAboveRedux}
          type="text"
          label="Digital Edition Link"
        />
        <div style={{ margin: "10px", textAlign: "center" }}>
          <Button color='blue' style={{ minWidth: "100px" }}
            onClick={previousPage}>
            Previous
            </Button>
          <Button color='blue' style={{ minWidth: "100px" }}
            disabled={submitting}>
            Next
            </Button>
        </div>
      </Form>
    )
  }
}


export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(AppLinks);