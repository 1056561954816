import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Segment, Form, Button, Header, Checkbox } from "semantic-ui-react";
import PropTypes from "prop-types";
import {
  getAccount,
  updateAccount,
  getPayPal,
} from "../../actions/accountActions";
import RenderFieldLeft from "../common/NormalForm/RenderFieldLeft";
import RenderFieldLeftAndRequired from "../common/NormalForm/RenderFieldLeftAndRequired";
import { isUserLoggedIn } from "../common/isUserLoggedIn";
import isEmpty from "../../extension/is-empty";
import getCountries from "../common/Countries";
import Spinner from "../common/Spinner";
 
class Account extends Component {
  state = {
    userImageName: "",
    fullName: "",
    mainContact: "",
    companyName: "",
    addressOne: "",
    addressTwo: "",
    addressThree: "",
    addressFour: "",
    country: "",
    postZip: "",
    mobile: "",
    telephone: "",
    emailAddress: "",
    singleEmailAddress: "",
    payPalString: "",
    serviceUserNumber: "",
    chargeableName: "",
    publishableKey: "",
    secretKey: "",
    mainCurrency: "",
    showPayContent: false,
    sellOverseas: true,
    showDDContent: false,
    selectedDD: "email",
    selectedCC: "",
    showStripeContent: false,
    showSTContent: false,
    user: "",
    pass: "",
    ref: "",
    errors: {},
    childKey: 1,
  };

  componentDidMount() {
    this.setState({
      userImageName: "",
      fullName: "",
      mainContact: "",
      companyName: "",
      addressOne: "",
      addressTwo: "",
      addressThree: "",
      addressFour: "",
      country: "",
      postZip: "",
      mobile: "",
      telephone: "",
      emailAddress: "",
      singleEmailAddress: "",
      mainCurrency: "",
      payPalString: "",
      serviceUserNumber: "",
      chargeableName: "",
      publishableKey: "",
      secretKey: "",
      selectedDD: "",
      user: "",
      pass: "",
      ref: "",
      sellOverseas: true,
      allowFullBackIssue: false,
    });
    this.props.getAccount();
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.account.payPalDetails) {
      const payPalDetails = nextProps.account.payPalDetails;

      payPalDetails.payPalString = !isEmpty(payPalDetails.payPalString)
        ? payPalDetails.payPalString
        : "";
      if (payPalDetails !== this.state.payPalString) {
        this.setState({ payPalString: payPalDetails.payPalString });
      }
    }

    if (nextProps.account.account) {
      const account = nextProps.account.account;
      // Set component fields state

      // If field doesn't exist, make an empty string
      account.fullName = !isEmpty(account.accountDets.fullName)
        ? account.accountDets.fullName
        : "";
      account.companyName = !isEmpty(account.accountDets.companyName)
        ? account.accountDets.companyName
        : "";
      account.mainContact = !isEmpty(account.accountDets.mainContact)
        ? account.accountDets.mainContact
        : "";
      account.addressOne = !isEmpty(account.accountDets.addressOne)
        ? account.accountDets.addressOne
        : "";
      account.addressTwo = !isEmpty(account.accountDets.addressTwo)
        ? account.accountDets.addressTwo
        : "";
      account.addressThree = !isEmpty(account.accountDets.addressThree)
        ? account.accountDets.addressThree
        : "";
      account.addressFour = !isEmpty(account.accountDets.addressFour)
        ? account.accountDets.addressFour
        : "";
      account.country = !isEmpty(account.accountDets.country)
        ? account.accountDets.country
        : "";
      account.postZip = !isEmpty(account.accountDets.postZip)
        ? account.accountDets.postZip
        : "";
      account.mobile = !isEmpty(account.accountDets.mobile)
        ? account.accountDets.mobile
        : "";
      account.telephone = !isEmpty(account.accountDets.telephone)
        ? account.accountDets.telephone
        : "";
      account.emailAddress = !isEmpty(account.accountDets.emailAddress)
        ? account.accountDets.emailAddress
        : "";
      account.singleEmailAddress = !isEmpty(
        account.accountDets.singleEmailAddress
      )
        ? account.accountDets.singleEmailAddress
        : "";
      account.userImageName = !isEmpty(account.accountDets.userImageName)
        ? account.accountDets.userImageName
        : "";
      account.mainCurrency = !isEmpty(account.accountDets.mainCurrency)
        ? account.accountDets.mainCurrency
        : "";
      account.payPalString = !isEmpty(account.accountDets.payPalDets)
        ? account.accountDets.payPalDets.payPalString
        : "";
      account.serviceUserNumber = !isEmpty(account.accountDets.directDebitDets)
        ? account.accountDets.directDebitDets.serviceUserNumber
        : "";
      account.chargeableName = !isEmpty(account.accountDets.directDebitDets)
        ? account.accountDets.directDebitDets.chargeableName
        : "";
      account.selectedDD = !isEmpty(account.accountDets.directDebitDets)
        ? account.accountDets.directDebitDets.selectedDD
        : "";
      account.publishableKey = !isEmpty(account.accountDets.stripeDets)
        ? account.accountDets.stripeDets.publishableKey
        : "";
      account.secretKey = !isEmpty(account.accountDets.stripeDets)
        ? account.accountDets.stripeDets.secretKey
        : "";
      account.user = !isEmpty(account.accountDets.secureTradingDets)
        ? account.accountDets.secureTradingDets.user
        : "";
      account.pass = !isEmpty(account.accountDets.secureTradingDets)
        ? account.accountDets.secureTradingDets.pass
        : "";
      account.ref = !isEmpty(account.accountDets.secureTradingDets)
        ? account.accountDets.secureTradingDets.ref
        : "";
      account.sellOverseas = !isEmpty(account.accountDets.sellOverseas)
        ? account.accountDets.sellOverseas
        : true;
      account.allowFullBackIssue = !isEmpty(account.accountDets.allowFullBackIssue)
        ? account.accountDets.allowFullBackIssue
        : false;
      account.automatedSubscriptionRenewalEmails = !isEmpty(account.accountDets.automatedSubscriptionRenewalEmails)
        ? account.accountDets.automatedSubscriptionRenewalEmails
        : false;
      if (account.fullName !== this.state.fullName) {
        if (
          account.payPalString ||
          account.publishableKey ||
          account.serviceUserNumber ||
          account.user ||
          account.pass ||
          account.ref
        ) {
          this.setState({ showPayContent: true });
        }
        // if (account.secretKey || account.publishableKey) {
        //   this.setState({ selectedCC: "1" });
        // }
        console.log("selectedCC TBD")
        if (account.user || account.pass || account.ref) {
          console.log("selectedCC 2")
          this.setState({ selectedCC: "2" });
        }else{
          console.log("selectedCC 1")
          this.setState({ selectedCC: "1" });
        }
        if (account.selectedDD) {
          this.setState({ showDDContent: true });
        }
        // Set component fields state
        this.setState({
          userImageName: account.userImageName,
          fullName: account.fullName,
          mainContact: account.mainContact,
          companyName: account.companyName,
          addressOne: account.addressOne,
          addressTwo: account.addressTwo,
          addressThree: account.addressThree,
          addressFour: account.addressFour,
          country: account.country,
          postZip: account.postZip,
          mobile: account.mobile,
          telephone: account.telephone,
          emailAddress: account.emailAddress,
          singleEmailAddress: account.singleEmailAddress,
          mainCurrency: account.mainCurrency,
          payPalString: account.payPalString,
          serviceUserNumber: account.serviceUserNumber,
          chargeableName: account.chargeableName,
          publishableKey: account.publishableKey,
          secretKey: account.secretKey,
          selectedDD: account.selectedDD,
          user: account.user,
          pass: account.pass,
          ref: account.ref,
          sellOverseas: account.sellOverseas,
          allowFullBackIssue: account.allowFullBackIssue,
          automatedSubscriptionRenewalEmails: account.automatedSubscriptionRenewalEmails,
        });
      }
    }
  }

  handleChange = (e, { value }) => {
    this.setState({ userType: value });
  };

  handleCountryChange = (e, { value }) => {
    this.setState({ country: value });
  };

  handleDDChange = (e, { value }) => {
    this.setState({ selectedDD: value });
  };

  handleCCChange = (e, { value }) => {
    this.setState({ selectedCC: value });
  };

  handleCurrencyChange = (e, { value }) => {
    this.setState({ mainCurrency: value });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onPaymentsClick = (e) => {
    this.setState({ showPayContent: !this.state.showPayContent });
  };

  onSellOverseasClick = (e) => {
    this.setState({ sellOverseas: !this.state.sellOverseas });
  };

  onDDClick = (e) => {
    this.setState({ showDDContent: !this.state.showDDContent, selectedDD: 'email' });
  };
  onAllowFullBackIssueClick = () => {
    this.setState({ allowFullBackIssue: !this.state.allowFullBackIssue });
  }

  automatedSubscriptionRenewalEmailsClick = () => {
    this.setState({ automatedSubscriptionRenewalEmails: !this.state.automatedSubscriptionRenewalEmails });
  }

  onSubmit = (e) => {
    e.preventDefault();

    const accountData = {
      fullName: this.state.fullName,
      mainContact: this.state.mainContact,
      companyName: this.state.companyName,
      addressOne: this.state.addressOne,
      addressTwo: this.state.addressTwo,
      addressThree: this.state.addressThree,
      addressFour: this.state.addressFour,
      country: this.state.country,
      postZip: this.state.postZip,
      mobile: this.state.mobile,
      telephone: this.state.telephone,
      emailAddress: this.state.emailAddress,
      singleEmailAddress: this.state.singleEmailAddress,
      payPalString: this.state.payPalString,
      mainCurrency: this.state.mainCurrency,
      serviceUserNumber: this.state.serviceUserNumber,
      chargeableName: this.state.chargeableName,
      publishableKey: this.state.publishableKey,
      secretKey: this.state.secretKey,
      selectedDD: this.state.selectedDD,
      showDDContent: this.state.showDDContent,
      allowFullBackIssue: this.state.allowFullBackIssue,
      automatedSubscriptionRenewalEmails: this.state.automatedSubscriptionRenewalEmails,
      user: this.state.user,
      pass: this.state.pass,
      ref: this.state.ref,
      selectedCC: this.state.selectedCC,
      sellOverseas: this.state.sellOverseas,      
    };
    this.props.updateAccount(accountData, this.props.history);
  };

  render() {
    const currencyOptions = [
      {
        key: "GBP",
        text: "GBP",
        value: "GBP",
      },
      {
        key: "USD",
        text: "USD",
        value: "USD",
      },
      {
        key: "EUR",
        text: "EUR",
        value: "EUR",
      },
    ];

    const DDOptions = [
      {
        key: "email",
        text: "Receive instructions to your Main Contact Email address",
        value: "email",
      },
      {
        key: "other",
        text: "Use your current Direct Debit provider",
        value: "other",
      },
    ];

    const CCOptions = [
      {
        key: "1",
        text: "Stripe Payments",
        value: "1",
      },
      // {
      //   key: "2",
      //   text: "Secure Trading",
      //   value: "2",
      // },
    ];

    const { errors } = this.state;
    const { loading } = this.props.account;
    // Select options for status
    const countryOptions = getCountries();

    let accountContent;

    let dDContentToggle;
    let payContent;
    let dDContent;
    let ccContent;

    this.state.selectedDD === "email"
      ? (dDContentToggle = null)
      : (dDContentToggle = (
          <div style={{ padding: "10px" }}>
            <p>
              Please email{" "}
              <a href="mailto:support@magstand.com">support@magstand.com</a>{" "}
              with your Direct Debit provider and we will set up the API
              interface.
            </p>
          </div>
        ));

    this.state.selectedCC === "1"
      ? (ccContent = (
          <React.Fragment>
            <RenderFieldLeft
              name="publishableKey"
              value={this.state.publishableKey}
              onChange={this.onChangeHandler}
              placeholder="Publishable Key"
              label="Publishable Key"
              error={errors.publishableKey}
            />
            <RenderFieldLeft
              name="secretKey"
              value={this.state.secretKey}
              onChange={this.onChangeHandler}
              placeholder="Secret Key"
              label="Secret Key"
              error={errors.secretKey}
            />
          </React.Fragment>
        ))
      : this.state.selectedCC === "2"
      ? (ccContent = (
          <React.Fragment>
            <RenderFieldLeft
              name="user"
              value={this.state.user}
              onChange={this.onChangeHandler}
              placeholder="Username"
              label="Username"
              error={errors.user}
            />
            <RenderFieldLeft
              name="pass"
              value={this.state.pass}
              onChange={this.onChangeHandler}
              placeholder="Password"
              label="Password"
              error={errors.pass}
            />
            <RenderFieldLeft
              name="ref"
              value={this.state.ref}
              onChange={this.onChangeHandler}
              placeholder="Site Reference"
              label="Site Reference"
              error={errors.ref}
            />
          </React.Fragment>
        ))
      : (ccContent = null);

    this.state.showDDContent
      ? (dDContent = (
          <React.Fragment>
            <div style={{ padding: "10px" }}>
              <Form.Select
                options={DDOptions}
                label="How would you like to receive direct debit mandates?"
                onChange={this.handleDDChange}
                placeholder="How would you like to receive direct debit mandates?"
                fluid
                size="large"
                selection
                name="selectedDD"
                value={this.state.selectedDD}
                error={errors.DDOptions}
              />
            </div>
            {dDContentToggle}
            <RenderFieldLeft
              name="serviceUserNumber"
              value={this.state.serviceUserNumber}
              onChange={this.onChangeHandler}
              placeholder="Service User Number"
              label="Service User Number"
              error={errors.serviceUserNumber}
            />
            <RenderFieldLeft
              name="chargeableName"
              value={this.state.chargeableName}
              onChange={this.onChangeHandler}
              placeholder="Chargeable Name"
              label="Chargeable Name (shown on Bank Statement)"
              error={errors.chargeableName}
            />
          </React.Fragment>
        ))
      : (dDContent = null);

    this.state.showPayContent
      ? (payContent = (
          <React.Fragment>
            <Segment>
              <Header as="h2">
                Paypal (
                <a
                  href="https://developer.paypal.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Paypal App Creation
                </a>{" "}
                required to get the Live Client ID)
              </Header>
              <RenderFieldLeft
                name="payPalString"
                value={this.state.payPalString}
                onChange={this.onChangeHandler}
                placeholder="Paypal String"
                label="Paypal Client ID"
                error={errors.payPalString}
              />
            </Segment>
            <Segment>
              <Header as="h2">Credit Cards and Recurring Subscriptions</Header>
              {/* This select should be removed but keep it here for now */}
              <div style={{ padding: "10px", display: 'none'}}>
                <Form.Select
                  options={CCOptions}
                  label="Please enter your Stripe Keys"
                  onChange={this.handleCCChange}
                  placeholder="Please enter your Stripe Keys"
                  fluid
                  size="large"
                  selection
                  name="selectedCC"
                  value={this.state.selectedCC}
                  error={errors.CCOptions}
                />
              </div>
              <label style={{ fontWeight: 'bold'}}>Please enter your Stripe keys</label>
              {ccContent}
              <p>
                If you'd like us to add your Credit Card provider, please email
                us with details to{" "}
                <a href="mailto:support@magstand.com">support@magstand.com</a>
              </p>
            </Segment>
            <Segment>
              <Header as="h2">Direct Debit</Header>
              <Checkbox
                toggle
                onClick={this.onDDClick}
                checked={this.state.showDDContent}
                name="showDDContent"
                label="Allow Direct Debits?"
              />
              {/* {dDContent} */}
            </Segment>
          </React.Fragment>
        ))
      : (payContent = null);

    if (loading) {
      accountContent = <Spinner />;
    } else {
      accountContent = (
        <Form onSubmit={this.onSubmit}>
          <Segment>
            <Header as="h2" textalign="center">
              My Account
            </Header>
            <RenderFieldLeftAndRequired
              name="fullName"
              value={this.state.fullName}
              onChange={this.onChangeHandler}
              placeholder="Full Name"
              label="Full Name"
              error={errors.fullName}
            />
            <RenderFieldLeft
              name="companyName"
              value={this.state.companyName}
              onChange={this.onChangeHandler}
              placeholder="Company Name"
              label="Company Name"
              error={errors.companyName}
            />
            <RenderFieldLeft
              name="addressOne"
              value={this.state.addressOne}
              onChange={this.onChangeHandler}
              placeholder="Address Line 1"
              label="Address Line 1"
              error={errors.addressOne}
            />
            <RenderFieldLeft
              name="addressTwo"
              value={this.state.addressTwo}
              onChange={this.onChangeHandler}
              placeholder="Address Line 2"
              label="Address Line 2"
              error={errors.addressTwo}
            />
            <RenderFieldLeft
              name="addressThree"
              value={this.state.addressThree}
              onChange={this.onChangeHandler}
              placeholder="Address Line 3"
              label="Address Line 3"
              error={errors.addressThree}
            />
            <RenderFieldLeft
              name="addressFour"
              value={this.state.addressFour}
              onChange={this.onChangeHandler}
              placeholder="Address Line 4"
              label="Address Line 4"
              error={errors.addressFour}
            />
            <RenderFieldLeft
              name="postZip"
              value={this.state.postZip}
              onChange={this.onChangeHandler}
              placeholder="Post/Zip Code"
              label="Post/Zip Code"
              error={errors.postZip}
            />
            <div style={{ padding: "10px" }}>
              <Form.Select
                options={countryOptions}
                label="Country"
                onChange={this.handleCountryChange}
                placeholder="Country"
                fluid
                size="large"
                selection
                name="country"
                value={this.state.country}
                error={errors.country}
              />
            </div>
            <RenderFieldLeft
              name="mainContact"
              value={this.state.mainContact}
              onChange={this.onChangeHandler}
              placeholder="Company Main Contact"
              label="Company Main Contact"
              error={errors.mainContact}
            />
            <RenderFieldLeft
              name="emailAddress"
              type="email"
              value={this.state.emailAddress}
              onChange={this.onChangeHandler}
              placeholder="Main Contact Email"
              label="Main Contact Email"
              error={errors.emailAddress}
            />
            <RenderFieldLeft
              name="singleEmailAddress"
              type="email"
              value={this.state.singleEmailAddress}
              onChange={this.onChangeHandler}
              placeholder="Single Issue Purchase receipts to be sent to:"
              label="Single Issue Purchase receipts to be sent to:"
              error={errors.singleEmailAddress}
            />
            <RenderFieldLeft
              name="telephone"
              value={this.state.telephone}
              onChange={this.onChangeHandler}
              placeholder="Main Contact Telephone"
              label="Main Contact Telephone"
              error={errors.telephone}
            />
            <RenderFieldLeft
              name="mobile"
              value={this.state.mobile}
              onChange={this.onChangeHandler}
              placeholder="Main Contact Mobile"
              label="Main Contact Mobile"
              error={errors.mobile}
            />
            {this.props.auth.user.userType === "publisher" ? (
              <Segment>
                <div style={{ padding: "10px" }}>
                  <Checkbox
                    toggle
                    onClick={this.onPaymentsClick}
                    checked={this.state.showPayContent}
                    name="showPayContent"
                    label="Are you providing Paid For Content?"
                  />
                  {payContent}
                </div>
              </Segment>
            ) : null}
            {this.props.auth.user.userType === "publisher" ? (
              <Segment>
                <div style={{ padding: "10px" }}>
                  <Checkbox
                    toggle
                    onClick={this.onSellOverseasClick}
                    checked={this.state.sellOverseas}
                    name="sellOverseas"
                    label="Do you sell Copies Overseas?"
                  />
                </div>
              </Segment>
            ) : null}
            {this.props.auth.user.userType === "publisher" ? (
              <div style={{ padding: "10px" }}>
                <Form.Select
                  options={currencyOptions}
                  label="Main Currency"
                  onChange={this.handleCurrencyChange}
                  placeholder="Main Currency"
                  fluid
                  size="large"
                  selection
                  name="mainCurrency"
                  value={this.state.mainCurrency}
                  error={errors.mainCurrency}
                />
              </div>
            ) : null}
            <Segment>
              <Header as="h2">Flickread Integration</Header>
              <Checkbox
                toggle
                onClick={this.onAllowFullBackIssueClick}
                checked={this.state.allowFullBackIssue}
                name="allowFullBackIssue"
                label="Allow full back issue access during subscription?"
              />
            </Segment>
            <Segment>
              <Header as="h2">Automated Subscription Renewal Emails</Header>
              <Checkbox
                toggle
                onClick={this.automatedSubscriptionRenewalEmailsClick}
                checked={this.state.automatedSubscriptionRenewalEmails}
                name="automatedSubscriptionRenewalEmails"
                label="Allow sending automated emails for subscription renewal?"
              />
            </Segment>
            <div style={{ padding: "10px" }}>
              <Button color="blue" type="submit" fluid>
                Update Account
              </Button>
            </div>
          </Segment>
        </Form>
      );
    }
    return <div>{accountContent}</div>;
  }
}

Account.propTypes = {
  getPayPal: PropTypes.func.isRequired,
  getAccount: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors,
  account: state.account,
});

export default connect(mapStateToProps, {
  getAccount,
  updateAccount,
  getPayPal,
})(withRouter(Account));
