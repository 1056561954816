import React from 'react';
import { Grid, Header, Segment, Icon, Step, Image, Modal, Button } from 'semantic-ui-react';
import axios from 'axios';
import CompanyInfo from './Steps/CompanyInfo';
import PlanType from './Steps/PlanType';
import PaymentInfo from './Steps/PaymentInfo';
import BillingInfo from './Steps/BillingInfo';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerUser, dispatchErrors } from '../../actions/authActions';
import LoadingOverlay from 'react-loading-overlay';
//import Logo from '../../assets/img/ms200blk.png';
import Logo from '../../assets/img/ms200blkNew.png';
import './Signup.css';


// See: https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined

const ModalProcessingFailed = (props) => {
  return <Modal
  open={props.open}
  onClose={() => props.onClose({ modalProcessingFailed: false})}
  basic
  size='small' className="modal-processing-failed">
  <Header content='Payment' />
  <Modal.Content>
    Sorry your payment has been declined. Please try with another payment method
  </Modal.Content>
  <Modal.Actions>
    <Button color='black' onClick={() => props.onClose({ modalProcessingFailed: false})} inverted>
      Close
    </Button>
  </Modal.Actions>
  </Modal>
}

class Signup extends React.Component {
  state = {
    currentStep: 1,
    isLoading: false,
    modalProcessingFailed: false
  }

  nextStep = () => {
    this.setState({ currentStep: this.state.currentStep + 1 })
  }

  prevStep = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }

  onTriggerLoading = () => {
    this.setState({ isLoading: true })
  }

  onSubmit = async (formValues) => {
  }

  renderStep = () => {
    switch (this.state.currentStep) {
      case 1:
        return <CompanyInfo onSubmit={this.nextStep} />
      case 2:
        return <BillingInfo previousPage={this.prevStep}
          onSubmit={this.nextStep} />
      case 3:
        return <PlanType previousPage={this.prevStep}
          onSubmit={this.nextStep} />
      case 4:
        return <PaymentInfo previousPage={this.prevStep}
          onSubmit={this.onSubmit} onTriggerLoading={this.onTriggerLoading} />
      default:
        return <div>Step1</div>
    }
  }

  render() {
    return (
      <LoadingOverlay      
      active={this.state.isLoading}
      spinner
      text='Processing your request...'
      >
      <div style={{ display: 'flex', height: '100vh', justifyContent: 'center'}}>
        <div className='login-form'>
          {/* <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        } body { background: #2185d0; }
      `}
          </style> */}

          <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {          
        } body { background: #2185d0; }
      `}
          </style>

          <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 1000 }}>

              <Segment>
                <Header as='h2' color='black' textAlign='center'>
                  <Image src={Logo} alt='Magstand Logo' style={{ width: "150px" }} />
                </Header>
                <Header as='h3' color='black' textAlign='center'>Sign Up</Header>

                <Step.Group>
                  <Step active={this.state.currentStep === 1}>
                    <Icon name='user' />
                    <Step.Content>
                      <Step.Title>Company</Step.Title>
                      <Step.Description>Company Information</Step.Description>
                    </Step.Content>
                  </Step>
                  <Step active={this.state.currentStep === 2}>
                    <Icon name='address book' />
                    <Step.Content>
                      <Step.Title>Billing</Step.Title>
                      <Step.Description>Billing Information</Step.Description>
                    </Step.Content>
                  </Step>
                  <Step active={this.state.currentStep === 3}>
                    <Icon name='add to cart' />
                    <Step.Content>
                      <Step.Title>Plan Type</Step.Title>
                      <Step.Description>Choose your plan</Step.Description>
                    </Step.Content>
                  </Step>
                  <Step active={this.state.currentStep === 4}>
                    <Icon name='payment' />
                    <Step.Content>
                      <Step.Title>Confirm and Pay</Step.Title>
                      <Step.Description>Confirm your account</Step.Description>
                    </Step.Content>
                  </Step>
                </Step.Group>
                <Segment attached>
                  {this.renderStep()}
                </Segment>
              </Segment>
            </Grid.Column>

          </Grid>
          <ModalProcessingFailed 
            open={this.state.modalProcessingFailed} 
            onClose={() => this.setState({ modalProcessingFailed: false})} />          
        </div>

      </div>


      </LoadingOverlay>      
    )
  }
}

Signup.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { registerUser })(withRouter(Signup));