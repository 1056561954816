// Main Add Publication Component

import React, { Fragment } from 'react';
import { Header, Segment, Icon, Step } from 'semantic-ui-react';
import PublicationInfo from './Steps/PublicationInfo';
import PublicationImages from './Steps/PublicationImages';
import PricingInfo from './Steps/PricingInfo';
import Questions from './Steps/Questions';
import AppLinks from './Steps/AppLinks';
import SocialLinks from './Steps/SocialLinks';
import EmailDetails from './Steps/EmailDetails';
import UserKeyDetails from './Steps/UserKeyDetails';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkNumPubs, addPublication } from '../../../actions/publicationActions';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';

import Spinner from '../../common/Spinner';


class NewPublication extends React.Component {
  state = {
    currentStep: 1
  }

  // Check to ensure the Publisher hasn't met their Publication quota
  componentDidMount() {
    if (this.props.auth.user.userType === "publisher" || this.props.auth.user.userType === 'pubadmin') {
      this.props.checkNumPubs(this.props.auth.user);
    } else {
      window.location.href = "/readers"
    }

  }

  componentWillReceiveProps() {
    isUserLoggedIn();
  }

  nextStep = () => {
    this.setState({ currentStep: this.state.currentStep + 1 })
  }

  prevStep = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }

  onSubmit = (formValues) => {
    // Save all the data in MongoDB
    return this.props.addPublication(formValues, this.props.history);
  }

  renderStep = () => {
    switch (this.state.currentStep) {
      case 1:
        return <PublicationInfo onSubmit={this.nextStep} />
      case 2:
        return <PublicationImages previousPage={this.prevStep}
          onSubmit={this.nextStep} />
      case 3:
        return <PricingInfo previousPage={this.prevStep}
          onSubmit={this.nextStep} />
      case 4:
        return <Questions previousPage={this.prevStep}
          onSubmit={this.nextStep} />
      case 5:
        return <AppLinks previousPage={this.prevStep}
          onSubmit={this.nextStep} />
      case 6:
        return <SocialLinks previousPage={this.prevStep}
          onSubmit={this.nextStep} />
      case 7:
        return <UserKeyDetails previousPage={this.prevStep}
          onSubmit={this.onSubmit} />
      default:
        return <div>Step1</div>
    }
  }

  render() {
    const numPubs = this.props.numPubs;
    const numberOfPublications = this.props.numberOfPublications;
    const loading = this.props.loading;
    let formItems;
    if (loading) {
      formItems = <Spinner />;
    } else {
      if (numPubs === numberOfPublications) {
        formItems = (
          <Segment>
            <Header>Publication Quota Met</Header>
            <p>You have met your quota for your MagStand Subscription.<br /><br />If you'd like to add more please email <a href="mailto:support@magstand.com">support@magstand.com</a></p>
          </Segment>
        )
      } else {
        formItems = (
          <Segment>
            <Header as='h3' color='black' textAlign='center'>Add Publication</Header>
            <Step.Group fluid stackable='tablet' size='mini'>
              <Step active={this.state.currentStep === 1}>
                <Icon name='info' color='blue' />
              </Step>
              <Step active={this.state.currentStep === 2}>
                <Icon name='images' color='blue' />
              </Step>
              <Step active={this.state.currentStep === 3}>
                <Icon name='currency' color='blue' />
              </Step>
              <Step active={this.state.currentStep === 4}>
                <Icon name='question circle' color='blue' />
              </Step>
              <Step active={this.state.currentStep === 5}>
                <Icon name='mobile alternate' color='blue' />
              </Step>
              <Step active={this.state.currentStep === 6}>
                <Icon name='facebook official' color='blue' />
              </Step>
              <Step active={this.state.currentStep === 7}>
                <Icon name='linkify' color="blue" />
              </Step>
            </Step.Group>
            <Segment attached>
              {this.renderStep()}
            </Segment>
          </Segment>
        )
      }
      return (
        <Fragment>
          {formItems}
        </Fragment>
      )
    }
  }
}

NewPublication.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  numPubs: state.publication.numPubs,
  numberOfPublications: state.auth.user.numberOfPublications,
})

export default connect(mapStateToProps, { checkNumPubs, addPublication })(withRouter(NewPublication));