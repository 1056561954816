// Description
//
// Constant Types for Redux Actions

// Generic
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING = "LOADING";
export const FINISHED_LOADING = "FINISHED_LOADING";
export const RESET_STATE = "RESET_STATE";
export const SHOW_MODAL = "SHOW_MODAL";

// User Action Types
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// My Account Action Types
export const GET_ACCOUNT = "GET_ACCOUNT";
export const ACCOUNT_LOADING = "ACCOUNT_LOADING";
export const CLEAR_CURRENT_ACCOUNT = "CLEAR_CURRENT_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_AVATAR = "UPDATE_AVATAR";
export const GET_PAYPAL = "GET_PAYPAL";
export const GET_ACCOUNT_TEL = "GET_ACCOUNT_TEL";

// Order Action Types
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ST_ORDER = "CREATE_ST_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const GET_TOTAL_ORDER_AMOUNT = "GET_TOTAL_ORDER_AMOUNT";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDER = "GET_ORDER";
export const CREATE_BACKISSUE_ORDER = "CREATE_BACKISSUE_ORDER";

// User Action Types
export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USER = "GET_USER";
export const DELETE_USER = "DELETE_USER";
export const DEACTIVATE_USER = "DEACTIVATE_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_PUBLISHERS = "GET_PUBLISHERS";
export const ADD_PUBLISHER = "ADD_PUBLISHER";

// Publication Action Types
export const GET_PUBLICATION = "GET_PUBLICATION";
export const GET_PUBLICATIONS_WITH_BACKISSUE =
  "GET_PUBLICATIONS_WITH_BACKISSUE";
export const GET_PUBLICATION_BY_URLKEY = "GET_PUBLICATION_BY_URLKEY";
export const GET_PUBLICATIONS = "GET_PUBLICATIONS";
export const PUBLICATIONS_LOADING = "PUBLICATIONS_LOADING";
export const ADD_PUBLICATION = "ADD_PUBLICATION";
export const UPDATE_PUBLICATION = "UPDATE_PUBLICATION";
export const UPDATE_PUBLICATION_IMAGE = "UPDATE_PUBLICATION_IMAGE";
export const CHECK_NUM_PUBS = "CHECK_NUM_PUBS";
export const GET_PROMOS = "GET_PROMOS";
export const GET_PROMO = "GET_PROMO";
export const ADD_PROMO = "ADD_PROMO";
export const UPDATE_PROMO = "UPDATE_PROMO";
export const DELETE_PROMO = "DELETE_PROMO";
export const GET_PAYPAL_DETAILS = "GET_PAYPAL_DETAILS";
export const UPDATE_PAGEVIEWS = "UPDATE_PAGEVIEWS";
export const UPDATE_RECEIPT_TEXT = "UPDATE_RECEIPT_TEXT";
export const UPDATE_RENEWAL_TEXT = "UPDATE_RENEWAL_TEXT";
export const CLEAR_PUB_DATA = "CLEAR_PUB_DATA";
export const DEACTIVATE_PUBLICATION = "DEACTIVATE_PUBLICATION";

// Reader Action Types
export const ADD_READER = "ADD_READER";
export const UPDATE_READER = "UPDATE_READER";
export const GET_READER = "GET_READER";
export const GET_ALL_DATA = "GET_ALL_DATA";
export const REMOVE_READER = "REMOVE_READER";
export const REACTIVATE_READER = "REACTIVATE_READER";
export const GET_READER_DIG_SUBS = "GET_READER_DIG_SUBS";
export const GET_READER_PRINT_SUBS = "GET_READER_PRINT_SUBS";
export const SEARCH_READERS = "SEARCH_READERS";
export const UPLOAD_ADDITIONAL = "UPLOAD_ADDITIONAL";
export const UPLOAD_REPLACEMENT = "UPLOAD_REPLACEMENT";
export const UPLOAD_ADD_AND_COMBINE = "UPLOAD_ADD_AND_COMBINE";
export const GET_TOTAL_READERS = "GET_TOTAL_READERS";
export const MAILER_DATA = "MAILER_DATA";
export const GET_NOFULLNAMECOUNT = "GET_NOFULLNAMECOUNT";
export const GET_NOFULLNAME = "GET_NOFULLNAME";
export const GET_NOFIRSTNAME = "GET_NOFIRSTNAME";
export const GET_NOPOSTCODECOUNT = "GET_NOPOSTCODECOUNT";
export const GET_NOPOSTCODE = "GET_NOPOSTCODE";
export const GET_EXPIRED_COUNT = "GET_EXPIRED_COUNT";
export const GET_EXPIRED = "GET_EXPIRED";
export const GET_EXPIRED_NEXT_THIRTY = "GET_EXPIRED_NEXT_THIRTY";
export const GET_EXPIRED_NEXT_THIRTY_COUNT = "GET_EXPIRED_NEXT_THIRTY_COUNT";
export const GET_EXPIRED_LAST_THIRTY_COUNT = "GET_EXPIRED_LAST_THIRTY_COUNT";
export const AUTO_FIX_FULLNAME = "AUTO_FIX_FULLNAME";
export const GET_NOFIRSTNAME_COUNT = "GET_NOFIRSTNAME_COUNT";
export const AUTO_FIX_FIRSTNAME = "AUTO_FIX_FIRSTNAME";
export const GET_DUPLICATE_FULLNAME_COUNT = "GET_DUPLICATE_FULLNAME_COUNT";
export const GET_DUPLICATE_FULLNAME = "GET_DUPLICATE_FULLNAME";
export const GET_DUPLICATE_LASTNAME_COUNT = "GET_DUPLICATE_LASTNAME_COUNT";
export const GET_DUPLICATE_LASTNAME = "GET_DUPLICATE_LASTNAME";
export const GET_DUPLICATE_POSTCODE_COUNT = "GET_DUPLICATE_POSTCODE_COUNT";
export const GET_DUPLICATE_POSTCODE = "GET_DUPLICATE_POSTCODE";
export const GET_DUPLICATE_ADDRESS_COUNT = "GET_DUPLICATE_ADDRESS_COUNT";
export const GET_DUPLICATE_EMAIL_COUNT = "GET_DUPLICATE_EMAIL_COUNT";
export const GET_DUPLICATE_EMAILS = "GET_DUPLICATE_EMAILS";
export const GET_DUPLICATE_ADDRESS = "GET_DUPLICATE_ADDRESS";
export const DELETE_READER_FULLNAME = "DELETE_READER_FULLNAME";
export const DELETE_READER_LASTNAME = "DELETE_READER_LASTNAME";
export const DELETE_READER_POSTCODE = "DELETE_READER_POSTCODE";
export const DELETE_READER_ADDRESS = "DELETE_READER_ADDRESS";
export const DELETE_READER = "DELETE_READER";
export const DELETE_READER_EMAIL = "DELETE_READER_EMAIL";
export const CLEAR_DATA = "CLEAR_DATA";
export const UPLOAD_POSTCODES = "UPLOAD_POSTCODES";
export const BAD_POSTCODE_COUNT = "BAD_POSTCODE_COUNT";
export const BAD_POSTCODE = "BAD_POSTCODE";
export const GET_BAD_ADDRESS_DATA_COUNT = "GET_BAD_ADDRESS_DATA_COUNT";
export const GET_BAD_ADDRESS_DATA = "GET_BAD_ADDRESS_DATA";
export const AUTO_FIX_ADDRESS = "AUTO_FIX_ADDRESS";

// Analytic Types

export const GET_NEW_SUBS = "GET_NEW_SUBS";
export const GET_NEW_SUBS_PRINT = "GET_NEW_SUBS_PRINT";
export const GET_NEW_SUBS_DIGITAL = "GET_NEW_SUBS_DIGITAL";
export const GET_NEW_SUBS_BUNDLE = "GET_NEW_SUBS_BUNDLE";
export const GET_NEW_ORDERS = "GET_NEW_ORDERS";
export const GET_ALL_SUBS = "GET_ALL_SUBS";
export const GET_ALL_SUBS_PRINT = "GET_ALL_SUBS_PRINT";
export const GET_ALL_SUBS_DIGITAL = "GET_ALL_SUBS_DIGITAL";
export const GET_ALL_SUBS_BUNDLE = "GET_ALL_SUBS_BUNDLE";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_TOTAL_PAGE_VIEWS = "GET_TOTAL_PAGE_VIEWS";
export const GET_NEW_PAGE_VIEWS = "GET_NEW_PAGE_VIEWS";
export const GET_NEW_UNIQUE_PAGE_VIEWS = "GET_NEW_UNIQUE_PAGE_VIEWS";
export const GET_UNIQUE_PAGE_VIEWS = "GET_UNIQUE_PAGE_VIEWS";
export const GET_YEARLY_GRAPH_DATA = "GET_YEARLY_GRAPH_DATA";

// Back Issues
export const GET_BACKISSUES = "GET_BACKISSUES";
export const GET_BACKISSUE = "GET_BACKISSUE";
export const GET_BACKISSUES_BY_URL = "GET_BACKISSUES_BY_URL";
export const CREATE_BACKISSUE = "CREATE_BACKISSUE";
export const UPDATE_BACKISSUE = "UPDATE_BACKISSUE";
export const REMOVE_BACKISSUE = "REMOVE_BACKISSUE";
