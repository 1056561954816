import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getPublishers, getAllUsers } from '../../actions/userActions';
import UserItem from './components/UserItem';
import { CardGroup, Button } from 'semantic-ui-react';
import Spinner from '../common/Spinner';
import { isUserLoggedIn } from '../common/isUserLoggedIn';

class Users extends Component {
  componentDidMount() {
    isUserLoggedIn();
    if (this.props.auth.user.userType === "superuser") {
      this.props.getAllUsers();
    } else if (this.props.auth.user.userType === "reseller") {
      this.props.getPublishers(this.props.auth.user.id);
    } else {
      window.location.href = "/readers"
    }

  }


  render() {
    // TODO: Change this to props and a let with a spinner
    let userItems;
    const { users } = this.props.users;

    if (users === null) {
      userItems = <Spinner />;
    } else {
      if (users.length > 0) {
        userItems = users.map(user => (
          <UserItem key={user._id} user={user} />
        ))
      }
    };

    return (
      <React.Fragment>
        <Link to="/addpublisher">
          <Button circular icon="plus circle" size="massive" color="blue" floated="right" />
        </Link>
        <CardGroup itemsPerRow={8}>
          {userItems}
        </CardGroup>
      </React.Fragment>
    )
  }
}

Users.propTypes = {
  getPublishers: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  users: state.users,
  auth: state.auth
})

export default connect(mapStateToProps, { getPublishers, getAllUsers })(Users);
