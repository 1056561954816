import React, { Component } from "react";
import { Card, Image, Button, Reveal } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { removeBackIssue } from "../../../actions/backIssueActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";

class BackIssueItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backIssue: this.props.backIssue,
      selectedFile: "",
    };
  }

  componentDidMount() {
    this.setState({ backIssue: this.props.backIssue });
  }

  fileSelectedHandler = (e) => {
    this.setState({
      selectedFile: e.target.files[0].name,
    });
    const fd = new FormData();
    fd.append("image", e.target.files[0], e.target.files[0].name);
    axios
      .post(`/api/backIssue/coverImg/${this.state.backIssue._id}`, fd)
      .then((res) => {
        toastr.success("Back Issue Image updated");
        this.setState({ backIssue: res.data });
      })
      .catch((err) => console.log(err.response.data));
  };

  onDeleteClick = (id) => {
    this.props.removeBackIssue(id);
  };

  render() {
    return (
      <Card>
        <Reveal
          style={{ margin: "10px", padding: "0px" }}
          animated="move"
          key={this.state.backIssue.volume}
        >
          <Reveal.Content visible>
            <div style={{ height: "360px", backgroundColor: "#fff" }}>
              <Image
                src={this.state.backIssue.coverImg}
                style={{ margin: "0px" }}
                size="massive"
              />
            </div>
          </Reveal.Content>
          <Reveal.Content hidden>
            <Card.Content>
              <Card.Description
                style={{
                  height: "360px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <input
                  type="file"
                  hidden
                  name="myfile"
                  id={this.state.backIssue._id}
                  onChange={this.fileSelectedHandler}
                />
                <Button
                  fluid
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    document.getElementById(this.state.backIssue._id).click();
                  }}
                >
                  Change Cover Image
                </Button>

                <Link to={`/editbackIssue/${this.state.backIssue._id}`}>
                  <Button fluid style={{ marginBottom: "10px" }}>
                    Edit Details
                  </Button>
                </Link>

                <Button
                  type="button"
                  onClick={(e) =>
                    window.confirm(
                      "Are you sure you want to remove this back issue?"
                    ) && this.onDeleteClick(this.state.backIssue._id)
                  }
                  fluid
                  style={{ marginBottom: "10px" }}
                >
                  Remove Back Issue
                </Button>
              </Card.Description>
            </Card.Content>
          </Reveal.Content>
        </Reveal>
      </Card>
    );
  }
}

BackIssueItem.propTypes = {
  removeBackIssue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { removeBackIssue })(BackIssueItem);
