// Colour Picker Component (react-color npm)

import React, { PureComponent } from 'react'
import { SketchPicker } from 'react-color'
import { Label } from 'semantic-ui-react';

class ColorPicker extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showPicker: false,
      buttonText: "Open Colour Picker",
      color: '#666233'
    }
  }

  // shows or hides the colour picker
  toggleColor = (e) => {
    e.preventDefault()

    this.setState({
      showPicker: !this.state.showPicker,
      buttonText: !this.state.showPicker ? "Close Colour Picker" : "Open Colour Picker"
    })
  }

  // sets the color in the state object
  handleChangeComplete = (color, event) => {
    const { onColorChange } = this.props
    this.setState({ color: color.hex })
    onColorChange(color.hex)
  }

  render() {
    return (
      <span className="color-picker__container">
        <button onClick={this.toggleColor}>
          <Label style={{ lineHeight: "22!important", color: "#000" }}>{this.state.buttonText}</Label>
        </button>
        {
          this.state.showPicker && <div className="color-picker__picker">
            <SketchPicker color={this.state.color}
              onChangeComplete={this.handleChangeComplete}
              disableAlpha={true} width={250} />
          </div>
        }
      </span >
    )
  }
}

export default ColorPicker