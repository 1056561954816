// Renders a Redux Form Input Field with label to the right

import React from 'react';
import { Input } from 'semantic-ui-react';

const RenderFieldRedux = ({ input, label, disabled, icon, type, meta: { touched, error }, autocomplete, onpaste }) => (
  <div>
    <div style={{ padding: "10px" }}>
      <Input fluid {...input} size='large' label={label} disabled={disabled} autoComplete={autocomplete} onPaste={onpaste} labelPosition='right' type={type} error={touched && error ? true : false} />
      {touched && error && <div className="ui pointing red basic label">{error}</div>}
    </div>
  </div>
)
export default RenderFieldRedux;