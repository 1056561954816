import React from 'react'
import axios from 'axios'
import SmartDataTable from 'react-smart-data-table'
import Spinner from './Spinner';

const sematicUI = {
  segment: 'ui basic segment',
  message: 'ui message',
  input: 'ui icon input',
  searchIcon: 'search icon',
  rowsIcon: 'numbered list icon',
  table: 'ui compact selectable table',
  select: 'ui dropdown',
  refresh: 'ui labeled primary icon button',
  refreshIcon: 'sync alternate icon',
  change: 'ui labeled secondary icon button',
  changeIcon: 'exchange icon',
  checkbox: 'ui toggle checkbox',
  loader: 'ui active text loader',
  deleteIcon: 'trash red icon',
}

class AddressDataTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      useApi: true,
      apiData: this.props.data,
      apiIdx: -1,
      numResults: this.props.data.length,
      data: this.props.data,
      headers: this.props.headers,
      filterValue: '',
      perPage: 10,
      apiURL: this.props.apiURL
    }

    this.setApiData = this.setApiData.bind(this)
    this.changeData = this.changeData.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnPerPage = this.handleOnPerPage.bind(this)
  }

  componentDidMount() {

    //this.setApiData()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data, numResults: nextProps.data.length })
  }

  setApiData() {

    axios.get(this.state.apiURL)
      .then((response) => {
        console.log('response', response.data)
        this.setState({
          data: response.data,
          numResults: response.data.length
        })
      })
      .catch((error) => {
        // console.log(error);
      });
  }





  handleOnChange({ target: { name, value } }) {
    this.setState({ [name]: value }, () => {
      if (name === 'numResults') this.setNewData()
    })
  }

  handleOnPerPage({ target: { name, value } }) {
    this.setState({ [name]: parseInt(value, 10) })
  }

  changeData() {
    const { useApi } = this.state
    this.setState({
      useApi: !useApi,
      filterValue: '',
      perPage: 0,
    })
  }

  render() {
    const {
      headers, data, filterValue, perPage, numResults
    } = this.state
    const divider = <span style={{ display: 'inline-block', margin: '10px' }} />

    let dataToShow = '';
    !this.state.data ? dataToShow = (<Spinner />) : dataToShow = (<>
      <div className={sematicUI.segment}>
        <div className={sematicUI.input}>
          <input
            type='text'
            name='filterValue'
            value={filterValue}
            placeholder='Filter results...'
            onChange={this.handleOnChange}
          />
          <i className={sematicUI.searchIcon} />
        </div>
        {divider}
        <select
          name='perPage'
          value={perPage}
          className={sematicUI.select}
          onChange={this.handleOnPerPage}
        >
          <option value='10'>
            10
            </option>
          <option value='25'>
            25
            </option>
          <option value='50'>
            50
            </option>
          <option value='100'>
            100
            </option>
        </select>

      </div>
      <div className={sematicUI.message}>
        Total Results: {numResults}
      </div>
      <SmartDataTable
        data={data}
        dataKey=''
        headers={headers}
        name='duplicate-table'
        className={sematicUI.table}
        filterValue={filterValue}
        perPage={perPage}
        sortable
        withLinks
        withHeader
        loader={(
          <div className={sematicUI.loader}>
            Loading...
            </div>
        )}

        emptyTable={(
          <Spinner />
        )}
      />
    </>
    )
    return (
      <>
        {dataToShow}
      </>
    )
  }
}

export default AddressDataTable;