import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  componentDidMount() {
    document.title = "Magstand - Privacy Policy";
  }
  render() {
    return (
      <div>
        <div style={{ padding: 25 }}>
          <div>
            <h1>Privacy Policy</h1>Magstand.com (the “data controller”) takes
            the privacy of your personal information very seriously and is
            committed to safeguarding your privacy and your personal
            information, complying with the Data Protection Act 2018 , the
            General Data Protection Regulation (GDPR) and the Privacy and
            Electronic Communications Regulations 2003. This Privacy Policy
            relates to the use of any personal information we collect from you
            directly, or indirectly via 3rd party data providers, covering
            individuals who:
            <br />
            <br />• visit our main website (magstand.com) or any of our network
            of websites use our mobile applications, products and services;
          </div>
          <div>
            <br />
          </div>
          <div>
            • contact us concerning our products and services; are a reader of
            publications we host; attend or register to attend any events
            (including online events) or other events in which Magstand.com
            participates; complete surveys Magstand.com has issued or
            commissioned;
            <br />
            <br />• complete competition entries Magstand.com has issued or
            commissioned; through telephone research of your organisation and
            your role in the organisation;&nbsp;
          </div>
          <div>
            <br />
          </div>
          <div>
            • through research of public resources to identify organisations and
            your role in the organisation;&nbsp;
          </div>
          <div>
            <br />
          </div>
          <div>
            • are provided to us as part of 3rd party data for inclusion in our
            future marketing campaigns and where consent has been provided for
            3rd party marketing campaigns;&nbsp;
          </div>
          <div>
            <br />
          </div>
          <div>• supply products or services to Magstand.com ;&nbsp;</div>
          <div>
            <br />
          </div>
          <div>
            • have applied to work for Magstand.com , or are current or former
            employees of Magstand.com ;&nbsp;
          </div>
          <div>
            <br />
          </div>
          <div>• or are a customer of our business&nbsp;</div>
          <p>
            <br />
          </p>
          <div>
            <br />
          </div>
          <div>
            <strong>This Privacy Policy will explain the following: </strong>
          </div>
          <div>
            <br />
          </div>
          <div>
            • what personal information may be collected about you; <br />
            <br />
          </div>
          <div>
            • how Magstand.com will use this personal information; <br />
            <br />
          </div>
          <div>
            • when Magstand.com may use this personal information to contact
            you; <br />
            <br />
          </div>
          <div>
            • how long will Magstand.com retain your personal information;{" "}
            <br />
            <br />
          </div>
          <div>
            • how this personal information may be shared with other parties;{" "}
            <br />
            <br />
          </div>
          <div>
            • how your personal information will be kept secure; <br />
            <br />
          </div>
          <div>
            • your rights regarding the personal information you provide to us;{" "}
            <br />
            <br />
          </div>
          <div>
            • the use of cookies on the Magstand.com websites; <br />
            <br />
          </div>
          <div>• how to contact us.&nbsp;</div>
          <div>
            <br />
          </div>
          <div>
            In this policy, personal information means information relating to
            an identified or identifiable natural person. An identifiable person
            is one who can be identified, directly or indirectly, in particular
            by reference to an identifier such as a name, an identification
            number, location data, and online identifier or to one or more
            factors specific to his/her physical, physiological, genetic,
            mental, economic, cultural or social identity.&nbsp;
          </div>
          <div>
            <br />
          </div>
          <div>
            Magstand.com encourages you to review the privacy statements of
            websites you choose to link to from Magstand.com so that you can
            understand how those websites collect, use and share your
            information. Magstand.com is not responsible for the privacy
            statements or other content on websites outside of the Magstand and
            Magstand.com family of websites.
          </div>
          <div>
            <br />
          </div>
          <div>
            <p>
              <strong>Users of our products and services</strong>
            </p>
            <p>
              Magstand collects personal information from you when you use our
              products or services, including name, email address, postal
              address, telephone number, and job title.
              <br />
              <br />
            </p>
            <p>
              <strong>We will use this personal information:</strong>
            </p>
            <ul type="disc">
              <li>
                To carry out obligations arising from being a registered user of
                our products and services. The lawful basis for processing your
                personal information for this purpose will be “Contractual
                Need”.
                <br />
                <br />
              </li>
              <li>
                To send you personalised communications which you have requested
                or may be of interest to you. These may include information
                about publications, webinars, events, surveys, product and
                service developments, promotions of our associated brands’
                products and services. The lawful basis for processing your
                personal information for this purpose will be “Legitimate
                Interest”.
                <br />
                <br />
              </li>
              <li>
                Magstand.com uses automated decision making and profiling to
                personalise and ensure the communications are relevant to you,
                with no significant effect on you or your rights. The lawful
                basis for processing your personal information for this purpose
                will be “Legitimate Interest”.
                <br />
                <br />
              </li>
              <li>
                This personal information may be made available to 3
                <sup>rd</sup>
                party recipients. To provide this information to others for
                direct marketing of their product or services by inclusion in
                Magstand.com's commercial dataset. The lawful basis for
                processing your personal information for this purpose will be
                “Legitimate Interest”.
                <br />
                <br />
              </li>
            </ul>
            <p>
              <strong>How will my personal information be shared?</strong>
            </p>
            <p>
              We share information, including personal information, with trusted
              third-party service providers, that we use to provide hosting for
              and maintenance of our websites, mobile applications, event
              management, customer relationship management, payment processing,
              support, accounts, analytics and other services for us. We do not
              permit our third-party service providers to use the personal
              information that we share with them for their marketing purposes
              or for any other purpose than in connection with the services they
              provide to us.
            </p>
            <p>
              To facilitate these services, the personal information we collect
              from you may be transferred to, and stored at, a destination
              outside the European Economic Area (“EEA”), in order to conduct
              our business operations. In such cases a data processing agreement
              will be in place with the data processors and 3rd parties to
              ensure your personal information is processed in accordance with
              the Data Protection Act.
            </p>
            <p>
              Magstand will in certain circumstance disclose your personal
              information if required to do so by law or in the good faith
              belief that such action is necessary to:
            </p>
            <ol type="1" start="1">
              <li>
                conform to the edicts of the law or comply with legal process
                served on Magstand.com;
                <br />
                <br />
              </li>
              <li>
                protect and defend the rights or property of Magstand.com; and,
                <br />
                <br />
              </li>
              <li>
                act under exigent circumstances to protect the personal safety
                of users of Magstand.com, or the public.
                <br />
                <br />
              </li>
            </ol>
            <p>
              Magstand may share our Commercial Data with businesses and
              organisations with whom we have entered into agreements to
              purchase or access our data.&nbsp;
            </p>
            <p>
              You can opt out of having your information shared with 3
              <sup>rd</sup> parties at the point at which you share it with us
              and when you receive any subsequent communication from us.
            </p>
            <p>
              We may share your personal data with a third-party auditing
              organisation (ABC) so they can verify aggregated statistics about
              circulation and usage of our clients products or review our
              policies, processes and procedures for compliance with relevant
              standards.
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>How long will you retain my personal information?</strong>
            </p>
            <p>
              We will retain and process your personal information as long as is
              needed to provide the product or service that you’ve elected to
              receive from us.
            </p>
            <p>
              For personal information used by us for our own direct marketing
              purposes, we will retain the data whilst accurate or responsive
              and if not, no longer actively use after 5 years.&nbsp;
            </p>
            <p>
              Personal information obtained from 3rd parties for the Magstand
              Commercial dataset will be recompiled on a regular basis to ensure
              the data is fresh and relevant.
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>How is my personal information kept secure?</strong>
            </p>
            <p>
              Magstand is committed to protecting the security of your personal
              information. We will use a variety of security technologies and
              procedures to protect your personal information from unauthorised
              access, use or disclosure. Although we will do our best to protect
              your personal information, we cannot guarantee the security of
              your data transmitted to our site; any transmission is at your own
              risk. Once we have received your information, we will use strict
              procedures and security features to try to prevent unauthorised
              access.&nbsp;
            </p>
            <p>
              Magstand secures the personal information you provide on computer
              servers in a controlled, secure environment, protected from
              unauthorised access, use or disclosure. Magstand.com ensures
              personal information is protected through the use of encryption,
              such as the Secure Socket Layer (SSL) protocol, when transmitted
              to other websites. We aim to have all our websites using SSL
              protocol.
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>What are my choices with my personal information?</strong>
            </p>
            <p>
              Magstand is committed to providing you with control on how your
              data is used and processed. Your rights concerning your personal
              information are:
            </p>
            <ul type="disc">
              <li>
                you can ask about the personal information we hold about you and
                we will show you, free of charge;
                <br />
                <br />
              </li>
              <li>
                you can request your personal information is corrected and we
                will update the details and inform you when completed, free of
                charge;
                <br />
                <br />
              </li>
              <li>
                you can object to your data being processed and we will stop
                processing your data once no longer required for performance of
                contract or legal obligations, free of charge;
                <br />
                <br />
              </li>
              <li>
                you can object to receiving direct marketing communications from
                us and we will stop all communications by channel (email, post,
                telephone or SMS) or within certain subject areas, free of
                charge. In addition, any direct marketing communications you
                receive from us will always include a clear, simple method to
                'opt-out' of future communications by channel.
                <br />
                <br />
              </li>
              <li>
                you can ask for your personal information to be erased, once no
                longer required for performance of contract or legal
                obligations, which we will complete bar keeping the minimal
                personal details to ensure we do not contact you again, free of
                charge;
                <br />
                <br />
              </li>
              <li>
                Any request by you to no longer use your personal information by
                3rd parties for commercial data information, will be completed
                free of charge.
              </li>
            </ul>
            <p>
              <br />
              <strong>How we use cookies on the Magstand.com websites</strong>
            </p>
            <p>
              Magstand.com use “cookies” to help personalise and enhance your
              online experience when visiting our website.
            </p>
            <p>
              A cookie is a text file that is placed on your hard disk by a Web
              page server. Cookies cannot be used to run programs or deliver
              viruses to your computer. Cookies are uniquely assigned to you,
              and can only be read by a web server in the domain that issued the
              cookie to you.
            </p>
            <p>
              You can control the use of cookies at the individual browser
              level. If you elect not to activate the cookie or to later disable
              cookies, you may still visit our Websites, but your ability to use
              some features or areas of the website may be limited. For
              instructions on how to control, disable or delete cookies for your
              browser, please visit{" "}
              <a href="http://www.aboutcookies.org/">www.aboutcookies.org</a>.
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>Your right to lodge a complaint with ICO</strong>
            </p>
            <p>
              You have the right to lodge a complaint with the Information
              Commissioner Office (
              <a href="http://www.ico.org.uk/">www.ico.org.uk</a>)
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>Changes to this Policy</strong>
            </p>
            <p>
              Magstand will occasionally update this Privacy Policy to reflect
              company and customer feedback. Magstand.com encourages you to
              periodically review this policy to be informed of how Magstand.com
              is protecting your information.
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>Contact Us</strong>
            </p>
            <p>
              Magstand welcomes your comments regarding this Privacy Policy or
              about Magstand.com’s privacy practice please contact:
            </p>
            <p>FAO: Data Protection Officer</p>
            <p>Magstand.com</p>
            <p>1 Accent Park</p>
            <p>Bakewell Road</p>
            <p>Peterborough, Cambridgeshire</p>
            <p>PE2 6XS.</p>
          </div>
        </div>
      </div>
    );
  }
}
