import axios from "axios";
import {
  GET_NEW_SUBS,
  GET_ERRORS,
  LOADING,
  GET_TOTAL_PAGE_VIEWS,
  GET_NEW_SUBS_PRINT,
  GET_NEW_SUBS_DIGITAL,
  GET_NEW_SUBS_BUNDLE,
  GET_NEW_ORDERS,
  GET_ALL_SUBS,
  GET_ALL_SUBS_PRINT,
  GET_ALL_SUBS_DIGITAL,
  GET_ALL_SUBS_BUNDLE,
  GET_ALL_ORDERS,
  GET_NEW_PAGE_VIEWS,
  GET_NEW_UNIQUE_PAGE_VIEWS,
  GET_UNIQUE_PAGE_VIEWS,
  GET_YEARLY_GRAPH_DATA,
  GET_EXPIRED_LAST_THIRTY_COUNT,
} from "./types";

// Get last 30 days of subs
export const getNewSubs = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/newsubscribers/${id}`)
    .then((res) => {
      dispatch({
        type: GET_NEW_SUBS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get last 30 days of subs
export const getAllNewSubs = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/newsubscribers")
    .then((res) => {
      dispatch({
        type: GET_NEW_SUBS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get last 30 days of print subs
export const getNewSubsPrint = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/newsubscribersprint/${id}`)
    .then((res) => {
      dispatch({
        type: GET_NEW_SUBS_PRINT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get last 30 days of print subs
export const getAllNewSubsPrint = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/newsubscribersprint")
    .then((res) => {
      dispatch({
        type: GET_NEW_SUBS_PRINT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get last 30 days of digital subs
export const getNewSubsDigital = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/newsubscribersdigital/${id}`)
    .then((res) => {
      dispatch({
        type: GET_NEW_SUBS_DIGITAL,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get last 30 days of digital subs
export const getAllNewSubsDigital = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/newsubscribersdigital")
    .then((res) => {
      dispatch({
        type: GET_NEW_SUBS_DIGITAL,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get last 30 days of bundle subs
export const getNewSubsBundle = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/newsubscribersbundle/${id}`)
    .then((res) => {
      dispatch({
        type: GET_NEW_SUBS_BUNDLE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get last 30 days of bundle subs
export const getAllNewSubsBundle = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/newsubscribersbundle")
    .then((res) => {
      dispatch({
        type: GET_NEW_SUBS_BUNDLE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get last 30 days of order price
export const getNewOrders = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/neworders/${id}`)
    .then((res) => {
      dispatch({
        type: GET_NEW_ORDERS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get last 30 days of order price
export const getAllNewOrders = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/neworders")
    .then((res) => {
      dispatch({
        type: GET_NEW_ORDERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get last 30 days of order price
export const getAllExpiredThirty = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/expiredsubscribers")
    .then((res) => {
      dispatch({
        type: GET_EXPIRED_LAST_THIRTY_COUNT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all subs count for publication
export const getAllSubs = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/allsubscribers/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ALL_SUBS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all subs count for publication
export const getAllAllSubs = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/allsubscribers")
    .then((res) => {
      dispatch({
        type: GET_ALL_SUBS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all print subs count for publication
export const getAllSubsPrint = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/allsubscribersprint/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ALL_SUBS_PRINT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all print subs count for publication
export const getAllAllSubsPrint = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/allsubscribersprint")
    .then((res) => {
      dispatch({
        type: GET_ALL_SUBS_PRINT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all digital subs count for publication
export const getAllSubsDigital = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/allsubscribersdigital/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ALL_SUBS_DIGITAL,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all digital subs count for publication
export const getAllAllSubsDigital = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/allsubscribersdigital")
    .then((res) => {
      dispatch({
        type: GET_ALL_SUBS_DIGITAL,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all bundle subs count for publication
export const getAllSubsBundle = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/allsubscribersbundle/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ALL_SUBS_BUNDLE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all bundle subs count for publication
export const getAllAllSubsBundle = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/allsubscribersbundle")
    .then((res) => {
      dispatch({
        type: GET_ALL_SUBS_BUNDLE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all order price total for publication
export const getAllOrders = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/allorders/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ALL_ORDERS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all order price total for publication
export const getAllAllOrders = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/allorders")
    .then((res) => {
      dispatch({
        type: GET_ALL_ORDERS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getAllAllPageViews = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/totalpageviews")
    .then((res) => {
      dispatch({
        type: GET_TOTAL_PAGE_VIEWS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getPageViews = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/totalpageviews/${id}`)
    .then((res) => {
      dispatch({
        type: GET_TOTAL_PAGE_VIEWS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getAllPageViews = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/totalpageviews")
    .then((res) => {
      dispatch({
        type: GET_TOTAL_PAGE_VIEWS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getNewPageViews = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/newpageviews/${id}`)
    .then((res) => {
      dispatch({
        type: GET_NEW_PAGE_VIEWS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getAllNewPageViews = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/newpageviews")
    .then((res) => {
      dispatch({
        type: GET_NEW_PAGE_VIEWS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getNewUniquePageViews = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/newuniquepageviews/${id}`)
    .then((res) => {
      dispatch({
        type: GET_NEW_UNIQUE_PAGE_VIEWS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getAllNewUniquePageViews = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/newuniquepageviews")
    .then((res) => {
      dispatch({
        type: GET_NEW_UNIQUE_PAGE_VIEWS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getUniquePageViews = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/uniquepageviews/${id}`)
    .then((res) => {
      dispatch({
        type: GET_UNIQUE_PAGE_VIEWS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getAllUniquePageViews = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/uniquepageviews")
    .then((res) => {
      dispatch({
        type: GET_UNIQUE_PAGE_VIEWS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getYearlyGraphData = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(`/api/analytics/graphyeardata/${id}`)
    .then((res) => {
      dispatch({
        type: GET_YEARLY_GRAPH_DATA,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get total page views
export const getAllYearlyGraphData = () => (dispatch) => {
  dispatch(loading());
  axios
    .get("/api/analytics/graphyeardata")
    .then((res) => {
      dispatch({
        type: GET_YEARLY_GRAPH_DATA,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Loading
export const loading = () => {
  return {
    type: LOADING,
  };
};
