import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Segment, Form, Button, Header, Grid, Table, Checkbox, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import getTitles from '../../common/Titles';
import getCountries from '../../common/Countries';
import { createReader } from '../../../actions/readerActions';
import { getPublication } from '../../../actions/publicationActions';
import RenderDatePickerTable from '../../common/NormalForm/RenderDatePickerTable';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';
import RenderFieldLabelAbove from '../../common/NormalForm/RenderFieldLabelAbove';
import isEmpty from '../../../extension/is-empty';

class AddReader extends Component {

  state = {
    billingPCLookup: '',
    showBillingPCLookup: false,
    displayBillingPostcodes: false,
    displayAddress: false,
    billingPostcodes: null,
    billingPostcode: '',
    open: false,
    firstName: '',
    lastName: '',
    title: '',
    userName: '',
    email: '',
    password: '',
    uniqueReference: '',
    fullName: '',
    jobTitle: '',
    webAddress: '',
    companyName: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    addressFour: '',
    city: '',
    country: '',
    postZip: '',
    fax: '',
    state: '',
    telephone: '',
    history: '',
    readerType: '',
    pubOffers: true,
    partnerOffers: true,
    pubOffersPost: true,
    partnerOffersPost: true,
    pubOffersTel: true,
    partnerOffersTel: true,
    pubOffersSMS: true,
    partnerOffersSMS: true,
    purchasePrice: '',
    dataSourceOne: '',
    dataSourceTwo: '',
    customQuestionAans: '',
    customQuestionBans: '',
    customQuestionCans: '',
    customQuestionDans: '',
    customQuestionEans: '',
    customQuestionA: '',
    customQuestionB: '',
    customQuestionC: '',
    customQuestionD: '',
    customQuestionE: '',
    publicationName: '',
    showQuestionArea: true,
    publicationID: this.props.match.params.id,
    sd: '',
    ed: '',
    type: '',
    rd: '',
    paid: '',
    subs: [],
    errors: {}
  }

  componentDidMount() {
    this.props.getPublication(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.publication) {
      const publication = nextProps.publication;

      publication.customQuestionA = !isEmpty(publication.customQuestionA) ? publication.customQuestionA : '';
      publication.customQuestionB = !isEmpty(publication.customQuestionB) ? publication.customQuestionB : '';
      publication.customQuestionC = !isEmpty(publication.customQuestionC) ? publication.customQuestionC : '';
      publication.customQuestionD = !isEmpty(publication.customQuestionD) ? publication.customQuestionD : '';
      publication.customQuestionE = !isEmpty(publication.customQuestionE) ? publication.customQuestionE : '';

      if (publication.customQuestionA === "" && publication.customQuestionB === "" && publication.customQuestionC === "" && publication.customQuestionD === "" && publication.customQuestionE === "") {
        this.setState({ showQuestionArea: false })
      } else {
        this.setState({ showQuestionArea: true })
      }
      this.setState({
        customQuestionA: publication.customQuestionA,
        customQuestionB: publication.customQuestionB,
        customQuestionC: publication.customQuestionC,
        customQuestionD: publication.customQuestionD,
        customQuestionE: publication.customQuestionE,
        publicationName: publication.publicationName,
        uniqueReference: this.makeid(7, publication.publicationName)
      });
    }
  }



  makeid(length, pubName) {
    if (this.state.uniqueReference === "") {
      let result = pubName.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')
      var characters = '0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    } else {
      return this.state.uniqueReference;
    }
  }

  // Change Event Handler for Controlled DatePicker Component
  handleDateChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  handleChange = (e, { value }) => {
    this.setState({ title: value })
  }

  handleTitleChange = (e, { value }) => {
    this.setState({ title: value })
  }

  // Country Dropdown Change Handler
  handleCountryChange = (e, { value, text }) => {
    e.persist();
    this.setState({ country: value });

    switch (value) {
      case "GB":
        return this.setState({
          showBillingPCLookup: true,
          displayAddress: false
        });
      case "US":
      case "PR":
      case "EC":
      case "SV":
      case "ZW":
      case "GU":
      case "VI":
      case "TL":
      case "WS":
      case "MP":
      case "FM":
      case "PW":
      case "MH":
        return this.setState({
          showBillingPCLookup: false,
          displayAddress: true
        });
      case "AD":
      case "AT":
      case "BE":
      case "CY":
      case "EE":
      case "FR":
      case "FI":
      case "DE":
      case "GR":
      case "IE":
      case "IT":
      case "LV":
      case "LT":
      case "LU":
      case "MT":
      case "MC":
      case "NL":
      case "PT":
      case "SM":
      case "SK":
      case "SI":
      case "ES":
        return this.setState({
          showBillingPCLookup: false,
          displayAddress: true
        });
      default:
        return this.setState({
          showBillingPCLookup: false,
          displayAddress: true
        });

    }
  }

  billingLookup = (e) => {
    e.preventDefault();
    axios
      .get(`/api/thirdparty/postcodelookup/${this.state.billingPCLookup}` + '?api-key=uz-hsvkS8kimWsfmj3znMg10941')
      .then(res => {
        var arrayLength = res.data.addresses.length;
        const newArray = [];
        for (var i = 0; i < arrayLength; i++) {
          newArray.push({ "items": i, "text": res.data.addresses[i].split(','), "value": res.data.addresses[i] });
        }

        this.setState({ billingPostcodes: newArray });
      })
      .catch(err => console.log(err)
      );
  }

  // Billing Postcode Dropdown Change Handler
  handleBillingChange = (e, { value, text }) => {
    e.persist();
    const address = value.split(",").map(item => item.trim());

    var filtered = address.filter(function (el) {
      return el != null;
    });
    if (address[0].includes("Limited") || address[0].includes("Ltd") || address[0].includes("Plc") || address[0].includes("LLP")) {
      this.setState({
        companyName: address[0],
        addressOne: address[1],
        addressTwo: address[2],
        addressThree: address[3],
        city: address[5],
        state: address[6]
      });
    } else {
      this.setState({
        addressOne: address[0],
        addressTwo: address[1],
        addressThree: address[2],
        city: address[5],
        state: address[6]
      });

    }
    this.setState({ postZip: this.state.billingPCLookup, displayAddress: true });
  }

  handleSubChange = (e, { value }) => {
    this.setState({ type: value })
  }

  handleQuestionAChange = (e, { value }) => {
    this.setState({ customQuestionAans: value })
  }

  handleQuestionBChange = (e, { value }) => {
    this.setState({ customQuestionBans: value })
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onPostCheckedHandler = () => {
    this.setState(prevState => ({ pubOffersPost: !prevState.pubOffersPost }))
  }

  onCheckedHandler = () => {
    this.setState(prevState => ({ pubOffers: !prevState.pubOffers }))
  }

  onPartnerCheckedHandler = () => {
    this.setState(prevState => ({ partnerOffers: !prevState.partnerOffers }))
  }

  onPartnerPostCheckedHandler = () => {
    this.setState(prevState => ({ partnerOffersPost: !prevState.partnerOffersPost }))
  }

  onPartnerSMSCheckedHandler = () => {
    this.setState(prevState => ({ partnerOffersSMS: !prevState.partnerOffersSMS }))
  }

  onPartnerTelCheckedHandler = () => {
    this.setState(prevState => ({ partnerOffersTel: !prevState.partnerOffersTel }))
  }

  onSMSCheckedHandler = () => {
    this.setState(prevState => ({ pubOffersSMS: !prevState.pubOffersSMS }))
  }

  onTelCheckedHandler = () => {
    this.setState(prevState => ({ pubOffersTel: !prevState.pubOffersTel }))
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    const newReader = {
      sd: this.state.sd,
      ed: this.state.ed,
      type: this.state.type,
      rd: this.state.rd,
      paid: this.state.paid,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      title: this.state.title,
      userName: this.state.userName,
      email: this.state.email,
      password: this.state.password,
      uniqueReference: this.state.uniqueReference,
      fullName: this.state.fullName,
      jobTitle: this.state.jobTitle,
      webAddress: this.state.webAddress,
      companyName: this.state.companyName,
      addressOne: this.state.addressOne,
      addressTwo: this.state.addressTwo,
      addressThree: this.state.addressThree,
      addressFour: this.state.addressFour,
      city: this.state.city,
      country: this.state.country,
      postZip: this.state.postZip,
      fax: this.state.fax,
      state: this.state.state,
      telephone: this.state.telephone,
      readerType: this.state.readerType,
      pubOffers: this.state.pubOffers,
      partnerOffers: this.state.partnerOffers,
      pubOffersPost: this.state.pubOffersPost,
      partnerOffersPost: this.state.partnerOffersPost,
      pubOffersTel: this.state.pubOffersTel,
      partnerOffersTel: this.state.partnerOffersTel,
      pubOffersSMS: this.state.pubOffersSMS,
      partnerOffersSMS: this.state.partnerOffersSMS,
      purchasePrice: this.state.purchasePrice,
      dataSourceOne: this.state.dataSourceOne,
      dataSourceTwo: this.state.dataSourceTwo,
      customQuestionAans: this.state.customQuestionAans,
      customQuestionBans: this.state.customQuestionBans,
      customQuestionCans: this.state.customQuestionCans,
      customQuestionDans: this.state.customQuestionDans,
      customQuestionEans: this.state.customQuestionEans,
      publicationID: this.props.match.params.id,
    }

    // this.props.history needed withRouter so we can use it in the redux action
    this.props.createReader(newReader, this.props.history);
  }

  render() {
    const billingOptions = this.state.billingPostcodes;
    const showBillingPCLookup = this.state.showBillingPCLookup;
    let billingPCLookupDisplay = '';
    let billingPCLookup = '';
    let billingPostcodesDisplay;
    const { errors, subs, open, dimmer } = this.state;
    const titleOptions = getTitles();
    const countryOptions = getCountries();

    if (showBillingPCLookup) {
      billingPCLookupDisplay = (
        <div style={{ margin: "10px" }}>
          <React.Fragment>
            <Input
              label={{ basic: true, content: 'Postcode Lookup' }}
              action={{ color: 'black', labelPosition: 'right', icon: 'search', content: 'Lookup', onClick: this.billingLookup }}
              name="billingPCLookup"
              value={this.state.billingPCLookup}
              onChange={this.onChangeHandler}
              fluid>
            </Input>
            <br />
          </React.Fragment>
        </div>
      );
    } else {
      billingPCLookup = null;
    }

    if (billingOptions !== null) {
      billingPostcodesDisplay = (
        <div style={{ margin: "10px" }}>
          <React.Fragment>
            <Form.Select
              options={billingOptions}
              onChange={this.handleBillingChange}
              placeholder="Select Your Address"
              fluid
              size="large"
              selection
              name="billingPostcode"
              value={this.state.billingPostcode}
            /><br />
          </React.Fragment>
        </div>
      )
    } else {
      billingPostcodesDisplay = null;
    }

    const yesNooptions = [
      {
        key: 'yes',
        text: 'Yes',
        value: 'Yes'
      },
      {
        key: 'no',
        text: 'No',
        value: 'No'
      }
    ];
    const subOptions = [
      {
        key: '1',
        text: 'Digital',
        value: 'digital'
      },
      {
        key: '2',
        text: 'Print',
        value: 'print'
      },
      {
        key: '3',
        text: 'Bundle',
        value: 'bundle'
      }
    ]

    let questionA = '';
    let questionB = '';
    let questionC = '';
    let questionD = '';
    let questionE = '';
    let subsRows = (
      <React.Fragment>

        {subs.map(sub => {
          return (
            <Table.Row key={sub.sd + sub.paid}>
              <Table.Cell>{sub.type}</Table.Cell>
              <Table.Cell>{sub.sd}</Table.Cell>
              <Table.Cell>{sub.ed}</Table.Cell>
              <Table.Cell>{sub.rd}</Table.Cell>
              <Table.Cell>{sub.paid}</Table.Cell>
            </Table.Row>
          )
        })}
      </React.Fragment>

    )

    if (this.state.customQuestionA !== '') {
      (
        questionA = (
          <div style={{ padding: "10px" }}>
            <Form.Select
              options={yesNooptions}
              label={this.state.customQuestionA}
              onChange={this.handleQuestionAChange}
              placeholder=""
              fluid
              size="large"
              selection
              name="customQuestionAans"
              value={this.state.customQuestionAans}
              error={errors.customQuestionAans}
            />
          </div>
        )
      )
    }

    if (this.state.customQuestionB !== '') {
      (
        questionB = (
          <div style={{ padding: "10px" }}>
            <Form.Select
              options={yesNooptions}
              label={this.state.customQuestionB}
              onChange={this.handleQuestionBChange}
              placeholder=""
              fluid
              size="large"
              selection
              name="customQuestionBans"
              value={this.state.customQuestionBans}
              error={errors.customQuestionBans}
            />
          </div>
        )
      )
    }

    if (this.state.customQuestionC !== '') {
      (
        questionC = (
          <RenderFieldLabelAbove
            name="customQuestionCans"
            value={this.state.customQuestionCans}
            label={this.state.customQuestionC}
            onChange={this.onChangeHandler}
            error={errors.customQuestionCans}
          />
        )
      )
    }

    if (this.state.customQuestionD !== '') {
      (
        questionD = (
          <RenderFieldLabelAbove
            name="customQuestionDans"
            value={this.state.customQuestionDans}
            label={this.state.customQuestionD}
            onChange={this.onChangeHandler}
            error={errors.customQuestionDans}
          />
        )
      )
    }

    if (this.state.customQuestionE !== '') {
      (
        questionE = (
          <RenderFieldLabelAbove
            name="customQuestionEans"
            value={this.state.customQuestionEans}
            label={this.state.customQuestionE}
            onChange={this.onChangeHandler}
            error={errors.customQuestionEans}
          />
        )
      )
    }

    return (
      <React.Fragment>
        <Form onSubmit={this.onSubmitHandler}>
          <Segment>
            <Header as="h2" textAlign='center'>Add Reader</Header>
          </Segment>
          <Grid stackable columns={3}>
            <Grid.Column>
              <Segment>
                <div style={{ padding: "10px" }}>
                  <Form.Select
                    options={titleOptions}
                    label="Title"
                    onChange={this.handleTitleChange}
                    placeholder="Title"
                    fluid
                    size="large"
                    selection
                    name="title"
                    value={this.state.title}
                    error={errors.title}
                  />
                </div>
                <RenderFieldLabelAbove
                  name="firstName"
                  value={this.state.firstName}
                  label="First Name"
                  onChange={this.onChangeHandler}
                  placeholder="First Name"
                  error={errors.firstName}
                />
                <RenderFieldLabelAbove
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.onChangeHandler}
                  placeholder="Last Name"
                  error={errors.lastName}
                  label="Last Name"
                />
                <RenderFieldLabelAbove
                  name="fullName"
                  value={this.state.fullName}
                  onChange={this.onChangeHandler}
                  placeholder="Full Name"
                  error={errors.fullName}
                  label="Full Name"
                />
                <RenderFieldLabelAbove
                  name="userName"
                  value={this.state.userName}
                  onChange={this.onChangeHandler}
                  placeholder="User Name"
                  error={errors.userName}
                  label="User Name"
                  autocomplete="new-password"
                />
                <RenderFieldLabelAbove
                  type="password"
                  value={this.state.password}
                  onChange={this.onChangeHandler}
                  placeholder="User Password"
                  name="password"
                  error={errors.password}
                  label="Password"
                  autocomplete="new-password"
                />
                <RenderFieldLabelAbove
                  value={this.state.uniqueReference}
                  onChange={this.onChangeHandler}
                  placeholder="Unique Reference"
                  name="uniqueReference"
                  error={errors.uniqueReference}
                  label="Unique Reference *"
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>

              <Segment>
                <div style={{ padding: "10px" }}>
                  <Form.Select
                    options={countryOptions}
                    label="Country"
                    onChange={this.handleCountryChange}
                    placeholder="Country"
                    fluid
                    size="large"
                    selection
                    name="country"
                    value={this.state.country}
                    error={errors.country}
                  />
                </div>

                {billingPCLookupDisplay}
                {billingPostcodesDisplay}
                <RenderFieldLabelAbove
                  name="addressOne"
                  value={this.state.addressOne}
                  onChange={this.onChangeHandler}
                  placeholder="Address 1"
                  error={errors.addressOne}
                  label="Address 1"
                />
                <RenderFieldLabelAbove
                  name="addressTwo"
                  value={this.state.addressTwo}
                  onChange={this.onChangeHandler}
                  placeholder="Address 2"
                  error={errors.addressTwo}
                  label="Address 2"
                />
                <RenderFieldLabelAbove
                  name="addressThree"
                  value={this.state.addressThree}
                  onChange={this.onChangeHandler}
                  placeholder="Address 3"
                  error={errors.addressThree}
                  label="Address 3"
                />
                <RenderFieldLabelAbove
                  name="city"
                  value={this.state.city}
                  onChange={this.onChangeHandler}
                  placeholder="City"
                  error={errors.city}
                  label="City"
                />
                <RenderFieldLabelAbove
                  name="state"
                  value={this.state.state}
                  onChange={this.onChangeHandler}
                  placeholder="County/State"
                  error={errors.state}
                  label="County/State"
                />

                <RenderFieldLabelAbove
                  name="postZip"
                  value={this.state.postZip}
                  onChange={this.onChangeHandler}
                  placeholder="Post/Zip Code"
                  error={errors.postZip}
                  label="Post/Zip Code"
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>

                <RenderFieldLabelAbove
                  name="companyName"
                  value={this.state.companyName}
                  label="Company Name"
                  onChange={this.onChangeHandler}
                  placeholder="Company Name"
                  error={errors.companyName}
                />
                <RenderFieldLabelAbove
                  name="telephone"
                  value={this.state.telephone}
                  label="Telephone"
                  onChange={this.onChangeHandler}
                  placeholder="Telephone"
                  error={errors.telephone}
                />
                <RenderFieldLabelAbove
                  name="fax"
                  value={this.state.fax}
                  onChange={this.onChangeHandler}
                  placeholder="Fax"
                  error={errors.fax}
                  label="Fax"
                />
                <RenderFieldLabelAbove
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChangeHandler}
                  placeholder="Email Address"
                  error={errors.email}
                  label="Email Address"
                />
                <RenderFieldLabelAbove
                  name="webAddress"
                  value={this.state.webAddress}
                  onChange={this.onChangeHandler}
                  placeholder="Web Address"
                  error={errors.webAddress}
                  label="Web Address"
                />
                <RenderFieldLabelAbove
                  value={this.state.dataSourceOne}
                  onChange={this.onChangeHandler}
                  placeholder="Data Source Note 1"
                  name="dataSourceOne"
                  error={errors.dataSourceOne}
                  label="Data Source Note 1"
                />
                <RenderFieldLabelAbove
                  value={this.state.dataSourceTwo}
                  onChange={this.onChangeHandler}
                  placeholder="Data Source Note 2"
                  name="dataSourceTwo"
                  error={errors.dataSourceTwo}
                  label="Data Source Note 2"
                />
              </Segment>
            </Grid.Column>
          </Grid>
          {this.state.showQuestionArea ?
            <Segment>
              <Header as="h2" textAlign="center">Questions</Header>
              {questionA}
              {questionB}
              {questionC}
              {questionD}
              {questionE}
            </Segment> : null}
          <Segment>
            <Header as="h2" textAlign="center">Subscription (Leave blank for Free Reader)</Header>
            <Table singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Start Date</Table.HeaderCell>
                  <Table.HeaderCell>End Date</Table.HeaderCell>
                  <Table.HeaderCell>Renewal Date</Table.HeaderCell>
                  <Table.HeaderCell>Paid</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>

                    <Form.Select
                      options={subOptions}
                      onChange={this.handleSubChange}
                      fluid
                      size="large"
                      selection
                      name="type"
                      value={this.state.type}
                      error={errors.type}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <RenderDatePickerTable
                      name="sd"
                      value={this.state.sd}
                      onChange={this.handleDateChange}
                      error={errors.sd}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <RenderDatePickerTable
                      name="ed"
                      value={this.state.ed}
                      onChange={this.handleDateChange}
                      error={errors.ed}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <RenderDatePickerTable
                      name="rd"
                      value={this.state.rd}
                      onChange={this.handleDateChange}
                      error={errors.rd}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <RenderFieldLabelAbove
                      value={this.state.paid}
                      onChange={this.onChangeHandler}
                      placeholder="Amount Paid (leave blank for free)"
                      name="paid"
                      error={errors.paid}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

          </Segment>
          <Segment>
            <Header as="h2" textAlign="center">Marketing Preferences <span style={{ fontSize: "14px" }}>(tick to opt out)</span></Header>
            <Checkbox
              name="pubOffers"
              label="Emails"
              onChange={this.onCheckedHandler}
              checked={this.state.pubOffers}
              style={{ marginRight: "20px" }}
            />
            <Checkbox
              name="pubOffersPost"
              label="Postal"
              onChange={this.onPostCheckedHandler}
              checked={this.state.pubOffersPost}
              style={{ marginRight: "20px" }}
            />
            <Checkbox
              name="pubOffersTel"
              label="Telephone"
              onChange={this.onTelCheckedHandler}
              checked={this.state.pubOffersTel}
              style={{ marginRight: "20px" }}
            />
            <Checkbox
              name="pubOffersSMS"
              label="SMS"
              onChange={this.onSMSCheckedHandler}
              checked={this.state.pubOffersSMS}
              style={{ marginRight: "20px" }}
            />
            <Checkbox
              name="partnerOffers"
              label="Partner Emails"
              onChange={this.onPartnerCheckedHandler}
              checked={this.state.partnerOffers}
              style={{ marginRight: "20px" }}
            />
            <Checkbox
              name="partnerOffersPost"
              label="Partner Postal"
              onChange={this.onPartnerPostCheckedHandler}
              checked={this.state.partnerOffersPost}
              style={{ marginRight: "20px" }}
            />
            <Checkbox
              name="partnerOffersTel"
              label="Partner Telephone"
              onChange={this.onPartnerTelCheckedHandler}
              checked={this.state.partnerOffersTel}
              style={{ marginRight: "20px" }}
            />
            <Checkbox
              name="partnerOffersSMS"
              label="Partner SMS"
              onChange={this.onPartnerSMSCheckedHandler}
              checked={this.state.partnerOffersSMS}
              style={{ marginRight: "20px" }}
            />
          </Segment>
          <Segment>
            <Button color="blue" type="submit" fluid>Add Reader</Button>
          </Segment>
        </Form>
      </React.Fragment>
    );
  }
}

AddReader.propTypes = {
  createReader: PropTypes.func.isRequired,
  getPublication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors,
  publication: state.publication.publication
});

export default connect(mapStateToProps, { createReader, getPublication })(withRouter(AddReader));
