import {
  CHECK_NUM_PUBS,
  LOADING,
  ADD_PUBLICATION,
  GET_PUBLICATIONS,
  GET_PUBLICATION,
  GET_PROMOS,
  DELETE_PROMO,
  ADD_PROMO,
  GET_PROMO,
  GET_PUBLICATION_BY_URLKEY,
  GET_PAYPAL_DETAILS,
  UPDATE_PUBLICATION,
  UPDATE_PROMO,
  CLEAR_PUB_DATA,
  DEACTIVATE_PUBLICATION,
  GET_PUBLICATIONS_WITH_BACKISSUE,
} from "../actions/types";

// Create an initial state for the model
const initialState = {
  numPubs: 0,
  publications: null,
  publication: null,
  promos: null,
  promo: null,
  paypal: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PUBLICATIONS:
      return {
        ...state,
        publications: action.payload,
        loading: false,
      };
    case GET_PUBLICATIONS_WITH_BACKISSUE:
      return {
        ...state,
        publications: action.payload,
        loading: false,
      };
    case GET_PUBLICATION:
      return {
        ...state,
        publication: action.payload,
        loading: false,
      };
    case GET_PUBLICATION_BY_URLKEY:
      return {
        ...state,
        publication: action.payload,
      };
    case ADD_PUBLICATION:
      return {
        ...state,
        publications: [action.payload, ...state.publications],
      };
    case UPDATE_PUBLICATION:
      return {
        ...state,
      };
    case GET_PROMOS:
      return {
        ...state,
        promos: action.payload,
        loading: false,
      };
    case GET_PROMO:
      return {
        ...state,
        promo: action.payload,
        loading: false,
      };
    case GET_PAYPAL_DETAILS:
      return {
        ...state,
        paypal: action.payload,
      };
    case ADD_PROMO:
      return {
        ...state,
        promos: [action.payload, ...state.promos],
      };
    case UPDATE_PROMO:
      return {
        ...state,
        promos: [action.payload, ...state.promos],
      };
    case DELETE_PROMO:
      return {
        ...state,
        promos: state.promos.filter((promo) => promo._id !== action.payload),
      };
    case CHECK_NUM_PUBS:
      return {
        ...state,
        numPubs: action.payload,
        loading: false,
      };
    case DEACTIVATE_PUBLICATION:
      return {
        ...state,
        publications: state.publications.filter(
          (pub) => pub._id !== action.payload
        ),
      };
    case CLEAR_PUB_DATA:
      return {
        ...state,
        publication: {},
      };
    default:
      return state;
  }
}
