import React, { Component, useState } from 'react';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js/pure";
import axios from 'axios';
import { Header, Modal, Grid } from 'semantic-ui-react';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/ordersuccess?url=interior-designer`,
      },
      //redirect: "if_required"
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      // alert(error.message);
      props.onError()
    } else {
      //alert("An unexpected error occurred.");
      props.onError()
    }
    //props.onClose()
    // props.handleSubmit()
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button type="submit" disabled={!stripe || !elements} style={{ marginTop: '10px'}}>
        Pay
      </button>
    </form>
  );
};

const BacsModal = (props) =>{
  const [stripePromise, setStripePromise] = React.useState(null)
  const [paymentIntent, setPaymentIntent] = React.useState(null)

  const createPaymentIntent = async () =>{
    if(props.secretKey){
      // Create payment intent
      const body = { "secret_key": props.secretKey, "amount": props.amount }
      const response = await axios.post(`/api/order/paymentintent/bacs/`, body)
      setPaymentIntent( response.data )
    }
  }

  React.useEffect(() => {
    // Only create payment intent on open
    if( props.open )
      createPaymentIntent()
  }, [props.open])   // eslint-disable-line react-hooks/exhaustive-deps

  const loadStripeLaunch = async () => {
    if( props.publishableKey ){      
      const stripePromise = await loadStripe(props.publishableKey);
      // NOTE: This is required to make the stripePromise to be reloaded
      setStripePromise(stripePromise)
    }else{
      setStripePromise(null)
    }
  }
  React.useEffect(() => {
    loadStripeLaunch()
  }, [props.publishableKey])   // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () =>   
    props.stripeSubmit(paymentIntent)

  const { accountDets } = props
  const company_phone = accountDets?accountDets.telephone:""
  const company_name = accountDets?accountDets.companyName:""
  const company_user_number = accountDets?accountDets.directDebitDets.serviceUserNumber:""
  const company_address = accountDets?`${accountDets.addressOne} ${accountDets.addressTwo} ${accountDets.addressThree}`:""
  const clientSecret = paymentIntent?paymentIntent.client_secret:null
  return <Modal open={props.open} onClose={() => props.onClose()} closeIcon
          size='large'>
            <Header content='Direct Debit Guarantee' />
            <Modal.Content>
              <Grid columns={2} stackable>
                <Grid.Row>
                  <Grid.Column>
                  <div className="stripe-widget">
                    {clientSecret && (
                    <Elements options={{ locale: 'en', clientSecret: clientSecret, theme: 'stripe' }}
                        stripe={stripePromise}>
                      <CheckoutForm onClose={() => props.onClose()} 
                        onError={() => props.onStripeError()}
                        handleSubmit={handleSubmit} amount={props.amount}/>
                    </Elements>)}
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <h1>PAYMENT DETAILS</h1>
                    <p>
                      If you are the bank account holder and the only person required 
                      to authorise Direct Debits from this account, please provide your 
                      account details here. If you are not the account holder or your 
                      account requires more than one authorisation, please call us on {company_phone}
                    </p>
                    <p>
                      More information about the Direct Debit agreement
                    </p>
                    <p>
                      The company name which will appear on your bank statement against the Direct Debit will be {company_name}
                    </p>
                    <p>
                      Service User Number {company_user_number}
                    </p>
                    <p>
                      {company_address}
                    </p>
                    <p>
                      Instruction to your Bank or Building Society to pay by Direct Debit
                    </p>
                    <p>
                      Please pay {company_name} Direct Debits from the account detailed in this instruction 
                      subject to the safeguards assured by the Direct Debit Guarantee. I understand 
                      that this instruction may remain with {company_name} and, if so, details will be passed 
                      electronically to my Bank or Building Society.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
          </Modal>
}

export default BacsModal