// Email Details Step

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { Button, Form } from 'semantic-ui-react';
import RenderTextAreaRedux from '../../../common/ReduxForm/RenderTextAreaRedux';
import RenderFieldLabelAboveRedux from '../../../common/ReduxForm/RenderFieldLabelAboveRedux';
import { email } from '../../../common/ReduxFormValidation/validationRules';


class PublicationInfo extends Component {
  render() {
    const { handleSubmit, submitting, previousPage } = this.props;
    return (
      <Form onSubmit={handleSubmit} size='large'>
        <Field
          name="ccemail"
          component={RenderFieldLabelAboveRedux}
          type="text"
          label="Email a copy of purchase receipts to"
          validate={[email]}
        />
        <Field
          name="emailText"
          component={RenderTextAreaRedux}
          label="Email Receipt Text"
          placeholder="Email Receipt Text"
        />
        <Field
          name="emailEnd"
          component={RenderTextAreaRedux}
          label="Mail Receipt Footer"
          placeholder="Mail Receipt Footer"
        />
        <div style={{ margin: "10px", textAlign: "center" }}>
          <Button color='blue'
            onClick={previousPage}>
            Previous
            </Button>
          <Button color='blue'
            disabled={submitting}>
            Next
            </Button>
        </div>
      </Form>
    )
  }
}

export default reduxForm({
  form: 'addwizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(PublicationInfo);