// async validation for the Add Publication to check whether the urlkey already exists

import axios from 'axios';
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const asyncValidate = (values/*, dispatch */) => {
  var error = {};
  axios
    .get(`/api/publication/checkurlkey/${values.urlKey}`)
    .then()
    .catch(err => {
      error.urlKey = 'That handle has already been taken. Please try another';
    })

  return sleep(100) // simulate server latency
    .then(() => {
      if (error.urlKey) {
        throw { urlKey: 'That handle has already been taken. Please try another' }
      }
    })
}

export default asyncValidate