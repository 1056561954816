import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import {
  Segment,
  Form,
  Button,
  Header,
  Grid,
  Table,
  Checkbox,
  TextArea,
  Input,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import getTitles from '../../common/Titles';
import getCountries from '../../common/Countries';
import {
  getReader,
  updateReader,
  deactivateReader,
  reactivateReader,
  deleteReader,
} from '../../../actions/readerActions';
import { getPublication } from '../../../actions/publicationActions';
import RenderDatePickerTable from '../../common/NormalForm/RenderDatePickerTable';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';
import RenderFieldLabelAbove from '../../common/NormalForm/RenderFieldLabelAbove';
import isEmpty from '../../../extension/is-empty';
import moment from 'moment';

class EditReader extends Component {
  state = {
    billingPCLookup: '',
    showBillingPCLookup: false,
    displayBillingPostcodes: false,
    displayAddress: false,
    billingPostcodes: null,
    billingPostcode: '',
    open: false,
    firstName: '',
    lastName: '',
    title: '',
    userName: '',
    email: '',
    gotDigSub: false,
    gotPrintSub: false,
    password: '',
    uniqueReference: '',
    fullName: '',
    jobTitle: '',
    webAddress: '',
    companyName: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    addressFour: '',
    city: '',
    country: '',
    postZip: '',
    fax: '',
    state: '',
    telephone: '',
    readerType: '',
    pubOffers: false,
    partnerOffers: false,
    pubOffersPost: false,
    partnerOffersPost: false,
    pubOffersTel: false,
    partnerOffersTel: false,
    pubOffersSMS: false,
    partnerOffersSMS: false,
    purchasePrice: '',
    dataSourceOne: '',
    dataSourceTwo: '',
    readerid: '',
    customQuestionAans: '',
    customQuestionBans: '',
    customQuestionCans: '',
    customQuestionDans: '',
    customQuestionEans: '',
    customQuestionA: '',
    customQuestionB: '',
    customQuestionC: '',
    customQuestionD: '',
    customQuestionE: '',
    publicationID: '',
    modified: '',
    modifiedBy: '',
    sd: '',
    ed: '',
    type: '',
    rd: '',
    paid: '',
    isDeleted: false,
    showQuestionArea: true,
    subs: [],
    errors: {},
  };

  componentDidMount() {
    this.props.getReader(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.reader.reader) {
      if (this.state.readerid !== nextProps.reader.reader._id) {
        const reader = nextProps.reader.reader;
        let country = !isEmpty(reader.country) ? reader.country : '';

        // If field doesnt exist, make empty string
        reader.firstName = !isEmpty(reader.firstName) ? reader.firstName : '';
        reader.lastName = !isEmpty(reader.lastName) ? reader.lastName : '';
        reader.title = !isEmpty(reader.title) ? reader.title : '';
        reader.email = !isEmpty(reader.email) ? reader.email : '';
        reader.userName = !isEmpty(reader.userName) ? reader.userName : '';
        reader.uniqueReference = !isEmpty(reader.uniqueReference)
          ? reader.uniqueReference
          : '';
        reader.fullName = !isEmpty(reader.fullName) ? reader.fullName : '';
        reader.webAddress = !isEmpty(reader.webAddress)
          ? reader.webAddress
          : '';
        reader.companyName = !isEmpty(reader.companyName)
          ? reader.companyName
          : '';
        reader.addressOne = !isEmpty(reader.addressOne)
          ? reader.addressOne
          : '';
        reader.addressTwo = !isEmpty(reader.addressTwo)
          ? reader.addressTwo
          : '';
        reader.addressThree = !isEmpty(reader.addressThree)
          ? reader.addressThree
          : '';
        reader.addressFour = !isEmpty(reader.addressFour)
          ? reader.addressFour
          : '';
        reader.city = !isEmpty(reader.city) ? reader.city : '';
        reader.country = this.getCountryCode(country);
        reader.postZip = !isEmpty(reader.postZip) ? reader.postZip : '';
        reader.fax = !isEmpty(reader.fax) ? reader.fax : '';
        reader.state = !isEmpty(reader.state) ? reader.state : '';
        reader.telephone = !isEmpty(reader.telephone) ? reader.telephone : '';
        reader.dataSourceOne = !isEmpty(reader.dataSourceOne)
          ? reader.dataSourceOne
          : '';
        reader.dataSourceTwo = !isEmpty(reader.dataSourceTwo)
          ? reader.dataSourceTwo
          : '';
        reader.password = !isEmpty(reader.password) ? reader.password : '';
        reader.customQuestionAans = !isEmpty(reader.customQuestionAans)
          ? reader.customQuestionAans
          : '';
        reader.customQuestionBans = !isEmpty(reader.customQuestionBans)
          ? reader.customQuestionBans
          : '';
        reader.customQuestionCans = !isEmpty(reader.customQuestionCans)
          ? reader.customQuestionCans
          : '';
        reader.customQuestionDans = !isEmpty(reader.customQuestionDans)
          ? reader.customQuestionDans
          : '';
        reader.customQuestionEans = !isEmpty(reader.customQuestionEans)
          ? reader.customQuestionEans
          : '';
        reader.modified = !isEmpty(reader.modified)
          ? (reader.modified = moment(reader.modified).format(
              'DD/MM/YYYY HH:mm',
            ))
          : '';
        reader.modifiedBy = !isEmpty(reader.modifiedBy)
          ? reader.modifiedBy
          : '';
        reader.history = !isEmpty(reader.history) ? reader.history : '';
        // Set component fields state
        this.setState({
          sd: moment(reader.subStartDate).format('DD/MM/YYYY'),
          ed: moment(reader.subEndDate).format('DD/MM/YYYY'),
          rd: moment(reader.subRenewDate).format('DD/MM/YYYY'),
          type: reader.subType,
          paid: reader.subPaid,
          history: reader.history,
          firstName: reader.firstName,
          lastName: reader.lastName,
          title: reader.title,
          userName: reader.userName,
          email: reader.email,
          password: reader.password,
          uniqueReference: reader.uniqueReference,
          fullName: reader.fullName,
          webAddress: reader.webAddress,
          companyName: reader.companyName,
          addressOne: reader.addressOne,
          addressTwo: reader.addressTwo,
          addressThree: reader.addressThree,
          addressFour: reader.addressFour,
          city: reader.city,
          country: reader.country,
          postZip: reader.postCode,
          fax: reader.fax,
          state: reader.state,
          telephone: reader.telephone,
          dataSourceOne: reader.dataSourceOne,
          dataSourceTwo: reader.dataSourceTwo,
          customQuestionAans: reader.customQuestionAans,
          customQuestionBans: reader.customQuestionBans,
          customQuestionCans: reader.customQuestionCans,
          customQuestionDans: reader.customQuestionDans,
          customQuestionEans: reader.customQuestionEans,
          pubOffers: reader.pubOffers,
          pubOffersPost: reader.pubOffersPost,
          partnerOffers: reader.partnerOffers,
          partnerOffersPost: reader.partnerOffersPost,
          pubOffersTel: reader.pubOffersTel,
          partnerOffersTel: reader.partnerOffersTel,
          pubOffersSMS: reader.pubOffersSMS,
          partnerOffersSMS: reader.partnerOffersSMS,
          readerid: reader._id,
          modified: reader.modified,
          modifiedBy: reader.modifiedBy,
          isDeleted: reader.isDeleted,
        });
        this.props.getPublication(reader.publication);
      }
    }

    if (nextProps.publication) {
      if (this.state.publicationID !== nextProps.publication._id) {
        const publication = nextProps.publication;

        publication.customQuestionA = !isEmpty(publication.customQuestionA)
          ? publication.customQuestionA
          : '';
        publication.customQuestionB = !isEmpty(publication.customQuestionB)
          ? publication.customQuestionB
          : '';
        publication.customQuestionC = !isEmpty(publication.customQuestionC)
          ? publication.customQuestionC
          : '';
        publication.customQuestionD = !isEmpty(publication.customQuestionD)
          ? publication.customQuestionD
          : '';
        publication.customQuestionE = !isEmpty(publication.customQuestionE)
          ? publication.customQuestionE
          : '';
        if (
          publication.customQuestionA === '' &&
          publication.customQuestionB === '' &&
          publication.customQuestionC === '' &&
          publication.customQuestionD === '' &&
          publication.customQuestionE === ''
        ) {
          this.setState({ showQuestionArea: false });
        } else {
          this.setState({ showQuestionArea: true });
        }
        this.setState({
          customQuestionA: publication.customQuestionA,
          customQuestionB: publication.customQuestionB,
          customQuestionC: publication.customQuestionC,
          customQuestionD: publication.customQuestionD,
          customQuestionE: publication.customQuestionE,
          publicationID: publication._id,
        });
      }
    }
  }

  deactivateReader = (e) => {
    e.preventDefault();
    this.props.deactivateReader(this.state.readerid, this.props.history);
  };

  reactivateReader = (e) => {
    e.preventDefault();
    this.props.reactivateReader(this.state.readerid, this.props.history);
  };

  deleteReader = (e) => {
    e.preventDefault();
    this.props.deleteReader(this.state.readerid, this.props.history);
  };

  getCountryCode = (value) => {
    console.log(value);
    const countries = getCountries();
    let currentCountry = countries.filter((v) => v.text === value)[0];
    if (!!currentCountry) return currentCountry.value;
    return '';
  };

  makeid(length) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // Change Event Handler for Controlled DatePicker Component
  handleDateChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleChange = (e, { value }) => {
    this.setState({ title: value });
  };

  handleTitleChange = (e, { value }) => {
    this.setState({ title: value });
  };

  // Country Dropdown Change Handler
  handleCountryChange = (e, { value, text }) => {
    e.persist();
    this.setState({ country: value });

    switch (value) {
      case 'GB':
        return this.setState({
          showBillingPCLookup: true,
          displayAddress: false,
        });
      case 'US':
      case 'PR':
      case 'EC':
      case 'SV':
      case 'ZW':
      case 'GU':
      case 'VI':
      case 'TL':
      case 'WS':
      case 'MP':
      case 'FM':
      case 'PW':
      case 'MH':
        return this.setState({
          showBillingPCLookup: false,
          displayAddress: true,
        });
      case 'AD':
      case 'AT':
      case 'BE':
      case 'CY':
      case 'EE':
      case 'FR':
      case 'FI':
      case 'DE':
      case 'GR':
      case 'IE':
      case 'IT':
      case 'LV':
      case 'LT':
      case 'LU':
      case 'MT':
      case 'MC':
      case 'NL':
      case 'PT':
      case 'SM':
      case 'SK':
      case 'SI':
      case 'ES':
        return this.setState({
          showBillingPCLookup: false,
          displayAddress: true,
        });
      default:
        return this.setState({
          showBillingPCLookup: false,
          displayAddress: true,
        });
    }
  };

  billingLookup = (e) => {
    e.preventDefault();
    axios
      .get(
        `/api/thirdparty/postcodelookup/${this.state.billingPCLookup}` +
          '?api-key=uz-hsvkS8kimWsfmj3znMg10941',
      )
      .then((res) => {
        var arrayLength = res.data.addresses.length;
        const newArray = [];
        for (var i = 0; i < arrayLength; i++) {
          newArray.push({
            items: i,
            text: res.data.addresses[i].split(','),
            value: res.data.addresses[i],
          });
        }

        this.setState({ billingPostcodes: newArray });
      })
      .catch((err) => console.log(err));
  };

  // Billing Postcode Dropdown Change Handler
  handleBillingChange = (e, { value, text }) => {
    e.persist();
    const address = value.split(',').map((item) => item.trim());

    var filtered = address.filter(function (el) {
      return el != null;
    });
    if (
      address[0].includes('Limited') ||
      address[0].includes('Ltd') ||
      address[0].includes('Plc') ||
      address[0].includes('LLP')
    ) {
      this.setState({
        companyName: address[0],
        addressOne: address[1],
        addressTwo: address[2],
        addressThree: address[3],
        city: address[5],
        state: address[6],
      });
    } else {
      this.setState({
        addressOne: address[0],
        addressTwo: address[1],
        addressThree: address[2],
        city: address[5],
        state: address[6],
      });
    }
    this.setState({
      postZip: this.state.billingPCLookup,
      displayAddress: true,
    });
  };

  handleSubChange = (e, { value }) => {
    this.setState({ type: value });
  };

  handleQuestionAChange = (e, { value }) => {
    this.setState({ customQuestionAans: value });
  };

  handleQuestionBChange = (e, { value }) => {
    this.setState({ customQuestionBans: value });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onPostCheckedHandler = () => {
    this.setState((prevState) => ({ pubOffersPost: !prevState.pubOffersPost }));
  };

  onCheckedHandler = () => {
    this.setState((prevState) => ({ pubOffers: !prevState.pubOffers }));
  };

  onPartnerCheckedHandler = () => {
    this.setState((prevState) => ({ partnerOffers: !prevState.partnerOffers }));
  };

  onPartnerPostCheckedHandler = () => {
    this.setState((prevState) => ({
      partnerOffersPost: !prevState.partnerOffersPost,
    }));
  };

  onPartnerSMSCheckedHandler = () => {
    this.setState((prevState) => ({
      partnerOffersSMS: !prevState.partnerOffersSMS,
    }));
  };

  onPartnerTelCheckedHandler = () => {
    this.setState((prevState) => ({
      partnerOffersTel: !prevState.partnerOffersTel,
    }));
  };

  onSMSCheckedHandler = () => {
    this.setState((prevState) => ({ pubOffersSMS: !prevState.pubOffersSMS }));
  };

  onTelCheckedHandler = () => {
    this.setState((prevState) => ({ pubOffersTel: !prevState.pubOffersTel }));
  };

  onSubmitHandler = (e) => {
    e.preventDefault();
    const newReader = {
      subStartDate: this.state.sd,
      subEndDate: this.state.ed,
      subRenewDate: this.state.rd,
      subType: this.state.type,
      subPaid: this.state.paid,
      history: this.state.history,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      title: this.state.title,
      userName: this.state.userName,
      email: this.state.email,
      password: this.state.password,
      uniqueReference: this.state.uniqueReference,
      fullName: this.state.fullName,
      jobTitle: this.state.jobTitle,
      webAddress: this.state.webAddress,
      companyName: this.state.companyName,
      addressOne: this.state.addressOne,
      addressTwo: this.state.addressTwo,
      addressThree: this.state.addressThree,
      addressFour: this.state.addressFour,
      city: this.state.city,
      country: this.state.country,
      postZip: this.state.postZip,
      fax: this.state.fax,
      state: this.state.state,
      telephone: this.state.telephone,
      readerType: this.state.readerType,
      pubOffers: this.state.pubOffers,
      partnerOffers: this.state.partnerOffers,
      pubOffersPost: this.state.pubOffersPost,
      partnerOffersPost: this.state.partnerOffersPost,
      pubOffersTel: this.state.pubOffersTel,
      partnerOffersTel: this.state.partnerOffersTel,
      pubOffersSMS: this.state.pubOffersSMS,
      partnerOffersSMS: this.state.partnerOffersSMS,
      purchasePrice: this.state.purchasePrice,
      dataSourceOne: this.state.dataSourceOne,
      dataSourceTwo: this.state.dataSourceTwo,
      customQuestionAans: this.state.customQuestionAans,
      customQuestionBans: this.state.customQuestionBans,
      customQuestionCans: this.state.customQuestionCans,
      customQuestionDans: this.state.customQuestionDans,
      customQuestionEans: this.state.customQuestionEans,
      publicationID: this.props.match.params.id,
      id: this.state.readerid,
    };
    // this.props.history needed withRouter so we can use it in the redux action
    this.props.updateReader(newReader, this.props.history);
  };

  render() {
    const billingOptions = this.state.billingPostcodes;
    const showBillingPCLookup = this.state.showBillingPCLookup;
    let billingPCLookupDisplay = '';
    let billingPCLookup;
    let billingPostcodesDisplay;
    const { errors, subs } = this.state;
    const titleOptions = getTitles();
    const countryOptions = getCountries();
    const yesNooptions = [
      {
        key: 'yes',
        text: 'Yes',
        value: 'Yes',
      },
      {
        key: 'no',
        text: 'No',
        value: 'No',
      },
    ];
    const subOptions = [
      {
        key: '1',
        text: 'Digital',
        value: 'digital',
      },
      {
        key: '2',
        text: 'Print',
        value: 'print',
      },
      {
        key: '3',
        text: 'Bundle',
        value: 'bundle',
      },
    ];

    if (showBillingPCLookup) {
      billingPCLookupDisplay = (
        <div style={{ margin: '10px' }}>
          <React.Fragment>
            <Input
              label={{ basic: true, content: 'Postcode Lookup' }}
              action={{
                color: 'black',
                labelPosition: 'right',
                icon: 'search',
                content: 'Lookup',
                onClick: this.billingLookup,
              }}
              name='billingPCLookup'
              value={this.state.billingPCLookup}
              onChange={this.onChangeHandler}
              fluid
            ></Input>
            <br />
          </React.Fragment>
        </div>
      );
    } else {
      billingPCLookup = null;
    }

    if (billingOptions !== null) {
      billingPostcodesDisplay = (
        <div style={{ margin: '10px' }}>
          <React.Fragment>
            <Form.Select
              options={billingOptions}
              onChange={this.handleBillingChange}
              placeholder='Select Your Address'
              fluid
              size='large'
              selection
              name='billingPostcode'
              value={this.state.billingPostcode}
            />
            <br />
          </React.Fragment>
        </div>
      );
    } else {
      billingPostcodesDisplay = null;
    }

    let questionA = '';
    let questionB = '';
    let questionC = '';
    let questionD = '';
    let questionE = '';
    let subsRows = (
      <React.Fragment>
        {subs.map((sub) => {
          return (
            <Table.Row key={sub.sd + sub.paid}>
              <Table.Cell>{sub.type}</Table.Cell>
              <Table.Cell>{sub.sd}</Table.Cell>
              <Table.Cell>{sub.ed}</Table.Cell>
              <Table.Cell>{sub.rd}</Table.Cell>
              <Table.Cell>{sub.paid}</Table.Cell>
            </Table.Row>
          );
        })}
      </React.Fragment>
    );

    if (this.state.customQuestionA !== '') {
      questionA = (
        <div style={{ padding: '10px' }}>
          <Form.Select
            options={yesNooptions}
            label={this.state.customQuestionA}
            onChange={this.handleQuestionAChange}
            placeholder=''
            fluid
            size='large'
            selection
            name='customQuestionAans'
            value={this.state.customQuestionAans}
            error={errors.customQuestionAans}
          />
        </div>
      );
    }

    if (this.state.customQuestionB !== '') {
      questionB = (
        <div style={{ padding: '10px' }}>
          <Form.Select
            options={yesNooptions}
            label={this.state.customQuestionB}
            onChange={this.handleQuestionBChange}
            placeholder=''
            fluid
            size='large'
            selection
            name='customQuestionBans'
            value={this.state.customQuestionBans}
            error={errors.customQuestionBans}
          />
        </div>
      );
    }

    if (this.state.customQuestionC !== '') {
      questionC = (
        <RenderFieldLabelAbove
          name='customQuestionCans'
          value={this.state.customQuestionCans}
          label={this.state.customQuestionC}
          onChange={this.onChangeHandler}
          error={errors.customQuestionCans}
        />
      );
    }

    if (this.state.customQuestionD !== '') {
      questionD = (
        <RenderFieldLabelAbove
          name='customQuestionDans'
          value={this.state.customQuestionDans}
          label={this.state.customQuestionD}
          onChange={this.onChangeHandler}
          error={errors.customQuestionDans}
        />
      );
    }

    if (this.state.customQuestionE !== '') {
      questionE = (
        <RenderFieldLabelAbove
          name='customQuestionEans'
          value={this.state.customQuestionEans}
          label={this.state.customQuestionE}
          onChange={this.onChangeHandler}
          error={errors.customQuestionEans}
        />
      );
    }

    let displayForm = '';
    const { loading } = this.props.reader;

    if (loading) {
      displayForm = <Spinner />;
    } else {
      displayForm = (
        <React.Fragment>
          <Form onSubmit={this.onSubmitHandler}>
            <Segment>
              <Header as='h2' textAlign='center'>
                Edit Reader -{' '}
                {this.state.isDeleted ? 'Deactivated Reader' : 'Active Reader'}
              </Header>
            </Segment>
            <Grid stackable columns={3}>
              <Grid.Column>
                <Segment>
                  <RenderFieldLabelAbove
                    name='title'
                    value={this.state.title}
                    label='Title'
                    onChange={this.onChangeHandler}
                    placeholder='Title'
                    error={errors.title}
                  />
                  <RenderFieldLabelAbove
                    name='firstName'
                    value={this.state.firstName}
                    label='First Name'
                    onChange={this.onChangeHandler}
                    placeholder='First Name'
                    error={errors.firstName}
                  />
                  <RenderFieldLabelAbove
                    name='lastName'
                    value={this.state.lastName}
                    onChange={this.onChangeHandler}
                    placeholder='Last Name'
                    error={errors.lastName}
                    label='Last Name'
                  />
                  <RenderFieldLabelAbove
                    name='fullName'
                    value={this.state.fullName}
                    onChange={this.onChangeHandler}
                    placeholder='Full Name'
                    error={errors.fullName}
                    label='Full Name'
                  />
                  <RenderFieldLabelAbove
                    name='userName'
                    value={this.state.userName}
                    onChange={this.onChangeHandler}
                    placeholder='User Name'
                    error={errors.userName}
                    label='User Name'
                    autocomplete='new-password'
                  />
                  <RenderFieldLabelAbove
                    name='password'
                    value={this.state.password}
                    onChange={this.onChangeHandler}
                    placeholder='Password'
                    error={errors.password}
                    type='password'
                    label='Password'
                    autocomplete='new-password'
                  />
                  <RenderFieldLabelAbove
                    value={this.state.uniqueReference}
                    onChange={this.onChangeHandler}
                    placeholder='Unique Reference'
                    name='uniqueReference'
                    error={errors.uniqueReference}
                    label='Unique Reference *'
                  />
                  <p style={{ padding: '10px', fontSize: '16px' }}>
                    Last Modified: {this.state.modified}
                    <br />
                    <br />
                    Modified By: {this.state.modifiedBy}
                  </p>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <div style={{ padding: '10px' }}>
                    <Form.Select
                      options={countryOptions}
                      label='Country'
                      onChange={this.handleCountryChange}
                      placeholder='Country'
                      fluid
                      size='large'
                      selection
                      name='country'
                      value={this.state.country}
                      error={errors.country}
                    />
                  </div>

                  {billingPCLookupDisplay}
                  {billingPostcodesDisplay}

                  <RenderFieldLabelAbove
                    name='addressOne'
                    value={this.state.addressOne}
                    onChange={this.onChangeHandler}
                    placeholder='Address 1'
                    error={errors.addressOne}
                    label='Address 1'
                  />
                  <RenderFieldLabelAbove
                    name='addressTwo'
                    value={this.state.addressTwo}
                    onChange={this.onChangeHandler}
                    placeholder='Address 2'
                    error={errors.addressTwo}
                    label='Address 2'
                  />
                  <RenderFieldLabelAbove
                    name='addressThree'
                    value={this.state.addressThree}
                    onChange={this.onChangeHandler}
                    placeholder='Address 3'
                    error={errors.addressThree}
                    label='Address 3'
                  />
                  <RenderFieldLabelAbove
                    name='city'
                    value={this.state.city}
                    onChange={this.onChangeHandler}
                    placeholder='City'
                    error={errors.city}
                    label='City'
                  />
                  <RenderFieldLabelAbove
                    name='state'
                    value={this.state.state}
                    onChange={this.onChangeHandler}
                    placeholder='County/State'
                    error={errors.state}
                    label='County/State'
                  />

                  <RenderFieldLabelAbove
                    name='postZip'
                    value={this.state.postZip}
                    onChange={this.onChangeHandler}
                    placeholder='Post/Zip Code'
                    error={errors.postZip}
                    label='Post/Zip Code'
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <RenderFieldLabelAbove
                    name='companyName'
                    value={this.state.companyName}
                    label='Company Name'
                    onChange={this.onChangeHandler}
                    placeholder='Company Name'
                    error={errors.companyName}
                  />

                  <RenderFieldLabelAbove
                    name='telephone'
                    value={this.state.telephone}
                    label='Telephone'
                    onChange={this.onChangeHandler}
                    placeholder='Telephone'
                    error={errors.telephone}
                  />
                  <RenderFieldLabelAbove
                    name='fax'
                    value={this.state.fax}
                    onChange={this.onChangeHandler}
                    placeholder='Fax'
                    error={errors.fax}
                    label='Fax'
                  />
                  <RenderFieldLabelAbove
                    type='email'
                    name='email'
                    value={this.state.email}
                    onChange={this.onChangeHandler}
                    placeholder='Email Address'
                    error={errors.email}
                    label='Email Address'
                  />
                  <RenderFieldLabelAbove
                    name='webAddress'
                    value={this.state.webAddress}
                    onChange={this.onChangeHandler}
                    placeholder='Web Address'
                    error={errors.webAddress}
                    label='Web Address'
                  />
                  <RenderFieldLabelAbove
                    value={this.state.dataSourceOne}
                    onChange={this.onChangeHandler}
                    placeholder='Data Source Note 1'
                    name='dataSourceOne'
                    error={errors.dataSourceOne}
                    label='Data Source Note 1'
                  />
                  <RenderFieldLabelAbove
                    value={this.state.dataSourceTwo}
                    onChange={this.onChangeHandler}
                    placeholder='Data Source Note 2'
                    name='dataSourceTwo'
                    error={errors.dataSourceTwo}
                    label='Data Source Note 2'
                  />
                </Segment>
              </Grid.Column>
            </Grid>
            {this.state.showQuestionArea ? (
              <Segment>
                <Header as='h2' textAlign='center'>
                  Questions
                </Header>
                {questionA}
                {questionB}
                {questionC}
                {questionD}
                {questionE}
              </Segment>
            ) : null}
            <Segment>
              <Header as='h2' textAlign='center'>
                Subscription
              </Header>
              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={2}>Type</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Start Date</Table.HeaderCell>
                    <Table.HeaderCell width={3}>End Date</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Renewal Date</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Paid</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Select
                        options={subOptions}
                        onChange={this.handleSubChange}
                        fluid
                        size='large'
                        selection
                        name='type'
                        value={this.state.type}
                        error={errors.type}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <RenderDatePickerTable
                        name='sd'
                        value={this.state.sd}
                        onChange={this.handleDateChange}
                        error={errors.sd}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <RenderDatePickerTable
                        name='ed'
                        value={this.state.ed}
                        onChange={this.handleDateChange}
                        error={errors.ed}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <RenderDatePickerTable
                        name='rd'
                        value={this.state.rd}
                        onChange={this.handleDateChange}
                        error={errors.rd}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <RenderFieldLabelAbove
                        value={this.state.paid}
                        onChange={this.onChangeHandler}
                        placeholder='Amount Paid (leave blank for free)'
                        name='paid'
                        error={errors.paid}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Header>Subscription History</Header>
              <TextArea
                value={this.state.history}
                placeholder=''
                readOnly
                name='history'
                error={errors.history}
              />
            </Segment>
            <Segment>
              <Header as='h2' textAlign='center'>
                Marketing Preferences{' '}
                <span style={{ fontSize: '14px' }}>(tick to opt out)</span>
              </Header>
              <Checkbox
                name='pubOffers'
                label='Emails'
                onChange={this.onCheckedHandler}
                checked={this.state.pubOffers}
                style={{ marginRight: '20px' }}
              />
              <Checkbox
                name='pubOffersPost'
                label='Postal'
                onChange={this.onPostCheckedHandler}
                checked={this.state.pubOffersPost}
                style={{ marginRight: '20px' }}
              />
              <Checkbox
                name='pubOffersTel'
                label='Telephone'
                onChange={this.onTelCheckedHandler}
                checked={this.state.pubOffersTel}
                style={{ marginRight: '20px' }}
              />
              <Checkbox
                name='pubOffersSMS'
                label='SMS'
                onChange={this.onSMSCheckedHandler}
                checked={this.state.pubOffersSMS}
                style={{ marginRight: '20px' }}
              />
              <Checkbox
                name='partnerOffers'
                label='Partner Emails'
                onChange={this.onPartnerCheckedHandler}
                checked={this.state.partnerOffers}
                style={{ marginRight: '20px' }}
              />
              <Checkbox
                name='partnerOffersPost'
                label='Partner Postal'
                onChange={this.onPartnerPostCheckedHandler}
                checked={this.state.partnerOffersPost}
                style={{ marginRight: '20px' }}
              />
              <Checkbox
                name='partnerOffersTel'
                label='Partner Telephone'
                onChange={this.onPartnerTelCheckedHandler}
                checked={this.state.partnerOffersTel}
                style={{ marginRight: '20px' }}
              />
              <Checkbox
                name='partnerOffersSMS'
                label='Partner SMS'
                onChange={this.onPartnerSMSCheckedHandler}
                checked={this.state.partnerOffersSMS}
                style={{ marginRight: '20px' }}
              />
            </Segment>
            <Segment>
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column>
                    <Button color='blue' type='submit' fluid>
                      Update Reader
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    {this.state.isDeleted ? (
                      <Button
                        color='yellow'
                        type='button'
                        onClick={this.reactivateReader}
                        fluid
                      >
                        Reactivate Reader
                      </Button>
                    ) : (
                      <Button
                        color='red'
                        type='button'
                        onClick={this.deactivateReader}
                        fluid
                      >
                        Deactivate Reader
                      </Button>
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      color='red'
                      type='button'
                      onClick={this.deleteReader}
                      fluid
                    >
                      Remove Reader
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Form>
        </React.Fragment>
      );
    }

    return <React.Fragment>{displayForm}</React.Fragment>;
  }
}

EditReader.propTypes = {
  getReader: PropTypes.func.isRequired,
  getPublication: PropTypes.func.isRequired,
  updateReader: PropTypes.func.isRequired,
  deactivateReader: PropTypes.func.isRequired,
  reactivateReader: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  reader: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors,
  publication: state.publication.publication,
  reader: state.reader,
});

export default connect(mapStateToProps, {
  getReader,
  getPublication,
  updateReader,
  deactivateReader,
  reactivateReader,
  deleteReader,
})(withRouter(EditReader));
