// Renders a Redux Form Input Field with label to left and asterisk to the right

import React from "react";
import { Input, Label, Icon } from "semantic-ui-react";

const RenderFieldLeftAndRequiredRedux = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error },
}) => (
  <div>
    <div style={{ padding: "10px" }}>
      <Input
        fluid
        {...input}
        size="small"
        labelPosition="right corner"
        type={type}
        placeholder={placeholder}
        error={touched && error ? true : false}
      >
        <Label color="blue" inverted="true">
          {label}
        </Label>
        <input />
        <Label color="blue" inverted="true" corner="right">
          <Icon name="asterisk" size="small" />
        </Label>
      </Input>
      {touched && error && (
        <div className="ui pointing red basic label">{error}</div>
      )}
    </div>
  </div>
);

RenderFieldLeftAndRequiredRedux.defaultProps = {
  type: "text",
};

export default RenderFieldLeftAndRequiredRedux;
