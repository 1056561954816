// Renders a Redux Form Input Field with label to the left

import React from "react";
import { Input, Label } from "semantic-ui-react";

const RenderFieldLeftRedux = ({
  input,
  label,
  icon,
  type,
  placeholder,
  meta: { touched, error },
}) => (
  <div style={{ padding: "10px" }}>
    <Input
      fluid
      {...input}
      size="small"
      labelPosition="left"
      type={type}
      error={touched && error ? true : false}
      placeholder={placeholder}
    >
      <Label color="blue">{label}</Label>
      <input />
    </Input>
    {touched && error && (
      <div className="ui pointing red basic label">{error}</div>
    )}
  </div>
);

export default RenderFieldLeftRedux;
