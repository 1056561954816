// Publication Information

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Segment, Button, Label, Input, Form } from "semantic-ui-react";
import EditForm from "./EditForm";
import {
  getBackIssue,
  updateBackIssue,
} from "../../../actions/backIssueActions";

class EditBackIssue extends Component {
  componentDidMount() {
    this.props.getBackIssue(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    console.log(formValues);
    // Save all the data in MongoDB
    return this.props.updateBackIssue(formValues, this.props.history);
  };

  render() {
    return <EditForm onSubmit={this.onSubmit} />;
  }
}
const mapStateToProps = (state) => {};

export default connect(mapStateToProps, { getBackIssue, updateBackIssue })(
  withRouter(EditBackIssue)
);
