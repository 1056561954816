import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getPromos } from '../../actions/publicationActions';
import PromoCodeItem from './components/PromoCodeItem';
import { CardGroup, Button } from 'semantic-ui-react';
import Spinner from '../common/Spinner';
import { isUserLoggedIn } from '../common/isUserLoggedIn';


class PromoCodes extends Component {
  componentDidMount() {
    if (this.props.auth.user.userType === "publisher" || this.props.auth.user.userType === 'pubadmin') {
      this.props.getPromos(this.props.match.params.id);
    } else {
      window.location.href = "/readers"
    }

  }

  componentWillReceiveProps() {
    isUserLoggedIn();
  }

  render() {
    // TODO: Change this to props and a let with a spinner
    let promoItems;
    const { promos, loading } = this.props.publication;

    if (loading) {
      promoItems = <Spinner />;
    } else {
      if (promos !== null) {
        if (promos.length === 0) {
          promoItems = (
            <div style={{ margin: "10px" }}>
              <h4>No Promo Codes found for this Publication</h4>
              <p>To add one, please click the Blue circle to the right</p>
            </div>
          )
        }
        else {
          if (promos.length > 0) {
            promoItems = promos.map(promo => (
              <PromoCodeItem key={promo._id} promo={promo} />
            ))
          }
        }
      }
    };

    return (
      <React.Fragment>
        <Link to={`/addpromo/${this.props.match.params.id}`}>
          <Button circular icon="plus circle" size="massive" color="blue" floated="right" />
        </Link>
        <CardGroup itemsPerRow={8}>
          {promoItems}
        </CardGroup>
      </React.Fragment>
    )
  }
}

PromoCodes.propTypes = {
  getPromos: PropTypes.func.isRequired,
  publication: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  publication: state.publication,
  auth: state.auth
})

export default connect(mapStateToProps, { getPromos })(PromoCodes);
