// Login Component

import React, { Component } from 'react';
import axios from 'axios';
import { Form, Grid, Header, Image, Segment, Button } from 'semantic-ui-react';
import RenderFieldWithRightLabel from '../common/NormalForm/RenderFieldWithRightLabel';

//import Logo from '../../assets/img/ms200blk.png';
import Logo from '../../assets/img/ms200blkNew.png';

class ResetBackend extends Component {
  state = {
    password: '',
    password2: '',
    passwordError: '',
    emailResetMessage: '',
    showForm: true
  };

  componentDidMount() {
    // First check it's a valid token or not
    var path = this.props.match.params.id;
    axios
      .get(`/api/users/reset/${path}`)
      .then(res => {
        if (res.data === "Invalid") {
          this.setState({ showForm: false })
        } else {
          this.setState({ showForm: true })
        }
      })
  }

  // Submit function
  onSubmit = (e) => {
    e.preventDefault();
    // Firstly check the fields are identical
    if (this.state.password === this.state.password2) {
      var path = this.props.match.params.id;
      const userData = {
        password: this.state.password
      };

      axios
        .post(`/api/users/reset/${path}`, userData)
        .then(res => {
          if (res.data === "Invalid") {
            this.setState({ showForm: false })
          } else {
            this.setState({ emailResetMessage: "Thank you. Your password has been successfully reset and a confirmation email has been sent.", emailError: "" })
          }

        })
    } else {
      this.setState({ emailError: "The passwords must match. " })
    }
  }

  // Controlled Component Change event
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    let showFormData;

    this.state.showForm ?
      showFormData = (
        <Form onSubmit={this.onSubmit}>
          <RenderFieldWithRightLabel
            placeholder='New Password'
            name='password'
            label='New Password'
            value={this.state.password}
            onChange={this.onChange}
            error={this.state.emailError1}
            type='password'
          />
          <RenderFieldWithRightLabel
            placeholder='Confirm New Password'
            name='password2'
            label='Confirm New Password'
            value={this.state.password2}
            onChange={this.onChange}
            error={this.state.emailError}
            type='password'
          />

          <div style={{ padding: "10px" }}>
            <Button color="blue" fluid type="submit">Reset Password</Button>
          </div>
          {this.state.emailResetMessage}
        </Form>
      ) :
      showFormData = (
        <div><p>The token you have provided is either incorrect or has expired. Please try again if you'd like to continue with your password reset</p></div>
      )

    return (
      <div className='login-form'>
        <style>{`
          body > div,
          body > div > div,
          body > div > div > div.login-form {
            height: 100%;
          }
          body { background: #2185d0; }
        `}
        </style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Segment>
              <Header as='h2' color='black' textAlign='center'>
                <Image src={Logo} alt='Magstand Logo' style={{ width: "150px" }} />
              </Header>
              <Header as='h3' color='black' textAlign='center'>Password Reset</Header>
              {showFormData}
            </Segment>
          </Grid.Column>
        </Grid>
      </div>

    );
  }
}

export default (ResetBackend);
