// Controlled Questions

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Segment, Header } from 'semantic-ui-react';
import RenderFieldLabelAboveRedux from '../../../common/ReduxForm/RenderFieldLabelAboveRedux';

class Questions extends Component {


  render() {
    const { handleSubmit, previousPage, submitting } = this.props;
    return (
      <Form onSubmit={handleSubmit} size='large'>
        <Segment fluid="true">
          <Header as="h2">Controlled Circulation (for controlled free circulations only)</Header>
          <Field
            name="customQuestionA"
            component={RenderFieldLabelAboveRedux}
            type="text"
            label="Yes/no option, answer must be yes to qualify (Q1)"
          />
          <Field
            name="customQuestionB"
            component={RenderFieldLabelAboveRedux}
            type="text"
            label="Yes/no option, answer must be yes to qualify (Q2)"
          />
        </Segment>
        <Segment fluid="true">
          <Header as="h2">Reader Survey</Header>
          <Field
            name="customQuestionC"
            component={RenderFieldLabelAboveRedux}
            type="text"
            label="General registration question 1: (Q3)"
          />
          <Field
            name="customQuestionD"
            component={RenderFieldLabelAboveRedux}
            type="text"
            label="General registration question 2: (Q4)"
          />
        </Segment>
        <Segment fluid="true">
          <Header as="h2">Audit Confirmation</Header>
          <Field
            name="customQuestionE"
            component={RenderFieldLabelAboveRedux}
            type="text"
            label="ABC audit confirmation question (Q5)"
          />
        </Segment>
        <div style={{ margin: "10px", textAlign: "center" }}>
          <Button color='blue'
            onClick={previousPage}>
            Previous
            </Button>
          <Button color='blue'
            disabled={submitting}>
            Next
            </Button>
        </div>
      </Form>
    )
  }
}


export default reduxForm({
  form: 'addwizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(Questions);