import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header, Card, Segment, Button, Modal, Grid } from 'semantic-ui-react';
import axios from 'axios';
import {
  getNoFullNameCount,
  getNoPostcodeCount,
  getExpiredThirtyCount,
  getExpiredCount,
  getNoFullName,
  getNoPostcode,
  getExpired,
  getExpiredThirty,
  autoFixNoFullName,
  getAllData,
  getDuplicateFullNameCount,
  getNoFirstName,
  getNoFirstNameCount,
  autoFixNoFirstName,
  getDuplicateFullName,
  getDuplicateLastNameCount,
  getDuplicateLastName,
  getDuplicatePostCodeCount,
  getDuplicatePostCode,
  getDuplicateEmailCount,
  getDuplicateEmails,
  getDuplicateAddressCount,
  getBadAddressCount,
  getBadAddress,
  getDuplicateAddress,
  deleteReaderAddress,
  deleteReaderFullName,
  deleteReaderLastName,
  deleteReaderEmail,
  autoFixAddress,
  deleteReaderPostCode,
  deleteReader,
  clearData,
  getBadPostcode,
  getBadPostcodeCount,
} from '../../../actions/readerActions';
import { CSVLink } from 'react-csv';
import DataTable from '../../common/DataTable';
import DuplicateDataTable from '../../common/DuplicateDataTable';
import AddressDataTable from '../../common/AddressDataTable';
import Spinner from '../../common/Spinner';

class DataLayout extends Component {
  state = {
    pubId: null,
    allData: [],
    publicationOptions: [],
    showReader: false,
    showModal: false,
    showDuplicateFullName: false,
    showDuplicateLastName: false,
    showDuplicatePostCode: false,
    showDuplicateAddress: false,
    showDuplicateEmails: false,
    showBadPostcodes: false,
    showBadAddresses: false,
    count: 0,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.pubid) {
      if (nextProps.pubid !== this.state.pubId) {
        this.props.getBadPostcodeCount(nextProps.pubid);
        this.props.getNoFullNameCount(nextProps.pubid);
        this.props.getNoFirstNameCount(nextProps.pubid);
        this.props.getNoPostcodeCount(nextProps.pubid);
        this.props.getExpiredCount(nextProps.pubid);
        this.props.getExpiredThirtyCount(nextProps.pubid);
        this.props.getDuplicateFullNameCount(nextProps.pubid);
        this.props.getDuplicateLastNameCount(nextProps.pubid);
        this.props.getDuplicatePostCodeCount(nextProps.pubid);
        this.props.getDuplicateAddressCount(nextProps.pubid);
        this.props.getDuplicateEmailCount(nextProps.pubid);
        //this.props.getBadAddressCount(nextProps.pubid);
        this.setState({ pubId: nextProps.pubid });
      }
    }
    if (
      nextProps.reader.duplicateFullNames.length > 0 &&
      this.state.count !== nextProps.reader.duplicateFullNames.length
    ) {
      if (!this.state.showDuplicateFullName) {
        this.setState({
          count: nextProps.reader.duplicateFullNames.length,
          showDuplicateFullName: true,
          showReader: false,
          showDuplicateLastName: false,
          showDuplicatePostCode: false,
          showDuplicateAddress: false,
          showDuplicateEmails: false,
          showBadPostcodes: false,
          showBadAddresses: false,
        });
      }
    }
    if (
      nextProps.reader.duplicateLastNames.length > 0 &&
      this.state.count !== nextProps.reader.duplicateLastNames.length
    ) {
      if (!this.state.showDuplicateLastName) {
        this.setState({
          count: nextProps.reader.duplicateLastNames.length,
          showDuplicateLastName: true,
          showReader: false,
          showDuplicateFullName: false,
          showDuplicatePostCode: false,
          showDuplicateAddress: false,
          showDuplicateEmails: false,
          showBadPostcodes: false,
          showBadAddresses: false,
        });
      }
    }
    if (
      nextProps.reader.duplicatePostCodes.length > 0 &&
      this.state.count !== nextProps.reader.duplicatePostCodes.length
    ) {
      if (!this.state.showDuplicatePostCode) {
        this.setState({
          count: nextProps.reader.duplicatePostCodes.length,
          showDuplicatePostCode: true,
          showReader: false,
          showDuplicateFullName: false,
          showDuplicateLastName: false,
          showDuplicateAddress: false,
          showDuplicateEmails: false,
          showBadPostcodes: false,
          showBadAddresses: false,
        });
      }
    }
    if (
      nextProps.reader.duplicateAddresses.length > 0 &&
      this.state.count !== nextProps.reader.duplicateAddresses.length
    ) {
      if (!this.state.showDuplicateAddress) {
        this.setState({
          count: nextProps.reader.duplicateAddresses.length,
          showDuplicateAddress: true,
          showReader: false,
          showDuplicateLastName: false,
          showDuplicatePostCode: false,
          showDuplicateFullName: false,
          showDuplicateEmails: false,
          showBadPostcodes: false,
          showBadAddresses: false,
        });
      }
    }
    if (
      nextProps.reader.duplicateEmails.length > 0 &&
      this.state.count !== nextProps.reader.duplicateEmails.length
    ) {
      if (!this.state.showDuplicateEmails) {
        this.setState({
          count: nextProps.reader.duplicateEmails.length,
          showDuplicateAddress: false,
          showReader: false,
          showDuplicateLastName: false,
          showDuplicatePostCode: false,
          showDuplicateFullName: false,
          showDuplicateEmails: true,
          showBadPostcodes: false,
          showBadAddresses: false,
        });
        console.log('got here emails');
      }
    }
    if (
      nextProps.reader.badPostcodes.length > 0 &&
      this.state.count !== nextProps.reader.badPostcodes.length
    ) {
      if (!this.state.showBadPostcodes) {
        this.setState({
          count: nextProps.reader.badPostcodes.length,
          showDuplicateAddress: false,
          showReader: false,
          showDuplicateLastName: false,
          showDuplicatePostCode: false,
          showDuplicateFullName: false,
          showBadPostcodes: true,
          showDuplicateEmails: false,
          showBadAddresses: false,
        });
      }
    }
    if (
      nextProps.reader.badAddress.length > 0 &&
      this.state.count !== nextProps.reader.badAddress.length
    ) {
      if (!this.state.showBadAddresses) {
        console.log(nextProps.reader.badAddress.length);
        this.setState({
          count: nextProps.reader.badAddress.length,
          showDuplicateAddress: false,
          showReader: false,
          showDuplicateLastName: false,
          showDuplicatePostCode: false,
          showDuplicateFullName: false,
          showBadAddresses: true,
          showBadPostcodes: false,
          showDuplicateEmails: false,
        });
      }
    }
    if (
      nextProps.reader.readers.length > 0 &&
      this.state.count !== nextProps.reader.readers.length
    ) {
      if (!this.state.showReader) {
        this.setState({
          count: nextProps.reader.readers.length,
          showReader: true,
          showDuplicateFullName: false,
          showDuplicateLastName: false,
          showDuplicatePostCode: false,
          showDuplicateAddress: false,
          showDuplicateEmails: false,
          showBadPostcodes: false,
          showBadAddresses: false,
        });
      }
    }
    console.log(this.state);
  }

  downloadAllData = (id) => {
    this.setState({ showAllModal: true });
    setTimeout(() => {
      axios
        .get(`/api/subscriber/expireddownload/${id}`)
        .then((res) => {
          if (res.data.length > 0) {
            this.setState({ allData: res.data });
          }
        })
        .then((res) => {
          let btn = this.refs.all;
          btn.link.click();
          this.setState({ allData: [] });
        })
        .catch((err) => console.log(err));
    }, 100);
  };

  downloadNext30 = (id) => {
    this.setState({ showAllModal: true });
    setTimeout(() => {
      axios
        .get(`/api/subscriber/expiredthirtydownload/${id}`)
        .then((res) => {
          if (res.data.length > 0) {
            this.setState({ allData: res.data });
          }
        })
        .then((res) => {
          let btn = this.refs.thirty;
          btn.link.click();
          this.setState({ allData: [] });
        })
        .catch((err) => console.log(err));
    }, 100);
  };

  doneDownloading = () => {
    this.setState({ showModal: false });
  };

  onNoFullNameHandler = (e) => {
    e.preventDefault();
    this.props.getNoFullName(this.state.pubId);
  };

  onNoFirstNameHandler = (e) => {
    e.preventDefault();
    this.props.getNoFirstName(this.state.pubId);
  };

  onDuplicateFullNameHandler = (e) => {
    e.preventDefault();
    this.props.getDuplicateFullName(this.state.pubId);
  };

  onDuplicateLastNameHandler = (e) => {
    e.preventDefault();
    this.props.getDuplicateLastName(this.state.pubId);
  };

  onDuplicatePostCodeHandler = (e) => {
    e.preventDefault();
    this.props.getDuplicatePostCode(this.state.pubId);
  };

  onDuplicateAddressHandler = (e) => {
    e.preventDefault();
    this.props.getDuplicateAddress(this.state.pubId);
  };

  onDuplicateEmailsHandler = (e) => {
    e.preventDefault();
    this.props.getDuplicateEmails(this.state.pubId);
  };

  autoFixNoFullName = (e) => {
    e.preventDefault();
    this.props.autoFixNoFullName(this.state.pubId);
  };

  autoFixNoFirstName = (e) => {
    e.preventDefault();
    this.props.autoFixNoFirstName(this.state.pubId);
  };

  autoFixAddress = (id) => {
    console.log(id);
    this.props.autoFixAddress(id);
  };

  onNoPostcodeHandler = (e) => {
    e.preventDefault();
    this.props.getNoPostcode(this.state.pubId);
  };

  onBadPostcodeHandler = (e) => {
    e.preventDefault();
    this.props.getBadPostcode(this.state.pubId);
  };

  onBadAddressHandler = (e) => {
    console.log('here');
    e.preventDefault();
    this.props.getBadAddress(this.state.pubId);
  };

  onExpiredHandler = (e) => {
    e.preventDefault();
    this.props.getExpired(this.state.pubId);
  };

  onExpiredThirtyHandler = (e) => {
    e.preventDefault();
    this.props.getExpiredThirty(this.state.pubId);
  };

  onAllDataHandler = (e) => {
    e.preventDefault();
    this.props.getAllData(this.state.pubId);
  };

  deleteReaderFullName = (id) => {
    this.props.deleteReaderFullName(id, this.state.pubId);
  };

  deleteReaderEmail = (id) => {
    this.props.deleteReaderEmail(id, this.state.pubId);
  };

  deleteReaderLastName = (id) => {
    this.props.deleteReaderLastName(id, this.state.pubId);
  };

  deleteReaderPostCode = (id) => {
    this.props.deleteReaderPostCode(id, this.state.pubId);
  };

  deleteReaderAddress = (id) => {
    this.props.deleteReaderAddress(id, this.state.pubId);
  };

  deleteReader = (id) => {
    this.props.deleteReader(id, this.state.pubId);
  };

  render() {
    let noFullName;
    let noFirstName;
    let noPostcode;
    let expiredSubs;
    let expiredSubs30;
    let duplicateFullName;
    let duplicateLastName;
    let duplicatePostCode;
    let duplicateAddress;
    let duplicateEmail;
    let badPostcode;
    let badAddresses;
    const {
      readers,
      badAddress,
      duplicateFullNames,
      duplicateLastNames,
      duplicatePostCodes,
      duplicateAddresses,
      duplicateEmails,
      badPostcodes,
    } = this.props.reader;
    let readerTable = '';

    let displayModal;

    const headers = {
      _id: {
        text: 'Id',
        invisible: true,
        sortable: false,
        filterable: false,
      },
      // title: {
      //   text: 'Title',
      //   invisible: false,
      //   sortable: true,
      //   filterable: true
      // },
      // firstName: {
      //   text: 'First Name',
      //   invisible: false,
      //   sortable: true,
      //   filterable: true
      // },
      // lastName: {
      //   text: 'Last Name',
      //   invisible: false,
      //   sortable: true,
      //   filterable: true
      // },
      fullName: {
        text: 'Full Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      companyName: {
        text: 'Company',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      postCode: {
        text: 'Post Code',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      email: {
        text: 'Email',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      uniqueReference: {
        text: 'Unique Reference',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      action: {
        text: 'View',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Link to={`/editreader/${row._id}`}>
              <Button fluid color='green'>
                View
              </Button>
            </Link>
          );
        },
      },
      placeholder: {
        text: 'Remove',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Button
              type='button'
              onClick={(e) => {
                if (
                  window.confirm(
                    'Are you sure you want to remove this Reader from the system?',
                  )
                )
                  this.deleteReader(row._id);
              }}
              fluid
              color='red'
            >
              Remove
            </Button>
          );
        },
      },
    };

    const dupFNHeaders = {
      'data.fullName': {
        text: 'Full Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.title': {
        text: 'Title',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.firstName': {
        text: 'First Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.lastName': {
        text: 'Last Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.addressOne': {
        text: 'Address 1',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.postCode': {
        text: 'Post Code',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.creationDate': {
        text: 'Created',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.subType': {
        text: 'Sub Type',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.subPaid': {
        text: 'Amount',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.id': {
        text: 'Remove',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Button
              type='button'
              onClick={(e) => {
                if (
                  window.confirm(
                    'Are you sure you want to remove this Reader from the system?',
                  )
                )
                  this.deleteReaderFullName(value);
              }}
              fluid
              color='red'
            >
              Remove
            </Button>
          );
        },
      },
    };

    const badAddressHeaders = {
      _id: {
        text: '',
        invisible: true,
        sortable: false,
        filterable: false,
      },
      fullName: {
        text: 'Full Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      title: {
        text: 'Title',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      firstName: {
        text: 'First Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      lastName: {
        text: 'Last Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      addressOne: {
        text: 'Address 1',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      addressTwo: {
        text: 'Address 2',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      addressThree: {
        text: 'Address 1',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      city: {
        text: 'City',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      state: {
        text: 'Address 1',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      postCode: {
        text: 'Post Code',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      subStartDate: {
        text: 'View',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Link to={`/editreader/${row._id}`}>
              <Button fluid color='green'>
                View
              </Button>
            </Link>
          );
        },
      },
      subEndDate: {
        text: 'Auto Fix',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Button
              type='button'
              onClick={(e) =>
                window.confirm(
                  "Are you sure you want to attempt to automatically fix this Reader's address?",
                ) && this.autoFixAddress(row._id)
              }
              fluid
              color='blue'
            >
              Auto Fix
            </Button>
          );
        },
      },
    };

    const dupEmailHeaders = {
      'data.fullName': {
        text: 'Full Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.title': {
        text: 'Title',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.firstName': {
        text: 'First Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.lastName': {
        text: 'Last Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.addressOne': {
        text: 'Address 1',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.postCode': {
        text: 'Post Code',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.email': {
        text: 'Email',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.creationDate': {
        text: 'Created',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.subType': {
        text: 'Sub Type',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.subPaid': {
        text: 'Amount',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.id': {
        text: 'View',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Link to={`/editreader/${value}`}>
              <Button fluid color='green'>
                View
              </Button>
            </Link>
          );
        },
      },
      'data.ed': {
        text: 'Remove',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Button
              type='button'
              onClick={(e) => {
                if (
                  window.confirm(
                    'Are you sure you want to remove this Reader from the system?',
                  )
                )
                  this.deleteReaderEmail(value);
              }}
              fluid
              color='red'
            >
              Remove
            </Button>
          );
        },
      },
    };

    const dupLNHeaders = {
      'data.fullName': {
        text: 'Full Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.title': {
        text: 'Title',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.firstName': {
        text: 'First Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.lastName': {
        text: 'Last Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.addressOne': {
        text: 'Address 1',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.postCode': {
        text: 'Post Code',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.creationDate': {
        text: 'Created',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.subType': {
        text: 'Sub Type',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.subPaid': {
        text: 'Amount',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.id': {
        text: 'Remove',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Button
              type='button'
              onClick={(e) => {
                if (
                  window.confirm(
                    'Are you sure you want to remove this Reader from the system?',
                  )
                ) {
                  this.deleteReaderLastName(value);
                }
              }}
              fluid
              color='red'
            >
              Remove
            </Button>
          );
        },
      },
    };

    const badPCHeaders = {
      title: {
        text: 'Title',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      firstName: {
        text: 'First Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      lastName: {
        text: 'Last Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      fullName: {
        text: 'Full Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      addressOne: {
        text: 'Address 1',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      addressTwo: {
        text: 'Address 2',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      addressThree: {
        text: 'Address 3',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      city: {
        text: 'City',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      postCode: {
        text: 'Post Code',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      id: {
        text: 'View',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Link to={`/editreader/${value}`}>
              <Button fluid color='green'>
                View
              </Button>
            </Link>
          );
        },
      },
    };

    const dupPCHeaders = {
      'data.fullName': {
        text: 'Full Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.title': {
        text: 'Title',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.firstName': {
        text: 'First Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.lastName': {
        text: 'Last Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.addressOne': {
        text: 'Address 1',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.postCode': {
        text: 'Post Code',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.creationDate': {
        text: 'Created',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.subType': {
        text: 'Sub Type',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.subPaid': {
        text: 'Amount',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.id': {
        text: 'Remove',
        invisible: false,
        transform: (value, index, row) => {
          // console.log(value);
          return (
            <Button
              type='button'
              onClick={(e) => {
                if (
                  window.confirm(
                    'Are you sure you want to remove this Reader from the system?',
                  )
                )
                  this.deleteReaderPostCode(value);
              }}
              fluid
              color='red'
            >
              Remove
            </Button>
          );
        },
      },
    };

    const dupAHeaders = {
      'data.fullName': {
        text: 'Full Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.title': {
        text: 'Title',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.firstName': {
        text: 'First Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.lastName': {
        text: 'Last Name',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.addressOne': {
        text: 'Address 1',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.postCode': {
        text: 'Post Code',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.creationDate': {
        text: 'Created',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.subType': {
        text: 'Sub Type',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.subPaid': {
        text: 'Amount',
        invisible: false,
        sortable: true,
        filterable: true,
      },
      'data.id': {
        text: 'Remove',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Button
              type='button'
              onClick={(e) => {
                if (
                  window.confirm(
                    'Are you sure you want to remove this Reader from the system?',
                  )
                )
                  this.deleteReaderAddress(value);
              }}
              fluid
              color='red'
            >
              Remove
            </Button>
          );
        },
      },
    };

    if (this.state.showModal) {
      displayModal = (
        <Modal
          dimmer={true}
          open={true}
          closeOnDimmerClick={false}
          onClose={this.close}
        >
          <Modal.Header>Downloading Entire Database</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Your download will appear in your browser downloads
              <Spinner />
            </Modal.Description>
          </Modal.Content>
        </Modal>
      );
    } else {
      displayModal = null;
    }

    if (this.props.pubid === null) {
      noFullName = null;
      noFirstName = null;
      noPostcode = null;
      expiredSubs = null;
      expiredSubs30 = null;
      duplicateFullName = null;
      duplicateAddress = null;
      duplicateLastName = null;
      duplicatePostCode = null;
      duplicateEmail = null;
      badPostcode = null;
      badAddresses = null;
    } else {
      noFullName = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center'>
            <Card.Header>Empty Full Names</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {' '}
                {this.props.reader.noFullNameCount}
              </Header>
              <Grid>
                <Grid.Column computer={16} widescreen={8}>
                  <Button
                    fluid
                    type='button'
                    onClick={this.onNoFullNameHandler}
                    disabled={
                      this.props.reader.noFullNameCount > 0 ? false : true
                    }
                    color='blue'
                  >
                    View
                  </Button>
                </Grid.Column>
                <Grid.Column computer={16} widescreen={8}>
                  <Button
                    fluid
                    type='button'
                    onClick={this.autoFixNoFullName}
                    disabled={
                      this.props.reader.noFullNameCount > 0 ? false : true
                    }
                    color='blue'
                  >
                    Auto Fix
                  </Button>
                </Grid.Column>
              </Grid>
            </Card.Description>
          </Card.Content>
        </Card>
      );
      noFirstName = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center'>
            <Card.Header>Empty Name Fields</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {' '}
                {this.props.reader.noFirstNameCount}
              </Header>
              <Grid>
                <Grid.Column computer={16} widescreen={6}>
                  <Button
                    fluid
                    type='button'
                    onClick={this.onNoFirstNameHandler}
                    disabled={
                      this.props.reader.noFirstNameCount > 0 ? false : true
                    }
                    color='blue'
                  >
                    View
                  </Button>
                </Grid.Column>
                <Grid.Column computer={16} widescreen={10}>
                  <Button
                    fluid
                    type='button'
                    onClick={this.autoFixNoFirstName}
                    disabled={
                      this.props.reader.noFirstNameCount > 0 ? false : true
                    }
                    color='blue'
                  >
                    Auto Fix using Full Name
                  </Button>
                </Grid.Column>
              </Grid>
            </Card.Description>
          </Card.Content>
        </Card>
      );
      badPostcode = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center'>
            <Card.Header>Queried Postcodes</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {' '}
                {this.props.reader.badPostcodeCount}
              </Header>
              <Button.Group fluid>
                <Button
                  type='button'
                  onClick={this.onBadPostcodeHandler}
                  disabled={
                    this.props.reader.badPostcodeCount > 0 ? false : true
                  }
                  color='blue'
                >
                  View
                </Button>
              </Button.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      );
      badAddresses = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center'>
            <Card.Header>Address Format</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {' '}
                {this.props.reader.badAddressCount}
              </Header>
              <Button.Group fluid>
                <Button
                  type='button'
                  onClick={this.onBadAddressHandler}
                  disabled={
                    this.props.reader.badAddressCount > 0 ? false : true
                  }
                  color='blue'
                >
                  View
                </Button>
              </Button.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      );
      noPostcode = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center'>
            <Card.Header>Missing Postcodes</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {this.props.reader.noPostcodeCount}
              </Header>
              <Button.Group fluid>
                <Button
                  onClick={this.onNoPostcodeHandler}
                  disabled={
                    this.props.reader.noPostcodeCount > 0 ? false : true
                  }
                  color='blue'
                >
                  View
                </Button>
              </Button.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      );
      expiredSubs = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center'>
            <Card.Header>Expired Subscribers</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {this.props.reader.totalExpiredCount}
              </Header>
              <Grid>
                <Grid.Column computer={16} widescreen={8}>
                  <Button
                    fluid
                    onClick={this.onExpiredHandler}
                    disabled={
                      this.props.reader.totalExpiredCount > 0 ? false : true
                    }
                    color='blue'
                    style={{ marginRight: '10px' }}
                  >
                    View
                  </Button>
                </Grid.Column>
                <Grid.Column computer={16} widescreen={8}>
                  <Button
                    fluid
                    onClick={() => {
                      this.downloadAllData(this.state.pubId);
                    }}
                    disabled={
                      this.props.reader.totalExpiredCount > 0 ? false : true
                    }
                    color='blue'
                  >
                    Export
                  </Button>
                </Grid.Column>
              </Grid>
              <CSVLink
                ref='all'
                style={{ display: 'none' }}
                filename='expired.csv'
                asyncOnClick={true}
                data={this.state.allData}
                onClick={(event, done) => {
                  done(this.doneDownloading());
                }}
              />
            </Card.Description>
          </Card.Content>
        </Card>
      );
      expiredSubs30 = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center'>
            <Card.Header>Expiring in next 30 days</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {this.props.reader.totalExpiredThirtyCount}
              </Header>
              <Grid>
                <Grid.Column computer={16} widescreen={8}>
                  <Button
                    fluid
                    onClick={this.onExpiredThirtyHandler}
                    disabled={
                      this.props.reader.totalExpiredThirtyCount > 0
                        ? false
                        : true
                    }
                    color='blue'
                    style={{ marginRight: '10px' }}
                  >
                    View
                  </Button>
                </Grid.Column>
                <Grid.Column computer={16} widescreen={8}>
                  <Button
                    fluid
                    onClick={() => {
                      this.downloadNext30(this.state.pubId);
                    }}
                    disabled={
                      this.props.reader.totalExpiredThirtyCount > 0
                        ? false
                        : true
                    }
                    color='blue'
                  >
                    Export
                  </Button>
                </Grid.Column>
              </Grid>
              <CSVLink
                ref='thirty'
                style={{ display: 'none' }}
                filename='expirednext30.csv'
                asyncOnClick={true}
                data={this.state.allData}
                onClick={(event, done) => {
                  done(this.doneDownloading());
                }}
              />
            </Card.Description>
          </Card.Content>
        </Card>
      );
      duplicateFullName = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center' style={styles.cardStretch}>
            <Card.Header>Full Name and Post Code</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {' '}
                {this.props.reader.duplicateFullNameCount}
              </Header>
              <Button.Group fluid>
                <Button
                  type='button'
                  onClick={this.onDuplicateFullNameHandler}
                  disabled={
                    this.props.reader.duplicateFullNameCount > 0 ? false : true
                  }
                  color='blue'
                >
                  View
                </Button>
              </Button.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      );
      duplicateEmail = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center' style={styles.cardStretch}>
            <Card.Header>
              Emails
              <br />
            </Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {' '}
                {this.props.reader.duplicateEmailCount}
              </Header>
              <Button.Group fluid style={{ vAlign: 'bottom' }}>
                <Button
                  type='button'
                  onClick={this.onDuplicateEmailsHandler}
                  disabled={
                    this.props.reader.duplicateEmailCount > 0 ? false : true
                  }
                  color='blue'
                >
                  View
                </Button>
              </Button.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      );
      duplicateLastName = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center' style={styles.cardStretch}>
            <Card.Header>Surname and Post Code</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {' '}
                {this.props.reader.duplicateLastNameCount}
              </Header>
              <Button.Group fluid>
                <Button
                  type='button'
                  onClick={this.onDuplicateLastNameHandler}
                  disabled={
                    this.props.reader.duplicateLastNameCount > 0 ? false : true
                  }
                  color='blue'
                >
                  View
                </Button>
              </Button.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      );
      duplicatePostCode = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center' style={styles.cardStretch}>
            <Card.Header>Post Code</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {' '}
                {this.props.reader.duplicatePostCodeCount}
              </Header>
              <Button.Group fluid>
                <Button
                  type='button'
                  onClick={this.onDuplicatePostCodeHandler}
                  disabled={
                    this.props.reader.duplicatePostCodeCount > 0 ? false : true
                  }
                  color='blue'
                >
                  View
                </Button>
              </Button.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      );
      duplicateAddress = (
        <Card style={{ width: '100%', height: '100%' }}>
          <Card.Content textAlign='center' style={styles.cardStretch}>
            <Card.Header>Address 1 and Post Code</Card.Header>
            <Card.Description style={{ minHeight: '60px' }}>
              <Header
                as='h4'
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {' '}
                {this.props.reader.duplicateAddressCount}
              </Header>
              <Button.Group fluid>
                <Button
                  type='button'
                  onClick={this.onDuplicateAddressHandler}
                  disabled={
                    this.props.reader.duplicateAddressCount > 0 ? false : true
                  }
                  color='blue'
                >
                  View
                </Button>
              </Button.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      );
    }

    if (readers !== null && this.state.showReader) {
      if (readers.length > 0) {
        readerTable = (
          <Segment>
            <DataTable data={readers} headers={headers} />
          </Segment>
        );
      } else {
        readerTable = (
          <Segment>
            <Header>There were no readers found for your search query</Header>
          </Segment>
        );
      }
    }

    if (duplicateFullNames !== null && this.state.showDuplicateFullName) {
      if (duplicateFullNames.length > 0) {
        readerTable = (
          <Segment>
            <DuplicateDataTable
              data={duplicateFullNames}
              headers={dupFNHeaders}
            />
          </Segment>
        );
      } else {
        readerTable = (
          <Segment>
            <Header>There were no readers found for your search query</Header>
          </Segment>
        );
      }
    }

    if (duplicateEmails !== null && this.state.showDuplicateEmails) {
      console.log(duplicateEmails.length);
      if (duplicateEmails.length > 0) {
        console.log('gothere');
        readerTable = (
          <Segment>
            <DuplicateDataTable
              data={duplicateEmails}
              headers={dupEmailHeaders}
            />
          </Segment>
        );
      } else {
        readerTable = (
          <Segment>
            <Header>There were no readers found for your search query</Header>
          </Segment>
        );
      }
    }

    if (duplicateLastNames !== null && this.state.showDuplicateLastName) {
      if (duplicateLastNames.length > 0) {
        readerTable = (
          <Segment>
            <DuplicateDataTable
              data={duplicateLastNames}
              headers={dupLNHeaders}
            />
          </Segment>
        );
      } else {
        readerTable = (
          <Segment>
            <Header>There were no readers found for your search query</Header>
          </Segment>
        );
      }
    }

    if (duplicatePostCodes !== null && this.state.showDuplicatePostCode) {
      if (duplicatePostCodes.length > 0) {
        readerTable = (
          <Segment>
            <DuplicateDataTable
              data={duplicatePostCodes}
              headers={dupPCHeaders}
            />
          </Segment>
        );
      } else {
        readerTable = (
          <Segment>
            <Header>There were no readers found for your search query</Header>
          </Segment>
        );
      }
    }

    if (duplicateAddress !== null && this.state.showDuplicateAddress) {
      if (duplicateAddresses.length > 0) {
        readerTable = (
          <Segment>
            <DuplicateDataTable
              data={duplicateAddresses}
              headers={dupAHeaders}
            />
          </Segment>
        );
      } else {
        readerTable = (
          <Segment>
            <Header>There were no readers found for your search query</Header>
          </Segment>
        );
      }
    }

    if (badPostcodes !== null && this.state.showBadPostcodes) {
      if (badPostcodes.length > 0) {
        readerTable = (
          <Segment>
            <DuplicateDataTable data={badPostcodes} headers={badPCHeaders} />
          </Segment>
        );
      } else {
        readerTable = (
          <Segment>
            <Header>There were no readers found for your search query</Header>
          </Segment>
        );
      }
    }

    if (badAddress !== null && this.state.showBadAddresses) {
      if (badAddress.length > 0) {
        readerTable = (
          <Segment>
            <AddressDataTable data={badAddress} headers={badAddressHeaders} />
          </Segment>
        );
      } else {
        readerTable = (
          <Segment>
            <Header>There were no readers found for your search query</Header>
          </Segment>
        );
      }
    }

    return (
      <React.Fragment>
        <Segment>
          <Header textAlign='center'>Data Warnings</Header>
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              {noFullName}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              {noFirstName}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              {expiredSubs30}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              {expiredSubs}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              {noPostcode}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              {badPostcode}
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment>
          <Header textAlign='center'>Duplicates</Header>
          <Grid columns={5} stackable className='mng--dups'>
            <Grid.Column>{duplicateFullName}</Grid.Column>
            <Grid.Column>{duplicateLastName}</Grid.Column>
            <Grid.Column>{duplicatePostCode}</Grid.Column>
            <Grid.Column>{duplicateAddress}</Grid.Column>
            <Grid.Column>{duplicateEmail}</Grid.Column>
          </Grid>
        </Segment>
        {/* <Segment>
          <Header textAlign="center">Data Errors</Header>
          <Card.Group itemsPerRow={4}>
            {badPostcodes}
          </Card.Group>
        </Segment> */}
        {readerTable}
        {displayModal}
      </React.Fragment>
    );
  }
}

const styles = {
  cardStretch: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};
const mapStateToProps = (state) => ({
  reader: state.reader,
  analytics: state.analytics,
  loading: state.reader.loading,
});

export default connect(mapStateToProps, {
  getNoFullNameCount,
  getNoPostcodeCount,
  getExpiredCount,
  getExpiredThirtyCount,
  getNoFullName,
  getNoPostcode,
  getExpired,
  getExpiredThirty,
  autoFixNoFullName,
  getAllData,
  getDuplicateFullNameCount,
  getDuplicateFullName,
  getDuplicateLastNameCount,
  getDuplicateLastName,
  getDuplicatePostCodeCount,
  getDuplicatePostCode,
  getDuplicateAddressCount,
  getDuplicateAddress,
  deleteReaderAddress,
  deleteReaderFullName,
  getDuplicateEmails,
  getDuplicateEmailCount,
  deleteReaderLastName,
  deleteReaderPostCode,
  deleteReader,
  getNoFirstName,
  getBadAddress,
  getBadAddressCount,
  deleteReaderEmail,
  clearData,
  getBadPostcode,
  getBadPostcodeCount,
  autoFixNoFirstName,
  getNoFirstNameCount,
  autoFixAddress,
})(DataLayout);
