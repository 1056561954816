import {
  CREATE_ORDER,
  GET_TOTAL_ORDER_AMOUNT,
  GET_ORDERS,
  LOADING,
  CANCEL_ORDER,
  CREATE_ST_ORDER,
  GET_ORDER,
  CREATE_BACKISSUE_ORDER,
} from "../actions/types";

// Create an initial state for the model
const initialState = {
  order: null,
  totalAmount: 0,
  orders: null,
  loading: false,
  orderDetail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case CREATE_ST_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case CREATE_BACKISSUE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case CANCEL_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case GET_ORDERS:
      return {
        ...state,
        loading: false,
        orders: action.payload,
      };
    case GET_ORDER:
      return {
        ...state,
        loading: false,
        orderDetail: action.payload,
      };
    case GET_TOTAL_ORDER_AMOUNT: {
      return {
        ...state,
        totalAmount: action.payload,
      };
    }
    default:
      return state;
  }
}
