import { GET_ACCOUNT, ACCOUNT_LOADING, CLEAR_CURRENT_ACCOUNT, GET_PAYPAL, GET_ACCOUNT_TEL } from '../actions/types';

const initialState = {
  account: null,
  loading: false,
  payPalDetails: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_LOADING:
      return {
        ...state,
        loading: true
      }
    case GET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
        loading: false
      }
    case GET_ACCOUNT_TEL:
      return {
        ...state,
        account: action.payload,
        loading: false
      }
    case GET_PAYPAL:
      return {
        ...state,
        payPalDetails: action.payload
      }
    case CLEAR_CURRENT_ACCOUNT:
      return {
        ...state,
        account: null
      }
    default: return state;
  }
}