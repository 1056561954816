import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Header, Card, Segment } from 'semantic-ui-react';
import { getPageViews, getNewPageViews, getUniquePageViews, getNewUniquePageViews, getAllPageViews, getAllNewPageViews, getAllNewUniquePageViews, getAllUniquePageViews } from '../../../actions/analyticActions';

class PageViews extends Component {

  state = {
    pubId: null,
    publicationOptions: []
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pubid) {
      if (nextProps.pubid !== this.state.pubId) {
        if (nextProps.pubid === "all") {
          this.props.getAllPageViews(nextProps.pubid);
          this.props.getAllNewPageViews(nextProps.pubid);
          this.props.getAllUniquePageViews(nextProps.pubid);
          this.props.getAllNewUniquePageViews(nextProps.pubid);

        } else {
          this.props.getPageViews(nextProps.pubid);
          this.props.getNewPageViews(nextProps.pubid);
          this.props.getUniquePageViews(nextProps.pubid);
          this.props.getNewUniquePageViews(nextProps.pubid);

        }
        this.setState({ pubId: nextProps.pubid })
      }
    }
  }

  render() {
    let pageViewContent;
    let newPageViewContent;
    let uniquePageViewContent;
    let newUniquePageViewContent;

    if (this.props.pubid === null) {
      pageViewContent = null;
      newPageViewContent = null;
      uniquePageViewContent = null;
      newUniquePageViewContent = null;
    } else {
      pageViewContent = (
        <Card>
          <Card.Content textAlign='center'>
            <Card.Header>Total</Card.Header>
            <Card.Description>
              <Header style={{ marginTop: "20px" }}>{this.props.analytics.pageViews}</Header>
            </Card.Description>
          </Card.Content>
        </Card>
      )
      newPageViewContent = (
        <Card>
          <Card.Content textAlign='center'>
            <Card.Header>New in last 30 days</Card.Header>
            <Card.Description>
              <Header style={{ marginTop: "20px" }}>{this.props.analytics.newPageViews}</Header>
            </Card.Description>
          </Card.Content>
        </Card>
      )
      uniquePageViewContent = (
        <Card>
          <Card.Content textAlign='center'>
            <Card.Header>Unique Total</Card.Header>
            <Card.Description>
              <Header style={{ marginTop: "20px" }}>{this.props.analytics.uniquePageViews}</Header>
            </Card.Description>
          </Card.Content>
        </Card>
      )
      newUniquePageViewContent = (
        <Card>
          <Card.Content textAlign='center'>
            <Card.Header>Unique in last 30 days</Card.Header>
            <Card.Description>
              <Header style={{ marginTop: "20px" }}>{this.props.analytics.newUniquePageViews}</Header>
            </Card.Description>
          </Card.Content>
        </Card>
      )
    }
    return (
      <Segment>
        <Header textAlign="center">Storefront Views</Header>
        <Card.Group itemsPerRow={4}>
          {newPageViewContent}
          {newUniquePageViewContent}
          {pageViewContent}
          {uniquePageViewContent}
        </Card.Group>
      </Segment>
    )
  }
}

const mapStateToProps = state => ({
  analytics: state.analytics,
  loading: state.reader.loading
});

export default connect(mapStateToProps, { getPageViews, getNewPageViews, getNewUniquePageViews, getUniquePageViews, getAllPageViews, getAllNewPageViews, getAllNewUniquePageViews, getAllUniquePageViews })(PageViews);
