// Renders a Normal Form Textarea Field

import React from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const RenderTextArea = ({ label,
  onChange,
  value,
  name,
  error,
  disabled,
  readOnly }) => {
  return (
    <div>
      <div style={{ padding: "10px" }}>
        <Form.TextArea
          size='large'
          label={label}
          value={value}
          name={name}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          error={error ? true : false} />
        {error && <div className="ui pointing red basic label">{error}</div>}
      </div>
    </div>
  )
}

RenderTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  label: PropTypes.string,
};


export default RenderTextArea;