// Top Navigation Component

import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Image,
  Menu,
  Header
} from 'semantic-ui-react'
import { getTotalReaders } from '../../../actions/readerActions';
import { getNumPubs } from '../../../actions/publicationActions';
import { getTotalOrderAmount } from '../../../actions/orderActions';

//import LogoBlack from '../../../assets/img/ms200blk.png';
import LogoBlack from '../../../assets/img/ms200blkNew.png';
import RedGraph from '../../../assets/img/red-graph-small.png';
import GreenGraph from '../../../assets/img/green-graph-small.png';
import BlueGraph from '../../../assets/img/blue-graph-small.png';


class TopMenu extends Component {
  state = {
    user: this.props.user
  };

  componentDidMount() {
    this.props.getTotalReaders("3");
    this.props.getNumPubs("3");
    this.props.getTotalOrderAmount("3");
  }

  render() {
    const { totalReaders, numPubs, totalAmount } = this.props;
    return (
      <Menu fixed="top" className="top-menu1" borderless>
        <Menu.Item className="logo-space-menu-item">
          <div className="display-inline1 logo-space1" style={{ marginLeft: "-2px" }}>
            <Image src={LogoBlack} alt="MagStand Logo" size='small' />
          </div>
        </Menu.Item>
        <Menu.Item><Header as="h2">{this.props.user.firstName + " " + this.props.user.lastName}</Header></Menu.Item>
        <Menu.Item>{this.props.user.companyName}</Menu.Item>

        {/* <Menu.Item
          className="no-border1"
          onClick={this.props.toggleSideMenu}
        >
          <Icon name="bars" />
        </Menu.Item> */}
        {this.props.user.userType === 'publisher' || this.props.user.userType === 'pubadmin' ? <Menu.Menu position="right">
          <div style={{ marginTop: "15px", fontSize: "1rem" }}>
            {numPubs} Titles
          </div>
          <div style={{ marginTop: "15px", marginLeft: "30px", fontSize: "1rem" }}>
            {totalReaders} Readers
            </div>
          <div style={{ marginTop: "15px", marginLeft: "30px", marginRight: "20px", fontSize: "1rem" }}>
            £{totalAmount.toFixed(2)}
          </div>
        </Menu.Menu> : ""
        }
      </Menu>
    );
  }
}

TopMenu.propTypes = {
  getTotalReaders: PropTypes.func.isRequired,
  getNumPubs: PropTypes.func.isRequired,
  getTotalOrderAmount: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    totalReaders: state.reader.totalReaders,
    numPubs: state.publication.numPubs,
    totalAmount: state.order.totalAmount
  }
}

export default connect(mapStateToProps, { getTotalReaders, getNumPubs, getTotalOrderAmount })(TopMenu);