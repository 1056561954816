import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Card, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deletePromoCode } from '../../../actions/publicationActions';

class PromoCodeItem extends Component {
  onDeleteClick = (id) => {
    this.props.deletePromoCode(id);
  }

  render() {
    const promo = this.props.promo;
    return (
      <Card style={{ margin: "10px", width: "200px" }} key={promo._id}>
        <Card.Content textAlign='center'>
          <Card.Header as='h2'>{promo.name}</Card.Header>
          <Card.Description>

            <p style={{ fontSize: "16px" }}>Code: {promo.code}<br /><br />Discount: {promo.discount}%<br /><br />
              Used to date: {promo.used}<br /><br />Uses allowed: {promo.uses === 0 ? "Unlimited" : promo.uses}<br /><br /></p>
            <Link to={`/editpromo/${promo._id}`}>
              <Button fluid color='blue'>Edit Promo Code</Button>
            </Link><br />

            <Button type="button" onClick={(e) => window.confirm("Are you sure you want to delete this Promo Code?") && this.onDeleteClick(promo._id)} fluid color='red'>Delete Promo Code</Button><br />
          </Card.Description>
        </Card.Content>
      </Card>
    )
  }
}

PromoCodeItem.propTypes = {
  deletePromoCode: PropTypes.func.isRequired,
};

export default connect(null, { deletePromoCode })(PromoCodeItem);

