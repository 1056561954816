import { GET_NEW_SUBS, GET_TOTAL_PAGE_VIEWS, GET_NEW_SUBS_BUNDLE, GET_NEW_SUBS_DIGITAL, GET_NEW_SUBS_PRINT, GET_NEW_ORDERS, GET_ALL_SUBS, GET_ALL_SUBS_PRINT, GET_ALL_SUBS_DIGITAL, GET_ALL_SUBS_BUNDLE, GET_ALL_ORDERS, GET_NEW_PAGE_VIEWS, GET_UNIQUE_PAGE_VIEWS, GET_NEW_UNIQUE_PAGE_VIEWS, GET_YEARLY_GRAPH_DATA } from '../actions/types';

const initialState = {
  newSubs: 0,
  newSubsPrint: 0,
  newSubsDigital: 0,
  newSubsBundle: 0,
  pageViews: 0,
  newPageViews: 0,
  uniquePageViews: 0,
  newUniquePageViews: 0,
  newOrders: 0,
  allOrders: 0,
  allSubs: 0,
  allSubsBundle: 0,
  allSubsDigital: 0,
  allSubsPrint: 0,
  yearlyGraphData: [],
  loading: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NEW_SUBS:
      return {
        ...state,
        newSubs: action.payload,
        loading: false
      }
    case GET_NEW_SUBS_PRINT:
      return {
        ...state,
        newSubsPrint: action.payload,
        loading: false
      }
    case GET_NEW_SUBS_DIGITAL:
      return {
        ...state,
        newSubsDigital: action.payload,
        loading: false
      }
    case GET_NEW_SUBS_BUNDLE:
      return {
        ...state,
        newSubsBundle: action.payload,
        loading: false
      }

    case GET_NEW_ORDERS:
      return {
        ...state,
        newOrders: action.payload,
        loading: false
      }
    case GET_ALL_SUBS:
      return {
        ...state,
        allSubs: action.payload,
        loading: false
      }
    case GET_ALL_SUBS_PRINT:
      return {
        ...state,
        allSubsPrint: action.payload,
        loading: false
      }
    case GET_ALL_SUBS_DIGITAL:
      return {
        ...state,
        allSubsDigital: action.payload,
        loading: false
      }
    case GET_ALL_SUBS_BUNDLE:
      return {
        ...state,
        allSubsBundle: action.payload,
        loading: false
      }
    case GET_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload,
        loading: false
      }
    case GET_TOTAL_PAGE_VIEWS:
      return {
        ...state,
        pageViews: action.payload,
        loading: false
      }
    case GET_NEW_PAGE_VIEWS:
      return {
        ...state,
        newPageViews: action.payload,
        loading: false
      }
    case GET_UNIQUE_PAGE_VIEWS:
      return {
        ...state,
        uniquePageViews: action.payload,
        loading: false
      }
    case GET_NEW_UNIQUE_PAGE_VIEWS:
      return {
        ...state,
        newUniquePageViews: action.payload,
        loading: false
      }
    case GET_YEARLY_GRAPH_DATA:
      return {
        ...state,
        yearlyGraphData: action.payload,
        loading: false
      }
    case GET_NEW_SUBS:
      return {
        ...state,
        newSubs: action.payload,
        loading: false
      }
    case GET_NEW_SUBS_PRINT:
      return {
        ...state,
        newSubsPrint: action.payload,
        loading: false
      }
    case GET_NEW_SUBS_DIGITAL:
      return {
        ...state,
        newSubsDigital: action.payload,
        loading: false
      }
    case GET_NEW_SUBS_BUNDLE:
      return {
        ...state,
        newSubsBundle: action.payload,
        loading: false
      }

    case GET_NEW_ORDERS:
      return {
        ...state,
        newOrders: action.payload,
        loading: false
      }
    case GET_ALL_SUBS:
      return {
        ...state,
        allSubs: action.payload,
        loading: false
      }
    case GET_ALL_SUBS_PRINT:
      return {
        ...state,
        allSubsPrint: action.payload,
        loading: false
      }
    case GET_ALL_SUBS_DIGITAL:
      return {
        ...state,
        allSubsDigital: action.payload,
        loading: false
      }
    case GET_ALL_SUBS_BUNDLE:
      return {
        ...state,
        allSubsBundle: action.payload,
        loading: false
      }
    case GET_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload,
        loading: false
      }
    case GET_TOTAL_PAGE_VIEWS:
      return {
        ...state,
        pageViews: action.payload,
        loading: false
      }
    case GET_NEW_PAGE_VIEWS:
      return {
        ...state,
        newPageViews: action.payload,
        loading: false
      }
    case GET_UNIQUE_PAGE_VIEWS:
      return {
        ...state,
        uniquePageViews: action.payload,
        loading: false
      }
    case GET_NEW_UNIQUE_PAGE_VIEWS:
      return {
        ...state,
        newUniquePageViews: action.payload,
        loading: false
      }
    case GET_YEARLY_GRAPH_DATA:
      return {
        ...state,
        yearlyGraphData: action.payload,
        loading: false
      }
    default: return state;
  }
}