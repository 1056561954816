import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Segment, Form, Button, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { createPublisher } from '../../../actions/userActions';
import RenderFieldLeft from '../../common/NormalForm/RenderFieldLeft';
import RenderFieldLeftAndRequired from '../../common/NormalForm/RenderFieldLeftAndRequired';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';
import getCountries from '../../common/Countries';

class AddUser extends Component {

  state = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    password: '',
    password2: '',
    parentPublisher: this.props.auth.user.id,
    userType: '',
    mainContact: '',
    companyName: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    addressFour: '',
    country: '',
    postZip: '',
    mobile: '',
    telephone: '',
    emailAddress: '',
    numberOfPublications: '',
    errors: {}
  }

  componentDidMount() {
    if (this.props.auth.user.userType !== "superuser" && this.props.auth.user.userType !== "reseller") {
      window.location.href = "/readers"
    }
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleCountryChange = (e, { value }) => {
    this.setState({ country: value })
  }

  handleChange = (e, { value }) => {
    this.setState({ userType: value })
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    const newUser = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      companyName: this.state.companyName,
      password: this.state.password,
      password2: this.state.password2,
      parentPublisher: this.state.parentPublisher,
      userType: this.state.userType,
      mainContact: this.state.mainContact,
      companyName: this.state.companyName,
      addressOne: this.state.addressOne,
      addressTwo: this.state.addressTwo,
      addressThree: this.state.addressThree,
      addressFour: this.state.addressFour,
      country: this.state.country,
      postZip: this.state.postZip,
      mobile: this.state.mobile,
      telephone: this.state.telephone,
      emailAddress: this.state.emailAddress,
      numberOfPublications: this.state.numberOfPublications,
      userKey: this.props.auth.user.id
    }

    // this.props.history needed withRouter so we can use it in the redux action
    this.props.createPublisher(newUser, this.props.history);
  }

  render() {
    const { errors } = this.state;
    const { value } = this.state.userType;
    // Select options for status
    const options = this.props.auth.user.userType === "reseller" ? [
      {
        key: 'publisher',
        text: 'Publisher',
        value: 'publisher'
      }
    ] : [
        {
          key: 'publisher',
          text: 'Publisher',
          value: 'publisher'
        },
        {
          key: 'reseller',
          text: 'Reseller',
          value: 'reseller'
        }
      ]

    const countryOptions = getCountries();

    return (
      <Form onSubmit={this.onSubmitHandler}>
        <Segment>
          <Header as="h2" textalign='center'>Add User</Header>
          <RenderFieldLeftAndRequired
            name="firstName"
            value={this.state.firstName}
            label="First Name"
            onChange={this.onChangeHandler}
            placeholder="First Name"
            error={errors.firstName}
          />
          <RenderFieldLeftAndRequired
            name="lastName"
            value={this.state.lastName}
            onChange={this.onChangeHandler}
            placeholder="Last Name"
            error={errors.lastName}
            label="Last Name"
          />
          <RenderFieldLeftAndRequired
            name="email"
            value={this.state.email}
            onChange={this.onChangeHandler}
            placeholder="Email Address"
            error={errors.email}
            label="Email Address"
          />
          <RenderFieldLeftAndRequired
            type="password"
            value={this.state.password}
            onChange={this.onChangeHandler}
            placeholder="Password"
            name="password"
            error={errors.password}
            label="Password"
          />
          <RenderFieldLeftAndRequired
            type="password"
            value={this.state.password2}
            onChange={this.onChangeHandler}
            placeholder="Confirm Password"
            name="password2"
            error={errors.password2}
            label="Confirm Password"
          />
          <RenderFieldLeft
            name="companyName"
            value={this.state.companyName}
            onChange={this.onChangeHandler}
            placeholder="Company Name"
            label="Company Name"
            error={errors.companyName}
          />
          <RenderFieldLeft
            name="addressOne"
            value={this.state.addressOne}
            onChange={this.onChangeHandler}
            placeholder="Address Line 1"
            label="Address Line 1"
            error={errors.addressOne}
          />
          <RenderFieldLeft
            name="addressTwo"
            value={this.state.addressTwo}
            onChange={this.onChangeHandler}
            placeholder="Address Line 2"
            label="Address Line 2"
            error={errors.addressTwo}
          />
          <RenderFieldLeft
            name="addressThree"
            value={this.state.addressThree}
            onChange={this.onChangeHandler}
            placeholder="Address Line 3"
            label="Address Line 3"
            error={errors.addressThree}
          />
          <RenderFieldLeft
            name="addressFour"
            value={this.state.addressFour}
            onChange={this.onChangeHandler}
            placeholder="Address Line 4"
            label="Address Line 4"
            error={errors.addressFour}
          />
          <RenderFieldLeft
            name="postZip"
            value={this.state.postZip}
            onChange={this.onChangeHandler}
            placeholder="Post/Zip Code"
            label="Post/Zip Code"
            error={errors.postZip}
          />
          <div style={{ padding: "10px" }}>
            <Form.Select
              options={countryOptions}
              label="Country"
              onChange={this.handleCountryChange}
              placeholder="Country"
              fluid
              size="large"
              selection
              name="country"
              value={this.state.country}
              error={errors.country}
            />
          </div>
          <RenderFieldLeft
            name="mainContact"
            value={this.state.mainContact}
            onChange={this.onChangeHandler}
            placeholder="Company Main Contact"
            label="Company Main Contact"
            error={errors.mainContact}
          />
          <RenderFieldLeft
            name="emailAddress"
            type="email"
            value={this.state.emailAddress}
            onChange={this.onChangeHandler}
            placeholder="Main Contact Email"
            label="Main Contact Email"
            error={errors.emailAddress}
          />
          <RenderFieldLeft
            name="telephone"
            value={this.state.telephone}
            onChange={this.onChangeHandler}
            placeholder="Main Contact Telephone"
            label="Main Contact Telephone"
            error={errors.telephone}
          />
          <RenderFieldLeft
            name="mobile"
            value={this.state.mobile}
            onChange={this.onChangeHandler}
            placeholder="Main Contact Mobile"
            label="Main Contact Mobile"
            error={errors.mobile}
          />
          <div style={{ padding: "10px" }}>
            <Form.Select
              options={options}
              onChange={this.handleChange}
              placeholder="Select User Type"
              fluid
              size="large"
              selection
              name="userType"
              value={value}
            />
          </div>
          <RenderFieldLeft
            name="numberOfPublications"
            type="number"
            value={this.state.numberOfPublications}
            onChange={this.onChangeHandler}
            placeholder="Main Contact Mobile"
            label="Number of Publications"
            error={errors.numberOfPublications}
          />
          <div style={{ padding: "10px" }}>
            <Button color="blue" type="submit" fluid>Add User</Button>
          </div>
        </Segment>
      </Form>

    );
  }
}

AddUser.propTypes = {
  createPublisher: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { createPublisher })(withRouter(AddUser));
