// Description
//
// Action Creators for Publication related actions

import axios from "axios";
import { toastr } from "react-redux-toastr";
import { reset } from "redux-form";

import {
  CLEAR_ERRORS,
  GET_ERRORS,
  LOADING,
  GET_BACKISSUES,
  GET_BACKISSUE,
  CREATE_BACKISSUE,
  UPDATE_BACKISSUE,
  REMOVE_BACKISSUE,
} from "./types";

// Get back issues for the logged in Publisher
export const getBackIssues = (id) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(`/api/backIssue/${id}`)
    .then((res) =>
      dispatch({
        type: GET_BACKISSUES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_BACKISSUES,
        payload: null,
      })
    );
};

// Get back issue of the id
export const getBackIssue = (id) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(`/api/backIssue/getbackIssue/${id}`)
    .then((res) =>
      dispatch({
        type: GET_BACKISSUE,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_BACKISSUE,
        payload: null,
      })
    );
};

// Create a back issue of the publications for the logged in Publisher
export const createBackIssue = (data, history) => async (dispatch) => {
  dispatch(loading());
  await axios
    .post(`/api/backIssue/`, data)
    .then((res) => {
      dispatch({
        type: CREATE_BACKISSUE,
        payload: res.data,
      });
      dispatch(reset("addBackIssue"));
      toastr.success("New Back Issue added");
      history.push("/backIssues");
    })
    .catch((err) =>
      dispatch({
        type: CREATE_BACKISSUE,
        payload: null,
      })
    );
};

// Update the back issue of the id
export const updateBackIssue = (data, history) => async (dispatch) => {
  dispatch(loading());
  await axios
    .post(`/api/backIssue/updatebackIssue`, data)
    .then((res) => {
      dispatch({
        type: UPDATE_BACKISSUE,
        payload: res.data,
      });
      dispatch(reset("editBackIssue"));
      toastr.success("The Back Issue has updated");
    })
    .catch((err) =>
      dispatch({
        type: UPDATE_BACKISSUE,
        payload: null,
      })
    );
};

// Remove the back issue of the id
export const removeBackIssue = (id, history) => async (dispatch) => {
  dispatch(loading());
  await axios
    .post(`/api/backIssue/removeBackIssue/${id}`)
    .then((res) => {
      dispatch({
        type: REMOVE_BACKISSUE,
        payload: res.data,
      });
      toastr.success("The Back Issue has deleted");
    })
    .catch((err) =>
      dispatch({
        type: REMOVE_BACKISSUE,
        payload: null,
      })
    );
};

// Loading
export const loading = () => {
  return {
    type: LOADING,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
