import React, { Fragment } from "react";
import { Header, Segment, Icon, Step } from "semantic-ui-react";
import PublicationInfo from "./Steps/PublicationInfo";
import PublicationImages from "./Steps/PublicationImages";
import PricingInfo from "./Steps/PricingInfo";
import Questions from "./Steps/Questions";
import AppLinks from "./Steps/AppLinks";
import SocialLinks from "./Steps/SocialLinks";
import UserKeyDetails from "./Steps/UserKeyDetails";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updatePublication } from "../../../actions/publicationActions";
import { isUserLoggedIn } from "../../common/isUserLoggedIn";
import Spinner from "../../common/Spinner";

class EditPublication extends React.Component {
  state = {
    currentStep: 1,
  };

  componentDidMount() {
    if (
      this.props.auth.user.userType !== "publisher" &&
      this.props.auth.user.userType !== "pubadmin"
    ) {
      window.location.href = "/readers";
    }
  }
  componentWillReceiveProps() {
    isUserLoggedIn();
  }

  nextStep = () => {
    this.setState({ currentStep: this.state.currentStep + 1 });
  };

  prevStep = () => {
    this.setState({ currentStep: this.state.currentStep - 1 });
  };

  onSubmit = (formValues) => {
    console.log(formValues);
    // Save all the data in MongoDB
    return this.props.updatePublication(formValues, this.props.history);
  };

  renderStep = () => {
    switch (this.state.currentStep) {
      case 1:
        return (
          <PublicationInfo
            pubid={this.props.match.params.id}
            buttonSubmit={this.onSubmit}
            onSubmit={this.nextStep}
          />
        );
      case 2:
        return (
          <PublicationImages
            previousPage={this.prevStep}
            onSubmit={this.nextStep}
          />
        );
      case 3:
        return (
          <PricingInfo previousPage={this.prevStep} onSubmit={this.nextStep} />
        );
      case 4:
        return (
          <Questions previousPage={this.prevStep} onSubmit={this.nextStep} />
        );
      case 5:
        return (
          <AppLinks previousPage={this.prevStep} onSubmit={this.nextStep} />
        );
      case 6:
        return (
          <SocialLinks previousPage={this.prevStep} onSubmit={this.nextStep} />
        );
      case 7:
        return (
          <UserKeyDetails
            previousPage={this.prevStep}
            onSubmit={this.onSubmit}
          />
        );
      default:
        return <div>Step1</div>;
    }
  };

  render() {
    const loading = this.props.loading;
    let formItems;
    if (loading) {
      formItems = <Spinner />;
    } else {
      formItems = (
        <Segment>
          <Header as="h3" color="black" textAlign="center">
            Edit Publication
          </Header>
          <Step.Group fluid stackable="tablet" size="mini">
            <Step active={this.state.currentStep === 1}>
              <Icon name="info" color="blue" />
            </Step>
            <Step active={this.state.currentStep === 2}>
              <Icon name="images" color="blue" />
            </Step>
            <Step active={this.state.currentStep === 3}>
              <Icon name="currency" color="blue" />
            </Step>
            <Step active={this.state.currentStep === 4}>
              <Icon name="question circle" color="blue" />
            </Step>
            <Step active={this.state.currentStep === 5}>
              <Icon name="mobile alternate" color="blue" />
            </Step>
            <Step active={this.state.currentStep === 6}>
              <Icon name="facebook official" color="blue" />
            </Step>
            <Step active={this.state.currentStep === 7}>
              <Icon name="linkify" color="blue" />
            </Step>
          </Step.Group>
          <Segment attached>{this.renderStep()}</Segment>
        </Segment>
      );
    }
    return <Fragment>{formItems}</Fragment>;
  }
}

EditPublication.propTypes = {
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updatePublication: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { updatePublication })(
  withRouter(EditPublication)
);
