import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Image, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteUser, deactivateUser } from '../../../actions/userActions';

class UserItem extends Component {
  onDeactivateClick = (id) => {
    this.props.deactivateUser(id);
  };

  onDeleteClick = (id) => {
    this.props.deleteUser(id);
  };

  render() {
    const user = this.props.user;
    return (
      <Card style={{ margin: '10px', width: '200px' }} key={user._id}>
        <Image
          src={
            '../../../public/assets/img/avatars/' +
            user.accountDets.userImageName
          }
          circular
        />
        <Card.Content textAlign='center'>
          <Card.Header>{user.accountDets.companyName}</Card.Header>
          <Card.Description>
            <h4>{user.userType}</h4>
            <Link to={`/edituser/${user._id}`}>
              <Button color='blue' fluid>
                Edit User
              </Button>
            </Link>
            <br />
            <Button
              disabled={
                user.userType === 'publisher' &&
                this.props.auth.user.userType !== 'superuser' &&
                this.props.auth.user.userType !== 'reseller'
                  ? true
                  : false
              }
              color='red'
              type='button'
              onClick={(e) =>
                window.confirm('Are you sure you want to delete this User?') &&
                this.onDeactivateClick(user._id)
              }
              fluid
            >
              Deactivate User
            </Button>
            <br />
            
            {/* <Button
              disabled={
                user.userType === 'publisher' &&
                this.props.auth.user.userType !== 'superuser' &&
                this.props.auth.user.userType !== 'reseller'
                  ? true
                  : false
              }
              color='red'
              type='button'
              onClick={(e) =>
                window.confirm('Are you sure you want to delete this User?') &&
                this.onDeleteClick(user._id)
              }
              fluid
            >
              Delete User
            </Button> */}
            <br />
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

UserItem.propTypes = {
  deleteUser: PropTypes.func.isRequired,
  deactivateUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteUser, deactivateUser })(
  UserItem,
);
