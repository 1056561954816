import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Button, Header, Card } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isUserLoggedIn } from '../common/isUserLoggedIn';
import Spinner from '../common/Spinner';
import { getTotalOrders } from '../../actions/orderActions';
import OrderDataTable from '../common/OrderDataTable';
import moment from 'moment';

class Payments extends Component {

  state = {
    errors: {},
    column: null,
    direction: null,
    data: null,
    orderlength: 0,
    totalAmount: 0,
    totalFulfilled: 0,
    totalLiability: 0,
    totalUSAmount: 0,
    totalUSFulfilled: 0,
    totalUSLiability: 0,
    totalEUAmount: 0,
    totalEUFulfilled: 0,
    totalEULiability: 0
  }

  componentDidMount() {
    this.props.getTotalOrders("3");
  }

  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.order.orders !== null) {
      var totalAmount = 0;
      var totalFulfilled = 0;
      var totalLiability = 0;
      var totalUSAmount = 0;
      var totalUSFulfilled = 0;
      var totalUSLiability = 0;
      var totalEUAmount = 0;
      var totalEUFulfilled = 0;
      var totalEULiability = 0;
      nextProps.order.orders.map(ord => {
        switch (ord.currency) {
          case "£":
            totalAmount += ord.productPrice;
            totalFulfilled += ord.fulfilled !== "-" ? Number(ord.fulfilled) : 0;
            totalLiability += ord.liability !== "-" ? Number(ord.liability) : 0;
            return;
          case "$":
            totalUSAmount += ord.productPrice;
            totalUSFulfilled += ord.fulfilled !== "-" ? Number(ord.fulfilled) : 0;
            totalUSLiability += ord.liability !== "-" ? Number(ord.liability) : 0;
            return;
          case "€":
            totalEUAmount += ord.productPrice;
            totalEUFulfilled += ord.fulfilled !== "-" ? Number(ord.fulfilled) : 0;
            totalEULiability += ord.liability !== "-" ? Number(ord.liability) : 0;
            return;
        }
      })
      this.setState({ totalAmount: totalAmount.toFixed(2), totalFulfilled: totalFulfilled.toFixed(2), totalLiability: totalLiability.toFixed(2), totalUSAmount: totalUSAmount.toFixed(2), totalUSFulfilled: totalUSFulfilled.toFixed(2), totalUSLiability: totalUSLiability.toFixed(2), totalEUAmount: totalEUAmount.toFixed(2), totalEUFulfilled: totalEUFulfilled.toFixed(2), totalEULiability: totalEULiability.toFixed(2) });

    }

  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: 'ascending',
      })

      return
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  render() {


    const headers = {
      id: {
        text: 'Id',
        invisible: true,
        sortable: false,
        filterable: false
      },
      orderDate: {
        text: 'Order Date',
        invisible: false,
        sortable: true,
        filterable: true
      },
      orderNumber: {
        text: 'Order Number',
        invisible: false,
        sortable: true,
        filterable: true
      },
      shippingTitle: {
        text: 'Title',
        invisible: false,
        sortable: true,
        filterable: true
      },
      shippingFirstName: {
        text: 'First Name',
        invisible: false,
        sortable: true,
        filterable: true
      },
      shippingLastName: {
        text: 'Last Name',
        invisible: false,
        sortable: true,
        filterable: true
      },
      publicationName: {
        text: 'Publication',
        invisible: false,
        sortable: true,
        filterable: true
      },
      productName: {
        text: 'Product Name',
        invisible: false,
        sortable: true,
        filterable: true
      },
      paymentType: {
        text: 'Payment Type',
        invisible: false,
        sortable: true,
        filterable: true
      },
      productPrice: {
        text: 'Amount',
        invisible: false,
        sortable: true,
        filterable: true
      },
      currency: {
        text: 'Currency',
        invisible: false,
        sortable: true,
        filterable: true
      },
      startDate: {
        text: 'Start Date',
        invisible: false,
        sortable: true,
        filterable: true
      },
      endDate: {
        text: 'End Date',
        invisible: false,
        sortable: true,
        filterable: true
      },
      gift: {
        text: 'Gift',
        invisible: false,
        sortable: true,
        filterable: true
      },
      fulfilled: {
        text: 'Fulfilled',
        invisible: false,
        sortable: true,
        filterable: true
      },
      liability: {
        text: 'Liability',
        invisible: false,
        sortable: true,
        filterable: true
      },
      view: {
        text: 'View',
        invisible: false,
        transform: (value, index, row) => {
          return (
            <Link to={`/orderDetails/${row.id}`}>
              <Button fluid color="green">View</Button>
            </Link>
          )
        }
      }
    }



    const { loading, orders } = this.props.order;
    let tableContent;

    if (orders !== null) {
      if (orders.length > 0) {
        const ordersProcessed = orders.map( item => ({
          ...item,
          orderDate: moment(item.orderDate, 'DD/MM/YYYY').format('YYYY/MM/DD')
        }))
        tableContent = (
          <Segment>
            <OrderDataTable data={ordersProcessed} headers={headers} />
          </Segment>
        )
      } else {
        tableContent = (
          <Segment>
            <div class="ui basic segment">
              <div class="ui icon input">
                <input type="text" name="filterValue" placeholder="Filter results..." value="" />
                <i class="search icon" />
              </div>
              <span style={{ display: "inline-block", margin: "10px" }} />
              <select class="ui dropdown" name="perPage">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span style={{ display: "inline-block", margin: "10px" }} />
              <button disabled class="ui blue button" type="button">Download All Orders</button>
            </div>
            <div class="ui message">
              Total Results: 0
            </div>
            <div class="rsdt rsdt-container">
              <table class="ui compact selectable table" data-table-name="test-table">
                <thead>
                  <tr>
                    <th>
                      <span>
                        Order Date
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Publication
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Order Number
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Title
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        First Name
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Last Name
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Product Name
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Payment Type
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Amount
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Currency
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Start Date
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        End Date
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Gift
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Fulfilled
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Liability
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                    <th>
                      <span>
                        View
                      </span>
                      <span class="rsdt rsdt-sortable">
                        <i class="rsdt rsdt-sortable-icon" role="button" tabindex="0"></i>
                      </span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </Segment>
        )
      }
    }

    let totalsContent;

    if (orders !== null) {
      if (orders.length > 0) {
        totalsContent = (
          <Segment>
            <Card.Group itemsPerRow="3">
              <Card>
                <Card.Content>
                  <Card.Header>Total Amount (£): {this.state.totalAmount}</Card.Header>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>Total Fulfilled (£): {this.state.totalFulfilled}</Card.Header>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>Total Liability (£): {this.state.totalLiability}</Card.Header>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>Total Amount ($): {this.state.totalUSAmount}</Card.Header>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>Total Fulfilled ($): {this.state.totalUSFulfilled}</Card.Header>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>Total Liability ($): {this.state.totalUSLiability}</Card.Header>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>Total Amount (€): {this.state.totalEUAmount}</Card.Header>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>Total Fulfilled (€): {this.state.totalEUFulfilled}</Card.Header>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>Total Liability (€): {this.state.totalEULiability}</Card.Header>
                </Card.Content>
              </Card>
            </Card.Group>
          </Segment>
        )
      }
    }

    let paymentContent;

    if (loading) {
      paymentContent = <Spinner />;
    } else {
      paymentContent = (
        <React.Fragment>
          <Segment>
            <Header as="h2" textalign='center'>Sales Ledger</Header>

            {tableContent}
          </Segment>
          <Segment>
            {totalsContent}
          </Segment>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        {paymentContent}
      </React.Fragment>
    );
  }
}

Payments.propTypes = {
  getTotalOrders: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors,
  order: state.order
});

export default connect(mapStateToProps, { getTotalOrders })(withRouter(Payments));
