import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Button, Header, Input, Form } from 'semantic-ui-react';
import RenderFieldRedux from '../../common/ReduxForm/RenderFieldRedux';
import axios from 'axios';
import asyncValidate from './functions/asyncValidate';
import getCountries from '../../common/Countries';

class BillingInfo extends React.Component {
  state = {
    showBillingPCLookup: false,
    displayBillingPostcodes: false,
    displayAddress: false,
    country: '',
    tempCountryText: '',
    billingPCLookup: '',
    billingPostcodes: null,
    billingPostcode: ''
  }

  // Country Dropdown Change Handler
  handleChange = (e, { value, text }) => {
    e.persist();
    this.props.change("country", value);

    switch (value) {
      case "GB":
        return this.setState({
          showBillingPCLookup: true,
          displayAddress: false
        });
      case "US":
      case "PR":
      case "EC":
      case "SV":
      case "ZW":
      case "GU":
      case "VI":
      case "TL":
      case "WS":
      case "MP":
      case "FM":
      case "PW":
      case "MH":
        return this.setState({
          showBillingPCLookup: false,
          displayAddress: true
        });
      case "AD":
      case "AT":
      case "BE":
      case "CY":
      case "EE":
      case "FR":
      case "FI":
      case "DE":
      case "GR":
      case "IE":
      case "IT":
      case "LV":
      case "LT":
      case "LU":
      case "MT":
      case "MC":
      case "NL":
      case "PT":
      case "SM":
      case "SK":
      case "SI":
      case "ES":
        return this.setState({
          showBillingPCLookup: false,
          displayAddress: true
        });
      default:
        return this.setState({
          showBillingPCLookup: false,
          displayAddress: true
        });

    }
  }

  // Generic Change Handler
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

  };

  billingLookup = (e) => {
    e.preventDefault();
    axios
      .get(`/api/thirdparty/postcodelookup/${this.state.billingPCLookup}` + '?api-key=uz-hsvkS8kimWsfmj3znMg10941')
      .then(res => {
        var arrayLength = res.data.addresses.length;
        const newArray = [];
        for (var i = 0; i < arrayLength; i++) {
          newArray.push({ "items": i, "text": res.data.addresses[i].split(','), "value": res.data.addresses[i] });
        }

        this.setState({ billingPostcodes: newArray });
      })
      .catch(err => console.log(err)
      );
  }


  // Billing Postcode Dropdown Change Handler
  handleBillingChange = (e, { value, text }) => {
    e.persist();
    const address = value.split(",").map(item => item.trim());

    var filtered = address.filter(function (el) {
      return el != null;
    });
    if (address[0].includes("Limited") || address[0].includes("Ltd") || address[0].includes("Plc") || address[0].includes("LLP")) {
      this.props.change("companyName", address[0]);
      this.props.change("addressOne", address[1]);
      this.props.change("addressTwo", address[2]);
      this.props.change("addressThree", address[3]);
      this.props.change("addressFour", address[5]);
    } else {


      this.props.change("addressOne", address[0]);
      this.props.change("addressTwo", address[1]);
      this.props.change("addressThree", address[2]);
      this.props.change("addressFour", address[5]);
    }
    this.props.change("postZip", this.state.billingPCLookup);
    this.setState({ displayAddress: true })
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    const billingOptions = this.state.billingPostcodes;
    const showBillingPCLookup = this.state.showBillingPCLookup;
    let billingPCLookupDisplay = '';
    let billingPCLookup = '';
    let billingPostcodesDisplay;

    const countryOptions = getCountries();
    if (showBillingPCLookup) {
      billingPCLookupDisplay = (
        <div style={{ margin: "10px" }}>
          <React.Fragment>
            <Input
              label={{ basic: true, content: 'Postcode Lookup' }}
              action={{ color: 'black', labelPosition: 'right', icon: 'search', content: 'Lookup', onClick: this.billingLookup }}
              name="billingPCLookup"
              value={this.state.billingPCLookup}
              onChange={this.onChange}
              fluid>
            </Input>
            <br />
          </React.Fragment>
        </div>
      );
    } else {
      billingPCLookup = null;
    }

    if (billingOptions !== null) {
      billingPostcodesDisplay = (
        <div style={{ margin: "10px" }}>
          <React.Fragment>
            <Form.Select
              options={billingOptions}
              onChange={this.handleBillingChange}
              placeholder="Select Your Address"
              fluid
              size="large"
              selection
              name="billingPostcode"
              value={this.state.billingPostcode}
            /><br />
          </React.Fragment>
        </div>
      )
    } else {
      billingPostcodesDisplay = null;
    }

    let addressDisplay = '';

    if (this.state.displayAddress) {
      addressDisplay = (
        <React.Fragment>
          <Field
            name="companyName"
            component={RenderFieldRedux}
            type="text"
            label="Company Name"
          />
          <Field
            name="addressOne"
            component={RenderFieldRedux}
            type="text"
            label="Address Line 1"
          />
          <Field
            name="addressTwo"
            component={RenderFieldRedux}
            type="text"
            label="Address Line 2"
          />
          <Field
            name="addressThree"
            component={RenderFieldRedux}
            type="text"
            label="Address Line 3"
          />
          <Field
            name="addressFour"
            component={RenderFieldRedux}
            type="text"
            label="Address Line 4"
          />
          <Field
            name="postZip"
            component={RenderFieldRedux}
            type="text"
            label="Post/Zip Code"
          />
          <Field
            name="country"
            component={RenderFieldRedux}
            type="text"
            label="Country Code"
          />
          <Button type='button' color='blue' onClick={previousPage}>Previous</Button>
          <Button color='blue'>
            Next
            </Button>
        </React.Fragment>

      )
    }

    return (
      <form onSubmit={handleSubmit} size='large'>
        <Header>
          Please select your Country and then fill in all the fields below and click Next to continue
        </Header>
        <div style={{ margin: "10px" }}>
          <Form.Select {...Field.Input}
            options={countryOptions}
            onChange={this.handleChange}
            placeholder="Your Country"
            fluid
            size="large"
            selection
            name="country"
            value={this.props.country}
          />
        </div>


        {billingPCLookupDisplay}
        {billingPostcodesDisplay}
        {addressDisplay}
      </form>
    )
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.firstName) {
    errors.firstName = 'Required'
  }

  if (!formValues.lastName) {
    errors.lastName = 'Required'
  }

  if (!formValues.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
    errors.email = 'Invalid email address'
  }

  if (!formValues.companyName) {
    errors.companyName = 'Required'
  }

  if (!formValues.password) {
    errors.password = 'Required'
  } else if (formValues.password.length < 6) {
    errors.password = 'Must be greater than 6 characters'
  }

  if (!formValues.password2) {
    errors.password2 = 'Required'
  } else if (formValues.password2 !== formValues.password) {
    errors.password2 = 'Passwords must match'
  }

  return errors;
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  asyncValidate,
  asyncBlurFields: ['email']
})(BillingInfo);
