import axios from 'axios';
import { toastr } from 'react-redux-toastr'
import { GET_ACCOUNT, ACCOUNT_LOADING, CLEAR_CURRENT_ACCOUNT, UPDATE_ACCOUNT, GET_ERRORS, GET_PAYPAL, GET_ACCOUNT_TEL, CLEAR_ERRORS } from './types';

// Get account
export const getAccount = () => dispatch => {
  dispatch(clearErrors());
  dispatch(setAccountLoading());
  axios.get('/api/users')
    .then(res =>
      dispatch({
        type: GET_ACCOUNT,
        payload: res.data
      })
    )
    .catch(err =>
      // On the off chance an empty profile is returned
      dispatch({
        type: GET_ACCOUNT,
        payload: {}
      }))
}

// Get account
export const getAccountTel = (id) => dispatch => {
  axios.get(`/api/users/storefront/${id}`)
    .then(res =>
      dispatch({
        type: GET_ACCOUNT_TEL,
        payload: res.data
      })
    )
    .catch(err =>
      // On the off chance an empty profile is returned
      dispatch({
        type: GET_ACCOUNT_TEL,
        payload: {}
      }))
}

// Get Paypal details
export const getPayPal = () => dispatch => {
  axios.get("/api/account/paypal/1")
    .then(res =>
      dispatch({
        type: GET_PAYPAL,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PAYPAL,
        payload: {}
      }))
}

// Account loading
export const setAccountLoading = () => {
  return {
    type: ACCOUNT_LOADING
  }
}

// Clear Account
export const clearAccount = () => {
  return {
    type: CLEAR_CURRENT_ACCOUNT
  }
}

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

// Update Account
export const updateAccount = (accountData, history) => dispatch => {
  axios
    .post('/api/users/', accountData)
    .then(res => {
      toastr.success('Account successfully updated');
      dispatch({
        type: UPDATE_ACCOUNT,
        payload: res.data
      });
      history.push('/dashboard')
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

