import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Header, Grid } from "semantic-ui-react";
import {
  getAllSubs,
  getAllSubsBundle,
  getAllSubsDigital,
  getAllSubsPrint,
  getAllOrders,
  getAllAllSubs,
  getAllAllOrders,
  getAllAllSubsBundle,
  getAllAllSubsPrint,
  getAllAllSubsDigital,
} from "../../../actions/analyticActions";
import { getExpiredCount } from "../../../actions/readerActions";

class OverallStats extends Component {
  state = {
    pubId: null,
    publicationOptions: [],
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.pubid) {
      if (nextProps.pubid !== this.state.pubId) {
        if (nextProps.pubid === "all") {
          this.props.getAllAllSubs(nextProps.pubid);
          this.props.getAllAllSubsPrint(nextProps.pubid);
          this.props.getAllAllSubsDigital(nextProps.pubid);
          this.props.getAllAllSubsBundle(nextProps.pubid);
          this.props.getAllAllOrders(nextProps.pubid);
        } else {
          this.props.getAllSubs(nextProps.pubid);
          this.props.getAllSubsPrint(nextProps.pubid);
          this.props.getAllSubsDigital(nextProps.pubid);
          this.props.getAllSubsBundle(nextProps.pubid);
          this.props.getAllOrders(nextProps.pubid);
          this.props.getExpiredCount(nextProps.pubid);
        }
        this.setState({ pubId: nextProps.pubid });
      }
    }
  }

  render() {
    let currencyIcon;
    switch (this.props.auth.user.mainCurrency) {
      case "GBP":
        currencyIcon = "£";
        break;
      case "USD":
        currencyIcon = "$";
        break;
      case "EUR":
        currencyIcon = "€";
        break;
      default:
        currencyIcon = "£";
        break;
    }

    let overallStatsContent;
    if (this.props.pubid === null) {
      overallStatsContent = null;
    } else {
      overallStatsContent = (
        <Grid.Row>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {this.props.analytics.allSubs}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {this.props.reader.totalExpiredCount}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {this.props.analytics.allSubsPrint}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {this.props.analytics.allSubsDigital}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {this.props.analytics.allSubsBundle}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p style={{ textAlign: "center" }}>
              {currencyIcon}
              {Number.isInteger(this.props.analytics.allOrders)
                ? this.props.analytics.allOrders.toFixed(2)
                : ""}
            </p>
          </Grid.Column>
        </Grid.Row>
      );
    }

    return (
      <Segment>
        <Header textAlign="center">Current</Header>
        <Grid columns={6}>
          <Grid.Row>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>Current Subscribers</p>
            </Grid.Column>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>
                Expirations
                <br />
              </p>
            </Grid.Column>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>
                Current Print Subs
                <br />
              </p>
            </Grid.Column>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>
                Current Digital Subs
                <br />
              </p>
            </Grid.Column>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>
                Current Bundle Subs
                <br />
              </p>
            </Grid.Column>
            <Grid.Column>
              <p style={{ textAlign: "center" }}>
                Total Overall Revenue
                <br />
              </p>
            </Grid.Column>
          </Grid.Row>
          {overallStatsContent}
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  analytics: state.analytics,
  reader: state.reader,
});

export default connect(mapStateToProps, {
  getAllSubs,
  getAllSubsBundle,
  getAllSubsDigital,
  getAllSubsPrint,
  getAllOrders,
  getAllAllSubs,
  getAllAllSubsBundle,
  getAllAllSubsDigital,
  getAllAllSubsPrint,
  getExpiredCount,
  getAllAllOrders,
})(OverallStats);
