import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  Button,
  Header,
  Form,
  Checkbox,
  Grid,
  Label,
  Segment,
} from 'semantic-ui-react';
import RenderInlineFieldRedux from '../../../common/ReduxForm/RenderInlineFieldRedux';

const PricingHeader = () => (
  <Form.Group widths='equal' style={{ marginBottom: -10 }}>
    <Label
      color='violet'
      ribbon
      style={{
        paddingLeft: '5px',
        paddingRight: '5px',
        marginRight: '-2em',
        visibility: 'hidden',
      }}
    >
      Sub price -<br />
      one year
    </Label>
    <div className='field'>UK Sales</div>
    <div className='field'>USA &amp; ROW</div>
    <div className='field'>Europe Sales</div>
  </Form.Group>
);

class PricingInfo extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      previousPage,
      subFree,
      printSubFree,
      printDigSubFree,
    } = this.props;
    let digitalPrices, printPrices, bundlePrices;

    if (!subFree) {
      digitalPrices = (
        <Fragment>
          <Header as='h2' fluid>
            Subscription e-commerce
          </Header>
          <PricingHeader />
          <Form.Group widths='equal'>
            <Label
              color='violet'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />
              one year
            </Label>
            <Field
              name='priceOneYear'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='priceOneYearUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='priceOneYearEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='violet'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />6 months
            </Label>
            <Field
              name='priceSixMonth'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='priceSixMonthUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='priceSixMonthEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='violet'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />3 months
            </Label>
            <Field
              name='priceThreeMonth'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='priceThreeMonthUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='priceThreeMonthEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='violet'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />1 month
            </Label>
            <Field
              name='price'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='priceUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='priceEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          {/* <Header as='h2' fluid>
            Auto Renewable Subscriptions
          </Header>

          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='violet'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />
              one year
            </Label>
            <Field
              name='priceOneYearRecurring'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='priceOneYearUSRecurring'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='priceOneYearEURecurring'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='violet'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />6 months
            </Label>
            <Field
              name='priceSixMonthRecurring'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='priceSixMonthUSRecurring'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='priceSixMonthEURecurring'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='violet'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />3 months
            </Label>
            <Field
              name='priceThreeMonthRecurring'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='priceThreeMonthUSRecurring'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='priceThreeMonthEURecurring'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group> */}
        </Fragment>
      );
    }

    if (!printSubFree) {
      printPrices = (
        <Fragment>
          <Header as='h2' fluid>
            Subscription e-commerce
          </Header>
          <PricingHeader />
          <Form.Group widths='equal'>
            <Label
              color='teal'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />
              one year
            </Label>
            <Field
              name='printPriceOneYear'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printPriceOneYearUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printPriceOneYearEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='teal'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />6 months
            </Label>
            <Field
              name='printPriceSixMonth'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printPriceSixMonthUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printPriceSixMonthEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='teal'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />3 months
            </Label>
            <Field
              name='printPriceThreeMonth'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printPriceThreeMonthUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printPriceThreeMonthEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='teal'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />1 month
            </Label>
            <Field
              name='printPrice'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printPriceUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printPriceEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          {/* <Header as='h2' fluid>
            Auto Renewable Subscriptions
          </Header>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='teal'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />
              one year
            </Label>
            <Field
              name='printPriceOneYearRecurring'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printPriceOneYearUSRecurring'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printPriceOneYearEURecurring'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='teal'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />6 months
            </Label>
            <Field
              name='printPriceSixMonthRecurring'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printPriceSixMonthUSRecurring'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printPriceSixMonthEURecurring'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='teal'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />3 months
            </Label>
            <Field
              name='printPriceThreeMonthRecurring'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printPriceThreeMonthUSRecurring'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printPriceThreeMonthEURecurring'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group> */}
        </Fragment>
      );
    }

    if (!printDigSubFree) {
      bundlePrices = (
        <Fragment>
          <Header as='h2' fluid>
            Subscription e-commerce
          </Header>
          <PricingHeader />
          <Form.Group widths='equal'>
            <Label
              color='green'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />
              one year
            </Label>
            <Field
              name='printDigPriceOneYear'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceOneYearUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceOneYearEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='green'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />6 months
            </Label>
            <Field
              name='printDigPriceSixMonth'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceSixMonthUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceSixMonthEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='green'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />3 months
            </Label>
            <Field
              name='printDigPriceThreeMonth'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceThreeMonthUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceThreeMonthEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='green'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />1 month
            </Label>
            <Field
              name='printDigPrice'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceUS'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceEU'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          {/* <Header as='h2' fluid>
            Auto Renewable Subscriptions
          </Header>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='green'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />
              one year
            </Label>
            <Field
              name='printDigPriceOneYearRecurring'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceOneYearUSRecurring'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceOneYearEURecurring'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='green'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />6 months
            </Label>
            <Field
              name='printDigPriceSixMonthRecurring'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceSixMonthUSRecurring'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceSixMonthEURecurring'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group>
          <Form.Group widths='equal' style={{ marginTop: '20px' }}>
            <Label
              color='green'
              ribbon
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '-2em',
              }}
            >
              Sub price -<br />3 months
            </Label>
            <Field
              name='printDigPriceThreeMonthRecurring'
              component={RenderInlineFieldRedux}
              icon='pound sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceThreeMonthUSRecurring'
              component={RenderInlineFieldRedux}
              icon='dollar sign'
              iconPosition='left'
            />
            <Field
              name='printDigPriceThreeMonthEURecurring'
              component={RenderInlineFieldRedux}
              icon='euro sign'
              iconPosition='left'
            />
          </Form.Group> */}
        </Fragment>
      );
    }

    return (
      <Form onSubmit={handleSubmit} size='large'>
        <Segment fluid='true'>
          <h4>
            Please note, in order to have paid for content, you must set up
            payment information in your <Link to='/account'>"My Account"</Link>{' '}
            area.
            <br />
            <br />
            Also, in order to set up subscriptions e-commerce, you must have an
            active{' '}
            <a
              href='https://stripe.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              Stripe
            </a>{' '}
            account setup.
          </h4>
        </Segment>

        <Grid stackable columns={3}>
          <Grid.Column textAlign='center'>
            <Segment>
              <Header>Digital</Header>
              <Field
                component={Checkbox}
                toggle
                name='freeDigital'
                label='Toggle for free subs'
                checked={subFree}
                onClick={() => {
                  this.props.change('subFree', !subFree);
                }}
              />
              {digitalPrices}
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Segment>
              <Header>Print</Header>
              <Checkbox
                toggle
                name='freePrint'
                label='Toggle for free subs'
                checked={printSubFree}
                onClick={() => {
                  this.props.change('printSubFree', !printSubFree);
                }}
              />
              {printPrices}
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Segment>
              <Header>Bundle Print &amp; Digital</Header>
              <Checkbox
                toggle
                name='freeBundle'
                label='Toggle for free subs'
                checked={printDigSubFree}
                onClick={() => {
                  this.props.change('printDigSubFree', !printDigSubFree);
                }}
              />
              {bundlePrices}
            </Segment>
          </Grid.Column>
        </Grid>
        <Segment>
          <div style={{ margin: '10px', textAlign: 'center' }}>
            <Button color='blue' onClick={previousPage}>
              Previous
            </Button>
            <Button color='blue' disabled={submitting}>
              Next
            </Button>
          </div>
        </Segment>
      </Form>
    );
  }
}

const selector = formValueSelector('wizard');

const mapStateToProps = (state) => {
  return {
    subFree: selector(state, 'subFree'),
    printSubFree: selector(state, 'printSubFree'),
    printDigSubFree: selector(state, 'printDigSubFree'),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'wizard', //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(PricingInfo),
);
