import {
  CLEAR_ERRORS,
  CLEAR_PUB_DATA,
  GET_ERRORS,
  CHECK_NUM_PUBS,
  LOADING,
  GET_BACKISSUES,
  GET_BACKISSUE,
  CREATE_BACKISSUE,
  UPDATE_BACKISSUE,
  REMOVE_BACKISSUE,
} from "../actions/types";

// Create an initial state for the model
const initialState = {
  backIssues: null,
  backIssue: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_BACKISSUE:
      return { ...state, backIssues: action.payload, loading: false };
    case GET_BACKISSUES:
      return {
        ...state,
        backIssues: action.payload,
        loading: false,
      };
    case GET_BACKISSUE:
      return {
        ...state,
        backIssue: action.payload,
        loading: false,
      };
    case UPDATE_BACKISSUE:
      return {
        ...state,
        backIssue: action.payload,
        loading: false,
      };
    case REMOVE_BACKISSUE:
      return {
        ...state,
        backIssues: state.backIssues.filter((bi) => bi._id !== action.payload),
        loading: false,
      };
    default:
      return state;
  }
}
